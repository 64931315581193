export default {
  experienceInviteGuest: {
    title: 'Spune-ne cine se va alătura ție!',
    description:
      'Introdu adresa de email a persoanei cu care intenționezi să împărtășești această Experiență.' +
      '\n\n' +
      'Aceasta va primi toți pașii următori pe adresa furnizată.',
    input: {
      placeholder: 'Introdu adresa de email...',
    },
    button: 'Trimite',
    info: {
      title: 'Invită un prieten să se alăture gratuit',
      description:
        'Împărtășește această Experiență cu o altă persoană fără costuri suplimentare - dar cu mai multă inspirație!',
      additionalInformation:
        'După ce achiziționezi această Experiență, vei primi un email prin care poți să completezi detaliile lor și să-i anunți despre surpriza ta!',
    },
  },
};
