export default {
  stripeRegistration: {
    button: {
      yes: 'Da',
      no: 'Nu',
    },
    registerForm: {
      title: 'Înregistrează-ți contul Stripe',
      errors: {
        birthDate: 'Data nașterii trebuie să fie înainte de azi',
        short: 'Prea Scurt!',
        long: 'Prea Lung!',
      },
      form: {
        firstName: 'Prenume*',
        lastName: 'Nume*',
        email: 'E-mail*',
        mobile: 'Mobil*',
        address: 'Adresă*',
        city: 'Oraș*',
        postalCode: 'Cod Poștal*',
        dateOfBirth: 'Data Nașterii*',
        iban: 'IBAN*',
        mcc: 'MCC',
        website: 'Site Web',
      },
    },
    registerBusinessForm: {
      title: 'Înregistrează-ți contul Stripe',
      businessForm: {
        companyName: 'Nume Companie*',
        companyCountry: 'Țara Companiei*',
        companyCity: 'Orașul Companiei*',
        companyAddress: 'Adresa Companiei*',
        companyState: 'Județul Companiei',
        companyPostalCode: 'Codul Poștal al Companiei*',
        cif: 'CIF*',
        regCom: 'Reg. Com.*',
        companyBankName: 'Numele Băncii*',
        companyBankIban: 'IBAN*',
        allowExmindsIssueInvoiceOnBehalfOfCompany:
          'Permite Exminds să trimită facturi Explorerilor din partea companiei tale',
      },
    },
    index: {
      title: 'Cum folosești Exminds?',
      description:
        'Aceste informații vor fi necesare dacă dorești să faci tranzacții în aplicație',
      companyUpgrade:
        'Dacă dorești să faci upgrade de la Individual la Companie trebuie să creezi un cont nou',
    },
    confirmationModal: {
      title: 'Ești sigur?',
      description:
        'Dacă în viitor vei dori să schimbi aceste detalii va fi necesară înregistrarea unui nou cont pe Stripe Connect.',
    },
    registerSuccess: {
      title: 'Ți-ai înregistrat contul fiscal',
      description: 'Acum poți face Experiența activă pentru Exploreri',
      confirmDescription:
        'Confirmarea contului tău Stripe este ultimul pas pentru a activa Experiența pentru Exploreri.',
      confirmStripeAccount: 'Confirmă Contul Stripe',
      seeExperiences: 'Vezi Experiențele',
    },
    entities: {
      individual: 'Individual',
      individualDescription: 'Folosesc Exminds ca persoană fizică',
      company: 'Companie',
      companyDescription: 'Folosesc Exminds ca persoană juridică',
    },
  },
};
