const formatNumberByLocale = (number: number, decimals = 2): string => {
  const locale = 'ro-RO';

  if (Math.floor(number) !== number) {
    return number.toLocaleString(locale, {
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    });
  }
  return number.toLocaleString(locale);
};

export default formatNumberByLocale;
