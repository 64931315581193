import styled from 'styled-components';
import { zIndex } from '../../../../helpers/constants/zIndexValues';

export const Container = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: ${zIndex.menuBar};
  width: 100%;
  min-width: 320px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  background: ${({ theme }) => theme.palette.main.white};
  border-radius: 15px 15px 0 0;
  padding: 15px 0 15px 0;
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const MainContent = styled.div`
  width: 100%;
  display: flex;
`;

export const ItemsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin: auto;
  gap: 10px;
  @media only screen and (max-width: 410px) {
    width: 100%;
    gap: 5px;
  }
`;

export const NotificationBadgeContainer = styled.div`
  position: absolute;
  right: -4px;
  top: -2px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
