export enum ExperienceCardType {
  Regular = 'regular',
  Auction = 'auction',
  Permanent = 'permanent',
}

export enum Role {
  Exminder = 'exminder',
  Explorer = 'explorer',
}

export enum ExperienceStatus {
  Draft = 'draft',
  Active = 'active',
  Upcoming = 'upcoming',
  Progress = 'in_progress',
  Completed = 'completed',
  Expired = 'expired',
  Canceled = 'canceled',
  ShadowBanned = 'shadow_banned',
}
export enum RequestStatus {
  Pending = 'pending',
  Approved = 'accepted',
  Rejected = 'rejected',
  AutomaticallyRejected = 'automatically_rejected',
}
export enum ExplorerExperienceStatus {
  Upcoming = 'upcoming',
  Auction = 'auction',
  Past = 'completed',
  Requested = 'requested',
}
