export default {
  experienceInviteGuest: {
    title: 'Tell us who is going to join you!',
    description:
      'Type in the email address of the person you intend to share this Experience with.' +
      '\n\n' +
      'They will receive all the future steps on the provided address.',
    input: {
      placeholder: 'Type the email address...',
    },
    button: 'Send',
    info: {
      title: 'Invite a friend to join for free',
      description:
        'Share this Experience with another person at no extra cost - but more inspiration!',
      additionalInformation:
        'After you buy this Experience you’ll receive an email where you can set their details and let them know about your surprise!',
    },
  },
};
