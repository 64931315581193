export default {
  benefitsRequirementsLayout: {
    benefitLayout: {
      title: 'Beneficii',
      description:
        'Înainte de a participa la Experiență, este nevoie ca Explorerii să înțeleagă care sunt motivele pentru care să îți aleagă Experiența.\n\nPentru o Experiență optimă, include cel puțin un Beneficiu.',
      titlePlaceholder: 'Adaugă titlul beneficiului',
      descriptionPlaceholder: 'Descrie beneficiul',
    },
    requirementLayout: {
      title: 'Criterii',
      description:
        'Înainte de a participa la Experiență, este nevoie ca Explorerii să îndeplinească cerințele esențiale setate de tine. Ai dreptul de a refuza participarea celor care nu îndeplinesc criteriile.\n\nPentru o Experiență optimă, include cel puțin un Criteriu.',
      titlePlaceholder: 'Adaugă titlul criteriului',
      descriptionPlaceholder: 'Descrie criteriul',
    },
    benefitsRequirementsPage: {
      benefitsTitle: 'Super!\n\nUrmează să adăugăm beneficiile Experienței',
      requirementsTitle: 'Super!\n\nUrmează să adăugăm cerințele Experienței',
      onCompleteTitle: 'Experiența ta este completă și pregătită de publicare',
      onCompleteSubtitle: 'Succes în găsirea unor Exploreri grozavi!',
    },
    delete: {
      areYouSureDelete: 'Ești sigur că vrei să ștergi acest lucru?',
    },
    next: 'Înainte',
    edit: 'Editează',
    back: 'Înapoi',
  },
};
