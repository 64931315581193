import { useQuery } from '@tanstack/react-query';
import { ApiResponseGetLanguages, languageRequest } from 'api/LanguageRequest';

function useGetLanguages(enabled?: boolean) {
  const QUERY_KEY = ['Languages'];
  return useQuery<ApiResponseGetLanguages[], Error>({
    queryKey: QUERY_KEY,
    queryFn: () => languageRequest.getLanguages(),
    enabled,
  });
}

export default useGetLanguages;
