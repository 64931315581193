import { useTranslation } from 'react-i18next';
import InProgressIcon from 'assets/icons/experience/InProgressIcon';
import UpcomingIcon from 'assets/icons/experience/UpcomingIcon';
import ApprovedIcon from 'assets/icons/experience/requests/ApprovedIcon';
import DeclinedIcon from 'assets/icons/experience/requests/DeclinedIcon';
import ExperienceCanceledIcon from 'assets/icons/experienceActions/ExperienceCanceledIcon';
import ExperienceCompletedIcon from 'assets/icons/experienceActions/ExperienceCompletedIcon';
import ExperienceDraftIcon from 'assets/icons/experienceActions/ExperienceDraftIcon';
import ExperienceExpiredIcon from 'assets/icons/experienceActions/ExperienceExpiredIcon';
import ExperienceShadowBanIcon from 'assets/icons/experienceActions/ExperienceShadowBanIcon';
import { ReactElement } from 'react';
import { ExperienceStatus, RequestStatus } from 'uiModels/enums';
import * as SC from './styles';

interface StatusMapping {
  Icon?: ReactElement;
  textKey?: string;
}

const statusMappings: Record<RequestStatus | ExperienceStatus, StatusMapping> =
  {
    [RequestStatus.Pending]: {
      Icon: <UpcomingIcon />,
      textKey: 'experience.inProgress',
    },
    [RequestStatus.Approved]: {
      Icon: <ApprovedIcon />,
      textKey: 'experience.request.approved',
    },
    [RequestStatus.Rejected]: {
      Icon: <DeclinedIcon />,
      textKey: 'experience.request.declined',
    },
    [RequestStatus.AutomaticallyRejected]: {
      Icon: <DeclinedIcon />,
      textKey: 'experience.request.declined',
    },
    [ExperienceStatus.Progress]: {
      Icon: <InProgressIcon />,
      textKey: 'experience.inProgress',
    },
    [ExperienceStatus.Upcoming]: {
      Icon: <UpcomingIcon />,
      textKey: 'experience.upcoming',
    },
    [ExperienceStatus.Draft]: {
      Icon: <ExperienceDraftIcon />,
      textKey: 'experience.status.draft',
    },
    [ExperienceStatus.Completed]: {
      Icon: <ExperienceCompletedIcon />,
      textKey: 'experience.status.completed',
    },
    [ExperienceStatus.Expired]: {
      Icon: <ExperienceExpiredIcon />,
      textKey: 'experience.status.expired',
    },
    [ExperienceStatus.Canceled]: {
      Icon: <ExperienceCanceledIcon />,
      textKey: 'experience.status.canceled',
    },
    [ExperienceStatus.ShadowBanned]: {
      Icon: <ExperienceShadowBanIcon />,
      textKey: 'experience.status.shadowBan',
    },
    [ExperienceStatus.Active]: {},
  };
interface ExperienceOverlayProps {
  currentStatus: RequestStatus | ExperienceStatus;
}

export default function ExperienceOverlay({
  currentStatus,
}: ExperienceOverlayProps) {
  const { t } = useTranslation();
  const { Icon, textKey } = statusMappings[currentStatus] || {};

  if (!Icon || !textKey) {
    return null;
  }

  return (
    <SC.StatusOverlay>
      <SC.OverlayStatusIconContainer>{Icon}</SC.OverlayStatusIconContainer>
      <SC.OverlayStatusText>{t(textKey)}</SC.OverlayStatusText>
    </SC.StatusOverlay>
  );
}
