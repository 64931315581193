// @ts-strict-ignore
import classic from '../../styles/theme/classic';
import TypeChecker from '../classes/TypeChecker';

export default function pxToRem(px: number | string): string {
  const baseUnits: string = classic.typography.fontSize;

  if (!px) return baseUnits;

  let pxUnits: number;

  if (TypeChecker.isString(px)) {
    pxUnits = +px.split('px')[0];
    if (!Number.isFinite(pxUnits)) {
      throw new Error('Invalid px input param!');
    }
  } else {
    pxUnits = px;
  }

  const parsedBaseUnits: number = parseFloat(baseUnits);

  const documentFontSizeInPx: number = parseFloat(
    getComputedStyle(document.documentElement).fontSize
  );

  return `${
    ((documentFontSizeInPx + pxUnits) / documentFontSizeInPx) * parsedBaseUnits
  }rem`;
}
