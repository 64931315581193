export default {
  experienceFeed: {
    searchExperiencesPeople: 'Caută Experiențe, persoane...',
    emptyState: {
      title:
        'Nicio Experiență aici încă, stai pe aproape pentru cele noi ce vor apărea.',
      subtitle: 'Nu fi trist(ă)!',
      subtitleExminder: 'Creează o nouă Experiență și împărtășește-o cu lumea!',
      subtitleExplorer:
        'Întotdeauna poți deveni un Exminder sau să revii mai târziu!',
      buttonCreateExperience: 'Creează Experiență',
      buttonCreateAuction: 'Creează Licitatie',
      buttonTurnOnNotification: 'Activează notificările',
    },
  },
};
