export default {
  invoices: {
    title: 'Permisiuni',
    description:
      'Autorizează aplicația Exminds să emită automat facturi către Exploreri în numele meu',
    searchbar: {
      placeholder: 'Tastează un cuvânt cheie',
    },
    dropdown: {
      placeholder: 'Filtrează după un interval de timp',
      all: 'Toate',
      today: 'Astăzi',
      last7days: 'Ultimele 7 zile',
      last30days: 'Ultimele 30 zile',
      last90days: 'Ultimele 90 zile',
      lastYear: 'Anul trecut',
    },
    empty: 'Nu există nicio factură emisă în perioada selectată',
    card: {
      from: 'De la',
    },
  },
};
