export default {
  afterExperience: {
    experienceHasEnded: 'Your Experience has ended!',
    wereYouHappy: 'Were you happy with your Experience?',
    no: 'NO!',
    yes: 'YES!',
    somethingWentWrong: 'Something went wrong!',
    itWasAsExpected: 'It was as expected!',
    tellUsMore: 'Tell us more!',
    whatWentWrong: 'What went wrong during your Experience?',
    optional: 'Optional*',
    receivedMessage: 'We’ve received your message!',
    thankYouForMessage: 'Thank you for your message!',
    assistantsWillRespond:
      'One of our assistants will respond to your request within 3 days.',
    weAreGlad: 'We’re glad your Experience went great!',
    tellUseMoreAbout:
      'Tell us more about the Explorer/Exminder and how everything went!',
    leaveAReview: 'Leave a review',
  },
};
