export default {
  milestoneModal: {
    title: 'ADAUGĂ REPER',
    titleEdit: 'EDITEAZĂ REPER',
    subtitle: '*Câmp obligatoriu',
    form: {
      name: 'Nume*',
      displayName: 'Nume afișat*',
      description: 'Descriere*',
      date: 'Data*',
      link: 'Link*',
      presentationPhoto: 'Fotografie de prezentare*',
    },
    view: {
      name: 'Nume',
      displayName: 'Nume afișat',
      description: 'Descriere',
      date: 'Data',
      link: 'Link',
      presentationPhoto: 'Fotografie de prezentare',
    },
    placeholder: {
      name: 'Ex: Puterea Prezentului',
      textOnProfile: 'Ex: Am publicat o carte',
      time: 'Ex: Iunie 2020',
      description: 'Scrie aici maximum 500 de caractere',
      link: 'Ex: link direct pentru a cumpăra de pe Amazon',
    },
    button: {
      exit: 'Ieșire',
      done: 'Gata',
    },
  },
};
