import { JSX, SVGProps } from 'react';

export default function ApprovedIcon(
  props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>
) {
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.5465 21.0375C13.6999 21.1922 13.8824 21.3149 14.0834 21.3987C14.2845 21.4824 14.5002 21.5256 14.718 21.5256C14.9358 21.5256 15.1515 21.4824 15.3526 21.3987C15.5536 21.3149 15.7361 21.1922 15.8895 21.0375L22.6215 14.3055C22.9322 13.9948 23.1068 13.5734 23.1068 13.134C23.1068 12.6946 22.9322 12.2732 22.6215 11.9625C22.3108 11.6518 21.8894 11.4772 21.45 11.4772C21.0106 11.4772 20.5892 11.6518 20.2785 11.9625L14.718 17.5395L12.7215 15.5265C12.4108 15.2158 11.9894 15.0412 11.55 15.0412C11.1106 15.0412 10.6892 15.2158 10.3785 15.5265C10.0678 15.8372 9.89325 16.2586 9.89325 16.698C9.89325 17.1374 10.0678 17.5588 10.3785 17.8695L13.5465 21.0375ZM31.35 0H1.65C1.21239 0 0.792709 0.173839 0.483274 0.483274C0.173839 0.792709 0 1.21239 0 1.65V31.35C0 31.7876 0.173839 32.2073 0.483274 32.5167C0.792709 32.8262 1.21239 33 1.65 33H31.35C31.7876 33 32.2073 32.8262 32.5167 32.5167C32.8262 32.2073 33 31.7876 33 31.35V1.65C33 1.21239 32.8262 0.792709 32.5167 0.483274C32.2073 0.173839 31.7876 0 31.35 0ZM29.7 29.7H3.3V3.3H29.7V29.7Z"
        fill="white"
      />
    </svg>
  );
}
