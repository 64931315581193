import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from './redux';
import { isAuthUserProfilePicCompressing } from '../redux/slices/selectors';
import { me } from '../redux/slices/auth';

const maxTimeout = 10000;
const initialTimeout = 500;
let timeout = 500;

let recursiveCheckInProgress = false;

function useCheckIfAuthUserProfilePicIsCompressing() {
  const dispatch = useAppDispatch();
  const isCompressing = useAppSelector(isAuthUserProfilePicCompressing);

  useEffect(() => {
    if (isCompressing && !recursiveCheckInProgress) {
      recursiveCheckInProgress = true;

      const recursiveDispatch = () => {
        dispatch(me())
          .unwrap()
          .then((user) => {
            const shouldTrigger = user.profilePicture.extension !== '.webp';

            if (shouldTrigger) {
              setTimeout(
                recursiveDispatch,
                Math.min(maxTimeout, (timeout += timeout))
              );
            } else {
              recursiveCheckInProgress = false;
              timeout = initialTimeout;
            }
          });
      };

      recursiveDispatch();
    }
  }, [isCompressing]);
}

export default useCheckIfAuthUserProfilePicIsCompressing;
