export default {
  review: {
    leaveAReview: 'Leave a review',
    howWasExperienceNoStar: 'How was the Experience?',
    howWasExperience: 'How was your Experience?*',
    howWasExminder: 'How was the Exminder?*',
    howWasExplorer: 'How was the Explorer?*',
    describeExperience: 'Describe your Experience',
    describeExminder: 'Describe the Exminder',
    describeExplorer: 'Describe the Explorer',
    uploadVideo: 'Upload a video',
    uploadReview: 'Upload review',
    congratulations: 'Congratulations!',
    reviewUploaded: 'Your review has been uploaded',
    experienceEnded: 'Your Experience "{{title}}" has ended',
    seeExperience: 'See Experiences',
    rateExperience: 'Rate the Experience "{{title}}"',
    rateExminder: 'Rate the Exminder ({{exminderName}})',
    rateExplorer: 'Rate the Explorer ({{explorerName}})',
    enjoyExperience: 'How much did you enjoy your Experience?',
    matchWithExminder: 'How much did you match with the Exminder?',
    matchWithExplorer: 'How much did you match with the Explorer?',
    notThatMuch: 'Not that\nmuch',
    veryMuch: 'Very\nmuch',
  },
};
