export default {
  referralDashboard: {
    exmindsPartners: 'Parteneri Exminds',
    description:
      'Invită-ți prietenii să se alăture Universului Exminds și câștigă bani pentru fiecare tranzacție reușită în aplicație.' +
      '\n\n' +
      'Doar distribuie link-ul de recomandare de mai jos, iar când prietenii tăi se înregistrează folosind link-ul tău, vei putea urmări Istoricul și Balanța ta de recompense.' +
      '\n\n' +
      'Profită de timpul tău... acum mai mult ca niciodată!\n\n',
    percentageGainAndDate:
      'Vei câștiga {{percentageGain}}% din taxa aplicației pentru fiecare tranzacție realizată de Exploreri care achiziționează Experiențe folosind link-ul tău de referință, până la data de {{dueDate}}.',
    referralLink: 'Link-ul tău de recomandare',
    referralAccountNotFound: 'Cont de recomandare negăsit',
    referralBalance: 'Balanță de recompense',
    statistics: 'Statistici',
    linkClicks: 'Click-uri pe link: ',
    registeredUsers: 'Utilizatori înregistrați: ',
    soldExperiences: 'Experiențe vândute: ',
    table: {
      placeholder:
        'Niciun Explorer nu s-a înregistrat și nu a achiziționat\nExperiențe folosind link-ul tău de recomandare',
      referralHistory: 'Istoric de recomandări',
      user: 'Utilizator',
      experienceName: 'Nume experiență',
      reward: 'Recompensă',
      transactionDate: 'Data tranzacției',
    },
  },
};
