export default {
  dispute: {
    tellUsWhatHappened: 'Tell us what happened',
    itHasBeen: 'It’s been',
    letUsKnow:
      ' since your Experience begun. Let us know about how you got along with the Exminder during your Experience.',
    thankYou: 'Thank you for keeping us in touch!',
    analyseWords:
      'We’re on our way to analyse your words and we’ll be back with an answer as soon as possible.',
    remember:
      'Remember!\n' +
      'If anything bad happens during the Experience contact us at ',
    supportMail: 'support@exminds.com',
  },
};
