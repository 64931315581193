export default {
  errorBoundary: {
    title: 'Oh, nu! Ceva nu funcționează!',
    subtitle:
      'În prezent întâmpinăm o situație nesoluționată. Intre timp poți explora celelalte secțiuni ale aplicației.',
    recoveryButton: 'Pagina principală',
  },
  experienceNotFound: {
    title: 'Această Experiență nu a fost găsită',
    subtitle:
      'Se pare că Experiența pe care o căutați nu este disponibilă în acest moment.\n\nEste posibil ca Experiența sa fie expirată sau a fost eliminată din profilul Exminderului.',
  },
};
