import { lazy } from 'react';
import PATH from './paths';
import { Route } from './types';

const ConnectedExperienceEndedPage = lazy(
  () =>
    import(
      '../components/smart/pages/experienceReview/ConnectedExperienceEndedPage'
    )
);

const ConnectedRateExperiencePage = lazy(
  () =>
    import(
      '../components/smart/pages/experienceReview/ConnectedRateExperiencePage'
    )
);

const ConnectedRateExminderPage = lazy(
  () =>
    import(
      '../components/smart/pages/experienceReview/ConnectedRateExminderPage'
    )
);

const ConnectedRateExplorerPage = lazy(
  () =>
    import(
      '../components/smart/pages/experienceReview/ConnectedRateExplorerPage'
    )
);

const ConnectedLeaveExperienceReviewPage = lazy(
  () =>
    import(
      '../components/smart/pages/experienceReview/ConnectedLeaveExperienceReviewPage'
    )
);

const ConnectedLeaveExminderReviewPage = lazy(
  () =>
    import(
      '../components/smart/pages/experienceReview/ConnectedLeaveExminderReviewPage'
    )
);

const ConnectedLeaveExplorerReviewPage = lazy(
  () =>
    import(
      '../components/smart/pages/experienceReview/ConnectedLeaveExplorerReviewPage'
    )
);

const ConnectedReviewUploadedPage = lazy(
  () =>
    import(
      '../components/smart/pages/experienceReview/ConnectedReviewUploadedPage'
    )
);

const EXPERIENCE_ENDED: Route = {
  component: ConnectedExperienceEndedPage,
  path: PATH.REVIEW.EXPERIENCE_ENDED,
  isProtected: true,
};

const RATE_EXPERIENCE: Route = {
  component: ConnectedRateExperiencePage,
  path: PATH.REVIEW.RATE_EXPERIENCE,
  isProtected: true,
};

const RATE_EXMINDER: Route = {
  component: ConnectedRateExminderPage,
  path: PATH.REVIEW.RATE_EXMINDER,
  isProtected: true,
};

const RATE_EXPLORER: Route = {
  component: ConnectedRateExplorerPage,
  path: PATH.REVIEW.RATE_EXPLORER,
  isProtected: true,
};

const REVIEW_EXPERIENCE: Route = {
  component: ConnectedLeaveExperienceReviewPage,
  path: PATH.REVIEW.EXPERIENCE_REVIEW,
  isProtected: true,
};

const REVIEW_EXMINDER: Route = {
  component: ConnectedLeaveExminderReviewPage,
  path: PATH.REVIEW.EXMINDER_REVIEW,
  isProtected: true,
};

const REVIEW_EXPLORER: Route = {
  component: ConnectedLeaveExplorerReviewPage,
  path: PATH.REVIEW.EXPLORER_REVIEW,
  isProtected: true,
};
const REVIEW_UPLOADED: Route = {
  component: ConnectedReviewUploadedPage,
  path: PATH.REVIEW.REVIEW_UPLOADED,
  isProtected: true,
};

export default [
  EXPERIENCE_ENDED,
  RATE_EXPERIENCE,
  RATE_EXMINDER,
  RATE_EXPLORER,
  REVIEW_EXPERIENCE,
  REVIEW_EXMINDER,
  REVIEW_EXPLORER,
  REVIEW_UPLOADED,
];
