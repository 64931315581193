import { ElementType } from 'react';
import { FieldProps as FormikFieldProps } from 'formik';
import * as SC from './styles';

interface Props extends FormikFieldProps {
  placeholder?: string;
  disabled?: boolean;
  autoComplete?: 'on' | 'off';
  type?: 'text' | 'date' | 'number';
  name?: string;
  as?: ElementType | string;
  [rest: string]: unknown;
}

export default function FieldInput({
  placeholder = '',
  disabled = false,
  autoComplete = 'on',
  type = 'text',
  name,
  as,
  field,
  form,
  ...rest
}: Props) {
  return (
    <SC.Container>
      <SC.ReferenceContainer>
        <SC.ReferenceInsideBorder>
          <SC.StyledInput
            {...field}
            as={as}
            name={name}
            type={type}
            placeholder={placeholder}
            disabled={disabled}
            autoComplete={autoComplete}
            {...rest}
          />
        </SC.ReferenceInsideBorder>
      </SC.ReferenceContainer>
    </SC.Container>
  );
}
