import React from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import CustomToastNotification from 'components/dumb/atomics/CustomToastNotification';
import ErrorBoundarySmart from 'components/smart/pages/ErrorBoundarySmart';
import * as Sentry from '@sentry/react';
import GlobalStyle from 'styles/global';
import { Provider } from 'react-redux';
import classic from './styles/theme/classic';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './translations/config';
import store from './redux/store';
import './redux/listeners';
import { NotificationSocketProvider } from './contexts/notificationSocket';
import {
  IS_DEV_APP_ENVIRONMENT,
  SENTRY_DSN,
} from './helpers/constants/envVars';

if (!IS_DEV_APP_ENVIRONMENT) {
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

let isFlutterView = false;
if (window.flutter_inappwebview) {
  isFlutterView = true;
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const queryClient = new QueryClient();
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={classic}>
        <GlobalStyle isFlutterView={isFlutterView} />
        <BrowserRouter>
          <NotificationSocketProvider>
            <QueryClientProvider client={queryClient}>
              <ErrorBoundarySmart>
                <App />
              </ErrorBoundarySmart>
              <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
          </NotificationSocketProvider>
          <CustomToastNotification />
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
