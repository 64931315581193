import React from 'react';
import * as SC from './styles';

interface ExperienceSkeletonProps {
  hasActionButton?: boolean;
  showUser?: boolean;
}

export default function ExperienceSkeleton({
  hasActionButton = false,
  showUser = false,
}: ExperienceSkeletonProps) {
  return (
    <SC.Container extendedCardHeight={hasActionButton} showUser={showUser}>
      <SC.CardContent>
        <SC.ImageContainer />
        <SC.ExperienceDetailsContainer>
          <SC.UserDetails>
            <SC.UserDetailsContent>
              <SC.UserAvatarContainer>
                <SC.UserAvatar />
              </SC.UserAvatarContainer>
              <SC.UserNameWrapper>
                <SC.UserNameContent>
                  <SC.Detail />
                  <SC.SmallDetail />
                  <SC.SmallDetail />
                </SC.UserNameContent>
              </SC.UserNameWrapper>
            </SC.UserDetailsContent>
          </SC.UserDetails>
          <SC.Divider />
          <SC.ExperienceDetailsContent>
            <SC.LargeDetail />
            <SC.DetailRow>
              <SC.LargeDetail />
              <SC.LargeDetail />
            </SC.DetailRow>
            <SC.LargeDetail />
          </SC.ExperienceDetailsContent>
          {hasActionButton && <SC.Button />}
        </SC.ExperienceDetailsContainer>
      </SC.CardContent>
    </SC.Container>
  );
}
