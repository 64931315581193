import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import FilterTab from './FilterTab';
import * as SC from './styles';

interface Props {
  onFilterChange: (filter: string) => void;
}

export default function ExperienceFilterBar({ onFilterChange }: Props) {
  const [selectedFilter, setSelectedFilter] = useState('Experiences');

  const { t } = useTranslation();

  const handleSelectedFilter = (filter: string) => {
    setSelectedFilter(filter);
    onFilterChange(filter);
  };

  const tabs = [
    t('experience.filters.experiences'),
    t('experience.filters.accounts'),
    // t('experience.filters.auctions'),
  ];

  const mappedTabs = tabs.map((tab) => (
    <FilterTab
      key={tab}
      tabText={tab}
      isSelected={selectedFilter === tab}
      handleSelectedFilter={handleSelectedFilter}
    />
  ));
  return <SC.Container>{mappedTabs}</SC.Container>;
}
