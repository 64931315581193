export default {
  errors: {
    short: 'This field is too short',
    long: 'This field is too long',
    required: 'This field is required',
    notANumber: 'This field must be a number',
    specific: {
      minimumLength: 'Minimum length:',
      maximumLength: 'Maximum length:',
      minimumValue: 'Minimum value:',
      maximumValue: 'Maximum value:',
      characters: 'characters',
    },
    price: {
      minimumPriceExceedsMax:
        'The minimum price cannot exceed the maximum price',
      maximumPriceBelowMin:
        'The maximum price cannot be less than the minimum price',
    },
    minimumPriceBelowMaximumPrice: 'Date must be before current date',
    dateBeforeCurrentDate: 'Date must be before current date',
    startDateBeforeCurrentDate: 'Start date must be before current date',
    endDateBeforeCurrentDate: 'End date must be before current date',
    startDateBeforeEndDate: 'Start date must be before end date',
    endDateAfterStartDate: 'End date must be after start date',
    invalidEmailAddress: 'Invalid email address',
    invalidIbanFormat: 'Invalid IBAN format',
    invalidPhoneNumber: 'Please, make sure the number is valid',
    underage18: 'Users must be at least 18 years old',
    underage13: 'Explorers must be at least 13 years old',
    general: 'Something went wrong. Please try again!',
    fileSizeTooLargeOneGb:
      'File size too large. Please select a file that is 1 GB or smaller in size!',
  },
};
