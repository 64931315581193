export default {
  workExperienceModal: {
    errors: {
      short: 'This field is too short',
      long: 'This field is too long',
      required: 'This field is required',
      futureDate: 'Cannot set a future date',
      afterStartDate: 'End date must be after start date',
    },
    title: 'ADD WORK EXPERIENCE',
    titleEdit: 'EDIT WORK EXPERIENCE',
    subtitle: '*Indicates required field',
    form: {
      title: 'Title*',
      companyName: 'Company Name*',
      country: 'Country*',
      location: 'City*',
      startDate: 'Start Date*',
      endDate: 'End Date*',
      isPresentJob: 'I’m currently working here',
      description: 'Description*',
      industry: 'Industry*',
    },
    placeholder: {
      title: 'Ex: Social Media Manager',
      companyName: 'Ex: Coca Cola',
      country: 'Ex: Germany',
      location: 'Ex: Berlin',
      startDate: 'Select month and year',
      endDate: 'Select month and year',
      description: 'Type here maximum 500 characters',
      industry: 'Ex: Marketing',
      present: 'Present',
    },
    button: {
      exit: 'Exit',
      done: 'Done',
    },
  },
};
