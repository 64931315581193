import { JSX, SVGProps } from 'react';

export default function NotificationsIcon(
  props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>
) {
  return (
    <svg
      width="21"
      viewBox="0 0 20 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.5 13.9262V10.0837C17.4982 8.3716 16.8697 6.71539 15.7258 5.40834C14.5818 4.1013 12.9963 3.22775 11.25 2.94241V1.62533C11.25 1.30486 11.1183 0.997511 10.8839 0.770905C10.6495 0.544298 10.3315 0.416992 10 0.416992C9.66848 0.416992 9.35054 0.544298 9.11612 0.770905C8.8817 0.997511 8.75 1.30486 8.75 1.62533V2.94241C7.00367 3.22775 5.41816 4.1013 4.27423 5.40834C3.13031 6.71539 2.50179 8.3716 2.5 10.0837V13.9262C1.77056 14.1755 1.13875 14.6367 0.691173 15.2468C0.243598 15.8568 0.00217439 16.5858 0 17.3337V19.7503C0 20.0708 0.131696 20.3781 0.366117 20.6047C0.600537 20.8314 0.918479 20.9587 1.25 20.9587H5.175C5.46285 21.9826 6.09125 22.8865 6.96325 23.5309C7.83524 24.1753 8.90237 24.5245 10 24.5245C11.0976 24.5245 12.1648 24.1753 13.0368 23.5309C13.9087 22.8865 14.5371 21.9826 14.825 20.9587H18.75C19.0815 20.9587 19.3995 20.8314 19.6339 20.6047C19.8683 20.3781 20 20.0708 20 19.7503V17.3337C19.9978 16.5858 19.7564 15.8568 19.3088 15.2468C18.8613 14.6367 18.2294 14.1755 17.5 13.9262V13.9262ZM5 10.0837C5 8.80178 5.52678 7.5724 6.46447 6.66598C7.40215 5.75955 8.67392 5.25033 10 5.25033C11.3261 5.25033 12.5979 5.75955 13.5355 6.66598C14.4732 7.5724 15 8.80178 15 10.0837V13.7087H5V10.0837ZM10 22.167C9.56372 22.1644 9.13572 22.0516 8.75854 21.8396C8.38136 21.6276 8.06814 21.3239 7.85 20.9587H12.15C11.9319 21.3239 11.6186 21.6276 11.2415 21.8396C10.8643 22.0516 10.4363 22.1644 10 22.167ZM17.5 18.542H2.5V17.3337C2.5 17.0132 2.6317 16.7058 2.86612 16.4792C3.10054 16.2526 3.41848 16.1253 3.75 16.1253H16.25C16.5815 16.1253 16.8995 16.2526 17.1339 16.4792C17.3683 16.7058 17.5 17.0132 17.5 17.3337V18.542Z"
        fill="#0082AA"
      />
    </svg>
  );
}
