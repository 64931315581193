export default {
  currency: {
    currency: 'Monedă',
    chooseCurrency: 'Alege moneda',
    noCurrencyFound: 'Nu s-au găsit monede',
    euro: 'EURO',
    usd: 'USD',
    ron: 'RON',
    cny: 'CNY',
  },
};
