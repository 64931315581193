export default {
  favorites: {
    lastDay: 'Last day',
    last7Days: 'Last 7 days',
    last30Days: 'Last 30 days',
    last90days: 'Last 90 days',
    all: 'All',
    favorites: 'Favorites',
    count_one: 'You have {{count}} saved Experience',
    count_none: 'You have no saved Experiences',
    count: 'You have {{count}} saved Experiences',
    seeMoreToChoose:
      'Explore the Exminds Universe to choose the best ones for you',
  },
};
