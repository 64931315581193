import styled from 'styled-components';

export const Container = styled.div<{ disabled: boolean }>`
  border: 0;
  margin: 0;
  padding: 0;
  background: transparent;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  cursor: ${({ disabled }) => (disabled ? `not-allowed` : `pointer`)};
  opacity: ${({ disabled }) => (disabled ? `0.5` : `1`)};

  @media (hover: hover) {
    &:hover {
      opacity: ${({ disabled }) => !disabled && `0.7`};
    }
  }
`;
