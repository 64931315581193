import languages from './languages/ro';
import general from './general/ro';
import passwordRequirements from './passwordRequirments/ro';
import auth from './auth/ro';
import formValidations from './formValidations/ro';
import welcome from './welcome/ro';
import pin from './pin/ro';
import domains from './domains/ro';
import buildProfile from './buildProfile/ro';
import addProfilePicture from './addProfilePicture/ro';
import chooseHobbies from './chooseHobbies/ro';
import yearsOfExperience from './yearsOfExperience/ro';
import aboutTheApp from './aboutTheApp/ro';
import mostPopularPeople from './mostPopularPeople/ro';
import menuBar from './menuBar/ro';
import paymentFlow from './paymentFlow/ro';
import disclaimer from './disclaimer/ro';
import toast from './toast/ro';
import exminderPackage from './exminderPackage/ro';
import experienceFeed from './experienceFeed/ro';
import acceptRejectExperience from './acceptRejectExperience/ro';
import benefitsRequirements from './benefitsRequirments/ro';
import experienceCreationLayout from './experienceCreation/ro';
import sidebarMenu from './sidebarMenu/ro';
import review from './review/ro';
import addExperience from './addExperience/ro';
import user from './user/ro';
import profile from './profile/ro';
import experienceActions from './experienceActions/ro';
import workExperienceModal from './workExperienceModal/ro';
import educationModal from './educationModal/ro';
import accountSettings from './accountSettings/ro';
import experience from './experience/ro';
import additionalCosts from './additionalCosts/ro';
import stripeRegistration from './stripeRegistration/ro';
import chat from './chat/ro';
import wallet from './wallet/ro';
import currency from './currency/ro';
import afterExperience from './afterExperience/ro';
import errors from './errors/ro';
import deleteButton from './deleteButton/ro';
import experienceChanges from './experienceChanges/ro';
import milestoneModal from './milestoneModal/ro';
import awardModal from './awardModal/ro';
import misc from './misc/ro';
import createProfile from './createProfile/ro';
import favorites from './favorites/ro';
import auction from './auction/ro';
import packageAddressSelection from './packageAddressSelection/ro';
import news from './newsNotifications/ro';
import notifications from './notifications/ro';
import dispute from './dispute/ro';
import report from './report/ro';
import placeholders from './placeholders/ro';
import errorBoundary from './errorBoundary/ro';
import faq from './faq/ro';
import moneyWithExminds from './moneyWithExminds/ro';
import appFeedback from './appFeedback/ro';
import experienceInviteGuest from './experienceInviteGuest/ro';
import invoices from './invoices/ro';
import filters from './filters/ro';
import countdown from './countdown/ro';
import referralDashboard from './referralDashboard/ro';
import communityGuidelines from './communityGuidelines/ro';

const ro = {
  translations: {
    ...general,
    ...passwordRequirements,
    ...auth,
    ...formValidations,
    ...welcome,
    ...pin,
    ...domains,
    ...buildProfile,
    ...addProfilePicture,
    ...chooseHobbies,
    ...yearsOfExperience,
    ...aboutTheApp,
    ...mostPopularPeople,
    ...menuBar,
    ...paymentFlow,
    ...disclaimer,
    ...toast,
    ...exminderPackage,
    ...experienceFeed,
    ...acceptRejectExperience,
    ...benefitsRequirements,
    ...experienceCreationLayout,
    ...sidebarMenu,
    ...review,
    ...addExperience,
    ...user,
    ...profile,
    ...experienceActions,
    ...languages,
    ...workExperienceModal,
    ...educationModal,
    ...accountSettings,
    ...experience,
    ...additionalCosts,
    ...stripeRegistration,
    ...chat,
    ...wallet,
    ...currency,
    ...afterExperience,
    ...errors,
    ...deleteButton,
    ...experienceChanges,
    ...milestoneModal,
    ...awardModal,
    ...misc,
    ...createProfile,
    ...favorites,
    ...auction,
    ...packageAddressSelection,
    ...news,
    ...notifications,
    ...dispute,
    ...report,
    ...placeholders,
    ...errorBoundary,
    ...faq,
    ...moneyWithExminds,
    ...appFeedback,
    ...experienceInviteGuest,
    ...invoices,
    ...filters,
    ...countdown,
    ...referralDashboard,
    ...communityGuidelines,
  },
};

export default ro;
