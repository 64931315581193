export default {
  chat: {
    messageInputPlaceholder: 'Scrie un mesaj...',
    standardMessageExplorerPin:
      'Ține minte! Distribuie PIN-ul doar în timpul Experienței pentru a evita pierderea achiziției fără posibilitatea de a solicita o rambursare dacă Exminderul nu este prezent la locație.',
    standardMessageExplorerMeet:
      'Asigură-te că te întâlnești cu Exminderul în termen de 15 zile de la data achiziției pachetului. Poți solicita întotdeauna o rambursare în termen de 3 zile după întâlnire sau 3 zile după expirarea celor 15 zile. Dacă nu se solicită nicio rambursare în termen de 3 zile după întâlnire, Experiența este considerată încheiată.',
    standardMessageExminderPin:
      'Ține minte! Solicită PIN-ul doar la începerea Experienței pentru a evita pierderea achiziției fără posibilitatea de a solicita banii înapoi dacă Explorerul nu se prezintă la locație.',
    standardMessageExminderMeet:
      'Asigură-te că Experiența are loc în termen de 15 zile, altfel Explorerul poate solicita o rambursare a banilor și poate lăsa feedback negativ pentru lipsa livrării serviciilor tale. Dacă nu se solicită nicio rambursare a banilor în termen de 3 zile după întâlnire, Experiența este considerată completă.',
  },
};
