export default {
  wallet: {
    myWallet: 'My Wallet',
    availableBalance: 'Available balance',
    upcomingMoney: 'Upcoming money',
    withdrawMoneyTo: 'Withdraw money to',
    primaryBankAccount: 'Primary Bank Account',
    note:
      'Note: You will automatically receive your money every 3 days after the Experience is successfully completed ' +
      'in between both parties, if you haven’t done it.',
    changeBankAccount:
      'You can always change your bank account by accessing the Financial Settings.',
    withdrawSuccessful: 'Withdraw Successful!',
    receiveStatement: 'Receive statement via',
    mail: 'Mail',
    download: 'Download',
  },
};
