export enum ExperienceType {
  HANDS_ON = 'actor',
  OBSERVER = 'observer',
}

export enum ExperienceDateType {
  FIXED = 'fixed',
  VARIABLE = 'variable',
}

export enum PaymentType {
  REGULAR = 'regular',
  AUCTION = 'auction',
}

export const ExperienceCategoryOption = {
  observer: {
    id: 1,
    label: 'Observer',
    value: ExperienceType.OBSERVER,
    description: 'The Explorer can only see what you’re doing',
  },
  handsOn: {
    id: 2,
    label: 'Hands-On',
    value: ExperienceType.HANDS_ON,
    description:
      'The Explorer can actively participate throughout the Experience',
  },
};

export enum Currency {
  EUR = 'EUR',
  RON = 'RON',
  USD = 'USD',
}

export enum ErrorCode {
  StripeSellerAccountNotFound = 9000,
  StripeSellerAccountNotEnabled = 9001,
  PhoneAlreadyExists = 1000,
  PackagePublishWithPurchaseDeadlineBelow2HoursFromNow = 2001,
}

export enum OpenedModalExminderProfile {
  Work = 'work',
  Education = 'education',
  Award = 'award',
  Milestone = 'milestone',
}

export enum MessageNotificationType {
  P2P = 'p2p',
  Group = 'group',
}

export enum SideBarSteps {
  MainPageStep = 0,
  ViewChangeConfirmationStep = 1,
  CompleteProfileStep = 2,
}

export enum ReportSteps {
  REASON = 0,
  DESCRIPTION = 1,
}

export enum SearchFilters {
  ACCOUNTS = 'Accounts',
  EXPERIENCES = 'Experiences',
  AUCTIONS = 'Auctions',
}
