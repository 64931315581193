import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 400px;
  background: ${({ theme }) => theme.palette.main.white};
  white-space: pre-wrap;
  border-radius: 40px;
  filter: drop-shadow(4px 5px 10px rgba(0, 0, 0, 0.11));

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 18px;
  cursor: text;
`;

export const InsideBorder = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 18px;
  padding: 15px 20px 15px 20px;
  ::before {
    content: '';
    position: absolute;
    z-index: -1;
    inset: 0;
    border-radius: 40px;
    border: 2px solid transparent;
    background: linear-gradient(
        96.44deg,
        rgba(0, 130, 170, 0.2) 29.58%,
        rgba(0, 130, 170, 0.042) 66.74%
      )
      border-box;
    mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
`;

export const InputText = styled.input`
  width: 100%;
  padding: 0;
  border: none;
  background: ${({ theme }) => theme.palette.main.white};
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: ${({ theme }) => theme.typography.pxToRem(0)};
  line-height: ${({ theme }) => theme.typography.pxToRem(0)};
  text-align: left;
  white-space: pre-wrap;

  font-weight: 500;
  color: ${({ theme }) => theme.palette.main.marine};

  &:active,
  &:focus,
  &:hover {
    outline: none;
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${({ theme }) => `${theme.palette.main.turquoise}80`};
    font-family: ${({ theme }) => theme.typography.fontFamily};
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${({ theme }) => `${theme.palette.main.turquoise}80`};
    font-family: ${({ theme }) => theme.typography.fontFamily};
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${({ theme }) => `${theme.palette.main.turquoise}80`};
    font-family: ${({ theme }) => theme.typography.fontFamily};
  }
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  svg {
    width: 24px;
    height: 24px;
    path {
      fill: ${({ theme }) => theme.palette.main.marine};
    }
  }
  &:hover {
    opacity: 0.7;
  }
  & {
    -webkit-tap-highlight-color: transparent;
  }
`;
