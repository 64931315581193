export default {
  educationModal: {
    errors: {
      short: 'Acest câmp este prea scurt',
      long: 'Acest câmp este prea lung',
      required: 'Acest câmp este obligatoriu',
      futureDate: 'Nu se poate seta o dată din viitor',
      afterStartDate: 'Data de finalizare trebuie să fie după data de început',
    },
    title: 'ADAUGĂ EDUCAȚIE',
    titleEdit: 'EDITEAZĂ EDUCAȚIA',
    subtitle: '*Câmp obligatoriu',
    form: {
      school: 'Instituție*',
      degree: 'Diplomă*',
      fieldOfStudy: 'Domeniu de studiu*',
      startDate: 'Data de începere*',
      endDate: 'Data de finalizare*',
      isCurrent: 'Încă studiez aici',
      description: 'Descriere*',
      grade: 'Nota obținută*',
      country: 'Țara*',
      location: 'Oraș*',
    },
    placeholder: {
      school: 'Ex: Universitatea din București',
      degree: 'Ex: Licență',
      fieldOfStudy: 'Ex: Inginerie Medicală',
      startDate: 'Selectează luna și anul',
      endDate: 'Selectează luna și anul',
      description: 'Scrie aici maximum 500 de caractere',
      grade: 'Ex: AAA',
      country: 'Ex: Germania',
      location: 'Ex: Berlin',
      present: 'Prezent',
    },
    button: {
      exit: 'Ieșire',
      done: 'Salvează',
    },
  },
};
