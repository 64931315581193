export default {
  experience: {
    type: {
      handsOn: 'Hands-On',
      observer: 'Observer',
    },
    status: {
      draft: 'Draft',
      completed: 'Completed',
      expired: 'Expired',
      canceled: 'Canceled',
      shadowBan: 'Waiting for approval',
    },
    title: 'Description',
    editForm: {
      reupload: 'Reupload',
      name: 'Name*',
      startDate: 'Start date*',
      endDate: 'End date*',
      startTime: 'Start time*',
      endTime: 'End time*',
      experienceType: 'Experience type*',
      domain: 'Domain*',
      participantsNumber: 'Participants number (maximum)*',
      deadlineDate: 'Deadline date*',
      deadlineTime: 'Deadline time*',
      presentationVideo: 'Presentation video*',
      presentationPhoto: 'Presentation photo*',
      location: 'Location*',
      benefits: 'Benefits*',
      requirements: 'Requirements*',
      exminderDisclaimer: 'Exminder disclaimer*',
      additionalCosts: 'Additional costs',
      currency: 'Currency*',
      price: 'Price*',
      discountsPercentage: 'Discounts percentage',
      referralCode: 'Referral code',
      contact: 'Contact',
      description: 'Experience description',
    },
    editDisclaimer: {
      title: 'Edit Exminder disclaimer',
      subtitle:
        'Provide all the important information for your Explorer to understand every aspect of your Experience',
      text: 'Disclaimer Text',
    },
    editDescription: {
      title: 'Edit Experience description',
      subtitle:
        'Provide all the important information for your Explorer to understand every aspect of this particular Experience',
      text: 'Description Text',
    },
    editCosts: {
      title: 'Edit additional costs',
      subtitle:
        'Provide all the important information regarding the potential additional costs for your Explorer to understand every aspect of your Experience',
      text: 'Additional costs',
    },
    ongoing: {
      mentionSomething: 'Mention something',
      takingPlace: 'This Experience is taking place.\nEnjoy!',
    },
    errors: {
      purchaseDeadlineDate: 'Purchase deadline date must be before start date',
    },
    filters: {
      accounts: 'Accounts',
      experiences: 'Experiences',
      auctions: 'Auctions',
    },
    explorer: {
      myExperiences: {
        welcome: 'Welcome, ',
        youHave: 'You have',
        experiencePurchased: 'purchased Experience',
        experiencesPurchased: 'purchased Experiences',
        experienceCompleted: 'completed Experience',
        experiencesCompleted: 'completed Experiences',
        experienceRequested: 'requested Experience',
        experiencesRequested: 'requested Experiences',
        purchasedBy: 'Purchased by',
        unpurchasedExperience: 'Unpurchased Experience',
        noActiveExplorersBought: 'No active Explorers bought this package',
        thisIsThePlace:
          'This is the place where you can store all your upcoming Experiences!',
        lookingForFreshExperiences:
          'Looking for fresh Experiences to learn from?',
        checkIfTheExminders:
          'Check if the Exminders have accepted your requests for the desired Experiences. Once accepted, you will need to proceed and buy the Experience.',
        upcoming: 'Upcoming',
        auction: 'Auction',
        past: 'Past',
        requested: 'Requests',
        discoverExperiences: 'Discover Experiences',
        noPurchasedExperiences:
          'It looks like you don’t have any purchased Experiences',
        noRequestedExperiences:
          'It looks like you don’t have any requested Experiences',
        noCompletedExperiences:
          'It looks like you don’t have any completed Experiences',
        findYourFavoriteOnes: 'Find more Experienes in the Exminds Universe!',
        exploreMore: 'Explore More',
      },
    },
    permanent: {
      name: 'Permanent',
      requestApproved: 'Request approved!',
      youHaveLeftToBuy: 'You have 3 days to buy this Experience',
      exminderIsOff: 'The Exminder is off!',
      isNotAvailable:
        'is not available at this moment for the Experience you are interested in',
    },
    refund: {
      request: 'Request Refund',
    },
    request: {
      approved: 'Approved',
      declined: 'Declined',
    },
    inProgress: 'In Progress',
    upcoming: 'Upcoming',
    requested: 'Requested',
    pinCodeValidatedAt: 'PIN code validated at',
    showProfile: 'Show Profile',
    checkNotifications: 'Check your notifications!',
    stayUpdated:
      'Stay updated on all the discussions with Explorers interested in this Experience',
    experienceHasNoBuyers: 'This Experience has no buyers',
    promoteExperience:
      'Promote your Experience for helping Explorers discover your skills',
  },
};
