export enum HttpStatusCode {
  OK = 200,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  TIMEOUT = 408,
  INTERNAL_SERVER_ERROR = 500,
  TOO_MANY_REQUESTS = 429,
  UNPROCESSABLE_ENTITY = 422,
}

export enum ApiMethod {
  GET = 'GET',
  PUT = 'PUT',
  POST = 'POST',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
}

export const AUTH_REFRESH_TOKENS_SUBROUTE = '/auth/refresh-tokens';

export const MIN_REQUEST_LIMIT = 5;

export const DEFAULT_REQUEST_LIMIT = 10;

export const BULK_REQUEST_LIMIT = 100;

export const DEBOUNCE_TIME = 500;

export interface DropdownItem {
  value: string;
  label: string;
}

export const DEFAULT_CATEGORY_FILTER = '';
