export default {
  experience: {
    type: {
      handsOn: 'Tip Practică',
      observer: 'Tip Observator',
    },
    status: {
      draft: 'Ciornă',
      completed: 'Finalizată',
      expired: 'Expirată',
      canceled: 'Anulată',
      shadowBan: 'În așteptarea aprobării',
    },
    title: 'Descriere',
    editForm: {
      reupload: 'Reîncarcă',
      name: 'Nume*',
      startDate: 'Data de începere*',
      endDate: 'Data de finalizare*',
      startTime: 'Ora de începere*',
      endTime: 'Ora de finalizare*',
      experienceType: 'Tipul experienței*',
      domain: 'Domeniu*',
      participantsNumber: 'Numărul de participanți (maxim)*',
      deadlineDate: 'Data limită*',
      deadlineTime: 'Ora limită*',
      presentationVideo: 'Video de prezentare*',
      presentationPhoto: 'Fotografie de prezentare*',
      location: 'Locație*',
      benefits: 'Beneficii*',
      requirements: 'Cerințe*',
      exminderDisclaimer: 'Cerințe Exminder*',
      additionalCosts: 'Costuri suplimentare',
      currency: 'Monedă*',
      price: 'Preț*',
      discountsPercentage: 'Procent discount',
      referralCode: 'Cod de referință',
      contact: 'Contact',
      description: 'Descrierea experienței',
    },
    editDisclaimer: {
      title: 'Editează cerințe Exminder',
      subtitle:
        'Oferă toate informațiile importante pentru Explorer pentru a înțelege fiecare aspect al Experienței',
      text: 'Descrierea cerințelor',
    },
    editDescription: {
      title: 'Editează descrierea experienței',
      subtitle:
        'Oferă toate informațiile importante pentru Explorer pentru a înțelege fiecare aspect al acestei experiențe particulare',
      text: 'Conținutul descrierii',
    },
    editCosts: {
      title: 'Editează costurile suplimentare',
      subtitle:
        'Oferă toate informațiile importante privind costurile suplimentare potențiale pentru Explorer pentru a înțelege fiecare aspect al Experienței',
      text: 'Costuri suplimentare',
    },
    ongoing: {
      mentionSomething: 'Menționează ceva',
      takingPlace: 'Această Experiență are loc.\nBucură-te!',
    },
    errors: {
      purchaseDeadlineDate:
        'Data limită de cumpărare trebuie să fie înainte de data de începere',
    },
    filters: {
      accounts: 'Conturi',
      experiences: 'Experiențe',
      auctions: 'Licitații',
    },
    explorer: {
      myExperiences: {
        welcome: 'Bun venit, ',
        youHave: 'Ai',
        experiencePurchased: 'Experiență cumpărată',
        experiencesPurchased: 'Experiențe cumpărate',
        experienceCompleted: 'Experiență finalizată',
        experiencesCompleted: 'Experiențe finalizate',
        experienceRequested: 'Experiență solicitată',
        experiencesRequested: 'Experiențe solicitate',
        purchasedBy: 'Cumpărat de',
        unpurchasedExperience: 'Experiență necumpărată',
        noActiveExplorersBought:
          'Niciun Explorer activ nu a cumpărat acest pachet',
        thisIsThePlace:
          'Acesta este locul unde poți stoca toate Experiențele tale viitoare!',
        checkIfTheExminders:
          'Verifică dacă Exminderii au acceptat solicitările tale pentru Experiențele dorite. Odată acceptate, va trebui să continui și să achiziționezi Experiența.',
        lookingForFreshExperiences: 'Cauți Experiențe noi din care să înveți?',
        upcoming: 'În viitor',
        auction: 'Licitație',
        past: 'Completate',
        requested: 'Solicitate',
        discoverExperiences: 'Descoperă Experiențe',
        noPurchasedExperiences: 'Se pare că nu ai nicio Experiență cumpărată',
        noRequestedExperiences: 'Se pare că nu ai nicio Experiență solicitată',
        noCompletedExperiences: 'Se pare că nu ai nicio Experiență completată',
        findYourFavoriteOnes:
          'Găsește mai multe Experiențe în Universul Exminds!',
        exploreMore: 'Explorează',
      },
    },
    permanent: {
      name: 'Permanent',
      requestApproved: 'Cerere aprobată!',
      youHaveLeftToBuy: 'Ai 3 zile să cumperi această Experiență',
      exminderIsOff: 'Exminderul este liber!',
      isNotAvailable:
        'nu este disponibil(ă) în acest moment pentru Experiența care te interesează',
    },
    refund: {
      request: 'Solicită Rambursare',
    },
    request: {
      approved: 'Aprobată',
      declined: 'Refuzată',
    },
    inProgress: 'În desfășurare',
    upcoming: 'În viitor',
    requested: 'Solicitată',
    pinCodeValidatedAt: 'Codul PIN validat la',
    showProfile: 'Arată Profilul',
    checkNotifications: 'Verifică-ți notificările!',
    stayUpdated:
      'Rămâi la curent cu toate discuțiile cu Explorerii interesați de această Experiență',
    experienceHasNoBuyers: 'Această Experiență nu are cumpărători',
    promoteExperience:
      'Promovează-ți Experiența pentru a ajuta Explorerii să descopere abilitățile tale',
  },
};
