export default {
  milestoneModal: {
    title: 'ADD MILESTONE',
    titleEdit: 'EDIT MILESTONE',
    subtitle: '*Indicates required field',
    form: {
      name: 'Name*',
      displayName: 'Display name*',
      description: 'Description*',
      date: 'Date*',
      link: 'Link*',
      presentationPhoto: 'Presentation photo*',
    },
    view: {
      name: 'Name',
      displayName: 'Display name',
      description: 'Description',
      date: 'Date',
      link: 'Link',
      presentationPhoto: 'Presentation photo',
    },
    placeholder: {
      name: 'Ex: The power of Now',
      textOnProfile: 'Ex: I’ve published one book',
      time: 'Ex: June 2020',
      description: 'Type here maximum 500 characters',
      link: 'Ex: direct link to buy it on Amazon',
    },
    button: {
      exit: 'Exit',
      done: 'Done',
    },
  },
};
