import { Filters } from 'components/dumb/layouts/FiltersModal/FiltersForm';
import { useSearchParams } from 'react-router-dom';

const useExperiencesUrlParams = () => {
  const [filterParams, setFilterParams] = useSearchParams();

  const urlParams = {
    experienceType: filterParams.get('experienceType') ?? '',
    minPrice: filterParams.get('minPrice') ?? '',
    maxPrice: filterParams.get('maxPrice') ?? '',
    currency: filterParams.get('currency') ?? '',
    country: filterParams.get('country') ?? '',
    city: filterParams.get('city') ?? '',
    language: filterParams.get('language') ?? '',
    domain: filterParams.get('domain') ?? '',
    cityLabel: filterParams.get('cityLabel') ?? '',
  };

  function setFilterParamsToUrlFromObject(filtersData: Filters) {
    Object.keys(filtersData).forEach((key) => {
      const item = filtersData[key as keyof Filters];
      if (typeof item === 'object' && item.value) {
        setFilterParams((params) => {
          params.set(key, item.value);
          if (key === 'city') {
            params.set('cityLabel', item.label);
          }
          return params;
        });
      }
      if (typeof item !== 'object') {
        if (item) {
          setFilterParams((params) => {
            params.set(key, item);
            return params;
          });
        } else {
          setFilterParams((params) => {
            params.delete(key);
            return params;
          });
        }
      }
    });
  }

  function removeFilterParamsFromUrlFromObject(filtersData: Filters) {
    Object.keys(filtersData).forEach((key) => {
      const item = filtersData[key as keyof Filters];
      if (typeof item === 'object' && item.value) {
        setFilterParams((params) => {
          params.delete(key);
          if (key === 'city') {
            params.delete('cityLabel');
          }
          return params;
        });
      } else if (typeof item !== 'object' && item) {
        setFilterParams((params) => {
          params.delete(key);
          return params;
        });
      }
    });
  }

  return {
    urlParams,
    setFilterParams,
    setFilterParamsToUrlFromObject,
    removeFilterParamsFromUrlFromObject,
  };
};

export default useExperiencesUrlParams;
