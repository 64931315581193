export default {
  pin: {
    pinSent: ' A verification pin has been sent to your email account',
    clickLink:
      'Please click on the link that has just been sent to your email account to verify your email and continue the registration process',
    codeNotReceived: "Didn't get the code?",
    resendCode: 'Click here to resend it',
    getPinCodeHere: 'Get the PIN code here',
    enterPinCodeHere: 'Enter the PIN code here',
    exminder: {
      enterTheCode: 'Enter the code from the Explorer',
    },
    explorer: {
      presentThisCode: 'Present this code to the Exminder',
    },
    weAreGlad: 'We’re glad you take the Exminds story to the next level!',
    letTheExperienceBegin: 'Let the Experience begin!',
  },
};
