import { ReactElement, useState } from 'react';
import { DEFAULT_CATEGORY_FILTER } from 'api/constants';
import * as SC from './styles';
import TabButton from '../TabButton';

interface TabButtonProps {
  icon: ReactElement;
  label: string;
  value: string;
  isChecked: boolean;
}

interface TabBarProps {
  buttons: TabButtonProps[];
  onDomainFilter: CallbackFunction;
}

export default function TabBar(props: TabBarProps) {
  const { buttons, onDomainFilter } = props;

  const [activeTabItem, setActiveTabItem] = useState<string>(
    DEFAULT_CATEGORY_FILTER
  );

  const handleCheck = (item: string) => {
    onDomainFilter(item);
    setActiveTabItem(item);
  };

  const buttonsItems = buttons?.map((button: TabButtonProps) => (
    <SC.DivContent key={button.label}>
      <TabButton
        icon={button.icon}
        label={button.label}
        value={button.value}
        isChecked={activeTabItem === button.value}
        handleCheck={handleCheck}
      />
    </SC.DivContent>
  ));

  return (
    <SC.Container>
      <SC.MainContent>
        <SC.ButtonsContainer>{buttonsItems}</SC.ButtonsContainer>
      </SC.MainContent>
    </SC.Container>
  );
}
