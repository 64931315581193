export default {
  general: {
    startHere: 'Start here',
    explore: 'Explore',
    explorer: 'Explorer',
    exminder: 'Exminder',
    submit: 'Submit',
    continue: 'Continue',
    skip: 'Skip',
    save: 'Save',
    edit: 'Edit',
    start: 'Start',
    clear: 'Clear',
    apply: 'Apply',
    reset: 'Reset',
    homepage: 'Homepage',
    goToApp: 'Go to App',
    back: 'Back',
    next: 'Next',
    yes: 'Yes',
    no: 'No',
    statistics: 'Statistics',
    milestones: 'Milestones',
    tryAgain: 'Try again',
    buy: 'Buy',
    join: 'Join',
    interested: 'I’m interested',
    expired: 'Expired',
    cancel: 'Cancel',
    confirm: 'Confirm',
    leave: 'Leave',
    accept: 'Accept',
    reject: 'Decline',
    accepted: 'Accepted',
    rejected: 'Declined',
    discount: 'Discount',
    experience: 'Experience',
    seeLess: 'See less',
    seeMore: 'See more',
    message: 'Message',
    messageExminder: 'Message Exminder',
    messageExplorer: 'Message Explorer',
    messageExminderSpacing: 'Message\nExminder',
    messageExplorerSpacing: 'Message\nExplorer',
    requirements: 'Requirements',
    benefits: 'Benefits',
    testimonials: 'Testimonials',
    seeProfile: 'See profile',
    quickShare: 'Quick Share',
    seeExperience: 'See Experience',
    seeExperienceSpacing: 'See\nExperience',
    buyExperience: 'Buy Experience',
    scrollDownToSeeMore: 'Scroll down to see more',
    loading: 'Loading...',
    typeYourAnswer: 'Type your answer...',
    noOptionsFound: 'No options found...',
    from: 'FROM',
    startPrice: 'Start Price',
    auction: 'Auction',
    confirmAction: 'Are you sure you want to confirm the action?',
    popularPerson: 'Popular Person',
    report: 'Report',
    shareExperience: 'Check out this Experience on Exminds:',
    shareExperienceUrl:
      'Hey, you! Get the Exminds App and check out this Experience: "{{experienceName}}" and their Profile where you can find many more! {{experienceUrl}}',
    shareOwnExperienceUrl:
      'Hey, you! Get the Exminds App and check out my Experience: "{{experienceName}}" and my Profile where you can find many more! {{experienceUrl}}',
    time: {
      hour: 'hour',
      minute: 'minute',
      second: 'second',
      hours: 'hours',
      minutes: 'minutes',
      seconds: 'seconds',
    },
    minimum: 'minimum',
    maximum: 'maximum',
    characters: 'characters',
    off: 'off',
  },
};
