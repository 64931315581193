import { JSX, SVGProps } from 'react';

export default function CloseModalIcon(
  props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>
) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.1149 13L24.5649 3.565C24.8474 3.28255 25.006 2.89946 25.006 2.5C25.006 2.10055 24.8474 1.71746 24.5649 1.435C24.2825 1.15255 23.8994 0.993866 23.4999 0.993866C23.1005 0.993866 22.7174 1.15255 22.4349 1.435L12.9999 10.885L3.56491 1.435C3.28245 1.15255 2.89936 0.993866 2.49991 0.993866C2.10046 0.993866 1.71736 1.15255 1.43491 1.435C1.15245 1.71746 0.993771 2.10055 0.993771 2.5C0.993771 2.89946 1.15245 3.28255 1.43491 3.565L10.8849 13L1.43491 22.435C1.29432 22.5744 1.18272 22.7403 1.10657 22.9231C1.03042 23.1059 0.991211 23.302 0.991211 23.5C0.991211 23.698 1.03042 23.8941 1.10657 24.0769C1.18272 24.2597 1.29432 24.4256 1.43491 24.565C1.57435 24.7056 1.74025 24.8172 1.92304 24.8933C2.10583 24.9695 2.30189 25.0087 2.49991 25.0087C2.69793 25.0087 2.89398 24.9695 3.07677 24.8933C3.25956 24.8172 3.42546 24.7056 3.56491 24.565L12.9999 15.115L22.4349 24.565C22.5744 24.7056 22.7403 24.8172 22.923 24.8933C23.1058 24.9695 23.3019 25.0087 23.4999 25.0087C23.6979 25.0087 23.894 24.9695 24.0768 24.8933C24.2596 24.8172 24.4255 24.7056 24.5649 24.565C24.7055 24.4256 24.8171 24.2597 24.8932 24.0769C24.9694 23.8941 25.0086 23.698 25.0086 23.5C25.0086 23.302 24.9694 23.1059 24.8932 22.9231C24.8171 22.7403 24.7055 22.5744 24.5649 22.435L15.1149 13Z"
        fill="black"
      />
    </svg>
  );
}
