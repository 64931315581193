import { lazy } from 'react';
import PATH from './paths';
import { Route } from './types';

const ConnectedInfluencerProfilePage = lazy(
  () =>
    import(
      '../components/smart/pages/fakeInfluencer/ConnectedFakeInfluencerViewOne'
    )
);

const INFLUENCER_VIEW_ONE: Route = {
  component: ConnectedInfluencerProfilePage,
  path: PATH.FAKE_INFLUENCER.VIEW_ONE,
  isProtected: true,
};

export default [INFLUENCER_VIEW_ONE];
