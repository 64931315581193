import styled from 'styled-components';
import { zIndex } from '../../../helpers/constants/zIndexValues';

export const MenuContent = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: ${zIndex.menuBar};
`;
