import { ReactElement } from 'react';
import * as SC from './styles';

interface TabButtonProps {
  icon: ReactElement;
  label: string;
  value: string;
  isChecked: boolean;
  handleCheck: CallbackFunction;
}

export default function TabButton(props: TabButtonProps) {
  const { icon, label, value, isChecked, handleCheck } = props;

  return (
    <SC.Container>
      <SC.ButtonContainer onClick={() => handleCheck(value)}>
        <SC.StyledIconWrapper>{icon}</SC.StyledIconWrapper>
        {label}
      </SC.ButtonContainer>
      <SC.SelectionLineContainer>
        {isChecked && <SC.SelectionLine />}
      </SC.SelectionLineContainer>
    </SC.Container>
  );
}
