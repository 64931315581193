import AbstractRequest from './AbstractRequest';
import { ApiMethod } from './constants';

export interface ApiResponseGetLanguages {
  id: string;
  code: string;
  name: string;
}

export class LanguageRequest extends AbstractRequest {
  get apiResource(): string {
    return 'language';
  }

  get routes(): Record<string, string> {
    return {
      GET_LANGUAGES: `${this.apiRoute}`,
    };
  }

  async getLanguages(): Promise<ApiResponseGetLanguages[]> {
    const result = await this.request(
      ApiMethod.GET,
      this.routes.GET_LANGUAGES!
    );

    return result.data;
  }
}

export const languageRequest = new LanguageRequest();
