export default {
  wallet: {
    myWallet: 'Portofelul Meu',
    availableBalance: 'Balanta disponibilă',
    upcomingMoney: 'Bani viitori',
    withdrawMoneyTo: 'Retrage banii către',
    primaryBankAccount: 'Contul Bancar Principal',
    note:
      'Notă: Vei primi automat banii tăi la fiecare 3 zile după ce Experiența este completată cu succes ' +
      'de ambele părți, dacă nu au fost retrași manual.',
    changeBankAccount:
      'Întotdeauna poți schimba contul tău bancar accesând Setările Financiare.',
    withdrawSuccessful: 'Banii au fost retrași cu succes!',
    receiveStatement: 'Alege cum să primești dovada',
    mail: 'Mail',
    download: 'Descarcă',
  },
};
