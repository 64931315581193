import styled from 'styled-components';
import ExmindsBlackTextLogo from '../../../../../assets/logo/ExmindsBlackTextLogo';

export const Container = styled.div`
  position: fixed;
  overflow: hidden;
  height: 100vh;
  height: 100svh;
  width: 100vw;
  min-width: 320px;
  background: ${({ theme }) => theme.palette.main.white};
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 20px;
`;

export const StyledLogo = styled(ExmindsBlackTextLogo)`
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }

  animation: pulse 3s ease-in-out infinite;
  animation-fill-mode: forwards;
`;
