export default {
  errors: {
    short: 'Acest câmp este prea scurt',
    long: 'Acest câmp este prea lung',
    required: 'Acest câmp este obligatoriu',
    notANumber: 'Acest câmp trebuie să fie un număr',
    specific: {
      minimumLength: 'Lungime minimă:',
      maximumLength: 'Lungime maximă:',
      minimumValue: 'Valoare minimă:',
      maximumValue: 'Valoare maximă:',
      characters: 'caractere',
    },
    price: {
      minimumPriceExceedsMax: 'Prețul minim nu poate depăși prețul maxim',
      maximumPriceBelowMin:
        'Prețul maxim nu poate fi mai mic decât prețul minim',
    },
    dateBeforeCurrentDate: 'Data trebuie să fie înainte de data curentă',
    startDateBeforeCurrentDate:
      'Data de început trebuie să fie înainte de data curentă',
    endDateBeforeCurrentDate:
      'Data de finalizare trebuie să fie înainte de data curentă',
    startDateBeforeEndDate:
      'Data de început trebuie să fie înainte de data de finalizare',
    endDateAfterStartDate:
      'Data de finalizare trebuie să fie după data de început',
    invalidEmailAddress: 'Adresa de email invalidă',
    invalidIbanFormat: 'Format IBAN invalid',
    invalidPhoneNumber: 'Vă rugăm, asigurați-vă că numărul este valid',
    underage18: 'Utilizatorii trebuie să aibă cel puțin 18 ani',
    underage13: 'Explorerii trebuie să aibă cel puțin 13 ani',
    general: 'Ceva a mers prost. Vă rugăm să încercați din nou!',
    fileSizeTooLargeOneGb:
      'Dimensiunea fișierului prea mare. Vă rugăm să selectați un fișier care este de 1 GB sau mai mic!',
  },
};
