import { useLocation, useNavigate } from 'react-router-dom';

function useInAppNavigate() {
  const routerNavigate = useNavigate();
  const { state } = useLocation();

  const navigate = (
    path: string,
    extraState?: { [key: string]: unknown },
    navigationOptions?: { replace?: boolean }
  ) => {
    routerNavigate(path, {
      state: {
        isNavigatedFromApp: true,
        ...(extraState ? { ...extraState } : {}),
      },
      replace: navigationOptions?.replace,
    });
  };

  const navigateBack = (fallbackRoute: string, index?: number) =>
    state?.isNavigatedFromApp
      ? routerNavigate(index ?? -1)
      : navigate(fallbackRoute);

  return { navigate, navigateBack };
}

export default useInAppNavigate;
