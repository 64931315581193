import { useQuery } from '@tanstack/react-query';
import { countryRequest } from 'api/CountryRequest';
import { ApiResponseGetCountries } from 'api/types/country';

function useGetCountries(enabled?: boolean) {
  const QUERY_KEY = ['Countries'];

  return useQuery<ApiResponseGetCountries[], Error>({
    queryKey: QUERY_KEY,
    queryFn: () => countryRequest.getCountries(),
    enabled,
  });
}
export default useGetCountries;
