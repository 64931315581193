import styled from 'styled-components';

export const Container = styled.button<{
  $hoverColor: string | undefined;
  $textColor: string;
}>`
  width: 100%;
  max-width: 185px;
  height: 45px;
  min-height: 45px;
  border-radius: 90px;
  border: 0;
  outline: none;

  box-shadow: 0 4px 7px rgba(0, 0, 0, 0.25);

  cursor: pointer;

  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-weight: 700;
  font-size: ${({ theme }) => theme.typography.pxToRem(0)};
  color: ${({ $textColor }) => $textColor};
  background: ${({ theme }) => theme.palette.main.white};
  white-space: pre-wrap;

  :disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  overflow: hidden;

  background: ${({ color }) => color};

  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 0 15px;

  @media (hover: hover) {
    &:hover:not([disabled]) {
      background: ${({ $hoverColor }) => $hoverColor};
    }
  }
`;
