export default {
  filters: {
    experienceType: 'Experience Type',
    priceRange: 'Price Range',
    currency: 'Currency',
    country: 'Country',
    city: 'City',
    spokenLanguage: 'Spoken Language',
    domain: 'Domain',
    minimumPrice: 'Minimum price',
    maximumPrice: 'Maximum price',
  },
};
