export default {
  workExperienceModal: {
    errors: {
      short: 'Acest câmp este prea scurt',
      long: 'Acest câmp este prea lung',
      required: 'Acest câmp este obligatoriu',
      futureDate: 'Nu se poate seta o dată în viitor',
      afterStartDate: 'Data de finalizare trebuie să fie după data de început',
    },
    title: 'ADAUGĂ EXPERIENȚĂ DE MUNCĂ',
    titleEdit: 'EDITEAZĂ EXPERIENȚA DE MUNCĂ',
    subtitle: '*Câmp obligatoriu',
    form: {
      title: 'Titlu*',
      companyName: 'Numele companiei*',
      country: 'Țara*',
      location: 'Oraș*',
      startDate: 'Data de început*',
      endDate: 'Data de finalizare*',
      isPresentJob: 'Lucrez în prezent aici',
      description: 'Descriere*',
      industry: 'Industria*',
    },
    placeholder: {
      title: 'Ex: Manager Rețele Sociale',
      companyName: 'Ex: Coca Cola',
      country: 'Ex: Germania',
      location: 'Ex: Berlin',
      startDate: 'Selectează luna și anul',
      endDate: 'Selectează luna și anul',
      description: 'Scrie aici maxim 500 de caractere',
      industry: 'Ex: Marketing',
      present: 'Prezent',
    },
    button: {
      exit: 'Ieșire',
      done: 'Gata',
    },
  },
};
