const formatDomainHobbyName = (string: string) => {
  if (!string) {
    return '';
  }
  const words = string.split('_');

  const formattedWords = words.map((word) => {
    const firstLetter = word.charAt(0).toUpperCase();
    const remainingLetters = word.slice(1);
    const formattedWord = firstLetter + remainingLetters;
    return formattedWord === 'And' ? '&' : formattedWord;
  });

  return formattedWords.join(' ');
};

export default formatDomainHobbyName;
