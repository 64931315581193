export default {
  notifications: {
    title: 'Notifications',
    news: 'News',
    messages: 'Messages',
    noNewsTitle: 'No news for today!',
    noNewsDescription:
      'Explore the Exminds Universe and get in touch with others around!',
    noMessagesTitle: 'No messages for today!',
    noMessagesDescription:
      'Explore the Exminds Universe and get in touch with others around!',
    experienceWillStart: 'Your Experience will start in',
    makeSureYouAreReady:
      'Make sure you are ready to enjoy your awaiting Experience!\n\nYou didn’t forget, didn’t you?',
    experienceWillTakePlace:
      'Your Experience will take place soon! Make sure you have everything ready.\n\nYou don’t want to miss it, right?',
    yourExperienceIsCloser:
      'Your Experience is closer than you think! Make sure you’re nearby your meeting point and you won’t be late!\n\nThe Exminder is waiting to meet you!',
  },
};
