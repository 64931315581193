import styled, { css, keyframes } from 'styled-components';

const determineHeight = (
  extendedCardHeight: boolean,
  showUser: boolean
): string => {
  if (extendedCardHeight) {
    return showUser ? '618px' : '537px';
  }
  if (showUser) {
    return '563px';
  }
  return '482px';
};

const shimmerAnimation = keyframes`
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
`;

const shimmerContainer = css`
  animation: ${shimmerAnimation} 1.5s infinite linear;
  background: linear-gradient(
    to right,
    rgba(111, 210, 215, 0.25) 4%,
    rgba(51, 191, 199, 0.22) 25%,
    rgba(111, 210, 215, 0.25) 50%
  );
  background-size: 200% 100%;
`;

export const Container = styled.div<{
  extendedCardHeight: boolean;
  showUser: boolean;
}>`
  position: relative;
  max-width: 350px;
  min-width: 220px;
  width: 100%;
  height: ${({ extendedCardHeight, showUser }) =>
    determineHeight(extendedCardHeight, showUser)};
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.05);
  border-radius: 24px;
  background: #c7e6e9;
  overflow: hidden;
`;
export const CardContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const ImageContainer = styled.div`
  position: relative;
  max-width: 350px;
  max-height: 350px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  ${shimmerContainer};
`;

export const UserAvatarContainer = styled.div`
  width: 60px;
  height: 60px;
`;

export const UserAvatar = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 10px;
  ${shimmerContainer};
`;

export const DetailRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

export const Detail = styled.div`
  width: 100%;
  height: 18px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  ${shimmerContainer};
`;

export const LargeDetail = styled(Detail)`
  height: 24px;
`;

export const SmallDetail = styled(Detail)`
  height: 16px;
`;

export const ExperienceDetailsContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
  padding: 20px;
  border-radius: 0 0 24px 24px;
`;

export const ExperienceDetailsContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  color: ${({ theme }) => theme.palette.main.white};
  background: ${({ theme }) => theme.palette.main.cyan};
  opacity: 0.5;
`;

export const Button = styled.div`
  width: 100%;
  height: 45px;
  border-radius: 90px;
  display: flex;
  ${shimmerContainer};
`;

export const UserDetails = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const UserDetailsContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export const UserNameWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const UserNameContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: space-between;
`;
