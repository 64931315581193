import { lazy } from 'react';
import PATH from './paths';
import { Route } from './types';

const ConnectedReferralDashboardPage = lazy(
  () =>
    import(
      '../components/smart/pages/referralDashboard/ConnectedReferralDashboardPage'
    )
);

const REFERRAL_DASHBOARD: Route = {
  component: ConnectedReferralDashboardPage,
  path: PATH.REFERRAL_DASHBOARD,
  isProtected: true,
  hasMenuBar: true,
};

export default [REFERRAL_DASHBOARD];
