import { ReactElement } from 'react';
import * as SC from './styles';

interface MenuBarItemProps {
  item: {
    name: string;
    value: string;
    icon: ReactElement;
    text: string;
    disabled: boolean;
    hasNotificationDot: boolean;
    clickFunction: CallbackFunction;
  };
  notificationsCount: number;
}
export default function MenuBarItem({
  item,
  notificationsCount,
}: MenuBarItemProps) {
  return (
    <SC.ItemContainer
      onClick={() => {
        item.clickFunction();
      }}
      disabled={item.disabled}
    >
      <SC.IconContainer>
        {item.icon}
        {item.hasNotificationDot && notificationsCount > 0 && (
          <SC.NotificationDot>
            {notificationsCount <= 9 ? notificationsCount : '9+'}
          </SC.NotificationDot>
        )}
      </SC.IconContainer>
      <SC.ItemText>{item.text}</SC.ItemText>
    </SC.ItemContainer>
  );
}
