import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
`;

export const TextContainer = styled.div`
  width: 100%;
  max-width: 320px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
`;

export const Text = styled.div`
  text-align: center;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-weight: 500;
  font-size: ${({ theme }) => theme.typography.pxToRem(0)};
  line-height: ${({ theme }) => theme.typography.pxToRem(4)};
  color: ${({ theme }) => theme.palette.main.darkMarine};
`;

export const LinkText = styled.div`
  text-align: center;
  text-decoration: underline;
  cursor: pointer;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-weight: 700;
  font-size: ${({ theme }) => theme.typography.pxToRem(0)};
  color: ${({ theme }) => theme.palette.main.darkMarine};
`;

export const AddButton = styled.div`
  width: 100%;
  max-width: 386px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    85.62deg,
    rgba(0, 175, 185, 0.45) 20.75%,
    rgba(0, 130, 170, 0.45) 100.57%
  );
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
  border-radius: 23px;
  cursor: pointer;
`;

export const WhiteText = styled.div`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-weight: 700;
  font-size: ${({ theme }) => theme.typography.pxToRem(0)};
  line-height: 18px;
  color: ${({ theme }) => theme.palette.main.white};
`;
