export default {
  languages: {
    editLanguages: 'Editează limbile',
    romanian: '🇷🇴 Română',
    english: '🇬🇧 Engleză',
    french: '🇫🇷 Franceză',
    italian: '🇮🇹 Italiană',
    spanish: '🇪🇸 Spaniolă',
    simplifiedChinese: '🇨🇳 Chineză Simplificată',
    german: '🇩🇪 Germană',
    portuguese: '🇵🇹 Portugheză',
    hungarian: '🇭🇺 Maghiară',
  },
};
