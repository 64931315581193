export default {
  stripeRegistration: {
    button: {
      yes: 'Yes',
      no: 'No',
    },
    registerForm: {
      title: 'Register your Stripe Account',
      errors: {
        birthDate: 'Birth date must be earlier than today',
        short: 'Too Short!',
        long: 'Too Long!',
      },
      form: {
        firstName: 'First Name*',
        lastName: 'Last Name*',
        email: 'E-mail*',
        mobile: 'Mobile*',
        address: 'Address*',
        city: 'City*',
        postalCode: 'Postal Code*',
        dateOfBirth: 'Date of Birth*',
        iban: 'IBAN*',
        mcc: 'MCC',
        website: 'Website',
      },
    },
    registerBusinessForm: {
      title: 'Register your Stripe Account',

      businessForm: {
        companyName: 'Company Name*',
        companyCountry: 'Company Country*',
        companyCity: 'Company City*',
        companyAddress: 'Company Address*',
        companyState: 'Company County',
        companyPostalCode: 'Company Postal Code*',
        cif: 'CIF*',
        regCom: 'Reg. Com.*',
        companyBankName: 'Bank Name*',
        companyBankIban: 'IBAN*',
        allowExmindsIssueInvoiceOnBehalfOfCompany:
          'Allow Exminds to send invoices to Explorers on behalf of your company',
      },
    },
    index: {
      title: 'How do you use Exminds?',
      description:
        ' This information will be required if you want to make transactions on the app',
      companyUpgrade:
        'If you want to upgrade from Individual to Company you need to create a new account',
    },
    confirmationModal: {
      title: 'Are you sure about this?',
      description:
        'You won’t be able to change it later. If you ever want to change these details, please register your account to Stripe Connect.',
    },
    registerSuccess: {
      title: 'You’ve registered your tax account',
      description: 'You can now make the Experience active for the Explorers',
      confirmDescription:
        'Confirming your Stripe account is the last step to activate the Experience for Explorers.',
      confirmStripeAccount: 'Confirm Stripe Account',
      seeExperiences: 'See Experiences',
    },
    entities: {
      individual: 'Individual',
      individualDescription: 'I use Exminds as an individual',
      company: 'Company',
      companyDescription: 'I use Exminds as a company',
    },
  },
};
