import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ButtonContainer = styled.div`
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 4px;

  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-weight: 700;
  font-style: normal;
  font-size: ${({ theme }) => theme.typography.pxToRem(-2)};
  color: ${({ theme }) => theme.palette.main.marine};
  line-height: 18px;
  text-align: center;

  @media (hover: hover) {
    &:hover {
      opacity: 0.7;
    }
  }

  & {
    -webkit-tap-highlight-color: transparent;
  }

  :disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export const SelectionLineContainer = styled.div`
  width: 100%;
  height: 5px;
`;

export const SelectionLine = styled.div`
  width: 100%;
  height: 5px;
  border-radius: 20px;
  background: ${({ theme }) => theme.palette.main.marine};
`;

export const StyledIconWrapper = styled.div`
  & svg {
    path {
      fill: ${({ theme }) => theme.palette.main.marine};
    }
  }
`;
