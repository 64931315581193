export default {
  exminderPackage: {
    subtitle: 'What do we learn?',
    book: 'Book',
    preview: {
      seeProfile: 'See\nProfile',
      presentationImage: 'Presentation Image',
      presentationVideo: 'Presentation Video',
      youCanAlsoSee: 'You can also see',
    },
    location: 'Location',
    directions: 'Directions',
    description: 'Description',
    exminderDisclaimer: 'Exminder Disclaimer',
    exmindsDisclaimer: 'Exminds Disclaimer',
    additionalCosts: 'Additional Costs',
  },
};
