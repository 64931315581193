export default {
  awardModal: {
    title: 'ADD AWARD',
    titleEdit: 'EDIT AWARD',
    subtitle: '*Indicates required field',
    form: {
      name: 'Name*',
      place: 'Place*',
      description: 'Description*',
      date: 'Date*',
      link: 'Link*',
      presentationPhoto: 'Presentation photo*',
    },
    view: {
      name: 'Name',
      place: 'Place',
      description: 'Description',
      date: 'Date',
      link: 'Link',
      presentationPhoto: 'Presentation photo',
    },
    placeholder: {
      name: 'Ex: The power of Now',
      place: 'Ex: 1st 2nd 3rd',
      time: 'Ex: June 2020',
      description: 'Type here maximum 500 characters',
      link: 'Ex: direct link to buy it on Amazon',
    },
    button: {
      exit: 'Exit',
      done: 'Done',
    },
  },
};
