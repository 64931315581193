import { JSX, SVGProps } from 'react';

export default function InProgressIcon(
  props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>
) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.9574 3.27637C16.7172 3.27637 13.5497 4.23721 10.8556 6.0374C8.16139 7.83758 6.06154 10.3963 4.82155 13.3899C3.58156 16.3834 3.25712 19.6775 3.88926 22.8555C4.52141 26.0335 6.08173 28.9527 8.37293 31.2439C10.6641 33.5351 13.5833 35.0954 16.7613 35.7275C19.9393 36.3597 23.2333 36.0352 26.2269 34.7952C29.2205 33.5553 31.7792 31.4554 33.5794 28.7612C35.3796 26.0671 36.3404 22.8996 36.3404 19.6593C36.3404 17.5079 35.9167 15.3775 35.0933 13.3899C34.27 11.4022 33.0633 9.59613 31.542 8.07483C30.0207 6.55353 28.2146 5.34677 26.2269 4.52345C24.2393 3.70013 22.1089 3.27637 19.9574 3.27637ZM19.9574 32.7657C17.3653 32.7657 14.8313 31.9971 12.6759 30.5569C10.5206 29.1168 8.84072 27.0698 7.84873 24.6749C6.85674 22.2801 6.59719 19.6448 7.1029 17.1024C7.60861 14.56 8.85688 12.2247 10.6898 10.3917C12.5228 8.55877 14.8581 7.31051 17.4005 6.8048C19.9429 6.29909 22.5782 6.55864 24.973 7.55063C27.3679 8.54262 29.4149 10.2225 30.855 12.3778C32.2952 14.5332 33.0638 17.0671 33.0638 19.6593C33.0638 23.1354 31.683 26.469 29.2251 28.927C26.7671 31.3849 23.4335 32.7657 19.9574 32.7657ZM23.3979 15.7766L21.5957 16.8251V11.4679C21.5957 11.0334 21.4231 10.6166 21.1159 10.3094C20.8087 10.0022 20.392 9.82956 19.9574 9.82956C19.5229 9.82956 19.1062 10.0022 18.799 10.3094C18.4918 10.6166 18.3192 11.0334 18.3192 11.4679V19.6593C18.3192 19.6593 18.3192 19.6593 18.3192 19.7576C18.3115 19.8667 18.3115 19.9762 18.3192 20.0853C18.3445 20.1859 18.3774 20.2845 18.4175 20.3802C18.4175 20.3802 18.4175 20.3802 18.4175 20.4785C18.4957 20.5947 18.5834 20.7043 18.6796 20.8062C18.8119 20.9374 18.9675 21.0429 19.1383 21.1174H19.2694C19.4828 21.2272 19.7177 21.2887 19.9574 21.2976C20.1484 21.2922 20.3369 21.2534 20.5145 21.183H20.6619H20.7766L25.0362 18.7255C25.4142 18.5083 25.6904 18.1497 25.8041 17.7288C25.9178 17.3079 25.8596 16.8591 25.6423 16.481C25.4251 16.103 25.0666 15.8268 24.6457 15.7131C24.2247 15.5994 23.7759 15.6576 23.3979 15.8749V15.7766Z"
        fill="white"
      />
    </svg>
  );
}
