export default {
  report: {
    whyAreYouReporting: 'Why are you reporting',
    letUsKnowMoreDetails:
      'Let us know more details about the situation. It will help figure it out more quickly.',
    category: {
      nudity: 'Nudity',
      violence: 'Violence',
      harassment: 'Harassment',
      suicide_or_self_injury: 'Self Injury',
      false_information: 'False Information',
      spam: 'Spam',
    },
    youHaveReported: 'You’ve reported',
    yourReportWasSent:
      'Your report was sent to our Support team. We’ll notify you once we’ve analyzed your request.',
    experience: {
      doubts: 'Having any doubts about the Experience?',
      reportExperience: 'Report Experience',
      whyAreYouReporting: 'Why are you reporting this Experience?',
      youHaveReported: 'You’ve reported this Experience',
    },
  },
};
