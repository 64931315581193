// @ts-strict-ignore
import AbstractRequest from './AbstractRequest';
import { ApiMethod } from './constants';
import AuthClientStore from '../clientStore/AuthClientStore';
import { FileResponse } from './types/file';
import {
  AWS_S3_CDN_ENDPOINT,
  IS_DEV_APP_ENVIRONMENT,
} from '../helpers/constants/envVars';

export interface ApiResponseUploadFile {
  id: string;
  extension: string;
  mimetype: string;
  name: string;
  path: string;
  size: string;
  uploaderId: string;
}

export class FileRequest extends AbstractRequest {
  get apiResource(): string {
    return 'file';
  }

  get routes(): Record<string, string> {
    return {
      UPLOAD_FILE: `${this.apiRoute}/upload`,
    };
  }

  async uploadFile(
    file: File,
    onUploadProgress: CallbackFunction = () => null
  ): Promise<ApiResponseUploadFile> {
    const formData = new FormData();
    formData.append('file', file);

    const response = await this.request(
      ApiMethod.POST,
      this.routes.UPLOAD_FILE!,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${AuthClientStore.getToken()}`,
        },
        onUploadProgress,
      }
    );

    return response?.data;
  }

  getPublicUrl(file: FileResponse): string {
    if (!file?.path) {
      console.warn('No file path provided.');
      return '';
    }
    let filePath = file.path.replace(/ /g, '%20');

    if (IS_DEV_APP_ENVIRONMENT) {
      filePath = filePath.replace('public/', '');
    }

    return `${AWS_S3_CDN_ENDPOINT}/${filePath}`;
  }
}

export const fileRequest = new FileRequest();
