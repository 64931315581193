import { lazy } from 'react';
import PATH from './paths';
import { Route } from './types';

const ConnectedFeedbackFormPage = lazy(
  () => import('../components/smart/pages/feedback/ConnectedFeedbackFormPage')
);

const ConnectedFeedbackConfirmationPage = lazy(
  () =>
    import(
      '../components/smart/pages/feedback/ConnectedFeedbackConfirmationPage'
    )
);

const SEND_FEEDBACK: Route = {
  component: ConnectedFeedbackFormPage,
  path: PATH.SEND_FEEDBACK,
  isProtected: true,
  hasMenuBar: false,
};

const FEEDBACK_SENT_CONFIRMATION: Route = {
  component: ConnectedFeedbackConfirmationPage,
  path: PATH.FEEDBACK_SENT_CONFIRMATION,
  isProtected: true,
  hasMenuBar: false,
};

export default [SEND_FEEDBACK, FEEDBACK_SENT_CONFIRMATION];
