export default {
  experienceChanges: {
    seeExperience: 'Vezi Experiența',
    reject: 'Refuză',
    accept: 'Acceptă',
    seeCalendar: 'Vezi Calendarul',
    goToMenu: 'Mergi la Meniu',
    exminderView: {
      pendingApproval: 'În așteptarea aprobării...',
      oneExplorerRejected: 'Un Explorer a refuzat noua dată a Experienței!',
      explorerRejected: 'Explorerul a refuzat noua dată a Experienței!',
      explorersRejected: 'Exploreri au refuzat noua dată a Experienței!',
      allExplorersRejected:
        'Toți Explorerii au refuzat noua dată a Experienței!',
      oneExplorerApproved: 'Un Explorer a aprobat noua dată a Experienței!',
      explorerApproved: 'Explorerul a aprobat noua dată a Experienței!',
      explorersApproved: 'Explorerii au aprobat noua dată a Experienței!',
      allExplorersAccepted:
        'Toți Explorerii au aprobat noua dată a Experienței!',
      youWillBeCharged:
        'Din acest motiv se va percepe o taxă de 3% din contul tău bancar',
      promoteYourExperience:
        'Continuă să îți promovezi Experiența pentru a întâlni noi Exploreri',
      youAreInLuck: 'Ești norocos!\nNu vei fi taxat pentru această schimbare!',
      seeFullList: 'Vezi Lista Completă',
      approved: 'Aprobată',
      rejected: 'Refuzată',
      updatedTheDateInYourCalendar:
        'Data a fost actualizată în Calendarul tău!',
    },
    experienceDateChange: {
      changedTheDate: 'a schimbat data Experienței pe care ai cumpărat-o',
      oldDate: 'Data Veche',
      newDate: 'Data Nouă',
    },
    experienceDateChangeConfirmation: {
      areYouSure: 'Ești sigur că vrei să schimbi data Experienței?',
      youWillBeCharged:
        'Ți se va percepe o taxă de 3% în cazul în care Explorerii nu vor accepta schimbarea și anulează Experiența',
      byTickingThisBox: 'Accept ',
    },
    experienceNewDateRejection: {
      experienceAutomaticallyCanceled:
        'Experiența a fost anulată automat din cauza lipsei de răspuns',
      exminderWillBeNotified: 'Exminderul va fi notificat despre decizia ta',
      youRejectedTheNewDate: 'Ai refuzat noua dată',
      yourCalendarWasUpdated: 'Calendarul tău a fost actualizat automat!',
    },
    areYouSureYouWantToMissTheExperience:
      'Ești sigur că vrei să abandonezi Experiența?',
    otherExplorers: 'Alți Exploreri vor putea să îți ia locul!',
    exmindsWillRefund:
      'Cu toate acestea, dacă dorești să continui, Exminds îți va rambursa costul Experienței în termen de 10 zile lucrătoare.',
    youRejectedTheExperience: 'Ai refuzat Experiența!',
    sorryToSeeYouGo: 'Ne pare rău că pleci!',
    weAreSorry: 'Ne pare rău!',
    canceledTheExperience: 'a anulat Experiența!',
  },
};
