import { Navigate } from 'react-router-dom';
import PATH from 'routes/paths';
import { ElementType, ReactElement } from 'react';
import {
  authUserOnboardingIsCompletedSelector,
  authUserOnboardingIsSkipedSelector,
  isAuthenticatedSelector,
} from 'redux/slices/selectors';
import { useAppSelector } from '../../../hooks/redux';
import MainLayout from '../MainLayout';

export default function AppRoute({
  component: Component,
  isProtected,
  redirectIfAuthenticated,
  isOnboarding,
  hasMenuBar,
}: {
  component: ElementType;
  isProtected: boolean;
  redirectIfAuthenticated?: boolean;
  isOnboarding?: boolean;
  hasMenuBar?: boolean;
}): ReactElement {
  const isAuthenticated = useAppSelector(isAuthenticatedSelector);
  const authUserOnboardingIsCompleted = useAppSelector(
    authUserOnboardingIsCompletedSelector
  );
  const isOnboardingSkipped = useAppSelector(
    authUserOnboardingIsSkipedSelector
  );

  if (isProtected && !isAuthenticated) {
    return <Navigate to={PATH.AUTH.WELCOME} replace />;
  }

  if (redirectIfAuthenticated && isAuthenticated) {
    return <Navigate to={PATH.INDEX} replace />;
  }

  if (
    isAuthenticated &&
    !authUserOnboardingIsCompleted &&
    !isOnboarding &&
    !isOnboardingSkipped
  ) {
    return <Navigate to={PATH.ONBOARDING.CHOOSE_ROLE} replace />;
  }

  if (hasMenuBar) {
    return (
      <MainLayout>
        <Component />
      </MainLayout>
    );
  }

  return <Component />;
}
