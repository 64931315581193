import { useEffect } from 'react';
import ExperienceViewClientStore from 'clientStore/ExperienceViewClientStore';
import PATH from 'routes/paths';
import { experienceId } from 'routes/paths/constants';
import useInAppNavigate from './useInAppNavigate';

function useRedirectToExperienceView(): void {
  const { navigate } = useInAppNavigate();
  useEffect(() => {
    const localStorageExperienceId =
      ExperienceViewClientStore.getExperienceView();

    if (localStorageExperienceId) {
      ExperienceViewClientStore.removeExperienceView();
      navigate(
        PATH.EXPERIENCE.VIEW_ONE.replace(experienceId, localStorageExperienceId)
      );
    }
  }, [experienceId]);
}

export default useRedirectToExperienceView;
