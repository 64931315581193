export default {
  appFeedback: {
    tellUsYourFeedback: 'Așteptăm cu interes feedback-ul tău!',
    continuouslyImproving:
      'Îmbunătățirea continuă a aplicației este o prioritate pentru noi. Valorăm și apreciem sincer orice formă de feedback, fie că este pozitiv sau constructiv.',
    typeInYourThoughts: 'Împărtășește-ți gândurile despre Exminds...',
    confirmation: {
      thankYou: 'Mulțumim pentru feedback-ul tău!',
      exmindsUniverse:
        'Universul Exminds se dezvoltă!' +
        '\n\n' +
        'Echipa noastră analizează acum feedback-ul primit. Vom lua legătura folosind adresa de email asociată acestui cont: ',
    },
  },
};
