export default {
  news: {
    new_package_approved: {
      title: 'Check out the new Experience',
      subtitle:
        'Hey {{receiverFirstName}}, a new Experience has been published by {{exminderName}}. Check out {{experienceName}}. It is happening in {{experienceLocation}} and is related to the {{experienceDomain}} domain.',
    },
    interested_in_experience: {
      title: 'Someone new is interested in your Experience',
      title_secondary: 'The Experiences are expiring',
      subtitle:
        'Check who wants to buy your Experience and start discussing on the available dates once the Experience is purchased!',
    },
    complete_profile: {
      title: 'Complete your profile information!',
      subtitle:
        'There’s more information the Explorers want to know about you, before publishing your first Experience.',
    },
    package_sold: {
      title: 'Your "{{experienceTitle}}" Experience was sold! 💸',
      subtitle: '{{explorerName}} just bought your Experience. Well done! 😁',
    },
    package_bought: {
      title: 'You can now message {{exminderName}}!',
      subtitle:
        'Find out more about the Experience by asking more questions or anything you like.',
    },
    package_canceled: {
      title: 'Your "{{experienceTitle}}" Experience was canceled! ❌',
      subtitle:
        'We’re sorry! {{exminderName}} just canceled your Experience 😔',
    },
    package_completed: {
      title:
        ' Your most recent experience "{{experienceTitle}}" is completed! Leave a review!',
      explorerSubtitle:
        'Tell us your impression about the Experience and about the Exminder {{exminderName}}!',
      exminderSubtitle:
        'Tell us your impression about the Explorer {{explorerName}}!',
    },
    purchased_package_date_updated: {
      title: '{{exminderName}} changed the date of the Experience!',
      subtitle:
        'The date of your recently bought Experience was changed just now by the Exminder!',
    },
    purchased_package_date_update_accepted: {
      title: '{{explorerName}} accepted the date update!',
      subtitle:
        'The date change you requested for your Experience was accepted by the Explorer!',
    },
    purchased_package_date_update_rejected: {
      title: '{{explorerName}} declined the date update!',
      subtitle:
        'The date change you requested for your Experience was declined by the Explorer.',
    },
    favorite_package_spot_freed: {
      title: 'The Experience you saved has an avaliable spot!',
      subtitle:
        'A spot for on of your favorite Experience just opened up! Explore it now!',
    },
    package_payment_refunded: {
      title: 'Your money were transfered in your bank account',
      subtitle:
        'Your funds from the experience were successfully transferred to your bank account. 💰',
    },
    package_7_days_before_start: {
      title: '7 days until your "{{experienceTitle}}" Experience',
      subtitle:
        'There’s one week left until your experience starts. Time to prepare yourself!',
    },
    package_1_day_before_start: {
      title: '1 day until your "{{experienceTitle}}" Experience',
      subtitle: 'Your Experience is only 1 day away. Get ready!',
    },
    package_2_hours_before_start: {
      title: 'Less than 2 hours until your "{{experienceTitle}}" Experience',
      subtitle:
        'Your Experience is happening in 2 hours!!! Are you ready already? Don’t be late!',
    },
    join_package_request_created: {
      title:
        'Someone new is interested in your "{{experienceTitle}}" Experience',
      subtitle:
        'Check who wants to buy your Experience and start discussing on the available dates once the Experience is purchased!',
    },
    join_package_request_accepted: {
      title: '{{exminderName}} approved your request',
      subtitle:
        'The Experience you requested is available now and the Exminder is ready to meet you! See more about it!',
    },
    join_package_request_rejected: {
      title: '{{exminderName}} declined your request',
      subtitle:
        'The Experience you requested is not available now. See more about it!',
    },
    pending_approval_package_accepted: {
      title: 'Your Experience was approved!',
      subtitle:
        '"{{experienceName}}" was approved by the Exminds team. Now it can be discovered by all Explorers! ',
    },
    pending_approval_package_rejected: {
      title: 'Your Experience was declined!',
      subtitle:
        '"{{experienceName}}" was declined by the Exminds team. Make sure the Experience follow the app’s requirements. ',
    },
    packageReminder_2days: {
      title: 'Don’t forget to buy the Experience!',
      subtitle:
        'Hey {{receiverFullName}}, just a friendly reminder that your request for {{experienceName}} Experience was accepted by {{exminderName}}. Make sure to buy it before it is too late!',
    },
    packageReminder_3days: {
      title: 'Are you still interested?',
      subtitle:
        'Hurry up, {{receiverFullName}}, your request for {{experienceName}} Experience was accepted by {{exminderName}}. Buy it before it is too late!',
    },
    packageReminder_lastDay: {
      title: 'Only one day left to buy the Experience',
      subtitle:
        'Hurry up, {{receiverFullName}}, it is the last day to buy {{experienceName}} Experience. Buy it before it is too late!',
    },
  },
};
