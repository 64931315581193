import { useQuery } from '@tanstack/react-query';
import { countryRequest } from 'api/CountryRequest';
import { ApiResponseGetCitiesByCountryId } from 'api/types/country';

function useGetCityByCountryId(
  queryOptions: {
    id: string;
    offset: number;
    limit: number;
    searchText?: string;
  },
  enabled?: boolean
) {
  const QUERY_KEY = ['Cities', queryOptions];
  return useQuery<ApiResponseGetCitiesByCountryId[], Error>({
    queryKey: QUERY_KEY,
    queryFn: () => countryRequest.getCitiesByCountryId(queryOptions),
    enabled,
  });
}
export default useGetCityByCountryId;
