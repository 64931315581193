import * as SC from './styles';

interface CustomToastNotificationProps {
  position?:
    | 'top-left'
    | 'top-right'
    | 'top-center'
    | 'bottom-left'
    | 'bottom-right'
    | 'bottom-center';
  limit?: number;
  autoClose?: number | false;
  closeOnClick?: boolean;
  draggable?: boolean;
  newestOnTop?: boolean;
  pauseOnFocusLoss?: boolean;
  rightToLeftLayout?: boolean;
}

export default function CustomToastNotification({
  position = 'top-right',
  limit = 0,
  autoClose = 5000,
  closeOnClick = true,
  draggable = true,
  newestOnTop = false,
  pauseOnFocusLoss = true,
  rightToLeftLayout = false,
}: CustomToastNotificationProps) {
  let isFlutterView = false;
  if (window.flutter_inappwebview) {
    isFlutterView = true;
  }

  return (
    <SC.StyledContainer
      position={position}
      limit={limit}
      autoClose={autoClose}
      closeOnClick={closeOnClick}
      draggable={draggable}
      newestOnTop={newestOnTop}
      pauseOnFocusLoss={pauseOnFocusLoss}
      rtl={rightToLeftLayout}
      isFlutterView={isFlutterView}
    />
  );
}
