import { lazy } from 'react';
import PATH from './paths';
import { Route } from './types';

const ConnectedNotificationsNewsPage = lazy(
  () => import('../components/smart/MainLayout/ConnectedNotificationsPage')
);

const ConnectedChatPage = lazy(
  () => import('../components/smart/pages/chat/ConnectedChatPage')
);

const NOTIFICATIONS_NEWS: Route = {
  component: ConnectedNotificationsNewsPage,
  path: PATH.NOTIFICATIONS.NEWS,
  isProtected: true,
  hasMenuBar: true,
};

const CHAT_MESSAGES: Route = {
  component: ConnectedChatPage,
  path: PATH.NOTIFICATIONS.MESSAGES,
  isProtected: true,
  hasMenuBar: false,
};

export default [NOTIFICATIONS_NEWS, CHAT_MESSAGES];
