import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import LoadingSpinner from 'components/dumb/atomics/LoadingSpinner';
import ArrowBackIcon from '../../../../assets/icons/buttonIcons/ArrowBackIcon';
import * as SC from './styles';
import SideBarMainPageStep from './Steps/SideBarMainPageStep';
import ChangeViewConfirmationStep from './Steps/ChangeViewConfirmationStep';
import Button from '../../atomics/Button';
import { SideBarSteps } from '../../../../helpers/constants/enums';
import ExplorerCompleteProfileStep, {
  ExplorerRequiredInformation,
} from './Steps/ExplorerCompleteProfileStep';
import ExminderCompleteProfileStep, {
  ExminderRequiredInformation,
} from './Steps/ExminderCompleteProfileStep';
import { backgroundContainerClass } from '../../../../helpers/constants/styling';

interface User {
  firstName: string | null;
  lastName: string | null;
}

interface SideBarMenuLayoutProps {
  open: boolean;
  user: User;
  avatar: string;
  isProfileImageCompressing: boolean;
  onClose: CallbackFunction;
  onMyProfileClick: CallbackFunction;
  onAccountSettingsClick: CallbackFunction;
  onMyCalendarClick: CallbackFunction;
  onFavoritesClick: CallbackFunction;
  onStatisticsClick: CallbackFunction;
  onMoneyWithExmindsClick: CallbackFunction;
  onReferralDashboardClick: CallbackFunction;
  onInvoicesClick: CallbackFunction;
  onCommunityGuidelinesClick: CallbackFunction;
  onFaqClick: CallbackFunction;
  onFeedbackClick: CallbackFunction;
  onTermsAndConditionsClick: CallbackFunction;
  onPrivacyPolicyClick: CallbackFunction;
  onLogout: CallbackFunction;
  onChangeAppRole: CallbackFunction;
  onCompleteProfile: CallbackFunction;
  exminderRequiredInformation: ExminderRequiredInformation;
  explorerRequiredInformation: ExplorerRequiredInformation;
  isExminder: boolean;
  isOnboardingSkipped: boolean;
  isChangeRoleLoading: boolean;
  hasReferralAccount: boolean;
}

export default function SideBarMenuLayout({
  open,
  user,
  avatar,
  isProfileImageCompressing,
  onClose,
  onMyProfileClick,
  onAccountSettingsClick,
  onMyCalendarClick,
  onFavoritesClick,
  onStatisticsClick,
  onMoneyWithExmindsClick,
  onReferralDashboardClick,
  onInvoicesClick,
  onCommunityGuidelinesClick,
  onFaqClick,
  onFeedbackClick,
  onTermsAndConditionsClick,
  onPrivacyPolicyClick,
  onLogout,
  onChangeAppRole,
  onCompleteProfile,
  exminderRequiredInformation,
  explorerRequiredInformation,
  isExminder,
  isOnboardingSkipped,
  isChangeRoleLoading,
  hasReferralAccount,
}: SideBarMenuLayoutProps) {
  const theme = useTheme();
  const { t } = useTranslation();
  const [step, setStep] = useState<SideBarSteps>(SideBarSteps.MainPageStep);
  const [animate, setAnimate] = useState<boolean>(false);

  const hasExminderRequiredInformation =
    exminderRequiredInformation.hasWorkExperience &&
    exminderRequiredInformation.hasEducation &&
    exminderRequiredInformation.hasMilestones &&
    exminderRequiredInformation.hasAwards &&
    exminderRequiredInformation.isStripeRegistered;

  const hasExplorerRequiredInformation =
    explorerRequiredInformation.hasBiography &&
    explorerRequiredInformation.hasEducation &&
    explorerRequiredInformation.hasJobRole;

  useEffect(() => {
    document.body.style.overflow = open ? 'hidden' : '';
  }, [open]);

  const handleChangeRole = () => {
    setAnimate(true);

    if (!hasExminderRequiredInformation && !isExminder) {
      setStep(SideBarSteps.CompleteProfileStep);
    } else if (!hasExplorerRequiredInformation && isExminder) {
      setStep(SideBarSteps.CompleteProfileStep);
    } else {
      setStep(SideBarSteps.MainPageStep);
    }

    onChangeAppRole();

    setTimeout(() => {
      setAnimate(false);
    }, 0);
  };

  const handleCompleteProfile = () => {
    onCompleteProfile();
    setStep(SideBarSteps.MainPageStep);
  };

  const handleCancel = () => {
    setStep(SideBarSteps.MainPageStep);
  };

  const onClickChangeRole = () => {
    setStep(SideBarSteps.ViewChangeConfirmationStep);
  };

  return (
    <>
      <SC.OverlayContainer open={open} />
      {user && (
        <SC.Container open={open}>
          <SC.BackgroundContent
            step={step}
            isExminder={isExminder}
            className={backgroundContainerClass}
          >
            <SC.IconButtonContainer>
              <SC.StyledIconButton
                color={theme.palette.main.white}
                icon={<ArrowBackIcon />}
                iconSize={{ width: '17px', height: '17px' }}
                onClick={() => {
                  onClose();
                  setTimeout(() => {
                    setStep(0);
                  }, 500);
                }}
                rotateIcon={180}
              />
            </SC.IconButtonContainer>
            <SC.MainContent currentStep={step}>
              <SideBarMainPageStep
                user={user}
                isOnboardingSkipped={isOnboardingSkipped}
                hasReferralAccount={hasReferralAccount}
                avatar={avatar}
                isProfileImageCompressing={isProfileImageCompressing}
                exminderView={isExminder}
                shouldAnimate={animate}
                onHandleChangeRole={onClickChangeRole}
                onMyProfileClick={onMyProfileClick}
                onAccountSettingsClick={onAccountSettingsClick}
                onMyCalendarClick={onMyCalendarClick}
                onFavoritesClick={onFavoritesClick}
                onStatisticsClick={onStatisticsClick}
                onMoneyWithExmindsClick={onMoneyWithExmindsClick}
                onReferralDashboardClick={onReferralDashboardClick}
                onInvoicesClick={onInvoicesClick}
                onCommunityGuidelinesClick={onCommunityGuidelinesClick}
                onFaqClick={onFaqClick}
                onFeedbackClick={onFeedbackClick}
                onTermsAndConditionsClick={onTermsAndConditionsClick}
                onPrivacyPolicyClick={onPrivacyPolicyClick}
                onLogout={onLogout}
                currentStep={step}
              />
              {step === SideBarSteps.ViewChangeConfirmationStep && (
                <SC.StepContent>
                  <ChangeViewConfirmationStep currentStep={step} />
                  <SC.ButtonGroupContainer>
                    <SC.ButtonGroup>
                      <Button
                        color={theme.palette.main.lightGray}
                        onClick={handleCancel}
                      >
                        {t(`general.cancel`)}
                      </Button>
                      <Button
                        color={theme.palette.button.main.yellow}
                        onClick={handleChangeRole}
                      >
                        {t(`general.confirm`)}
                      </Button>
                    </SC.ButtonGroup>
                  </SC.ButtonGroupContainer>
                </SC.StepContent>
              )}
              {step === SideBarSteps.CompleteProfileStep && (
                <SC.StepContent>
                  {!hasExminderRequiredInformation &&
                    isExminder &&
                    !isChangeRoleLoading && (
                      <ExminderCompleteProfileStep
                        currentStep={step}
                        shouldAnimate={animate}
                        exminderRequiredInformation={
                          exminderRequiredInformation
                        }
                      />
                    )}
                  {!hasExplorerRequiredInformation &&
                    !isExminder &&
                    !isChangeRoleLoading && (
                      <ExplorerCompleteProfileStep
                        currentStep={step}
                        shouldAnimate={animate}
                        explorerRequiredInformation={
                          explorerRequiredInformation
                        }
                      />
                    )}
                  {isChangeRoleLoading && (
                    <SC.StepContent>
                      <LoadingSpinner />
                    </SC.StepContent>
                  )}
                  {!isChangeRoleLoading && (
                    <SC.ButtonGroupContainer>
                      <SC.ButtonGroup>
                        <SC.StyledButton
                          color={
                            isExminder
                              ? theme.palette.button.main.yellow
                              : theme.palette.button.main.marine
                          }
                          onClick={handleCompleteProfile}
                          isExminder={isExminder}
                        >
                          {t(`sidebarMenu.completeProfile.button`)}
                        </SC.StyledButton>
                      </SC.ButtonGroup>
                    </SC.ButtonGroupContainer>
                  )}
                </SC.StepContent>
              )}
            </SC.MainContent>
          </SC.BackgroundContent>
        </SC.Container>
      )}
    </>
  );
}
