export default {
  disclaimer: {
    exminds: 'Termenii & Condițiile Exminds',
    stripe: 'Termenii & Condițiile Stripe',
    exminder: 'Cerințe Exminder',
    explorer: 'Cerințe Explorer',
    termsAndConditions: 'Termeni & Condiții',
    privacyPolicy: 'Politica de Confidențialitate',
    privacyPolicyExminds: 'Politica de Confidențialitate Exminds',
    termsAndConditionsAgree:
      'Pentru a utiliza aplicația trebuie să fii de acord cu Termenii & Condițiile Exminds',
    privacyPolicyAgree:
      'Pentru a utiliza aplicația trebuie să fii de acord cu Politica de Confidențialitate Exminds',
    updated: 'Am actualizat',
    newVersion:
      'Pentru a utiliza aplicația trebuie să fii de acord cu noua versiune a documentului',
    agreeWith: 'Sunt de acord cu',
    agreeWithNew: 'Sunt de acord cu noii',
    agreeWithTermsAndConditions:
      'Sunt de acord cu Termenii & Condițiile Stripe',
    exmindsContent:
      'Vă mulțumim că utilizați aplicația Exminds, dezvoltată și deținută de către Exminds SRL. Înainte de a continua să utilizați această aplicație, vă rugăm să citiți cu atenție următorul disclaimer, care stabilește termenii și condițiile pentru utilizarea acestei experiențe.\n' +
      '1. Acceptarea termenilor\n' +
      '2. Prin utilizarea aplicației Exminds, sunteți de acord și acceptați în mod implicit toți termenii și condițiile prezentate în acest disclaimer. Dacă nu sunteți de acord cu acești termeni, vă rugăm să întrerupeți utilizarea aplicației.\n' +
      '3. Comportamentul utilizatorilor\n' +
      '4. Aplicația Exminds reprezintă o platformă deschisă pentru experiențe și interacțiuni între utilizatori. Cu toate acestea, Exminds SRL nu poate fi responsabilă pentru comportamentul, acțiunile sau declarațiile utilizatorilor săi. Fiecare utilizator își asumă întreaga răspundere pentru propriul său comportament și pentru consecințele acestuia în cadrul experienței oferite de aplicație.\n' +
      '5. Conținutul generat de utilizatori\n' +
      '6. Aplicația Exminds poate conține conținut generat de utilizatori, inclusiv comentarii, recenzii, imagini sau alte tipuri de conținut. Exminds SRL nu își asumă responsabilitatea pentru acuratețea, legalitatea sau relevanța acestui conținut generat de utilizatori. Utilizatorii sunt singurii responsabili pentru conținutul pe care îl încarcă sau îl distribuie prin intermediul aplicației.\n' +
      '7. Limitarea responsabilității\n' +
      '8. Exminds SRL depune toate eforturile pentru a asigura funcționarea corectă și continuă a aplicației. Cu toate acestea, nu putem garanta că aplicația va fi întotdeauna lipsită de erori, întreruperi, întârzieri sau defecțiuni tehnice. Prin urmare, nu ne asumăm nicio responsabilitate pentru eventualele prejudicii sau pierderi suferite ca rezultat al utilizării aplicației Exminds.\n' +
      '9. Modificări ale aplicației și disclaimerului\n' +
      '10. Exminds SRL își rezervă dreptul de a aduce modificări aplicației și acestui disclaimer fără o notificare prealabilă. Utilizatorii sunt responsabili să revină periodic pentru a verifica ultima versiune a acestui disclaimer și să fie la curent cu eventualele schimbări.\n' +
      '11. Contact\n' +
      '12. Pentru orice întrebări, sugestii sau clarificări legate de aplicația Exminds sau de acest disclaimer, vă rugăm să ne contactați la adresa de e-mail contact@exminds.com.\n' +
      'Prin continuarea utilizării aplicației Exminds, vă exprimați acordul cu toate prevederile și condițiile prezentate în acest disclaimer. Vă recomandăm să citiți acest disclaimer periodic pentru a fi la curent cu orice modificări sau actualizări.',
    exminderContent:
      'Prezentul document are natura juridica a unui contract la distanta incheiat intre: a) dumneavoastra in calitate de utlizator al platformei Exminds si b) Exminds SRL, o societate cu sediul in Bucuresti, Ale. Buhusi nr. 5, bl. 6, sc. 1, ap. 5, Sector 3, Romania, inregistrata la Registrul Comertului cu nr. J40/12811/2023, avand CUI 48469851, in calitate de proprietar al platformei Exminds si prestator al serviciilor Exminds.\n' +
      'Prezentul document reglementeaza conditiile in care puteti utiliza Platforma si serviciile Exminds, precum si drepturile si obligatiile dvs. in calitate de utilizator.\n' +
      '\n' +
      '1. Definitii\n' +
      '1.1. Urmatorii termeni folositi in prezentul document au urmatorul inteles, cu exceptia situatiei in care acestia sunt folositi in mod vadit in alt sens.\n' +
      'a) „Platforma” – Platforma Exminds, astfel cum aceasta este disponibila la adresa https://www.app.exminds.com/ \n' +
      'b) „Prestatorul” si/sau „Noi”– EXMINDS SRL, cu sediul in in Bucuresti, Ale. Buhusi nr. 5, bl. 6, sc. 1, ap. 5, Sector 3, Romania, inregistrata la Registrul Comertului cu nr. J40/12811/2023, avand CUI 48469851; Prestatorul este proprietarul Platformei si prestatorul Serviciilor Exminds.\n' +
      'c) „Serviciile Exminds” – Serviciile prestate de Prestator Utilizatorilor, prin intermediul Platformei.\n' +
      'd) „Cont de utilizator” – Contul de Utilizator de tipul „exminder” pe care o persoana il poate crea in cadrul Platformei in scopul utilizarii acesteia. \n' +
      'e) „Utilizator” – orice persoana fizica si/sau juridica ce creaza un cont de utilizator de tipul „exminder” in cadrul Platformei, in scopul utilizarii acesteia; atunci cand creati un cont de utilizator de tip „exminder” aveti totodata acces la optiunile oferite utilizatorilor „explorer” pentru a putea achizitiona Experiente de la alti Utilizatori Exminder\n' +
      'f) „Utilizatori Explorer” –  categorie de utilizatori care creaza un cont de tip „explorer” in cadrul Platformei, avand ca scop achizitia de Experiente prin intermediul Platformei.\n' +
      'g) „Experiente” – experientele listate si vandute de Utilizatorii Exminder prin intermediul Platformei.\n' +
      'h) „Termeni si Conditii” si/sau „T&C” – prezentul document, Nota de informare privind prelucrarea datelor cu caracter personal, termenii si conditii suplimentari disponibili in pagina fiecarei Experiente, precum si dupa caz, orice alte documente suplimentare care reglementeaza relatia juridica dintre Prestator si Utilizatori, astfel cum aceste documente vor fi disponibile in cadrul Platformei;\n' +
      '\n' +
      '2. Acceptarea termenilor si conditiilor. Modificarea acestora.\n' +
      '2.1. Prezentul document se considera inteles si acceptat fara rezerve prin bifarea rubricii „Accept termenii si conditiile” in procedura de creare a contului de utilizator. \n' +
      '2.2. Ne rezervam dreptul de a modifica din timp in timp Termenii si Conditiile cu notificarea prealabila a dvs. In acest sens, va vom notifica prin email sau prin intermediul Platformei privind noua versiune a Termenilor si Conditiilor. Pentru a putea continua utilizarea Platformei si a serviciilor Exminds veti avea obligatia de a accepta noua versiune a Termenilor si Conditiilor.\n' +
      '2.3. In masura in care vom aduce modificari Platformei (inclusiv privind adaugarea de noi functionalitati si/sau vom modificarea functionalitatile existente), prezentele T&C se vor aplica si pentru aceste modificari, fara a fi necesara nicio actiune suplimentara din partea noastra sau a dvs., cu exceptia cazului in care vom publica Termeni si Conditii suplimentari pentru aceste modificari. \n' +
      '2.4. In masura in care vom adauga noi servicii Exminds si/sau vom modifica serviciile actuale, prezetul document se va aplica si cu privire la noile servicii si/sau serviciile modificate, fara a fi necesara nicio actiune suplimentara din partea noastra sau a dvs., cu exceptia cazului in care vom publica Termeni si Conditii suplimentari pentru noile servicii/serviciile modificate. \n' +
      '2.5. Ne rezervam dreptul ca pentru anumite functionalitati ale Platformei sau servicii Exminds sa publicam Termeni si Conditii suplimentari, care sa deroge de la Termenii si Conditiile generale stabilite prin prezentul document. Intr-o atare situatie va vom informa despre T&C suplimentari si va vom solicita sa ii acceptati inainte de a utiliza functionalitatile/Serviciile aferente.\n' +
      '\n' +
      '3. Utilizarea Platformei. Limitele dreptului de utilizare.\n' +
      '3.1. Prestatorul confera Utilizatorului un drept limitat, neexclusiv, netransmisibil si temporar de a utiliza Platforma si serviciile Exminds, in conditiile prezentului document si ale legislatiei incidente.\n' +
      '3.2. Dreptul de utilizare a Platformei  este conferit cu titlu oneros. \n' +
      '3.3. Utilizatorul dobandeste dreptul de a utiliza numai acele functii si servicii ale Platformei pentru care plateste pretul aferent, pe durata si in conditiile mentionate de Prestator prin intermediul prezentelor T&C sau prin intermediul Platformei.   \n' +
      '3.4. In situatia in care Serviciile Exminds sunt oferite in contrapartida platii unui abonament, Utilizatorul va putea beneficia de aceste Servicii numai pe durata platii abonamentului, in avans.\n' +
      '3.5. Prestatorul isi rezerva dreptul de a modifica oricand conditiile de utilizare ale Platformei si/sau Serviciile Exminds, inclusiv pretul acestora.  \n' +
      '3.6. Platforma si Serviciile Exminds pot fi utilizate exclusiv in urmatorul scop: Utilizatorii pot utiliza Platforma: a) pentru a lista si a vinde Experiente catre Utilizatorii Explorers; b) pentru a se informa si dupa caz pentru a achizitiona  Experientele listate de alti Utilizatori Exminder in cadrul Platformei (“scopul permis al utilizarii”).\n' +
      '3.7. Utilizarea Platformei in alte scopuri, cu rea credinta si/sau cu incalcarea prezentelor T&C si/sau a legistiei aplicabile, atrage retragerea dreptului de utilizare a Platformei cu efect imediat. \n' +
      '3.8. Prestatorul isi rezerva dreptul de a suspenda si/sau retrage dreptul Utilizatorului de a utiliza Platforma si serviciile Exminds, cu efect imediat, in situatii precum dar fara a se limita la: utilizatorul incalca prevederile prezentelor T&C si/sau a legislatiei incidente sau exista o suspiciune rezonabila in acest sens; utilizatorul inregistreaza intarziere la plata oricaror sume datorate Prestatorului, recenzii negative sau plageri primite de la Utilizatorii Explorer cu care utilizatorul a interactionat, orice alte situatii obiective. Prestatorul poate notifica Utilizatorul prin intermediul Platformei si/sau prin email cu privire la suspendarea si/sau retragerea dreptului de utilizare.\n' +
      '3.9. Prestatorul isi rezerva dreptul de a modifica oricand si fara notificarea prealabila a Utilizatorului functiile si/sau Serviciile Platformei, sa renunte la anumite functii si/sau servicii, precum si sa adauge noi functii sau servicii. Utilizatorul este in drept sa renunte la utilizarea Platformei si/sau Serviciile Exminds in situatia in care nu este de acord cu functiile/serviciile modificate ale Platformei.\n' +
      '\n' +
      '4. Crearea contului de utilizator. Stergerea acestuia.\n' +
      '4.1. Platforma poate fi utilizata exclusiv de catre utilizatorii inregistrati. Astfel, pentru a putea utiliza Platforma aveti obligatia de a va crea un cont de utilizator in cadrul Platformei.\n' +
      '4.2. Atunci cand creati un cont de utilizator veti dobandi dreptul de a utiliza Platforma in conformitate cu tipul de utilizator selectat, respectiv aveti obligatia de a selecta optiunea „Exminder” pentru a utiliza Platforma ca si “Exminder”. \n' +
      '4.3. Conditii si obligatii aplicabile la crearea contului de utilizator:\n' +
      'a) Puteti crea un cont de utilizator numai daca aveti cel putin 18 ani impliniti.\n' +
      'b) Aveti obligatia de a completa formularul de creare cont utilizator numai cu date si informatii reale, care va apartin, fara a va insusi identitatea unei alte persoane.\n' +
      'c) Aveti obligatia de a accepta Termenii si Conditiile Platformei pentru a putea finaliza procesul de creare cont de utilizator.\n' +
      '4.4. Deschiderea unui cont de utilizator de tip exminder trebuie aprobata de catre Prestator. Prestatorul isi rezerva dreptul de a refuza unui cont de utilizator de tip exminder, fara nicio justificare.\n' +
      '4.5. De asemenea, Prestatorul isi rezerva dreptul de a verifica datele furnizate in cadrul procedurii de creare cont de utilizator si de a refuza deschiderea conturilor de utilizator in cazul in care exista suspiciunea rezonabila ca prezentele T&C sunt incalcate precum si in alte situatii obiective.\n' +
      '\n' +
      '4.6. Stergerea contului de utilizator. \n' +
      '4.6.1. Puteti decide la orice moment sa va stergeti contul de utilizator, caz in care toate datele si informatiile pe care le-ati adaugat in cadrul Platformei nu va vor mai fi disponibile. Datele dvs. cu caracter personal vor fi anonimizate inreversibil, cu exceptia cazurilor mentionate in nota de informare privire prelucrarea datelor cu caracter personal.\n' +
      '4.6.2. Prestatorul poate restrictiona accesul dvs. la contul de utilizator si/sau poate sterge contul dvs. de utilizator, in cazul in care acesta decide terminarea dreptului dvs. de a utiliza Platforma, conform prezentelor T&C. Atunci cand Prestatorul suspenda sau termina dreptul dvs. de a utiliza Platforma nu veti mai avea acces la contul dvs. de utilizator si nici la datele si informatiile pe care le-ati adaugat in cadrul Platformei. \n' +
      '\n' +
      '5. Utilizarea Platformei si a Serviciilor Exminds.\n' +
      '\n' +
      '5.1. Platforma Exminds este o platforma de tip marketplace pentru Experiente. In cadrul Platformei, Utilizatorii Exminder listeaza Experiente care pot fi achizitionate de catre utilizatorii de tip Explorer. \n' +
      '5.2. Atunci cand creati un cont de utilizator de tip Exminder, aveti posibilitatea sa:\n' +
      'a) Listati si vindeti Experiente catre Utilizatorii Explorer\n' +
      'b) Achizitionati Experiente listate de alti utilizatori Exminder\n' +
      '5.3. Atunci cand achizitionati Experiente, va sunt aplicabile si aveti obligatia de accepta in mod suplimentar Termenii si Conditiile aferente contului de utilizator de tip Explorer. \n' +
      '5.4. Procesul de listare si vanzare a unei Experiente prin intermediul Platformei este urmatorul:\n' +
      'a) Utilizatorul Exminder listeaza in cadrul Platformei una sau mai multe Experiente\n' +
      'b) Utilizatorul Explorer achizitioneaza o experienta pe care o plateste cu cardul, prin intermediul procesatorului de plati Stripe\n' +
      'c) Utilizatorii se intalnesc conform detaliilor convenite pentru a livra, respectiv pentru a beneficia de Experienta achizitionata\n' +
      'd) Utilizatorii confirma in cadrul Platformei ca intalnirea pentru livrarea Experientei a avut loc\n' +
      '5.5. Pretul Experientei  va fi platit in avans, la data achizitiei Experientei, prin plata cu cardul. Restituirea pretului Experientei poate avea loc numai in cazurile si conditiile expres mentionate prin prezentele T&C.\n' +
      '5.6. In situatia in care listati o Experienta permanenta:\n' +
      'a) Utilizatorul Explorer poate achizitiona Experienta numai daca va dati acordul in acest sens. \n' +
      'b) Dupa achizitie, aveti obligatia de a stabili data Experientei (maxim 2 saptamani de la achizitie) prin consultare cu Utlizatorul Explorer care a achizitionat-o.\n' +
      '5.7. La data la care Experienta urmeaza sa aiba loc, utilizatorii confirma in cadrul Platformei ca intalnirea dintre acestia a avut loc. Aceasta confirmare are ca si consecinta deblocarea pretului Experientei catre Utilizatorul Exminder. \n' +
      '5.8. Modificarea Datei la care Experienta are loc si/sau anularea acesteia\n' +
      '5.8.1. Modificarea datei Experientei la initiativa oricarei parti, poate fi realizata numai cu acordul celeilalte parti, cu exceptia cazului in care in pagina Experientei ati mentionat ca va rezervati dreptul de a modifica unilateral data Experientei.\n' +
      '5.8.2. Anularea Experientei la initiativa Utilizatorului Explorer atrage restituirea pretului platit, numai daca va dati acordul in acest sens. Intr-o atare situatie, veti plati comisionul Platformei.\n' +
      '5.8.3. Anularea Experientei la initiativa dvs., atrage restituirea pretului platit de catre Utilizatorul Explorer, precum si obligatia dvs. de a plati in mod suplimentar comisionul Platformei. \n' +
      '5.8.4. Avand in vedere prevederile art. 16 din OUG 34/2014, Utilizatorul intelege si accepta ca nu beneficiaza de dreptul de retragere din contract prevazut de OUG mai sus mentionata,  intrucat serviciile Exminds sunt servicii digitale, cu prestare imediata. Utilizatorul declara ca este de acord ca serviciile Exminds sa ii fie prestate imediat, fara a astepta implinirea termenului de retragere de 14 zile prevazut de OUG 34/2014 si intelege ca in acest context nu va beneficia de dreptul de retragere.\n' +
      '5.9. Utilizatorii inteleg si accepta ca:\n' +
      '5.9.1. Platforma are un rol exclusiv de a intermedia listarea Experientelor si achizitionarea lor. Tranzactia de vanzare-cumparare a Experientelor are loc direct intre Utilizatorii Exminder, in calitate de vanzatori si Utilizatorii Explorer, in calitate de cumparatori.\n' +
      '5.9.2. Factura aferenta Experientei achizitionate, va fi emisa de catre Utilizatorul Exminder, in calitate de furnizor al Experientei si nu de catre noi. Pe cale de consecinta, orice plangere in legatura cu Experienta achizitionata, precum si orice eventuala cerere de rambursare a pretului platit, va fi adresata si solutionata direct intre Utilizatorul Explorer si Utilizatorul Exminder. Prestatorul nu solutioneaza nicio plangere si nu restituie pretul Experientei, decat daca intalnirea nu a avut loc din culpa Utilizatorului Exminder.\n' +
      '5.9.3. Prestatorul nu verifica identitatea Utilizatorilor Explorer, reputatia, sau buna credinta a acestora si nu raspunde in acest sens. Utilizatorul isi asuma orice riscuri derivate din vanzarea Experientelor catre Utilizatorii Explorer, inclusiv eventuale riscuri derivate din intalnirea cu aceste persoane.\n' +
      '5.9.4. Utilizatorii sunt exclusiv responsabili fata de Utilizatorii Explorer pentru Experienta livrata (inclusiv calitatea acesteia, concordanta dintre descrierea Experientei si Experienta livrata etc.) Prestatorul nu mediaza si nu solutioneaza nicio disputa generata ca urmare a nelivrarii Experientei sau livrarii neconforme a acesteia.\n' +
      'Experiente permise spre listare in cadrul Platformei\n' +
      '5.10. In cadrul Platformei pot fi listate numai Experiente care nu contravin legii si bunelor moravuri. Cu titlu de exemplu nu pot fi listate Experiente care presupun: prostitutie, servicii de escorta, videochat, consum de alcool sau droguri etc. \n' +
      '5.11. Atunci cand listati Experiente pentru a caror livrare aveti nevoie de aprobari, avize, permise sau certificari prevazute de lege, puteti lista aceste Experiente numai daca detineti toate aceste aprobari, avize, permise sau certificari. \n' +
      '5.12. Prin listarea unei astfel de Experiente in platforma garantati ca cerintele prevazute la art. 5.10 si 5.11 de mai sus sunt indeplinite si sunteti exclusiv responsabil pentru consecintele si/sau prejudiciile produse Utilizatorilor Explorer, Prestatorului si/sau oricaror altor terti in cazul in care declaratiile dvs. se dovedesc a fi false.\n' +
      '5.13. Prestatorul isi rezerva dreptul de a nu aproba listarea sau dupa caz de a delista orice Experienta care incalca cerintele de mai sus.\n' +
      'Alte reguli pentru listarea unei Experiente in cadrul Platformei\n' +
      '5.14. Pentru a lista o Experienta in cadrul Platformei, Utilizatorul va completa toate campurile solicitate in procesul de listare a Experientei. \n' +
      '5.15. Utlizatorul are obligatia de a utiliza numai informatii reale in descrierea Experientei. Atunci cand adauga materiale text, foto, video sau audio, Utilizatorul are obligatia de a se asigura ca detine toate drepturile de autor asupra acestora, asumandu-si orice consecinte in acest sens. \n' +
      '5.16. Este interzisa insusirea identitatii altei persoane sau prezentarea de informatii eronate privind persoana Utilizatorului (precum experienta profesionala sau istoric fals, certificari sau studii false etc.).\n' +
      '5.17. De asemenea, in masura in care pentru livrarea Experientei sunt aplicabile reguli sau conditii specifice si/sau daca Experienta nu este adresata oricaror persoane, atunci Utilizatorul are obligatia de a completa in pagina Experientei toate conditiile specifice aplicabile. \n' +
      '5.18. Este interzisa copierea Experientelor listate de alti Utilizatori.\n' +
      '5.19. Pretul Experientei va fi determinat de catre Utilizator, in mod liber, fara implicarea Prestatorului. \n' +
      '5.20. Utilizatorul poate delista oricand Experientele pe care le-a listat in Platforma, insa Experientele deja achizitionate vor trebui onorate, sau anulate, cu consecinta restituirii pretului platit de Utilizatorul Explorer si al comisionului datorat Platformei.\n' +
      '5.21. Prestatorul isi rezerva dreptul de a nu aproba listarea sau dupa caz de a delista orice Experienta care incalca cerintele de mai sus.\n' +
      '5.22. Totodata, Prestatorul isi rezerva dreptul de a nu aproba listarea unor Experiente, fara a invoca niciun motiv. \n' +
      '5.23. Totodata, Utilizatorul intelege ca pentru a finaliza listarea unei Experiente, Utilizatorul trebuie sa isi conecteze contul Stripe, intrucat incasarea pretului se va realiza prin intermediul contului Stripe. In masura in care Utilizatorul nu are un cont Stripe, aceasta are obligatia de a si-l crea in procesul de listare a unei Experiente. \n' +
      'Serviciile Exminds\n' +
      '5.24. Prestatorul pune la dispozitia Utilizatorilor, prin intermediul Platformei, o serie de Servicii, care pot fi accesate contracost (precum listarea si intermedierea vanzarii Experientelor, promovarea Experientelor etc.). Dupa caz, la latitudinea Prestatorului, anumite servicii pot fi oferite gratuit sau in sistem free trial.\n' +
      '5.25. Pentru a utiliza Serviciile Exminds Utilizatorul are obligatia de a plati pretul acestora. \n' +
      '5.26. Serviciile Exminds se poti modifica din timp in timp, inclusiv in ceea ce priveste pretul acestora, fara notificarea prealabila a Utilizatorilor. Utilizatorii au obligatia de a consulta pretul serviciilor, astfel cum acesta este mentionat in cadrul Platformei. La expirarea perioadei pentru care pretul serviciilor a fost achitat in avans, pentru a continua utilizarea serviciilor, Utilizatorul va achita noul pret al acestora.\n' +
      'Recenzii in cadrul Platformei\n' +
      '5.27. In cadrul Platformei, dupa livrarea  Experientei, fiecare parte poate publica o recenzie. \n' +
      '5.28. Utilizatorul are dreptul de a publica o recenzie  privind Utilizatorul Explorer caruia i-a livrat Experienta numai dupa livrarea Experientei. Recenziile trebuie sa fie reale si sa nu contina injurii. \n' +
      '5.29. Prestatorul isi rezerva dreptul de a nu publica, respectiv de a sterge recenziile false, care contin injurii, sau care nu sunt la obiect. \n' +
      '5.30. Utilizatorul intelege si accepta ca la randul sau poate primi o recenzie publica, prin intermediul Platformei, din partea Utilizatorului Explorer. Utilizatorul poate solicita stergerea unei recenzii numai daca aceasta este falsa, sau contine injurii. \n' +
      '\n' +
      '6. Drepturile si obligatiile utilizatorilor\n' +
      '6.1. Utilizatorii se obliga:\n' +
      'a) sa foloseasca Platforma cu buna credinta si numai in acord cu Scopul permis al utilizarii;\n' +
      'b) sa respecte prevederile legale in vigoare atunci cand utilizeaza Platforma; sa respecte prevederile legale in vigoare in cadrul intalnirii cu Utlizatorul Explorer care a  achizitionat Experienta. In acest sens, utilizatorii sunt in mod exclusiv si deplin raspunzatori de felul in care folosesc Platforma, precum si pentru comportamentul si actiunile acestora in cadru intalnirii avute cu Utilizatorul Explorer, Prestatorul neputand fi tinut raspunzator in acest sens fata de nicio parte. \n' +
      'c) Sa nu utilizeze Platforma in scopuri disimulate, precum dar fara a se limita la: vanzarea de Experiente disimulate pentru a obtine de la Utilizatorii Explorer sume de bani cu scopuri precum: mita, vanzare droguri, arme si munitii, vanzare servicii de escorta, prostitutie sau videochat, spalare de bani etc.\n' +
      'd) Sa listeze si sa vanda numai Experientele care nu contravin legii si/sau pentru care detine toate autorizatiile, permisele si/sau certificarile cerute de lege\n' +
      'e) Sa respecte toate obligatiile care ii incumba conform prezentului document si legislatiei in vigoare. \n' +
      '6.2. Totodata se interzice Utilizatorilor: \n' +
      'a) Divulgarea datelor de logare in contul de utilizator catre terti;\n' +
      'b) Divulgarea functionalitatilor si/sau a continutului Platformei catre terti\n' +
      'c) Copierea si/sau adaptarea Experientelor, a conceptului Platformei si/sau a continutului Platformei pe alte site-uri si/sau aplicatii mobile. \n' +
      'd) copierea partiala sau totala, decompilarea, dezasamblarea, crearea de opere derivate sau folosirea in orice alta modalitate a codului sursa al Platformei, si/sau a textelor, imaginilor, video-urilor cuprinse in Platforma, precum si orice alt act prin care ar putea fi incalcata proprietatea intelectuala a Prestatorului asupra Platformei;\n' +
      'e) folosirea Platformei in legatura cu orice dispozitiv sau serviciu destinat sa ocoleasca masurile tehnice de securitate implementate pentru a controla accesul la Platforma;\n' +
      'f) copierea, modificarea, stergerea sau deteriorarea oricarei informatii continute pe echipamentele tehnice, incluzand dar nelimitandu-se la serverele si calculatoarele utilizate sau controlate de catre Prestator.\n' +
      'g) folosirea Platformei pentru a invada intimitatea tertilor sau pentru a obtine informatii despre oricare dintre utilizatorii Platformei sau pentru a obtine o lista de utilizatori ai acesteia;\n' +
      'h) utilizarea Platformei pentru a incalca un drept legal al tertilor, inclusiv orice drept de confidentialitate, de autor sau alte drepturi de proprietate intelectuala, sau de a intreprinde orice actiune hartuitoare, calomnioasa, defaimatoare, abuziva, necinstita, amenintatoare, daunatoare sau altele similare; sau folosirea oricarei tehnologii de data mining, roboti sau similare de colectare a datelor;\n' +
      'i) accesarea sau utilizarea frauduloasa a oricarei zone a Platformei care poate fi accesata sub parola sau contra cost;\n' +
      'j) utilizarea oricaror mijloace automate pentru a accesa sau utiliza Platformei (inclusiv scripturi, "bot", sau software similar);\n' +
      'k) modificarea, traducerea, decompilarea, reproducerea, dezasamblarea, sau incercarea prin alte moduri a obtinerii accesului la cheia privata sau orice cheie dev ("dev key\') (acest termen este unul general cunoscut in industrie) asociate cu Platforma, continutul sau serviciile oferite, sau folosirea sau incercarea de utilizare a oricarei chei private sau dev key intr-un mod in care sa se incalce termenii prezentului document;\n' +
      'l) orice alta actiune prin care s-ar putea crea concurenta neloiala Prestatorului sau prin care ar putea fi prejudiciate drepturile acestuia de orice fel (drepturi civile, comerciale, de natura intelectuala si altele). Cu titlu de exemplu, Prestatorul intelege si accepta ca reprezinta concurenta neloială si incalcarea drepturilor de proprietate intelectuala ale Prestatorului situația în care un Utilizator copiază sau adaptează în afacerea sa sau a altui tert orice materiale, documente, idei, concepte, strategii, oferte etc. disponibile în cadrul Platformei.\n' +
      '7. Pretul Experientelor, Pretul Serviciilor Exminds si metode de plata\n' +
      '\n' +
      'Pretul Experientelor\n' +
      '7.1. Experientele listate in cadrul Platformei pot fi achizitionate contra cost, prin plata pretului acestora, astfel cum acesta este afisat in cadrul Platformei.\n' +
      '7.2. Fiecare Experienta are un pret diferit, stabilit de catre Utilizatorul Exminder care o propune. Prestatorul nu stabileste pretul Experientelor si nu raspunde in legatura cu acesta. \n' +
      '7.3. Pentru a achizitiona o Experienta, Utilizatorul Explorer va efectua plata pretului acesteia, in avans, prin apasarea butonului cumpara disponibil in pagina Experientei. Plata se va realiza cu cardul, prin intermediul procesatorului de plati Stripe. \n' +
      '7.4. La data platii, Utlizatorul Explorer va primi din partea procesatorului de plati confirmarea platii. Ulterior datei la care Experienta a avut loc, Utilizatorul va primi de asemenea factura fiscala aferenta Experientei care va fi emisa de Utilizatorul Exminder in calitate de furnizor al Experientei.\n' +
      '7.5. Pentru evitarea oricarui dubiu, suma platita de Utilizatorul Explorer va fi blocata in contul Stripe pana la data la care partile confirma in Platforma ca intalnirea pentru livrarea Experientei a avut loc, dar nu mai mult de 14 zile de la data achizitiei Experientei (“Data deblocarii”). La data Deblocarii, Utlizatorul va incasa Pretul Experientei minus Comisionul datorat Prestatorului conform prezentelor T&C.\n' +
      '\n' +
      'Pretul Serviciilor Exminds\n' +
      '7.6. Prestatorul confera Utlizatorului serviciile sale contracost. \n' +
      '7.7. In acest sens, pentru serviciul de listare si intermediere a vanzarii Experientelor prin intermediul Platformei, Prestatorul va percepe cu titlu de pret un comision procentual calculat din pretul fiecarei Experiente. Valoarea comisionului va fi mentionata in cadrul Platformei, Utilizatorul avand obligatia de a consulta valoarea comisionului anterior listarii unei Experiente.\n' +
      '7.8. Valoarea comisionului poate fi modificata oricand de catre Prestator, caz in care valoarea noului comision va fi mentionata in Platforma. Noul comision se aplica numai Experientelor listate sau reinoite dupa data la care noul comision a fost mentionat in Platforma.\n' +
      '7.9. Comisionul Exminds va fi incasat de catre Prestator prin retinere din pretul Experientei, conform art. 7.5. de mai sus.\n' +
      '7.10. Pretul oricaror altor Servicii Exminds va fi mentionat in Platforma.\n' +
      'Restituirea Pretului platit catre Utilizatorul Explorer\n' +
      '7.11. Prestatorul va restitui pretul platit al unei Experiente numai in urmatoarele situatii:\n' +
      'a) Daca in perioada cuprinsa intre achizitia Experientei si data acesteia, Utilizatorul Explorer solicita anularea Experientei, iar Utilizatorul Exminder isi da acordul pentru anularea Experientei si restituirea pretului platit.\n' +
      'b) Daca in perioada cuprinsa intre achizitia Experientei si data acesteia, Utilizatorul Exminder anuleaza Experienta.\n' +
      'c) La data Experientei Utilizatorul Exminder nu se prezinta, respectiv partile nu confirma in Platforma ca intalnirea a avut loc\n' +
      '7.12. Orice alte cereri de restituire a pretului, in situatii precum: plangeri privind calitatea Experientei, neconcordante intre descrierea Experientei si Experienta in sine etc. vor fi solutionate direct intre Utilizator si Utilizatorul Exminder, fara implicarea Prestatorului.\n' +
      '\n' +
      '8. Raspunderea partilor\n' +
      'Limitarea raspunderii Prestatorului privind functionarea Platformei si Serviciile Exminds.\n' +
      '8.1. Utilizatorul înțelege și acceptă că Prestatorul pune la dispoziția acestuia Platforma si Serviciile Exminds “as is”. Pe cale de consecință, Utilizatorul înțelege și acceptă că în anumite situații există posibilitatea ca Platforma Exminds să nu funcționeze în mod continuu, stabilit și/sau la parametri tehnici optimi sau poate funcționa cu erori.\n' +
      '8.2. Prestatorul va depune toate diligențelele necesare pentru a repara într-un timp optim orice defecțiune apăruta, insa Prestatorul nu raspunde pentru si nu va repara prejudiciile directe sau indirecte ocazionate Utilizatorilor ca urmare a defectiunilor sau discontinuitatii Platformei.\n' +
      '8.3. Prestatorul raspunde in baza prezentelor T&C numai fata de Utilizator, respectiv pentru prejudiciile directe cauzate acestuia ca urmare a incalcarii prezentelor T&C de catre Prestator. \n' +
      '8.4. Prestatorul nu raspunde si nu va repara in nicio situatie:  prejudiciul indirect cauzat Utilizatorului, inclusiv in ceea ce priveste beneficiul nerealizat de acesta; prejudiciile cauzate de Utlizatorii Explorer care achizitioneaza Experientele listate de Utilizator. \n' +
      '8.5. Atunci cand raspunderea Prestatorului va putea fi angajata, conform prezentelor T&C, acesta va repara exclusiv prejudiciul direct cauzat Utilizatorului, in limita pretului platit de Utilizator pentru Serviciile Exminds in luna in care Prestatorul si-a incalcat obligatiile asumate fata de acesta.\n' +
      '\n' +
      'Raspunderea Utilizatorului\n' +
      '8.6. Utilizatorul va repara intreg prejudiciul direct si indirect cauzat Prestatorului, prin incalcarea prezentelor T&C si a legislatiei incidente. \n' +
      '8.7. Utilizatorul intelege si accepta ca este direct si exclusiv responsabil pentru utilizarea si consecintele utilizarii Platformei si a serviciilor Exminds, Prestatorul neputând fi ținut responsabil pentru modalitatea de utilizare a Platformei, pentru orice consecințe și/sau daune rezultate din utilizarea acesteia.\n' +
      '8.8. Utilizatorul intelege si accepta ca este direct si exclusiv responsabil in relatie cu Utilizatorul Explorer caruia ii vinde/livreaza o Experienta, fara ca Prestatorul sa poata fi tinut responsabil in acest sens. Atunci cand Utilizatorul cauzeaza un prejudiciu unui Utilizator Explorer, acesta il va repara direct, fara implicarea Prestatorului, cu exceptia cazului in care prin prezentele T&C se stabiliesc reguli diferite. \n' +
      '\n' +
      '9. Drepturi de proprietate intelectuala\n' +
      '9.1. Toate drepturile de proprietate intelectuală privind Platforma Exminds aparțin Prestatorului. Prin prezentul contract, Utilizatorul nu dobândește niciun drept de proprietate intelectuală cu privire la Platforma Exminds,  cu excepția dreptului de utilizare temporară și neexclusivă a acesteia, în condițiile  T&C.\n' +
      '9.2. Utlizatorul confera Prestatorului o licenta de utilizare a Experientelor (inclusiv materialele text, foto, video si/sau audio care le insotesc) pe care le listeaza in cadrul Platformei, cu titlu gratuit, pe toata durata in care acestea sunt listate in Platforma.\n' +
      '9.3. Utilizatorul se obligă să respecte toate drepturile de proprietate intelectuală ale Prestatorului si să se abțină de la orice acțiune prin care drepturile de proprietate intelectuală ale acestora ar putea fi încălcate, precum dar fara a se limita la: accesarea, copierea, divulgarea codului sursă al Platformei, copierea și/sau divulgarea  conținutului Platformei (denumiri, descrieri, concepte, materiale foto, video, etc.), copierea și/sau divulgarea funcționalităților Platformei, etc.\n' +
      '\n' +
      '10. Legea aplicabila si instanta competenta\n' +
      'Prezentul document este redactat in limba romana si va fi interpretat potrivit intelegerii sensului termenilor sai in limba romana. \n' +
      'Prezentul document este guvernat de legislatia romana incidenta. Orice litigiu in legatura cu utilizarea Platformei se va supune spre solutionare instantelor judecatoresti competente de la sediul Prestatorului.\n' +
      '11. Alte dispozitii\n' +
      'Ne puteti contacta oricand prin email la adresa: contact@exminds.com\n' +
      'Prezentul document a fost aprobat si se află în in vigoare incepand cu data de: ',
    explorerContent:
      'Prezentul document are natura juridica a unui contract la distanta incheiat intre: a) dumneavoastra in calitate de utlizator al platformei Exminds si b) Exminds SRL, o societate cu sediul in Bucuresti, Ale. Buhusi nr. 5, bl. 6, sc. 1, ap. 5, Sector 3, Romania, inregistrata la Registrul Comertului cu nr. J40/12811/2023, avand CUI 48469851, in calitate de proprietar al platformei Exminds si prestator al serviciilor Exminds.\n' +
      'Prezentul document reglementeaza conditiile in care puteti utiliza Platforma si serviciile Exminds, precum si drepturile si obligatiile dvs. in calitate de utilizator.\n' +
      '\n' +
      '1. Definitii\n' +
      '1.1. Urmatorii termeni folositi in prezentul document au urmatorul inteles, cu exceptia situatiei in care acestia sunt folositi in mod vadit in alt sens.\n' +
      'a) „Platforma” – Platforma Exminds, astfel cum aceasta este disponibila la adresa https://www.app.exminds.com/ \n' +
      'b) „Prestatorul” si/sau „Noi”– EXMINDS SRL, cu sediul in in Bucuresti, Ale. Buhusi nr. 5, bl. 6, sc. 1, ap. 5, Sector 3, Romania, inregistrata la Registrul Comertului cu nr. J40/12811/2023, avand CUI 48469851; Prestatorul este proprietarul Platformei si prestatorul Serviciilor Exminds.\n' +
      'c) „Serviciile Exminds” – Serviciile prestate de Prestator Utilizatorilor, prin intermediul Platformei.\n' +
      'd) „Cont de utilizator” – Contul de Utilizator de tipul „explorer” pe care o persoana il poate crea in cadrul Platformei in scopul utilizarii acesteia. \n' +
      'e) „Utilizator” – orice persoana fizica si/sau juridica ce creaza un cont de utilizator de tipul „explorer” in cadrul Platformei, in scopul utilizarii acesteia;\n' +
      'f) „Utilizatori Exminder” –  categorie de utilizatori care listeaza Experiente in cadrul Platformei.\n' +
      'g) „Experiente” – experientele oferite de Utilizatorii Exminder prin intermediul Platformei.\n' +
      'h) „Termeni si Conditii” si/sau „T&C” – prezentul document, Nota de informare privind prelucrarea datelor cu caracter personal, termenii si conditii suplimentari disponibili in pagina fiecarei Experiente, precum si dupa caz, orice alte documente suplimentare care reglementeaza relatia juridica dintre Prestator si Utilizatori, astfel cum aceste documente vor fi disponibile in cadrul Platformei;\n' +
      '\n' +
      '2. Acceptarea termenilor si conditiilor. Modificarea acestora.\n' +
      '2.1. Prezentul document se considera inteles si acceptat fara rezerve prin bifarea rubricii „Accept termenii si conditiile” in procedura de creare a contului de utilizator. \n' +
      '2.2. Ne rezervam dreptul de a modifica din timp in timp Termenii si Conditiile cu notificarea prealabila a dvs. In acest sens, va vom notifica prin email sau prin intermediul Platformei privind noua versiune a Termenilor si Conditiilor. Pentru a putea continua utilizarea Platformei si a serviciilor Exminds veti avea obligatia de a accepta noua versiune a Termenilor si Conditiilor.\n' +
      '2.3. In masura in care vom aduce modificari Platformei (inclusiv privind adaugarea de noi functionalitati si/sau vom modificarea functionalitatile existente), prezentele T&C se vor aplica si pentru aceste modificari, fara a fi necesara nicio actiune suplimentara din partea noastra sau a dvs., cu exceptia cazului in care vom publica Termeni si Conditii suplimentari pentru aceste modificari. \n' +
      '2.4. In masura in care vom adauga noi servicii Exminds si/sau vom modifica serviciile actuale, prezetul document se va aplica si cu privire la noile servicii si/sau serviciile modificate, fara a fi necesara nicio actiune suplimentara din partea noastra sau a dvs., cu exceptia cazului in care vom publica Termeni si Conditii suplimentari pentru noile servicii/serviciile modificate. \n' +
      '2.5. Ne rezervam dreptul ca pentru anumite functionalitati ale Platformei sau servicii Exminds sa publicam Termeni si Conditii suplimentari, care sa deroge de la Termenii si Conditiile generale stabilite prin prezentul document. Intr-o atare situatie va vom informa despre T&C suplimentari si va vom solicita sa ii acceptati inainte de a utiliza functionalitatile/Serviciile aferente.\n' +
      '\n' +
      '3. Utilizarea Platformei. Limitele dreptului de utilizare.\n' +
      '3.1. Prestatorul confera Utilizatorului un drept limitat, neexclusiv, netransmisibil si temporar de a utiliza Platforma si serviciile Exminds, in conditiile prezentului document si ale legislatiei incidente.\n' +
      '3.2. Dreptul de utilizare a Platformei este conferit utilizatorilor cu titlu gratuit. Prestatorul isi rezerva dreptul de a modifica oricand conditiile de utilizare ale Platformei, inclusiv pentru a solicita un pret in contrapartida dreptului de utilizare a Platformei.\n' +
      '3.3. De asemenea, pentru utilizarea anumitor functii, sectiuni sau servicii ale Platformei, Prestatorul isi rezerva dreptul de a solicita plata unui pret, caz in care accesarea si utilizarea respectivelor functii, sectiuni, servicii va fi permisa numai dupa plata pretului aferent. \n' +
      '3.4. Platforma si Serviciile Exminds pot fi utilizate exclusiv in urmatorul scop: Utilizatorii pot utiliza Platforma pentru a se informa si dupa caz pentru a achizitiona  Experientele listate de Utilizatorii Exminder in cadrul Platformei (“scopul permis al utilizarii”).\n' +
      '3.5. Utilizarea Platformei in alte scopuri, cu rea credinta si/sau cu incalcarea prezentelor T&C si/sau a legistiei aplicabile, atrage retragerea dreptului de utilizare a Platformei cu efect imediat. \n' +
      '3.6. Prestatorul isi rezerva dreptul de a suspenda si/sau retrage dreptul Utilizatorului de a utiliza Platforma si serviciile Exminds, cu efect imediat, in situatii precum dar fara a se limita la: utilizatorul incalca prevederile prezentelor T&C si/sau a legislatiei incidente sau exista o suspiciune rezonabila in acest sens; utilizatorul inregistreaza intarziere la plata oricaror sume datorate Prestatorului sau Utlizatorilor Exminder, recenzii negative sau plageri primite de la Utilizatorii Exminder cu care utilizatorul a interactionat, orice alte situatii obiective. Prestatorul poate notifica Utilizatorul prin intermediul Platformei si/sau prin email cu privire la suspendarea si/sau retragerea dreptului de utilizare.\n' +
      '3.7. Prestatorul isi rezerva dreptul de a modifica oricand si fara notificarea prealabila a Utilizatorului functiile si/sau Serviciile Platformei, sa renunte la anumite functii si/sau servicii, precum si sa adauge noi functii sau servicii. Utilizatorul este in drept sa renunte la utilizarea Platformei si/sau Serviciile Exminds in situatia in care nu este de acord cu functiile/serviciile modificate ale Platformei.\n' +
      '\n' +
      '4. Crearea contului de utilizator. Stergerea acestuia.\n' +
      '4.1. Platforma poate fi utilizata exclusiv de catre utilizatorii inregistrati. Astfel, pentru a putea utiliza Platforma aveti obligatia de a va crea un cont de utilizator in cadrul Platformei.\n' +
      '4.2. Atunci cand creati un cont de utilizator veti dobandi dreptul de a utiliza Platforma in conformitate cu tipul de utilizator selectat, respectiv aveti obligatia de a selecta optiunea „explorer” pentru a utiliza Platforma ca si “explorer”. \n' +
      '4.3. Conditii si obligatii aplicabile la crearea contului de utilizator:\n' +
      'a) Puteti crea un cont de utilizator numai daca aveti cel putin 18 ani impliniti.\n' +
      'b) Aveti obligatia de a completa formularul de creare cont utilizator numai cu date si informatii reale, care va apartin, fara a va insusi identitatea unei alte persoane.\n' +
      'c) Aveti obligatia de a accepta Termenii si Conditiile Platformei pentru a putea finaliza procesul de creare cont de utilizator.\n' +
      '4.4. Prestatorul isi rezerva dreptul de a verifica datele furnizate in cadrul procedurii de creare cont de utilizator si de a refuza deschiderea conturilor de utilizator in cazul in care exista suspiciunea rezonabila ca prezentele T&C sunt incalcate precum si in alte situatii obiective.\n' +
      '\n' +
      '4.5. Stergerea contului de utilizator. \n' +
      '4.5.1. Puteti decide la orice moment sa va stergeti contul de utilizator, caz in care toate datele si informatiile pe care le-ati adaugat in cadrul Platformei nu va vor mai fi disponibile. Datele dvs. cu caracter personal vor fi anonimizate inreversibil, cu exceptia cazurilor mentionate in nota de informare privire prelucrarea datelor cu caracter personal.\n' +
      '4.5.2. Prestatorul poate restrictiona accesul dvs. la contul de utilizator si/sau poate sterge contul dvs. de utilizator, in cazul in care acesta decide terminarea dreptului dvs. de a utiliza Platforma, conform prezentelor T&C. Atunci cand Prestatorul suspenda sau termina dreptul dvs. de a utiliza Platforma nu veti mai avea acces la contul dvs. de utilizator si nici la datele si informatiile pe care le-ati adaugat in cadrul Platformei. \n' +
      '\n' +
      '5. Utilizarea Platformei si a Serviciilor Exminds.\n' +
      '\n' +
      '5.1. Platforma Exminds este o platforma de tip marketplace pentru Experiente. In cadrul Platformei, Utilizatorii Exminder listeaza Experiente care pot fi achizitionate de catre utilizatorii de tip Explorer. \n' +
      '5.2. Procesul de achizitie a unei Experiente prin intermediul Platformei este urmatorul:\n' +
      'a) Utilizatorul Exminder listeaza in cadrul Platformei una sau mai multe Experiente\n' +
      'b) Utilizatorul Explorer achizitioneaza o experienta pe care o plateste cu cardul, prin intermediul procesatorului de plati Stripe\n' +
      'c) Utilizatorii se intalnesc conform detaliilor convenite pentru a oferi, respectiv pentru a beneficia de Experienta achizitionata\n' +
      'd) Utilizatorii confirma in cadrul Platformei ca intalnirea pentru livrarea Experientei a avut loc\n' +
      '5.3. Pretul Experientei  va fi platit in avans, la data achizitiei Experientei, prin plata cu cardul. Restituirea pretului Experientei poate avea loc numai in cazurile si conditiile expres mentionate prin prezentele T&C.\n' +
      '5.4. Data la care Experienta va avea loc va fi stabilita dupa caz, la momentul achizitiei, sau dupa caz, ulterior, la o data stabilita de Utilizatorul Exminder (data care nu poate depasi un interval de 2 saptamani de la data achizitiei Experientei). Intr-o atare situatie, Utilizatorul va fi consultat cu privire la stabilirea datei Experientei insa nu poate solicita restituirea pretului platit pe motiv ca nu este de acord cu data Experientei. \n' +
      '5.5. La data la care Experienta urmeaza sa aiba loc, utilizatorii confirma in cadrul Platformei ca intalnirea dintre acestia a avut loc. Aceasta confirmare are ca si consecinta deblocarea pretului Experientei catre Utilizatorul Exminder. \n' +
      '5.6. Utilizatorul intelege si accepta ca pentru o anumita categorie de Experiente, achizitia acestora este posibila numai dupa ce Utilizatorul Exminder si-a dat acordul prealabil dupa cum urmeaza: a) Utilizatorul apasa butonul „Sunt interesat” in pagina Experientei; b) Utilizatorul Exminder primeste cererea Utilizatorului si confirma daca este de acord; c) Utilizatorul primeste acceptul Utilizatorului Exminder si plateste Experienta in Platforma. Astfel, Utilizatorul Exminder isi rezerva dreptul de a alege Utilizatorii care vor beneficia de Experienta listata, fara a avea obligatia de a-si motiva decizia fata de acestia.\n' +
      '5.7. Modificarea Datei la care Experienta are loc si/sau anularea acesteia\n' +
      '5.7.1. Modificarea datei Experientei la initiativa oricarei parti, poate fi realizata numai cu acordul celeilalte parti, cu exceptia cazului in care in pagina Experientei Utilizatorul Exminder mentioneaza ca isi rezerva dreptul de a modifica unilateral data Experientei.\n' +
      '5.7.2. Anularea Experientei la initiativa Utilizatorului atrage restituirea pretului platit, numai daca Utilizatorul Exminder isi da acordul in acest sens. \n' +
      '5.7.3. Anularea Experientei la initiativa Utilizatorului Exminder atrage restituirea pretului platit de catre Utilizator. Utilizatorul intelege si accepta ca acesta este singurul sau remediu in cazul in care Utilizatorul Exminder anuleaza Experienta. \n' +
      '5.7.4. Avand in vedere prevederile art. 16 din OUG 34/2014, Utilizatorul intelege si accepta ca nu beneficiaza de dreptul de retragere din contract prevazut de OUG mai sus mentionata,  intrucat serviciile utilizatorilor Exminder  constand in furnizarea Experientelor sunt considerate servicii privind activităţile de agrement pentru care partile au stabilit o data sau o perioada de executare specifica. In subsidiar, Utlizatorul isi da acordul ca Experienta sa aiba loc inainte de implinirea termenului de retragere de 14 zile prevazut de lege, cu consecinta neaplicarii dreptului de retragere.\n' +
      '5.8. Utilizatorii inteleg si accepta ca:\n' +
      '5.8.1. Platforma are un rol exclusiv de a intermedia listarea Experientelor si achizitionarea lor. Tranzactia de vanzare-cumparare a Experientelor are loc direct intre Utilizatorii Exminder, in calitate de vanzatori si Utilizatorii Explorer, in calitate de cumparatori.\n' +
      '5.8.2. Factura aferenta Experientei achizitionate, va fi emisa de catre Utilizatorul Explorer, in calitate de furnizor al Experientei si nu de catre noi. Pe cale de consecinta, orice plangere in legatura cu Experienta achizitionata, precum si orice eventuala cerere de rambursare a pretului platit, va fi adresata direct Utilizatorului Explorer de la care Experienta a fost achizitionata. Prestatorul nu solutioneaza nicio plangere si nu restituie pretul Experientei, decat daca intalnirea nu a avut loc din culpa Utilizatorului Exminder.\n' +
      '5.8.3. Prestatorul nu verifica identitatea Utilizatorilor Exminder, reputatia, buna credinta, statutul de expert al acestora, cunostintele, realizarile sau experienta profesionala ale acestora si/sau alte asemenea. Utilizatorul este exclusiv responsabil sa verifice persoana de la care achizitioneaza Experienta, anterior achizitiei sale.\n' +
      '5.8.4. Prestatorul verifica Experientele listate in cadrul Platformei, astfel incat acestea sa nu faca parte din tipurile de Experiente care nu pot fi listate pe Platforma. Prestatorul nu verifica insa detaliile fiecarei Experiente si nu isi asuma nicio raspundere in legatura cu acestea. Utilizatorul este exclusiv responsabil sa verifice fiecare Experienta in parte, anterior achizitiei sale.\n' +
      '5.8.5. Prestatorul nu poate fi tinut responsabil daca Utilizatorii Exminder nu livreaza Experienta, sau nu o livreaza conform descrierii. \n' +
      'Serviciile Exminds\n' +
      '5.9. In mod aditional, Prestatorul poate oferi Utilizatorilor, prin intermediul Platformei, o serie de Servicii, contracost. Intr-o atare situatie, pretul si conditiile de prestare al Serviciilor vor fi mentionate in cadrul Platformei. \n' +
      '5.10. Serviciile Exminds se poti modifica din timp in timp, inclusiv in ceea ce priveste pretul acestora, fara notificarea prealabila a Utilizatorilor. Utilizatorii au obligatia de a consulta pretul serviciilor, astfel cum acesta este mentionat in cadrul Platformei. La expirarea perioadei pentru care pretul serviciilor a fost achitat in avans, pentru a continua utilizarea serviciilor, Utilizatorul va achita noul pret al acestora.\n' +
      'Recenzii in cadrul Platformei\n' +
      '5.11. In cadrul Platformei, dupa finalizarea Experientei, fiecare parte poate publica o recenzie. \n' +
      '5.12. Utilizatorul are dreptul de a publica o recenzie privind Experienta achizitionata si/sau persoana Utilizatorului Exminder care a livrat Experienta, numai dupa finalizarea acesteia. Recenziile trebuie sa fie reale si sa nu contina injurii. \n' +
      '5.13. Prestatorul isi rezerva dreptul de a nu publica, respectiv de a sterge recenziile false, care contin injurii, sau care nu sunt la obiect. \n' +
      '5.14. Utilizatorul intelege si accepta ca la randul sau poate primi o recenzie publica, prin intermediul Platformei, din partea Utilizatorului Exminder. Utilizatorul poate solicita stergerea unei recenzii numai daca aceasta este falsa, sau contine injurii.\n' +
      '6. Drepturile si obligatiile utilizatorilor\n' +
      '6.1. Utilizatorii se obliga:\n' +
      'a) sa foloseasca Platforma cu buna credinta si numai in acord cu Scopul permis al utilizarii;\n' +
      'b) sa respecte prevederile legale in vigoare atunci cand utilizeaza Platforma; sa respecte prevederile legale in vigoare in cadrul intalnirii cu Utlizatorul Exminder de la care au  achizitionat Experienta. In acest sens, utilizatorii sunt in mod exclusiv si deplin raspunzatori de felul in care folosesc Platforma, precum si pentru comportamentul si actiunile acestora in cadru intalnirii avute cu Utilizatorul Exminder, Prestatorul neputand fi tinut raspunzator in acest sens fata de nicio parte. \n' +
      'c) Sa nu utilizeze Platforma in scopuri disimulate, precum dar fara a se limita la: achizitia de Experiente disimulate pentru a plati Utlizatorului Exminder sume de bani in scopuri precum: mita, achizitie droguri, arme si munitii, achizitie servicii de escorta sau prostitutie, spalare de bani etc.\n' +
      'd) Sa se asigure ca Experientele pe care le achizitioneaza sunt potrivite pentru sine sau dupa caz pentru persoana care va beneficia de acestea si in acest sens as citeasca si sa respecte conditiile speciale aplicabile fiecarei Experiente, astfel cum acestea sunt mentionate in pagina Experientei din cadrul Platformei\n' +
      'e) Sa respecte toate obligatiile care ii incumba conform prezentului document si legislatiei in vigoare. \n' +
      '6.2. Totodata se interzice Utilizatorilor: \n' +
      'a) Divulgarea datelor de logare in contul de utilizator catre terti;\n' +
      'b) Divulgarea functionalitatilor si/sau a continutului Platformei catre terti\n' +
      'c) Copierea si/sau adaptarea Experientelor, a conceptului Platformei si/sau a continutului Platformei pe alte site-uri si/sau aplicatii mobile. \n' +
      'd) copierea partiala sau totala, decompilarea, dezasamblarea, crearea de opere derivate sau folosirea in orice alta modalitate a codului sursa al Platformei, si/sau a textelor, imaginilor, video-urilor cuprinse in Platforma, precum si orice alt act prin care ar putea fi incalcata proprietatea intelectuala a Prestatorului asupra Platformei;\n' +
      'e) folosirea Platformei in legatura cu orice dispozitiv sau serviciu destinat sa ocoleasca masurile tehnice de securitate implementate pentru a controla accesul la Platforma;\n' +
      'f) copierea, modificarea, stergerea sau deteriorarea oricarei informatii continute pe echipamentele tehnice, incluzand dar nelimitandu-se la serverele si calculatoarele utilizate sau controlate de catre Prestator.\n' +
      'g) folosirea Platformei pentru a invada intimitatea tertilor sau pentru a obtine informatii despre oricare dintre utilizatorii Platformei sau pentru a obtine o lista de utilizatori ai acesteia;\n' +
      'h) utilizarea Platformei pentru a incalca un drept legal al tertilor, inclusiv orice drept de confidentialitate, de autor sau alte drepturi de proprietate intelectuala, sau de a intreprinde orice actiune hartuitoare, calomnioasa, defaimatoare, abuziva, necinstita, amenintatoare, daunatoare sau altele similare; sau folosirea oricarei tehnologii de data mining, roboti sau similare de colectare a datelor;\n' +
      'i) accesarea sau utilizarea frauduloasa a oricarei zone a Platformei care poate fi accesata sub parola sau contra cost;\n' +
      'j) utilizarea oricaror mijloace automate pentru a accesa sau utiliza Platformei (inclusiv scripturi, "bot", sau software similar);\n' +
      'k) modificarea, traducerea, decompilarea, reproducerea, dezasamblarea, sau incercarea prin alte moduri a obtinerii accesului la cheia privata sau orice cheie dev ("dev key\') (acest termen este unul general cunoscut in industrie) asociate cu Platforma, continutul sau serviciile oferite, sau folosirea sau incercarea de utilizare a oricarei chei private sau dev key intr-un mod in care sa se incalce termenii prezentului document;\n' +
      'l) orice alta actiune prin care s-ar putea crea concurenta neloiala Prestatorului sau prin care ar putea fi prejudiciate drepturile acestuia de orice fel (drepturi civile, comerciale, de natura intelectuala si altele). Cu titlu de exemplu, Prestatorul intelege si accepta ca reprezinta concurenta neloială si incalcarea drepturilor de proprietate intelectuala ale Prestatorului situația în care un Utilizator copiază sau adaptează în afacerea sa sau a altui tert orice materiale, documente, idei, concepte, strategii, oferte etc. disponibile în cadrul Platformei.\n' +
      '7. Pret si metode de plata\n' +
      '\n' +
      'Pretul Experientelor\n' +
      '7.1. Experientele listate in cadrul Platformei pot fi achizitionate contra cost, prin plata pretului acestora, astfel cum acesta este afisat in cadrul Platformei.\n' +
      '7.2. Fiecare Experienta are un pret diferit, stabilit de catre Utilizatorul Exminder care o propune. Prestatorul nu stabileste pretul Experientelor si nu raspunde in legatura cu acesta. \n' +
      '7.3. Pentru a achizitiona o Experienta, Utilizatorul va efectua plata pretului acesteia, in avans, prin apasarea butonului cumpara disponibil in pagina Experientei. Plata se va realiza cu cardul, prin intermediul procesatorului de plati Stripe. Prestatorul nu stocheaza datele cardului dvs. bancar. \n' +
      '7.4. La data platii, Utlizatorul va primi din partea procesatorului de plati confirmarea platii. Ulterior datei la care Experienta a avut loc, Utilizatorul va primi de asemenea factura fiscala aferenta Experientei care va fi emisa de Utilizatorul Exminder in calitate de furnizor al Experientei.\n' +
      '\n' +
      'Conditii privind restituirea pretului platit\n' +
      '7.5. Prestatorul va restitui pretul platit al unei Experiente numai in urmatoarele situatii:\n' +
      'a) Daca in perioada cuprinsa intre achizitia Experientei si data acesteia, Utilizatorul solicita anularea Experientei, iar Utilizatorul Exminder isi da acordul pentru restituirea pretului platit.\n' +
      'b) Daca in perioada cuprinsa intre achizitia Experientei si data acesteia, Utilizatorul Exminder anuleaza Experienta.\n' +
      'c) La data Experientei Utilizatorul Exminder nu se prezinta, respectiv partile nu confirma in Platforma ca intalnirea a avut loc\n' +
      '7.6. In acest sens, Utilizatorul intelege si accepta ca Prestatorul va debloca pretul Experientei si va face plata catre Utilizatorul Exminder dupa ce partile confirma in Platforma ca intalnirea pentru realizarea Experientei a avut loc. Dupa acest moment, Prestatorul nu mai poate efectua nicio restituire a pretului catre Utilizator, indiferent de motivul invocat. \n' +
      '7.7. Orice alte cereri de restituire a pretului, in situatii precum: plangeri privind calitatea Experientei, neconcordante intre descrierea Experientei si Experienta in sine etc. vor fi solutionate direct intre Utilizator si Utilizatorul Exminder, fara implicarea Prestatorului.\n' +
      '\n' +
      '8. Raspunderea partilor\n' +
      'Limitarea raspunderii Prestatorului privind functionarea Platformei si Serviciile Exminds.\n' +
      '8.1. Utilizatorul înțelege și acceptă că Prestatorul pune la dispoziția acestuia Platforma si Serviciile Exminds “as is”. Pe cale de consecință, Utilizatorul înțelege și acceptă că în anumite situații există posibilitatea ca Platforma Exminds să nu funcționeze în mod continuu, stabilit și/sau la parametri tehnici optimi sau poate funcționa cu erori.\n' +
      '8.2. Prestatorul va depune toate diligențelele necesare pentru a repara într-un timp optim orice defecțiune apăruta, insa Prestatorul nu raspunde pentru si nu va repara prejudiciile directe sau indirecte ocazionate Utilizatorilor ca urmare a defectiunilor sau discontinuitatii Platformei.\n' +
      '8.3. Prestatorul raspunde in baza prezentelor T&C numai fata de Utilizator, respectiv pentru prejudiciile directe cauzate acestuia ca urmare a incalcarii prezentelor T&C de catre Prestator. \n' +
      '8.4. Prestatorul nu raspunde si nu va repara in nicio situatie:  prejudiciul indirect cauzat Utilizatorului, inclusiv in ceea ce priveste beneficiul nerealizat de acesta; prejudiciile cauzate de Utlizatorii Exminder de la care Utilizatorul achizitioneaza Experiente. \n' +
      '8.5. Atunci cand raspunderea Prestatorului va putea fi angajata, conform prezentelor T&C, acesta va repara exclusiv prejudiciul direct cauzat Utilizatorului, in limita pretului platit de Utilizator pentru Serviciile Exminds in luna in care Prestatorul si-a incalcat obligatiile asumate fata de acesta.\n' +
      '\n' +
      'Raspunderea utilizatorului\n' +
      '8.6. Utilizatorul va repara intreg prejudiciul direct si indirect cauzat Prestatorului, prin incalcarea prezentelor T&C si a legislatiei incidente. \n' +
      '8.7. Utilizatorul intelege si accepta ca este direct si exclusiv responsabil pentru utilizarea si consecintele utilizarii Platformei si a serviciilor Exminds, Prestatorul neputând fi ținut responsabil pentru modalitatea de utilizare a Platformei, pentru orice consecințe și/sau daune rezultate din utilizarea acesteia.\n' +
      '8.8. Utilizatorul intelege si accepta ca este direct si exclusiv responsabil in relatie cu Utilizatorul Exminder de la care achizitioneaza o Experienta, fara ca Prestatorul sa poata fi tinut responsabil in acest sens. Atunci cand Utilizatorul cauzeaza un prejudiciu unui Utilizator Exminder, acesta in va repara direct fata de Utilizatorul Exminder, fara implicarea Prestatorului, cu exceptia cazului in care prin prezentele T&C se stabiliesc reguli diferite. \n' +
      '\n' +
      '9. Drepturi de proprietate intelectuala\n' +
      '9.1. Toate drepturile de proprietate intelectuală privind Platforma Exminds aparțin Prestatorului. Prin prezentul contract, Utilizatorul nu dobândește niciun drept de proprietate intelectuală cu privire la Platforma Exminds,  cu excepția dreptului de utilizare temporară și neexclusivă a acesteia, în condițiile  T&C.\n' +
      '9.2. Toate drepturile de proprietate intelectuala privind Experientele listate in cadrul Platformei (conceptul si descrierea acestora, materiale foto si video listate in pagina Experientei) apartin Utilizatorului Exminder care a listat Experienta, sau Prestatorului dupa caz. Prin achizitia unei Experiente, Utilizatorul nu dobandeste niciun drept de proprietate intelectuală cu privire la Experienta achizitionata.\n' +
      '9.3. Utilizatorul se obligă să respecte toate drepturile de proprietate intelectuală ale Prestatorului si ale Utilizatorilor Exminder, dupa caz, și să se abțină de la orice acțiune prin care drepturile de proprietate intelectuală ale acestora ar putea fi încălcate, precum dar fara a se limita la: accesarea, copierea, divulgarea codului sursă al Platformei, copierea și/sau divulgarea  conținutului Platformei (denumiri, descrieri, concepte, materiale foto, video, Experientele listate in cadrul acestora etc.), copierea și/sau divulgarea funcționalităților Platformei, etc.\n' +
      '\n' +
      '10. Legea aplicabila si instanta competenta\n' +
      'Prezentul document este redactat in limba romana si va fi interpretat potrivit intelegerii sensului termenilor sai in limba romana. \n' +
      'Prezentul document este guvernat de legislatia romana incidenta. Orice litigiu in legatura cu utilizarea Platformei se va supune spre solutionare instantelor judecatoresti competente de la sediul Prestatorului.\n' +
      '\n' +
      '11. Alte dispozitii\n' +
      'Ne puteti contacta oricand prin email la adresa: contact@exminds.com\n' +
      'Prezentul document a fost aprobat si se află în in vigoare incepand cu data de: ',
    stripeContent:
      'Vă mulțumim pentru utilizarea aplicației Exminds, dezvoltată și operată de către Exminds SRL. Înainte de a vă înregistra pe platforma noastră și de a crea un cont pe Stripe Connect, vă rugăm să citiți cu atenție următorul disclaimer, care explică implicațiile acestei acțiuni.\n' +
      '\n' +
      'Crearea contului pe Stripe Connect. Aplicația Exminds oferă posibilitatea utilizatorilor de tip "Individual" să-și creeze un cont pe Stripe Connect pentru a gestiona plățile și tranzacțiile în cadrul experiențelor oferite de platformă. Prin crearea acestui cont, sunteți de acord că Exminds SRL va acționa în numele dumneavoastră în ceea ce privește înregistrarea și administrarea acestui cont pe Stripe Connect.\n' +
      '\n' +
      'Responsabilitatea privind taxele locale. Dacă alegeți să vă creați un cont de tip "Individual" pe Stripe Connect, vă asumați în mod expres și implicit responsabilitatea de a vă conforma legilor fiscale și cerințelor fiscale aplicabile în țara dvs. de reședință. Exminds SRL nu va fi responsabilă pentru calcularea, declararea sau plata taxelor dvs. către autoritățile fiscale locale sau internaționale.\n' +
      '\n' +
      'Consiliere fiscală. Vă recomandăm să căutați consultanță și asistență fiscală din partea unui specialist calificat pentru a vă asigura că respectați toate obligațiile fiscale aplicabile în țara dvs. de reședință. Exminds SRL nu furnizează consultanță fiscală și nu poate oferi sfaturi legale sau fiscale specifice.\n' +
      '\n' +
      'Datele personale și securitatea. Pentru a crea contul pe Stripe Connect, este posibil să fiți solicitat să furnizați anumite informații personale și financiare. Exminds SRL va gestiona aceste date cu maximă atenție și conform politicii noastre de confidențialitate. Cu toate acestea, nu putem garanta securitatea absolută a datelor dvs., iar utilizarea aplicației Exminds și a Stripe Connect este în întregime pe propria dvs. răspundere.\n' +
      '\n' +
      'Crearea contului "Company" pe Stripe Connect. Aplicația Exminds vă oferă opțiunea de a vă înregistra și de a crea un cont de tip "Company" pe platforma Stripe Connect pentru a vă permite să gestionați plățile și tranzacțiile în cadrul experiențelor oferite de platformă. În acest caz, veți fi redirecționat către platforma Stripe, unde vi se va solicita să introduceți datele specifice ale companiei și detalii bancare.\n' +
      '\n' +
      'Confidențialitate și securitate. Vă asigurăm că Exminds SRL tratează cu maximă atenție informațiile dvs. personale și detalii financiare. Toate datele introduse pe platforma Stripe Connect vor fi gestionate conform politicii noastre de confidențialitate și în conformitate cu regulile de securitate a datelor. Cu toate acestea, trebuie să țineți cont că utilizarea aplicației Exminds și a Stripe Connect se face pe propria dvs. răspundere, iar Exminds SRL nu poate fi responsabilă pentru eventualele breșe de securitate sau utilizări neautorizate ale informațiilor dvs.\n' +
      '\n' +
      'Responsabilitatea privind datele și conformitatea legală În momentul în care furnizați datele companiei și detalii bancare pe platforma Stripe Connect, sunteți singurul responsabil pentru corectitudinea, actualitatea și legalitatea acestor informații. Exminds SRL nu își asumă responsabilitatea pentru erori sau omisiuni în datele introduse de dvs. și nu poate fi trasă la răspundere pentru consecințele acestora.\n' +
      '\n' +
      'Consultanță legală și fiscală. Pentru a vă asigura că respectați toate cerințele legale și fiscale aplicabile în crearea și administrarea unui cont "Company" pe Stripe Connect, vă recomandăm să căutați consultanță și asistență din partea unui specialist juridic sau fiscal calificat. Exminds SRL nu furnizează consultanță juridică sau fiscală și nu poate oferi sfaturi specifice referitoare la înființarea sau administrarea unei companii.\n' +
      '\n' +
      '\n' +
      'Aplicația va percepe furnizorului de conținut un comision de 24% din orice tranzacție efectuată cu succes. În caz de dispute între utilizatori sau neprezentarea furnizorului de conținut la data și ora stabilită, Exminds SRL își rezervă dreptul de a restitui întreaga sumă de plată către cumpărător.\n' +
      'Modificări ale disclaimerului Exminds SRL își rezervă dreptul de a aduce modificări acestui disclaimer fără notificare prealabilă. Prin urmare, vă recomandăm să verificați periodic acest disclaimer pentru a fi la curent cu orice schimbări.\n' +
      '\n' +
      'Prin continuarea procesului de înregistrare pe Stripe Connect și utilizarea aplicației Exminds, sunteți de acord să vă asumați responsabilitatea pentru toate taxele pe care le aveți de plată în țara dvs. de reședință și să vă conformați termenilor și condițiilor prezentate în acest disclaimer.',
    privacyPolicyContent:
      'Informare privind prelucrarea datelor cu caracter personal\n' +
      '\n' +
      '1. Introducere\n' +
      'Prezentul document este intocmit in acord cu si in vederea implementarii dispozitiilor Regulamentului (UE) nr. 2016/679 privind protectia persoanelor fizice in ceea ce priveste prelucrarea datelor cu caracter personal si privind libera circulatie a acestor date („Regulamentul”);\n' +
      'Prezentul document va este adresat in calitatea dvs. utilizator al Platformei Exminds (disponibila la adresa https://www.app.exminds.com/) si are ca scop informarea dvs. in acord cu prevederile Regulamentului, cu privire la prelucrarea datelor dvs. cu caracter personal de catre EXMINDS SRL.\n' +
      'In acord cu prevederile art. 13 din Regulament, va aducem la cunostinta urmatoarele informatii: \n' +
      '\n' +
      '2. Identitatea si datele de contact ale Operatorului\n' +
      'Datele dvs. cu caracter personal sunt prelucrate de catre: EXMINDS SRL, cu sediul in in Bucuresti, Ale. Buhusi nr. 5, bl. 6, sc. 1, ap. 5, Sector 3, Romania, inregistrata la Registrul Comertului cu nr. J40/12811/2023, avand CUI 48469851\n' +
      'Conform Termenilor si Conditiile Platformei Exminds (T&C), EXMINDS SRL este detinatorul Platformei Exminds si furnizeaza servicii in legatura cu utilizarea acesteia catre utilizatori (adica si catre dvs.) Pe cale de consecinta, EXMINDS SRL prelucreaza o serie de date cu caracter personal care va apartin.\n' +
      'Conform Regulamentului, EXMINDS SRL detine calitatea de Operator, respectiv persoana juridica ce stabileste scopurile si mijloacele de prelucrare a datelor dvs. cu caracter personal.\n' +
      '\n' +
      '3. Datele cu caracter personal pe care le prelucram\n' +
      'Putem prelucra urmatoarele date cu caracter personal, in ceea ce va priveste: nume si prenume, email, telefon, adresa, CNP, IP, date privind contul dvs. Stripe, dupa caz alte date cu caracter personal care va apartin si pe care ni le puneti la dispozitie in procesul de creare a contului de utilizator sau pe parcursul utilizarii Platformei.\n' +
      '\n' +
      '4. Scopurile in care va sunt prelucrate datele cu caracter personal\n' +
      'Prelucram datele dvs. in urmatoarele scopuri: pentru a va putea furniza serviciie pe care le-ati contractat, conform T&C, in legatura cu utilizarea Platformei Exminds, respectiv pentru a va putea permite utilizarea Platformei Exminds si a va putea presta serviciile Exminds contractate, spre exemplu: pentru a putea vizualiza si/sau achizitiona Experientele listate in Platforma, pentru putea lista si vinde Experiente prin intermediul Platformei.\n' +
      'Dupa caz, in masura in care ati fost de acord sa va prelucram datele in scop de marketing, va vom prelucra o parte din datele cu caracter personal, in acest scop.\n' +
      'Nu prelucram datele dvs. cu caracter personal in alte scopuri, urmand a va informa si/sau dupa caz a obtine consimtamantul dvs., conform prevederilor legale incidente, in situatia in care vom intentiona sa prelucram/vom prelucra datele dvs. cu caracter personal si in alte scopuri.\n' +
      '\n' +
      '5. Temeiurile in baza carora prelucram datele dvs. cu caracter personal\n' +
      'Prelucram datele cu caracter personal mentionate la art. 3 in baza dispozitiilor art. 6 alin. 1 lit. b din Regulament, respectiv in temeiul executarii unui contract (respectiv pentru a va putea furniza Serviciile contractate conform Termenilor si Conditiilor Platformei Exminds). \n' +
      'De asemenea prelucram datele cu caracter personal mentionate la art. 3 in baza dispozitiilor art. 6 alin. 1 lit. c din Regulament, in temeiul unei obligatii legale, pentru indeplinirea obligatiilor legale incidente, precum obligatii legate de facturare sau pastrarea evidentelor contabile.\n' +
      'In masura incare nu sunteti de acord sa ne furnizati aceste date si sa vi le prelucram conform prezentei politici, nu va vom putea presta Serviciile noastre, respectiv nu veti avea posibilitatea de a utiliza Platforma Exminds.\n' +
      '\n' +
      '6. Perioada de stocare a datelor dvs. cu caracter personal\n' +
      'Vom stoca datele dvs. cu caracter personal mentionate la art. 3 pe toata durata in care aveti un cont de utilizator in Platforma si 30 de zile dupa stergerea acestuia (pentru a ne asigura ca orice relatie contractuala cu ceilalti utilizatori Exminder/Explorer derivata din vanzarea/achizitia de Experiente a fost finalizata). \n' +
      'In situatia in care contul dvs. de utilizator a fost sters din initiativa noastra, sau in masura in care consideram ca ati incalcat T&C, datele dvs. cu caracter personal (nume, email, IP) vor fi stocate pe o perioada suplimentara de 12 luni, exclusiv in scopul de a ne asigura ca nu va vom mai permite crearea unui nou cont de utilizator in Platforma.\n' +
      'De asemenea , putem prelucra datele dvs. cu caracter pe o perioada mai mare de timp, atunci cand avem o atare obligatie legala.\n' +
      'La expirarea perioadei pentru care datele dvs. sunt stocate, acestea vor fi sterse sau anonimizate ireversibil.\n' +
      '\n' +
      '7. Destinatarii datelor cu caracter personal\n' +
      'Datele dvs. cu caracter personal sunt prelucrate de catre noi, in calitate de Operator.\n' +
      'Nu vom divulga datele dvs. cu caracter personal catre terti, cu urmatoarele exceptii: vom putea transmite datele dvs. cu caracter personal catre: parteneri sau furnizori, in masura in care transmiterea datelor dvs. cu caracter personal catre aceste persoane este necesara pentru prestarea serviciilor contractate; catre autoritatile competente care ne vor solicita sau carora avem obligatia de a le furniza datele dvs. in temeiul unei prevederi legale; catre avocati, executori judecatoresti sau instante de judecata in situatia in care incalcati obligatiile asumate fata de Prestator conform termenilor si conditiilor Platformei Exminds si intelegem sa ne exercitam drepturile legale in vederea recuperarii prejudiciului cauzat, in alte situatii prevazute de lege.\n' +
      'De asemenea, intelegeti si acceptati ca pentru a va putea presta Serviciile Exminds si in masura necesara pentru prestare acestor servicii , datele dvs. cu caracter personal vor putea  fi transmise dupa caz catre: utilizatorii Exminder de la care achizitionati Experiente, utilizatorii Explorer care v-au achizitionat Experientele.\n' +
      'Precizam ca in fiecare situatie in care vom transmite datele dvs. tertilor ne vom asigura ca transferam numai acele date necesare pentru indeplinirea scopului avut in vedere, respectiv nu vom transmite in nicio situatie mai multe date decat este necesar.\n' +
      '\n' +
      '8. Unde stocam datele dvs. cu caracter personal\n' +
      'Datele dvs. cu caracter personal sunt stocate pe teritoriul Uniunii Europene.\n' +
      '\n' +
      '9. Masuri de securitate\n' +
      'Prelucram datele dvs. cu caracter personal, in acord cu politica noastra interna privind securitatea datelor. In acest sens va asiguram ca am implementat toate masurile de securitate impuse de legislatia in vigoare pentru asigurarea securitatii datelor dvs.\n' +
      'In caz de incalcare a securitatii datelor dvs. cu caracter personal susceptibila sa genereze un risc ridicat pentru drepturile si libertatile dvs., va vom informa fara intarzieri despre aceasta incalcare si vom lua toate masurile necesare pentru a remedia problema si/sau pentru a atenua efectele negative.\n' +
      '\n' +
      '10. Procese decizionale automate\n' +
      'Precizam ca nu folosim procese decizionale automate in ceea ce va priveste, respectiv nu veti face obiectul unor decizii bazate exclusiv pe prelucrarea automata, inclusiv crearea de profiluri.\n' +
      '\n' +
      '11. Drepturile dvs.\n' +
      '1.\tDreptul de acces.\n' +
      'Aveti dreptul sa obtineti o confirmare a prelucrarii datelor dvs. cu caracter personal si sa obtineti accesul la aceste date. In masura in care ne veti transmite o solicitare in acest sens, va vom pune la dispozitie o copie a datelor cu caracter personal pe care le prelucram despre dvs. alaturi de toate informatiile pe care avem obligatia sa vi le furnizam, in acord cu prevederile Regulamentului.\n' +
      '2.\tDreptul de rectificare si stergere.\n' +
      'Aveti dreptul sa solicitati corectarea datelor cu caracter personal incorecte, inexacte sau incomplete. De asemenea aveti dreptul sa solicitati stergerea datelor cu caracter personal cand acestea nu mai sunt necesare in raport de scopurile pentru care au fost prelucrate, sau daca prelucrarea acestora este ilegala.\n' +
      '3.\tDreptul la restrictionarea prelucrarii\n' +
      'Aveti dreptul sa solicitati restrictionarea prelucrarii datelor dvs. cu caracter personal in urmatoarele cazuri: contestati exactitatea datelor, caz in care puteti solicita restrictioarea prelucrarii pentru perioada de timp in care facem verificam exactitatea datelor; prelucrarea este ilegala si va opuneti stergerii datelor; nu mai avem nevoie de datele dvs. cu caracter personal, dar dvs. le solicitati pentru constatarea, exercitarea sau apararea unui drept in instanta.\n' +
      '4.\tDreptul la opozitie\n' +
      'Aveti dreptul sa va opuneti prelucrarii datelor dvs. cu caracter personal in scopuri de marketing sau din motive legate de situatia particulara in care va aflati. Intr-o atare situatie, nu vom mai prelucra datele dvs. cu caracter personal, cu exceptia situatiei in care vom demonstra ca avem motive legitime si imperioase care justifica prelucrarea si care prevaleaza asupra intereselor, drepturilor si libertatilor dvs.\n' +
      '5.\tDreptul la portabilitatea datelor\n' +
      'Aveti dreptul de a primi datele cu caracter personal pe care ni le-ati furnizat si care va privesc intr-un format structurat, utilizat in mod curent si care poate fi citit automat. Aveti totodata dreptul de a transmite aceste date unui alt operator.\n' +
      '\n' +
      '12. Exercitarea drepturilor dvs.\n' +
      'Pentru a va exercita oricare din drepturile, va rugam sa ne transmiteti solicitarea dvs. in scris, la adresa de email: contact@exminds.com\n' +
      'Vom raspunde solicitarii dvs. fara intarzieri nejustificate, in termen de cel mult o luna, cu exceptia situatiei in care din cauza complexitatii sau a numarului cererilor vom fi nevoiti sa prelungim termenul de raspuns cu maxim 2 luni, conform Regulamentului.\n' +
      'Totodata previzam ca va vom putea solicita sa furnizati informatii pentru a va confirma identitatea (de exemplu, sa faceti clic pe un link de verificare, sa introduceti un nume de utilizator sau o parola) pentru a va exercita drepturile.\n' +
      '\n' +
      '13. Cookie-uri\n' +
      'Dupa caz, atunci cand utilizam cookie-uri, acestea vor fi supuse politicii privind cookie-urile pe cu privire la care veti fi informat atunci cand accesati Platforma Exminds.\n' +
      '\n' +
      '14. Depunerea unei plangeri la Autoritatea de Supraveghere\n' +
      'In cazul in care apreciati ca drepturile dvs. au fost incalcate, puteti depune o plangere la Autoritatea de Supraveghere competenta, respectiv Autoritatea Nationala de Supraveghere a Prelucrarii Datelor cu Caracter Personal, cu sediul in B-dul G-ral. Gheorghe Magheru 28-30 Sector 1, cod postal 010336 Bucuresti, Romania.\n' +
      '\n' +
      '15. Date de contact\n' +
      'Pentru orice solicitare ne puteti contacta la adresa de email: contact@exminds.com\n' +
      'Prezentul document a fost creat si se afla in vigoare incepand cu data de: ',
    exminderDisclaimerForExperienceCreation:
      'Experiențele Exminds reflectă realitatea - asta înseamnă că pot exista și situații foarte plăcute sau că viața ne poate surprinde cu situații spontane. Este nevoie să fim pregătiți de orice și să înțelegem că această este realitatea Exminds.',
  },
};
