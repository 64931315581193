import { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { AppRole } from 'api/types/user';
import avatarPlaceholder from 'assets/images/placeholders/ProfilePicEmpty.webp';
import * as SC from './styles';

export interface User {
  id: string;
  profilePhoto: string;
  firstName: string;
  lastName: string;
  role: string;
}

interface Props {
  user: User;
  onClick: CallbackFunction;
}

export default function AccountFilterResult({ user, onClick }: Props) {
  const { t } = useTranslation();

  const displayUserRole = (role: string): string => {
    if (role === AppRole.ContentCreator) {
      return t(`general.exminder`);
    }
    if (role === AppRole.Consumer) {
      return t(`general.explorer`);
    }
    return t(`general.popularPerson`);
  };

  return (
    <SC.Container onClick={() => onClick(user.role, user.id)}>
      <SC.AvatarContainer
        src={user.profilePhoto}
        onError={(e: SyntheticEvent<HTMLImageElement>) => {
          e.currentTarget.src = avatarPlaceholder;
        }}
      />
      <SC.UserDetails>
        <SC.BoldText>{`${user.firstName} ${user.lastName}`}</SC.BoldText>
        <SC.RegularText>{displayUserRole(user.role)}</SC.RegularText>
      </SC.UserDetails>
    </SC.Container>
  );
}
