import { authRequest } from 'api/AuthRequest';
import AuthClientStore from 'clientStore/AuthClientStore';
import { differenceInMilliseconds } from 'date-fns';
import { useEffect } from 'react';
import { useAppSelector } from './redux';
import { isAuthenticatedSelector } from '../redux/slices/selectors';

function useRefreshUserTokens() {
  const isAuthenticated = useAppSelector(isAuthenticatedSelector);

  useEffect(() => {
    if (!isAuthenticated || !AuthClientStore.getRefreshToken()) {
      return undefined;
    }

    const accessTokenExpDate = authRequest.getDecodedAccessToken().exp;
    const twoMinutesCushion = 2 * 60 * 1000;
    const refreshTokensTimeout = accessTokenExpDate
      ? Math.max(
          differenceInMilliseconds(
            +accessTokenExpDate * 1000 - twoMinutesCushion,
            Date.now()
          ),
          0
        )
      : 0;
    let refreshTimeoutId: ReturnType<typeof setTimeout>;

    const refresh = () => {
      authRequest.refreshAccessToken().then(() => {
        const newRefreshTokensTimeout = Math.max(
          differenceInMilliseconds(
            +authRequest.getDecodedAccessToken().exp * 1000 - twoMinutesCushion,
            Date.now()
          ),
          0
        );

        refreshTimeoutId = setTimeout(refresh, newRefreshTokensTimeout);
      });
    };

    const timeoutId = setTimeout(refresh, refreshTokensTimeout);

    return () => {
      clearTimeout(timeoutId);
      clearTimeout(refreshTimeoutId);
    };
  }, [isAuthenticated]);
}

export default useRefreshUserTokens;
