/* eslint-disable max-len */
import { AxiosRequestConfig } from 'axios';
import AuthClientStore from 'clientStore/AuthClientStore';
import TypeChecker from 'helpers/classes/TypeChecker';
import jwtDecode from 'jwt-decode';
// eslint-disable-next-line import/no-cycle
import AbstractRequest from './AbstractRequest';
import { ApiMethod, AUTH_REFRESH_TOKENS_SUBROUTE } from './constants';

export interface LoginData {
  email: string;
  password: string;
}

interface DecodedAccessToken {
  sub: string;
  iat: string;
  exp: string;
}

/*
 * These variables are used to debounce the refreshAccessToken function
 */
let debouncedPromise: Promise<unknown> | null;
let debouncedResolve: CallbackFunction;
let debouncedReject: CallbackFunction;
let timeout: NodeJS.Timeout;

export class AuthRequest extends AbstractRequest {
  private readonly authClientStoreService: typeof AuthClientStore;

  private _token: string | null;

  private _refreshToken: string | null;

  private requestInterceptorId: number | null;

  constructor(
    authClientStoreService: typeof AuthClientStore = AuthClientStore
  ) {
    super();
    this.authClientStoreService = authClientStoreService;
    this._token = AuthClientStore.getToken();
    this._refreshToken = AuthClientStore.getRefreshToken();
    this.requestInterceptorId = null;
  }

  get apiResource(): string {
    return 'auth';
  }

  get routes(): Record<string, string> {
    return {
      LOGIN: `${this.apiRoute}/login`,
      TOKEN_LOGIN: `${this.apiRoute}/token-login`,
      REGISTER: `${this.apiRoute}/register`,
      RESEND_REGISTER_CONFIRMATION: `${this.apiRoute}/register/resend-confirmation`,
      REGISTER_GOOGLE: `${this.apiRoute}/google`,
      REGISTER_APPLE: `${this.apiRoute}/apple`,
      ME: `${this.apiRoute}/me`,
      FORGOT_PASSWORD: `${this.apiRoute}/reset-password`,
      RESET_PASSWORD: `${this.apiRoute}/confirm-reset-password`,
      REFRESH_TOKENS: `${this.apiUrl}${AUTH_REFRESH_TOKENS_SUBROUTE}`,
    };
  }

  get token(): string | null {
    return this._token;
  }

  set token(token: string | null) {
    if (token) {
      this.authClientStoreService.setToken(token);
    } else {
      this.authClientStoreService.removeToken();
    }

    this._token = token;
  }

  get refreshToken(): string | null {
    return this._refreshToken;
  }

  set refreshToken(refreshToken: string | null) {
    if (refreshToken) {
      this.authClientStoreService.setRefreshToken(refreshToken);
    } else {
      this.authClientStoreService.removeRefreshToken();
    }

    this._refreshToken = refreshToken;
  }

  setAuthorizationHeader() {
    if (!this.token) {
      return;
    }
    this.requestInterceptorId = this.addRequestInterceptor(
      (config: AxiosRequestConfig) => {
        if (config?.headers && !config.skipAuthorizationInterceptor) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `Bearer ${this.token}`;
        }

        return config;
      }
    );
  }

  unsetAuthorizationHeader() {
    if (TypeChecker.isNumber(this.requestInterceptorId)) {
      this.removeRequestInterceptor(this.requestInterceptorId);
    }
    this.requestInterceptorId = null;
  }

  async login(data: LoginData) {
    const response = await this.request(
      ApiMethod.POST,
      this.routes.LOGIN!,
      data
    );
    this.token = response.data.access_token;
    this.refreshToken = response.data.refresh_token;
    this.setAuthorizationHeader();

    const decodedJwt = this.getDecodedAccessToken();
    return { user: { id: decodedJwt.sub } };
  }

  async tokenLogin(token: string) {
    const response = await this.request(
      ApiMethod.POST,
      this.routes.TOKEN_LOGIN!,
      { token }
    );
    this.token = response.data.access_token;
    this.refreshToken = response.data.refresh_token;
    this.setAuthorizationHeader();

    const decodedJwt = this.getDecodedAccessToken();
    return { user: { id: decodedJwt.sub } };
  }

  logout(): null {
    this.token = null;
    this.refreshToken = null;
    this.unsetAuthorizationHeader();

    return null;
  }

  async me() {
    this.setAuthorizationHeader();
    try {
      const result = await this.request(
        ApiMethod.GET,
        this.routes.ME!,
        undefined,
        { headers: { 'Cache-Control': 'no-store' } }
      );

      return result.data;
    } catch (e) {
      this.unsetAuthorizationHeader();
      throw e;
    }
  }

  async register(data: LoginData) {
    const result = await this.request(
      ApiMethod.POST,
      this.routes.REGISTER!,
      data,
      { withCredentials: true }
    );

    return result.data;
  }

  async resendRegister(data: { email: string }) {
    const result = await this.request(
      ApiMethod.POST,
      this.routes.RESEND_REGISTER_CONFIRMATION!,
      data
    );

    return result.data;
  }

  registerGoogle(): void {
    window.location.href = this.routes.REGISTER_GOOGLE ?? '';
  }

  registerApple(): void {
    window.location.href = this.routes.REGISTER_APPLE ?? '';
  }

  getDecodedAccessToken(): DecodedAccessToken {
    return jwtDecode(this.token ?? '');
  }

  async forgotPassword(data: { email: string }) {
    const result = await this.request(
      ApiMethod.POST,
      this.routes.FORGOT_PASSWORD!,
      data
    );

    return result.data;
  }

  async resetPassword(data: { token: string; password: string }) {
    const result = await this.request(
      ApiMethod.POST,
      this.routes.RESET_PASSWORD!,
      data
    );

    return result.data;
  }

  /*
   * Refreshes the access token
   * So basically what it needs to do:
   * It debounces if there are multiple calls within 200 ms to prevent spamming
   * In the end, it makes a real call to refresh the token and returns the result of this (single) debouncedPromise to each user who made a call to refreshAccessToken
   */
  async refreshAccessToken(signal?: AbortSignal) {
    clearTimeout(timeout);
    if (!debouncedPromise) {
      debouncedPromise = new Promise((resolve, reject) => {
        debouncedResolve = resolve;
        debouncedReject = reject;
      });
    }

    timeout = setTimeout(() => {
      const executeLogic = async () => {
        if (!this.refreshToken) {
          throw new Error('Refresh token is not set');
        }

        const response = await this.request(
          ApiMethod.POST,
          this.routes.REFRESH_TOKENS!,
          undefined,
          {
            headers: { Authorization: `Bearer ${this.refreshToken}` },
            skipAuthorizationInterceptor: true,
            ...(signal && { signal }),
          }
        );

        this.token = response.data.access_token;
        this.refreshToken = response.data.refresh_token;
        this.unsetAuthorizationHeader();
        this.setAuthorizationHeader();
      };

      executeLogic().then(debouncedResolve).catch(debouncedReject);

      debouncedPromise = null;
    }, 200);

    return debouncedPromise;
  }
}

export const authRequest = new AuthRequest();
