import { useCallback } from 'react';
import throttle from 'lodash.throttle';
import notificationSound from '../assets/audio/generalSound.mp3';

function playSound() {
  const audio = new Audio(notificationSound);
  audio.play().catch((e) => console.error(e));
}

function playNotificationSound(
  maxPlaysBeforeCooldown: number,
  cooldownTimeInMs: number,
  resetMaxPlaysCountInMs: number
) {
  let block = false;
  let plays = 0;
  let resetPlaysTimeoutId: ReturnType<typeof setTimeout>;

  return () => {
    if (block) {
      return;
    }
    plays += 1;

    if (plays === maxPlaysBeforeCooldown) {
      block = true;

      setTimeout(() => {
        block = false;
        plays = 0;
      }, cooldownTimeInMs);
    }

    clearTimeout(resetPlaysTimeoutId);
    resetPlaysTimeoutId = setTimeout(() => {
      plays = 0;
    }, resetMaxPlaysCountInMs);

    playSound();
  };
}

const usePlayNotificationSound = (
  maxPlaysBeforeCooldown = 3,
  throttleSoundInMs = 750,
  cooldownTimeInMs = 30000,
  resetMaxPlaysCountInMs = 10000
) => {
  const memoizedPlayNotificationSound = useCallback(
    throttle(
      playNotificationSound(
        maxPlaysBeforeCooldown,
        cooldownTimeInMs,
        resetMaxPlaysCountInMs
      ),
      throttleSoundInMs
    ),
    []
  );

  return { playNotificationSound: memoizedPlayNotificationSound };
};

export default usePlayNotificationSound;
