import { KeyboardEvent, MouseEvent, ReactNode } from 'react';
import * as SC from './styles';

interface Props {
  children: ReactNode;
  disabled?: boolean;
  onClick?: (e: MouseEvent) => void;
}

export default function ButtonWrapper({
  children,
  disabled = false,
  onClick,
  ...props
}: Props) {
  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (
      (e.key === ' ' || e.key === 'Enter' || e.key === 'Spacebar') &&
      onClick &&
      !disabled
    ) {
      e.preventDefault();
      onClick(e as unknown as MouseEvent);
    }
  };

  return (
    <SC.Container
      role="button"
      aria-pressed="false"
      tabIndex={0}
      {...props}
      disabled={disabled}
      onClick={(e: MouseEvent) => {
        if (onClick && !disabled) {
          onClick(e);
        }
      }}
      onKeyDown={handleKeyDown}
    >
      {children}
    </SC.Container>
  );
}
