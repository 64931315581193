import styled from 'styled-components';
import { zIndex } from '../../../../helpers/constants/zIndexValues';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  min-width: 320px;
  min-height: 100vh;

  background: ${({ theme }) => theme.palette.main.white};
  white-space: pre-wrap;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Background = styled.div`
  width: 100%;
  min-width: 320px;
  min-height: 100vh;
  background: ${({ theme }) =>
    `linear-gradient(180deg, ${theme.palette.main.cyan}40 0%, ${theme.palette.main.cyan}80 100%)`};
  white-space: pre-wrap;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

export const MainContent = styled.div`
  width: 100%;
  height: 100%;
  max-width: 1200px;
  white-space: pre-wrap;
  padding: 40px 20px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;
  @media only screen and (max-width: 768px) {
    align-items: flex-start;
  }
`;

export const FilteringContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 0 20px 20px 20px;
`;

export const FiltersButton = styled.div`
  position: relative;
`;

export const FiltersCountBadge = styled.div`
  position: absolute;
  top: -2px;
  right: -2px;
  width: 17px;
  height: 17px;
  border-radius: 50px;

  display: flex;
  justify-content: center;
  align-items: center;

  background: ${({ theme }) => theme.palette.main.marine};
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-weight: 700;
  font-size: ${({ theme }) => theme.typography.pxToRem(-4)};
  line-height: ${({ theme }) => theme.typography.pxToRem(-4)};
  color: ${({ theme }) => theme.palette.main.white};
`;

export const SearchBarContainer = styled.div`
  width: 100%;
  max-width: 400px;
`;

export const TabBarContainer = styled.div`
  width: 100%;
  overflow: auto;
`;

export const ExperiencesContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 30px;
`;

export const UsersContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
`;

export const NavigationBarContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
  left: 0;
  z-index: ${zIndex.navigationBar};
  padding-top: 40px;
  background: ${({ theme }) => theme.palette.main.white};

  box-shadow: ${({ theme }) => `0px 4px 4px ${theme.palette.main.turquoise}80`};

  overflow: hidden;
`;

export const NavigationBarContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
