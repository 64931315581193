export default {
  review: {
    leaveAReview: 'Lasă un review',
    howWasExperienceNoStar: 'Spune-ne mai multe despre cum a fost Experiența.',
    howWasExperience: 'Spune-ne mai multe despre cum a fost Experiența.*',
    howWasExminder:
      'Spune-ne mai multe despre cum a fost interacțiunea ta cu Exminderul.*',
    howWasExplorer:
      'Spune-ne mai multe despre cum a fost interacțiunea ta cu Explorerul.*',
    describeExperience: 'Descrie cât mai în detaliu',
    describeExminder: 'Descrie cât mai în detaliu',
    describeExplorer: 'Descrie cât mai în detaliu',
    uploadVideo: 'Încarcă un video',
    uploadReview: 'Încarcă review-ul',
    congratulations: 'Felicitări!',
    reviewUploaded: 'Review-ul tău a fost încărcat',
    experienceEnded: 'Experiența "{{title}}" a luat sfârșit',
    seeExperience: 'Vezi Experiențele',
    rateExperience: 'Evaluează Experiența "{{title}}"',
    rateExminder: 'Evaluează Exminderul ({{exminderName}})',
    rateExplorer: 'Evaluează Explorerul ({{explorerName}})',
    enjoyExperience: 'Cât de mult ți-a plăcut Experiența?',
    matchWithExminder: 'Cât de bine te-ai înțeles cu Exminderul?',
    matchWithExplorer: 'Cât de bine te-ai înțeles cu Explorerul?',
    notThatMuch: 'Nu prea\nmult',
    veryMuch: 'Foarte\nmult',
  },
};
