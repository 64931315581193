import { JSX, SVGProps } from 'react';

export default function MyExperiencesIcon(
  props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>
) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.4 0H8.4C7.44522 0 6.52955 0.379285 5.85442 1.05442C5.17929 1.72955 4.8 2.64522 4.8 3.6V4.8H3.6C2.64522 4.8 1.72955 5.17929 1.05442 5.85442C0.379285 6.52955 0 7.44522 0 8.4V20.4C0 21.3548 0.379285 22.2705 1.05442 22.9456C1.72955 23.6207 2.64522 24 3.6 24H15.6C16.5548 24 17.4705 23.6207 18.1456 22.9456C18.8207 22.2705 19.2 21.3548 19.2 20.4V19.2H20.4C21.3548 19.2 22.2705 18.8207 22.9456 18.1456C23.6207 17.4705 24 16.5548 24 15.6V3.6C24 2.64522 23.6207 1.72955 22.9456 1.05442C22.2705 0.379285 21.3548 0 20.4 0ZM16.8 20.4C16.8 20.7183 16.6736 21.0235 16.4485 21.2485C16.2235 21.4736 15.9183 21.6 15.6 21.6H3.6C3.28174 21.6 2.97652 21.4736 2.75147 21.2485C2.52643 21.0235 2.4 20.7183 2.4 20.4V12H16.8V20.4ZM16.8 9.6H2.4V8.4C2.4 8.08174 2.52643 7.77652 2.75147 7.55147C2.97652 7.32643 3.28174 7.2 3.6 7.2H15.6C15.9183 7.2 16.2235 7.32643 16.4485 7.55147C16.6736 7.77652 16.8 8.08174 16.8 8.4V9.6ZM21.6 15.6C21.6 15.9183 21.4736 16.2235 21.2485 16.4485C21.0235 16.6736 20.7183 16.8 20.4 16.8H19.2V8.4C19.1968 7.99063 19.1237 7.58481 18.984 7.2H21.6V15.6ZM21.6 4.8H7.2V3.6C7.2 3.28174 7.32643 2.97652 7.55147 2.75147C7.77652 2.52643 8.08174 2.4 8.4 2.4H20.4C20.7183 2.4 21.0235 2.52643 21.2485 2.75147C21.4736 2.97652 21.6 3.28174 21.6 3.6V4.8Z"
        fill="#0082AA"
      />
    </svg>
  );
}
