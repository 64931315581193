export default {
  packageAddressSelection: {
    buttons: {
      back: 'Înapoi',
      next: 'Înainte',
    },
    title: 'Locul Experienței',
    subtitle: 'Unde va avea loc Experiența?',
    hintSearch: 'Introdu locația ta...',
    expand: 'Clic pentru a extinde harta',
  },
};
