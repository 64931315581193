export default {
  experienceFeed: {
    searchExperiencesPeople: 'Search Experiences, people...',
    emptyState: {
      title: 'No Experiences here yet, stay tuned for new upcoming ones.',
      subtitle: 'Don’t be sad!',
      subtitleExminder: 'Create a new Experience and share it with the world!',
      subtitleExplorer: 'You can always become an Exminder or come back later!',
      buttonCreateExperience: 'Create Experience',
      buttonCreateAuction: 'Create Auction',
      buttonTurnOnNotification: 'Turn on notifications',
    },
  },
};
