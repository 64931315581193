import { lazy } from 'react';
import PATH from './paths';
import { Route } from './types';

const ConnectedAccountSettingsPage = lazy(
  () =>
    import(
      '../components/smart/pages/accountSettings/ConnectedAccountSettingsPage'
    )
);

const ConnectedAccountDetailsPage = lazy(
  () =>
    import(
      '../components/smart/pages/accountSettings/ConnectedAccountDetailsPage'
    )
);

const ConnectedPersonalDetailsPage = lazy(
  () =>
    import(
      '../components/smart/pages/accountSettings/ConnectedPersonalDetailsPage'
    )
);

const ConnectedFinancialDetailsPage = lazy(
  () =>
    import(
      '../components/smart/pages/accountSettings/ConnectedFinancialDetailsPage'
    )
);

const ConnectedAppLanguagePage = lazy(
  () =>
    import('../components/smart/pages/accountSettings/ConnectedAppLanguagePage')
);

const ConnectedFirstNamePage = lazy(
  () =>
    import('../components/smart/pages/accountSettings/ConnectedFirstNamePage')
);

const ConnectedLastNamePage = lazy(
  () =>
    import('../components/smart/pages/accountSettings/ConnectedLastNamePage')
);

const ConnectedPasswordPage = lazy(
  () =>
    import('../components/smart/pages/accountSettings/ConnectedPasswordPage')
);

const ConnectedEmailPage = lazy(
  () => import('../components/smart/pages/accountSettings/ConnectedEmailPage')
);

const ConnectedEmailPinInputPage = lazy(
  () =>
    import(
      '../components/smart/pages/accountSettings/ConnectedEmailPinInputPage'
    )
);

const ConnectedEmailVerifiedPage = lazy(
  () =>
    import(
      '../components/smart/pages/accountSettings/ConnectedEmailVerifiedPage'
    )
);

const ConnectedPhoneNumberPage = lazy(
  () => import('../components/smart/pages/accountSettings/ConnectedPhonePage')
);

const ConnectedPhoneNumberPinInputPage = lazy(
  () =>
    import(
      '../components/smart/pages/accountSettings/ConnectedPhonePinInputPage'
    )
);

const ConnectedPhoneNumberVerifiedPage = lazy(
  () =>
    import(
      '../components/smart/pages/accountSettings/ConnectedPhoneNumberVerifiedPage'
    )
);

const ConnectedPhoneConfirmationPage = lazy(
  () =>
    import(
      '../components/smart/pages/accountSettings/ConnectedConfirmPhoneNumberPage'
    )
);

const ConnectedDeleteAccountPage = lazy(
  () =>
    import(
      '../components/smart/pages/accountSettings/ConnectedDeleteAccountPage'
    )
);

const ACCOUNT_SETTINGS_INDEX: Route = {
  component: ConnectedAccountSettingsPage,
  path: PATH.ACCOUNT_SETTINGS.INDEX,
  isProtected: true,
};

const ACCOUNT_DETAILS: Route = {
  component: ConnectedAccountDetailsPage,
  path: PATH.ACCOUNT_SETTINGS.SET_ACCOUNT_DETAILS,
  isProtected: true,
};

const PERSONAL_DETAILS: Route = {
  component: ConnectedPersonalDetailsPage,
  path: PATH.ACCOUNT_SETTINGS.SET_PERSONAL_DETAILS,
  isProtected: true,
};

const ACCOUNT_FIRST_NAME: Route = {
  component: ConnectedFirstNamePage,
  path: PATH.ACCOUNT_SETTINGS.SET_FIRST_NAME,
  isProtected: true,
};

const ACCOUNT_LAST_NAME: Route = {
  component: ConnectedLastNamePage,
  path: PATH.ACCOUNT_SETTINGS.SET_LAST_NAME,
  isProtected: true,
};

const ACCOUNT_CHANGE_PASSWORD: Route = {
  component: ConnectedPasswordPage,
  path: PATH.ACCOUNT_SETTINGS.SET_PASSWORD,
  isProtected: true,
};

const ACCOUNT_CHANGE_EMAIL: Route = {
  component: ConnectedEmailPage,
  path: PATH.ACCOUNT_SETTINGS.SET_EMAIL,
  isProtected: true,
};

const ACCOUNT_EMAIL_PIN_INPUT: Route = {
  component: ConnectedEmailPinInputPage,
  path: PATH.ACCOUNT_SETTINGS.PIN_INPUT_EMAIL,
  isProtected: true,
};

const ACCOUNT_EMAIL_VERIFIED: Route = {
  component: ConnectedEmailVerifiedPage,
  path: PATH.ACCOUNT_SETTINGS.EMAIL_VERIFIED,
  isProtected: true,
};

const ACCOUNT_CHANGE_PHONE_NUMBER: Route = {
  component: ConnectedPhoneNumberPage,
  path: PATH.ACCOUNT_SETTINGS.SET_PHONE_NUMBER,
  isProtected: true,
};

const ACCOUNT_PHONE_NUMBER_PIN_INPUT: Route = {
  component: ConnectedPhoneNumberPinInputPage,
  path: PATH.ACCOUNT_SETTINGS.PIN_INPUT_PHONE_NUMBER,
  isProtected: true,
};

const ACCOUNT_PHONE_NUMBER_VERIFIED: Route = {
  component: ConnectedPhoneNumberVerifiedPage,
  path: PATH.ACCOUNT_SETTINGS.PHONE_NUMBER_VERIFIED,
  isProtected: true,
};

const ACCOUNT_FINANCIAL_REGISTRATION: Route = {
  component: ConnectedFinancialDetailsPage,
  path: PATH.ACCOUNT_SETTINGS.FINANCIAL_REGISTRATION,
  isProtected: true,
};

const APP_LANGUAGE: Route = {
  component: ConnectedAppLanguagePage,
  path: PATH.ACCOUNT_SETTINGS.APP_LANGUAGE,
  isProtected: true,
};

const ACCOUNT_PHONE_CONFIRMATION: Route = {
  component: ConnectedPhoneConfirmationPage,
  path: PATH.ACCOUNT_SETTINGS.PHONE_NUMBER_CONFIRMATION,
  isProtected: true,
};

const DELETE_MY_ACCOUNT: Route = {
  component: ConnectedDeleteAccountPage,
  path: PATH.ACCOUNT_SETTINGS.DELETE_MY_ACCOUNT,
  isProtected: true,
};

export default [
  ACCOUNT_SETTINGS_INDEX,
  ACCOUNT_DETAILS,
  PERSONAL_DETAILS,
  ACCOUNT_FINANCIAL_REGISTRATION,
  APP_LANGUAGE,
  ACCOUNT_FIRST_NAME,
  ACCOUNT_LAST_NAME,
  ACCOUNT_CHANGE_PASSWORD,
  ACCOUNT_CHANGE_EMAIL,
  ACCOUNT_EMAIL_PIN_INPUT,
  ACCOUNT_EMAIL_VERIFIED,
  ACCOUNT_CHANGE_PHONE_NUMBER,
  ACCOUNT_PHONE_NUMBER_PIN_INPUT,
  ACCOUNT_PHONE_NUMBER_VERIFIED,
  ACCOUNT_PHONE_CONFIRMATION,
  DELETE_MY_ACCOUNT,
];
