export default {
  awardModal: {
    title: 'ADAUGĂ PREMIU',
    titleEdit: 'EDITEAZĂ PREMIU',
    subtitle: '*Câmp obligatoriu',
    form: {
      name: 'Nume*',
      place: 'Loc*',
      description: 'Descriere*',
      date: 'Data*',
      link: 'Link*',
      presentationPhoto: 'Fotografie de prezentare*',
    },
    view: {
      name: 'Nume',
      place: 'Loc',
      description: 'Descriere',
      date: 'Data',
      link: 'Link',
      presentationPhoto: 'Fotografie de prezentare',
    },
    placeholder: {
      name: 'Ex: Puterea prezentului',
      place: 'Ex: 1, 2, 3',
      time: 'Ex: Iunie 2020',
      description: 'Tastați aici maxim 500 de caractere',
      link: 'Ex: link direct pentru a-l cumpăra de pe Amazon',
    },
    button: {
      exit: 'Ieșire',
      done: 'Gata',
    },
  },
};
