import { userId } from 'routes/paths/constants';
import AbstractRequest from './AbstractRequest';
import { ApiMethod } from './constants';
import {
  ApiGetPopolarPersonsResponse,
  ApiGetPopularPersonResponse,
} from './types/popularPerson';

export class PopularPersonRequest extends AbstractRequest {
  get apiResource(): string {
    return 'popular-person-request';
  }

  get routes(): Record<string, string> {
    return {
      GET_POPULAR_PERSON_SEARCHED: `${this.apiRoute}/search`,
      GET_POPULAR_PERSON: `${this.apiRoute}/${this.dynamicIds.id}`,
      REQUEST_POPULAR_PERSON: `${this.apiRoute}/${this.dynamicIds.id}/request`,
      CHECK_IF_REQUESTED_POPULAR_PERSON: `${this.apiRoute}/${this.dynamicIds.id}/check-if-requested`,
    };
  }

  get dynamicIds(): Record<string, string> {
    return {
      id: '{id}',
    };
  }

  async getPopularPersonSearched(queryOptions: {
    limit: number;
    offset: number;
    searchText?: string;
  }): Promise<ApiGetPopolarPersonsResponse> {
    const queryParams = [
      { key: 'limit', value: `${queryOptions.limit}` },
      { key: 'offset', value: `${queryOptions.offset}` },
    ];

    if (queryOptions.searchText) {
      queryParams.push({ key: 'searchText', value: queryOptions.searchText });
    }

    const result = await this.request(
      ApiMethod.GET,
      this.routes.GET_POPULAR_PERSON_SEARCHED +
        this.buildQueryParams(queryParams)
    );
    return result.data;
  }

  async getPopularPerson(id: string): Promise<ApiGetPopularPersonResponse> {
    const result = await this.request(
      ApiMethod.GET,
      this.routes.GET_POPULAR_PERSON!.replace(this.dynamicIds.id!, id)
    );

    return result.data;
  }

  async requestPopularPerson(id: string): Promise<string> {
    const result = await this.request(
      ApiMethod.POST,
      this.routes.REQUEST_POPULAR_PERSON!.replace(this.dynamicIds.id!, id)
    );

    return result.data;
  }

  async checkRequestedPopularPerson(id: string): Promise<boolean> {
    const result = await this.request(
      ApiMethod.GET,
      this.routes.CHECK_IF_REQUESTED_POPULAR_PERSON!.replace(
        this.dynamicIds.id!,
        id
      )
    );

    return result.data;
  }
}

export const popularPersonRequest = new PopularPersonRequest();
