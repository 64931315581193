export default {
  countdown: {
    month: '{{count}} month',
    months: '{{count}} months',
    day: '{{count}} day',
    days: '{{count}} days',
    hour: '{{count}} hour',
    hours: '{{count}} hours',
    h: '{{count}}h',
    minute: '{{count}} minute',
    minutes: '{{count}} minutes',
    m: '{{count}}m',
    second: '{{count}} second',
    seconds: '{{count}} seconds',
    s: '{{count}}s',
  },
};
