import theme from 'styles/theme/classic';
import * as SC from './styles';

interface LoadingSpinnerProps {
  color?: string;
  size?: number;
  weight?: number;
}

export default function LoadingSpinner({
  size = 18,
  weight = 2.5,
  color = theme.palette.main.white,
}: LoadingSpinnerProps) {
  return <SC.LoadingSpinnerStyled size={size} weight={weight} color={color} />;
}
