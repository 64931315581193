import { lazy } from 'react';
import PATH from './paths';
import { Route } from './types';

const ConnectedExminderProfilePage = lazy(
  () =>
    import('../components/smart/pages/exminder/ConnectedExminderProfilePage')
);

const EXMINDER_VIEW_ONE: Route = {
  component: ConnectedExminderProfilePage,
  path: PATH.EXMINDER.VIEW_ONE,
  isProtected: true,
  hasMenuBar: true,
};

export default [EXMINDER_VIEW_ONE];
