import { lazy } from 'react';
import PATH from './paths';
import { Route } from './types';

const ConnectedPrivacyPolicyPage = lazy(
  () =>
    import('../components/smart/pages/privacyPolicy/ConnectedPrivacyPolicyPage')
);

const PRIVACY_POLICY: Route = {
  component: ConnectedPrivacyPolicyPage,
  path: PATH.PRIVACY_POLICY,
  isProtected: true,
};

export default [PRIVACY_POLICY];
