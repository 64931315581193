export default {
  invoices: {
    title: 'Permissions',
    description:
      'Authorize Exminds to automatically issue invoices to the Explorers on my behalf',
    searchbar: {
      placeholder: 'Type a keyword',
    },
    dropdown: {
      placeholder: 'Filter by time period',
      all: 'All',
      today: 'Today',
      last7days: 'Last 7 days',
      last30days: 'Last 30 days',
      last90days: 'Last 90 days',
      lastYear: 'Last year',
    },
    empty: 'You have no invoices issued during the selected time',
    card: {
      from: 'From',
    },
  },
};
