import styled from 'styled-components';
import { zIndex } from '../../../../helpers/constants/zIndexValues';

export const DropdownContainer = styled.div<{ visible: boolean }>`
  display: ${(props) => (props.visible ? 'block' : 'none')};
  z-index: ${zIndex.popperElement};
  box-sizing: border-box;
  width: 100%;
`;
export const Container = styled.div`
  width: 100%;
  position: relative;
`;
export const InputContainer = styled.div`
  width: 100%;
  z-index: ${zIndex.popperReferenceElement};
`;
