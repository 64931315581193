export default {
  accountSettings: {
    myAccount: 'My Account',
    personalDetails: 'Personal Details',
    accountDetails: 'Account Details',
    appLanguage: 'App Language',
    currentLanguage: 'Current Language',
    addCurrentLanguage: 'Add current language',
    paymentDetails: 'Payment Details',
    financialDetails: 'Financial Details',
    notificationsDetails: 'Notifications',
    appSettings: 'App Settings',
    deleteMyAccount: 'Delete My Account',
    personalDetailsSettings: {
      dateOfBirth: 'Date of Birth',
      nativeLanguage: 'Native language',
      addNativeLanguage: 'Add native language',
      otherLanguages: 'Other languages',
      addOtherLanguages: 'Add other languages',
      country: 'Country',
      addCountry: 'Add country',
      city: 'City',
      addCity: 'Add city',
      currentLocation: 'Current Location',
      addCurrentLocation: 'Add current location',
      notOldEnough: 'You must be at least 18 years old',
    },
    accountDetailsSettings: {
      firstName: 'First Name',
      middleName: 'Middle Name',
      lastName: 'Last Name',
      email: 'Email',
      mobile: 'Mobile',
      password: 'Password',
    },
    firstNameSettings: {
      currentName: 'Current first name',
      newName: 'New first name',
      newNamePlaceholder: 'Write your new first name',
      details: 'Details',
      detailsPlaceholder:
        'Give us more details about the reason you want to change your name',
      nameRequired: 'First name is required',
      detailsRequired: 'Details are required',
    },
    middleNameSettings: {
      currentName: 'Current middle name',
      newName: 'New middle name',
      newNamePlaceholder: 'Write your new middle name',
      details: 'Details',
      detailsPlaceholder:
        'Give us more details about the reason you want to change your name',
      nameRequired: 'Middle name is required',
      detailsRequired: 'Details are required',
    },
    lastNameSettings: {
      currentName: 'Current last name',
      newName: 'New last name',
      newNamePlaceholder: 'Write your new last name',
      details: 'Details',
      detailsPlaceholder:
        'Give us more details about the reason you want to change your name',
      nameRequired: 'Last name is required',
      detailsRequired: 'Details are required',
    },
    nameChangedConfirmation: {
      message:
        ' We’ll send you a notification on your email address and on the app to keep you updated if the name change received approval.',
    },
    emailSettings: {
      currentEmail: 'Current email',
      newEmail: 'New email',
      newEmailPlaceholder: 'Write your new email',
      emailConfirmation:
        'I think your new email address wants to tell you something...See what it is!',
      emailVerified:
        'Everything seems good! Your email address has been confirmed.',
      emailRequired: 'Email is required',
      emailInvalid: 'Invalid email address',
    },
    mobileSettings: {
      currentMobile: 'Current mobile',
      newMobile: 'New mobile',
      newMobilePlaceholder: 'Write your new mobile',
      pinConfirmation: 'Your phone speaks in codes...\nTell us what it says!',
      typeCode: 'Type in the code',
      resendCode: 'Re-send code',
      pinVerified: 'Yay! Your new mobile number has been confirmed.',
      mobileRequired: 'Mobile number is required',
      mobileInvalid: 'Invalid mobile number',
      mobileNotSet: 'Mobile number not set',
    },
    passwordSettings: {
      currentPassword: 'Current password',
      newPassword: 'New password',
      repeatPassword: 'Repeat new password',
      currentPasswordPlaceholder: 'Write your current password',
      newPasswordPlaceholder: 'Write your new password',
      repeatPasswordPlaceholder: 'Re-write your new password',
      currentPasswordRequired: 'Current password is required',
      passwordRequired: 'Password is required',
      confirmPasswordRequired: 'Confirmation password is required',
      noMatch: 'Passwords do not match',
      notStrong:
        'Password must be at least 8 characters long, contain at least one uppercase letter, one lowercase letter, and one number',
    },
    paymentDetailsSettings: {
      paymentDetails: 'Payment Details',
      bankAccount: 'Bank Account',
      invalidIban: 'Invalid IBAN format',
      requiredIban: 'IBAN is required',
    },
    financialDetailsSettings: {
      financialDetails: 'Financial Details',
      financialRegistration: {
        title: 'Financial Registration',
        registered: {
          subtitle: 'You are registered with Stripe',
          description:
            'You have already registered your account.' +
            '\n\n' +
            'Enjoy the feeling of monetizing your skills thorugh Experience Packages!\n',
        },
        notRegistered: {
          subtitle: 'You are not registered with Stripe',
          description:
            'In order to make your Experiences public you must Register your Account to Stripe Connect.' +
            '\n\n' +
            'Your Experiences will be hidden until you make this change.',
          action: 'Register with Stripe',
        },
        phoneNotSet: {
          subtitle: 'Your phone number is not set',
          description:
            'In order to register with Stripe you must set your phone number first.',
          action: 'Set Phone Number',
        },
      },
      financialReport: {
        title: 'Financial Report',
        subtitle: 'Earnings History',
        description:
          'In order to access the history of your earnings check the following button:',
        action: 'See Stripe Express Dashboard',
      },
      financialPermissions: {
        title: 'Financial Permissions',
        action:
          'Authorize Exminds to automatically issue invoices to the Explorers on my behalf',
        authorize:
          'Now Exminds is authorized to issue invoices to the Explorers on your behalf',
        unauthorize:
          'Now Exminds is no longer authorized to issue invoices to the Explorers on your behalf',
      },
    },
    deleteAccountSettings: {
      warning: 'Beware!',
      description:
        'This action cannot be undone and your profile information will be permanently deleted',
      checkbox:
        'I understand and agree that all my profile information will be permanently deleted',
      deleteAccount: 'Delete Account',
    },
    phoneConfirmation: {
      title: 'Phone Confirmation',
      description:
        'To publish your Experience, you need to request a code in order to confirm your current phone number ',
      sendCode: 'Request Code',
    },
    thankYou: 'Thank you',
    save: 'Save',
  },
};
