export default {
  referralDashboard: {
    exmindsPartners: 'Exminds Partners',
    description:
      'Invite your friends to join The Exminds Universe and earn money for each successful transaction on the app.' +
      '\n\n' +
      "Just share your unique Referral Link below, and when your friends sign up using your link, you'll be able to track your Referral History and Referral Balance." +
      '\n\n' +
      'Get your time’s worth... now more than ever!\n\n',
    percentageGainAndDate:
      'You will earn {{percentageGain}}% of the app fee from each transaction made by Explorers who purchase Experiences using your referral link, until {{dueDate}}.',
    referralLink: 'Your Referral Link',
    referralAccountNotFound: 'Referral Account Not Found',
    referralBalance: 'Referral Balance',
    statistics: 'Statistics',
    linkClicks: 'Link Clicks: ',
    registeredUsers: 'Registered Users: ',
    soldExperiences: 'Sold Experiences: ',
    table: {
      placeholder:
        'No Explorers have registered and purchased\nExperiences using your referral link yet',
      referralHistory: 'Referral History',
      user: 'User',
      experienceName: 'Experience Name',
      reward: 'Reward',
      transactionDate: 'Transaction Date',
    },
  },
};
