import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle<{ isFlutterView: boolean }>`
  html {
    height: 100%;
    margin: 0;
    padding: 0;
  }

  body {
    background: ${({ theme }) => theme.palette.main.white};
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: ${({ theme }) =>
      theme.typography
        .fontFamily}, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: ${({ theme }) => theme.typography.fontSize};
    font-weight: ${({ theme }) => theme.typography.fontWeight};
    line-height: ${({ theme }) => theme.typography.lineHeight};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
    touch-action: pan-x pan-y;

    .background-container {
      padding-top: ${({ isFlutterView }) => isFlutterView && '70px'};
    }

    .menu-bar {
      padding-bottom: 78px;
    }
  }

  h1, h2, h3, h4, h5, h6, p {
    margin: 0;
  }

  input, textarea {
    font-size: ${({ theme }) => theme.typography.fontSize};

    ::placeholder {
      font-size: ${({ theme }) => theme.typography.fontSize};
    }

    :-ms-input-placeholder {
      font-size: ${({ theme }) => theme.typography.fontSize};
    }

    ::-ms-input-placeholder {
      font-size: ${({ theme }) => theme.typography.fontSize};
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-background-clip: text;
      -webkit-text-fill-color: ${({ theme }) => theme.palette.main.white};
      box-shadow: inset 0 0 0 100px ${({ theme }) =>
        `${theme.palette.main.yellow}73`};
    }
  }

  * {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    tap-highlight-color: rgba(0, 0, 0, 0);
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  .sb-show-main.sb-main-padded {
    padding: 0;
  }
`;

export default GlobalStyle;
