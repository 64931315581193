import { lazy } from 'react';
import PATH from './paths';
import { Route } from './types';

const ConnectedReportUserPage = lazy(
  () => import('../components/smart/pages/report/ConnectedReportExminderPage')
);

const ConnectedReportExperiencePage = lazy(
  () => import('../components/smart/pages/report/ConnectedReportExperiencePage')
);

const ConnectedReportConfirmationPage = lazy(
  () =>
    import('../components/smart/pages/report/ConnectedReportConfirmationPage')
);

const REPORT_USER_PAGE: Route = {
  component: ConnectedReportUserPage,
  path: PATH.REPORT.USER,
  isProtected: true,
};

const REPORT_EXPERIENCE_PAGE: Route = {
  component: ConnectedReportExperiencePage,
  path: PATH.REPORT.EXPERIENCE,
  isProtected: true,
};

const REPORT_USER_CONFIRMATION_PAGE: Route = {
  component: ConnectedReportConfirmationPage,
  path: PATH.REPORT.USER_REPORTED_CONFRIMATION,
  isProtected: true,
};

export default [
  REPORT_USER_PAGE,
  REPORT_EXPERIENCE_PAGE,
  REPORT_USER_CONFIRMATION_PAGE,
];
