import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as SC from './styles';
import ExminderViewClientStore from '../../../../clientStore/ExminderViewClientStore';
import ConfirmActionModal from '../Modals/ConfirmActionModal';

interface EmptyPackagesStateProps {
  isExminder: boolean;
  isOnboardingSkipped: boolean;
  onCreateExperienceClick: CallbackFunction;
  onCreateAuctionClick: CallbackFunction;
  onTurnOnNotifications: CallbackFunction;
  onBuildProfile: CallbackFunction;
}

export default function EmptyPackagesState({
  isExminder,
  isOnboardingSkipped,
  onCreateAuctionClick,
  onCreateExperienceClick,
  onTurnOnNotifications,
  onBuildProfile,
}: EmptyPackagesStateProps) {
  const [isBuildProfileModalOpen, setIsBuildProfileModalOpen] = useState(false);

  const { t } = useTranslation();
  return (
    <SC.Container>
      <SC.TextContainer>
        <SC.Text> {t(`experienceFeed.emptyState.title`)}</SC.Text>
        <SC.Text>
          {t(`experienceFeed.emptyState.subtitle`)}
          <br />
          {isExminder && ExminderViewClientStore.getExminderView()
            ? t(`experienceFeed.emptyState.subtitleExminder`)
            : t(`experienceFeed.emptyState.subtitleExplorer`)}
        </SC.Text>
      </SC.TextContainer>

      {
        isExminder && ExminderViewClientStore.getExminderView() ? (
          // <ButtonsCreateExperienceAuction
          //   onCreateAuctionClick={onCreateAuctionClick}
          //   onCreateExperienceClick={onCreateExperienceClick}
          // />
          <SC.AddButton
            onClick={() =>
              isOnboardingSkipped
                ? setIsBuildProfileModalOpen(true)
                : onCreateExperienceClick()
            }
          >
            <SC.WhiteText>
              {t(`addExperience.createExperienceBtn`)}
            </SC.WhiteText>
          </SC.AddButton>
        ) : null
        // (
        // <SC.LinkText onClick={onTurnOnNotifications}>
        //   {t(`experienceFeed.emptyState.buttonTurnOnNotification`)}
        // </SC.LinkText>
        // )
      }
      <ConfirmActionModal
        isOpen={isBuildProfileModalOpen}
        onClose={() => {
          setIsBuildProfileModalOpen(false);
        }}
        onConfirm={onBuildProfile}
        title={t(`createProfile.onBoardingSkippedModal.title`)}
        description={[t(`createProfile.onBoardingSkippedModal.subtitle`)]}
        cancelText={t(`general.cancel`)}
        confirmationText={t(
          `createProfile.onBoardingSkippedModal.createProfile`
        )}
      />
    </SC.Container>
  );
}
