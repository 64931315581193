export default {
  addProfilePicture: {
    addAProfilePicture: 'Add a profile picture',
    profilePictureIdealSize: 'Ideal size: at least 200 x 200 pixels',
    checkRecommendations: '⚠️️ Check the recommendations below',
    tryNotToHave: 'Try not to have',
    blurry: 'blurry or unclear pictures',
    containOtherPeople: 'pictures that contain other people',
    busyBackground: 'pictures with a busy background that detracts from you',
    sunglasses: 'sunglasses',
    misleading: 'pictures in a way that is misleading or obstructs your face',
    closeOrFar:
      'pictures where you are too close to or far away from the camera',
    brightOrDark: 'pictures that are too bright or dark',
  },
};
