export default {
  formValidations: {
    correctEmailOrPassword: 'Correct the following errors: email or password',
    correctEmailAndPassword: 'Correct the following errors: email and password',
    correctEmail: 'Correct the following errors: email',
    correctPassword: 'Correct the following errors: password',
    pleaseFillOutThisField: 'Please fill out this field',
    invalidEmailAddress: 'Invalid email address',
    duplicateEmail: 'The email is already being used',
    passwordMismatch: "Passwords don't match",
  },
};
