import AbstractClientStore from './AbstractClientStore';

const LOCAL_STORAGE_EXMINDER_VIEW_KEY = 'exminderView';

class ExminderViewClientStore extends AbstractClientStore {
  static getExminderView(): boolean {
    const exminderView = this.storage.getItem(LOCAL_STORAGE_EXMINDER_VIEW_KEY);
    if (exminderView === null) {
      return false;
    }
    return exminderView === 'true';
  }

  static setExminderView(value: boolean): void {
    this.storage.setItem(LOCAL_STORAGE_EXMINDER_VIEW_KEY, value.toString());
  }

  static removeExminderView(): void {
    this.storage.removeItem(LOCAL_STORAGE_EXMINDER_VIEW_KEY);
  }
}

export default ExminderViewClientStore;
