import * as SC from './styles';

interface FilterTabProps {
  tabText: string;
  isSelected: boolean;
  handleSelectedFilter: CallbackFunction;
}

export default function FilterTab({
  tabText,
  isSelected = false,
  handleSelectedFilter,
}: FilterTabProps) {
  return (
    <SC.Container onClick={() => handleSelectedFilter(tabText)}>
      <SC.Content isSelected={isSelected}>{tabText}</SC.Content>
      <SC.SelectionLineContainer>
        {isSelected && <SC.SelectionLine />}
      </SC.SelectionLineContainer>
    </SC.Container>
  );
}
