export default {
  benefitsRequirementsLayout: {
    benefitLayout: {
      title: 'Benefits',
      description:
        'Before participating in your Experience, Explorers should clearly understand how your Experience will benefit them.\n\nFor an optimal Experience, please include at least one Benefit.',
      titlePlaceholder: 'Add benefit title here',
      descriptionPlaceholder: 'Describe your benefit',
    },
    requirementLayout: {
      title: 'Requirements',
      description:
        'Before participating in your Experience, Explorers must be aware of and meet the essential requirements. You have the right to decline participation to those who do not meet your criteria.\n\nFor an optimal Experience, please include at least one Requirement.',
      titlePlaceholder: 'Add requirement title here',
      descriptionPlaceholder: 'Describe your requirement',
    },
    benefitsRequirementsPage: {
      benefitsTitle: 'Amazing!\n\nLet’s add benefits to your Experience',
      requirementsTitle:
        'Amazing!\n\nLet’s add requirements to your Experience',
      onCompleteTitle: 'Your Experience is done and ready to be published',
      onCompleteSubtitle: 'Good luck in finding great Explorers!',
    },
    delete: {
      areYouSureDelete: 'Are you sure you want to delete this?',
    },
    next: 'Next',
    edit: 'Edit',
    back: 'Back',
  },
};
