import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  cursor: pointer;
`;

export const AvatarContainer = styled.img`
  width: 45px;
  height: 45px;
  border-radius: 50%;
  object-fit: cover;
  object-position: 50% 0%;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
`;

export const UserDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;
export const BoldText = styled.div`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-weight: 700;
  font-size: ${({ theme }) => theme.typography.pxToRem(2)};
  line-height: ${({ theme }) => theme.typography.pxToRem(2)};
  color: ${({ theme }) => theme.palette.main.marine};
`;
export const RegularText = styled.div`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-weight: 500;
  font-size: ${({ theme }) => theme.typography.pxToRem(0)};
  line-height: ${({ theme }) => theme.typography.pxToRem(0)};
  color: ${({ theme }) => theme.palette.main.marine};
`;
