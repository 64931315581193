import { ReactNode, useState } from 'react';
import { usePopper } from 'react-popper';
import * as PopperJS from '@popperjs/core';
import { Offsets } from '@popperjs/core';
import { VariationPlacement } from '@popperjs/core/lib/enums';
import * as SC from './styles';

interface PoperElementProps {
  referenceElement: ReactNode;
  popperElement: ReactNode;
  placementValue?: PopperJS.Placement;
  strategyValue?: PopperJS.PositioningStrategy;
  offsetValue?: Offsets;
  flipPlacements?: VariationPlacement[];
  visible?: boolean;
}

export default function PopperElement({
  referenceElement,
  popperElement,
  placementValue = 'bottom-start',
  offsetValue = { x: 0, y: 0 },
  visible = false,
  flipPlacements = [],
  strategyValue = 'fixed',
}: PoperElementProps) {
  const [referenceRef, setReferenceRef] = useState<HTMLDivElement | null>(null);
  const [popperRef, setPopperRef] = useState<HTMLDivElement | null>(null);

  const { styles, attributes } = usePopper(referenceRef, popperRef, {
    strategy: strategyValue,
    placement: placementValue,
    modifiers: [
      {
        name: 'offset',
        enabled: offsetValue.x !== 0 || offsetValue.y !== 0,
        options: {
          offset: [offsetValue.x, offsetValue.y],
        },
      },
      {
        name: 'preventOverflow',
        options: {
          mainAxis: false,
        },
      },
      {
        name: 'flip',
        options: {
          fallbackPlacements: flipPlacements,
        },
      },
    ],
  });
  return (
    <SC.Container ref={setReferenceRef}>
      <SC.InputContainer>{referenceElement}</SC.InputContainer>
      <SC.DropdownContainer
        visible={visible}
        ref={setPopperRef}
        style={styles.popper}
        {...attributes.popper}
      >
        {popperElement}
      </SC.DropdownContainer>
    </SC.Container>
  );
}
