export default {
  chooseHobbies: {
    letUsKnowMore: 'What hobbies do you have?',
    chooseUpToSevenHobbies:
      ' Choose up to seven options that best represent you.',
    editYourHobbies: 'Edit your hobbies',
    leadership: '🧑‍💼 Leadership',
    sports: '🎾 Sports',
    cooking: '🍝 Cooking',
    hiking: '🧗 Hiking',
    music: '🎶 Music',
    arts_and_crafts: '🎨 Arts & Crafts',
    agriculture: '🥕 Agriculture',
    fishing: '🎣 Fishing',
    graphic_design: '🖥️ Graphic Design',
    public_speaking: '🎤 Public Speaking',
    reading: '📚 Reading',
    architecture: '🏠 Architecture',
    coding: '🧑‍💻 Coding',
    gardening: '⛲️ Gardening',
    writing: '✏️ Writing',
    dancing: '💃 Dancing',
    video_editing: '🎥 Video editing',
    gaming: '🕹️ Gaming',
    volunteering: '🙋 Volunteering',
    acting: '🎭 Acting',
    yoga: '🧘 Yoga',
    swimming: '🏊 Swimming',
    karting: '🏎️ Karting',
    ai: '‍🦾 AI',
    streaming: '🎙️ Streaming',
    photography: '📸 Photography',
    travelling: '🏝️ Travelling',
    beauty: '💄 Beauty',
    languages: '🇰🇷 Languages',
    back: 'Back',
    next: 'Next',
  },
};
