import styled from 'styled-components';

export const LoadingSpinnerStyled = styled.div<{
  size: number;
  weight: number;
  color: string;
}>`
  border: ${({ color, weight }) => `${weight}px solid ${color}`};
  border-top: ${({ weight }) => `${weight}px solid transparent`};
  border-radius: 50%;
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  animation: spin 1s linear infinite;
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
