import { AppRole, OnboardingStatus } from 'api/types/user';
import MenuBar from 'components/dumb/atomics/MenuBar';
import { useAppSelector } from 'hooks/redux';
import {
  appViewRole,
  authUserMessagesCount,
  authUserNewsCount,
  authUserOnboardingIsSkipedSelector,
  authUserOnboardingStatusSelector,
} from 'redux/slices/selectors';
import PATH from 'routes/paths';
import useInAppNavigate from '../../../../hooks/useInAppNavigate';

interface MenuBarProps {
  onMenuClick: CallbackFunction;
}

export default function ConnectedMenuBar({ onMenuClick }: MenuBarProps) {
  const { navigate } = useInAppNavigate();

  const appRole = useAppSelector(appViewRole);
  const isOnboardingSkipped = useAppSelector(
    authUserOnboardingIsSkipedSelector
  );
  const onboardingStatus = useAppSelector(authUserOnboardingStatusSelector);
  const userMessagesCount = useAppSelector(authUserMessagesCount);
  const userNewsCount = useAppSelector(authUserNewsCount);

  return (
    <MenuBar
      isExminder={appRole === AppRole.ContentCreator}
      isOnboardingSkipped={
        isOnboardingSkipped &&
        onboardingStatus !== OnboardingStatus.ProfilePictureSet
      }
      notificationsCount={userMessagesCount + userNewsCount}
      onMenuClick={onMenuClick}
      onMindFeedClick={() => {
        navigate(PATH.MIND_FEED);
      }}
      onNotificationClick={() => {
        navigate(PATH.NOTIFICATIONS.NEWS);
      }}
      onMarketplaceClick={() => navigate(PATH.INDEX)}
      onStarClick={() => {
        if (appRole === AppRole.ContentCreator) {
          navigate(PATH.EXMINDER.MY_EXPERIENCES);
        } else {
          navigate(PATH.EXPLORER.MY_EXPERIENCES);
        }
      }}
      onBuildProfile={() => navigate(PATH.ONBOARDING.CHAT)}
    />
  );
}
