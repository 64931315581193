import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as SC from './styles';
import PopperElement from '../PopperElement';

export type Option = {
  label: string;
  value: string;
};

interface DropdownInputProps {
  direction?: 'top' | 'bottom';
  hasIcon?: boolean;
  isLoading?: boolean;
  disabled?: boolean;
  error?: boolean;
  isSelectedDisabled?: boolean;
  placeholder?: string;
  options?: Option[];
  optionSelected: Option | null;
  onSelectOption: (option: Option) => void;
  onInputChange?: CallbackFunction;
  onKeyDown?: (event: React.KeyboardEvent) => void;
  onDropdownVisibilityChange?: (isVisible: boolean) => void;
  disableFilter?: boolean;
  serverSideSearchValue?: string;
  isServerSideFiltering?: boolean;
  onClearServerSideSearchValue?: CallbackFunction;
}

export default function DropdownInput(props: DropdownInputProps) {
  const { t } = useTranslation();
  const {
    direction = 'bottom',
    hasIcon = false,
    isLoading,
    disabled = false,
    error = false,
    isSelectedDisabled = false,
    placeholder = t(`general.typeYourAnswer`),
    options = [],
    optionSelected,
    onSelectOption,
    onInputChange,
    onKeyDown,
    onDropdownVisibilityChange,
    disableFilter = false,
    serverSideSearchValue = '',
    isServerSideFiltering = false,
    onClearServerSideSearchValue,
  } = props;
  const [inputValue, setInputValue] = useState<string>('');
  const [searchValue, setSearchValue] = useState<string>('');
  const [selectedOption, setSelectedOption] = useState<Option | null>(
    optionSelected || null
  );
  const [keyboardHighlightedOption, setKeyboardHighlightedOption] =
    useState<Option | null>(null);
  const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const optionsRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});

  useEffect(() => {
    if (onDropdownVisibilityChange) {
      onDropdownVisibilityChange(dropdownVisible);
    }
  }, [dropdownVisible, onDropdownVisibilityChange]);

  const displayValue = () => {
    if (dropdownVisible) {
      if (isServerSideFiltering) {
        return serverSideSearchValue;
      }
      return searchValue;
    }
    return inputValue;
  };

  useEffect(() => {
    if (optionSelected) {
      setSelectedOption(optionSelected);
      if (!isSelectedDisabled) {
        setInputValue(optionSelected?.label ?? '');
      }
    }
  }, [optionSelected, isSelectedDisabled]);

  const handleOptionClick = (option: Option | undefined) => {
    if (!option) {
      return;
    }
    setSelectedOption(option);
    setInputValue(isSelectedDisabled ? '' : option.label);
    setSearchValue('');
    onSelectOption(option);
    setKeyboardHighlightedOption(null);
    if (inputRef.current) {
      inputRef.current.focus();
    }
    setDropdownVisible(false);
  };

  const handleOptionChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const newValue = event.target.value;
    setInputValue(newValue ?? '');
    if (!isServerSideFiltering) {
      setSearchValue(newValue);
    }
    if (!newValue) {
      setSelectedOption(null);
    }
    setSelectedOption(null);
    if (onInputChange) {
      onInputChange(newValue);
    }
    setDropdownVisible(true);
  };

  let filteredOptions: Option[] = options;

  if (!isServerSideFiltering && !disableFilter) {
    filteredOptions = options
      ? options
          .filter((option: Option) =>
            option?.label?.toLowerCase().includes(searchValue.toLowerCase())
          )
          .sort((a, b) =>
            a?.label?.toLowerCase().localeCompare(b?.label?.toLowerCase())
          )
      : [];
  }

  const optionsItems = filteredOptions.map((option: Option) => (
    <SC.OptionDropdownItem
      key={option.value}
      isSelectedDisabled={isSelectedDisabled}
      isSelected={selectedOption?.value === option.value}
      isHighlighted={keyboardHighlightedOption?.value === option.value}
      onMouseEnter={() => setKeyboardHighlightedOption(option)}
      onMouseLeave={() => setKeyboardHighlightedOption(null)}
      onClick={() => handleOptionClick(option)}
      ref={(element: HTMLDivElement) => {
        optionsRefs.current[option.value] = element;
      }}
    >
      <SC.OptionLabel>{`${option.label}`}</SC.OptionLabel>
    </SC.OptionDropdownItem>
  ));

  const getOptions = () => {
    if (filteredOptions.length === 0) {
      return (
        <SC.EmptyDummyItem>{t(`general.noOptionsFound`)}</SC.EmptyDummyItem>
      );
    }
    return optionsItems;
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        setDropdownVisible(false);
        if (selectedOption) {
          setSearchValue('');
          setInputValue(isSelectedDisabled ? '' : selectedOption.label);
        }
        setKeyboardHighlightedOption(null);
      }
    };

    const handleEscape = (e: KeyboardEvent) => {
      if (e.code === 'Escape') {
        setDropdownVisible(false);
        if (selectedOption) {
          setSearchValue('');
          setInputValue(isSelectedDisabled ? '' : selectedOption.label);
        }
        setKeyboardHighlightedOption(null);
        if (inputRef.current) {
          inputRef.current.blur();
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keyup', handleEscape);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keyup', handleEscape);
    };
  }, [wrapperRef, selectedOption, isSelectedDisabled]);

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (onKeyDown) {
      onKeyDown(event);
    }

    const visibleOptions = filteredOptions;
    let currentIndex = -1;

    if (keyboardHighlightedOption !== null) {
      currentIndex = visibleOptions.indexOf(keyboardHighlightedOption);
    }

    const isOptionHighlighted = keyboardHighlightedOption !== null;

    if (event.key === 'ArrowDown' || (event.key === 'Tab' && dropdownVisible)) {
      event.preventDefault();
      let nextIndex;
      if (isOptionHighlighted) {
        nextIndex = (currentIndex + 1) % visibleOptions.length;
      } else {
        nextIndex = 0;
      }
      const nextOption = visibleOptions[nextIndex];
      setKeyboardHighlightedOption(nextOption ?? null);
      if (nextOption) {
        if (!isSelectedDisabled) {
          setInputValue(nextOption.label);
          onSelectOption(nextOption);
        }
        optionsRefs.current[nextOption.value]?.scrollIntoView({
          block: 'nearest',
        });
      }
    } else if (event.key === 'ArrowUp') {
      event.preventDefault();
      if (dropdownVisible) {
        let prevIndex;
        if (isOptionHighlighted) {
          prevIndex =
            (currentIndex - 1 + visibleOptions.length) % visibleOptions.length;
        } else {
          prevIndex = visibleOptions.length - 1;
        }
        const prevOption = visibleOptions[prevIndex];
        setKeyboardHighlightedOption(prevOption ?? null);
        if (prevOption) {
          if (!isSelectedDisabled) {
            setInputValue(prevOption.label);
            onSelectOption(prevOption);
          }
          optionsRefs.current[prevOption.value]?.scrollIntoView({
            block: 'nearest',
          });
        }
      } else {
        setDropdownVisible(true);
      }
    } else if (event.key === 'Escape' && dropdownVisible) {
      event.preventDefault();
      if (inputValue && selectedOption) {
        if (currentIndex >= 0 && visibleOptions[currentIndex]) {
          handleOptionClick(visibleOptions[currentIndex]);
        } else {
          setDropdownVisible(false);
        }
      } else {
        setSelectedOption(null);
        setDropdownVisible(false);
        setSearchValue('');
      }
    } else if (event.key === 'Enter' && dropdownVisible) {
      event.preventDefault();
      if (currentIndex >= 0 && visibleOptions[currentIndex]) {
        handleOptionClick(visibleOptions[currentIndex]);
      } else {
        setDropdownVisible(false);
      }
    }
  };

  const handleFocusClick = () => {
    if (disabled) {
      return;
    }
    setDropdownVisible(true);
    setSearchValue('');
    setInputValue('');
    if (onClearServerSideSearchValue) {
      onClearServerSideSearchValue();
    }
  };

  return (
    <SC.Container ref={wrapperRef}>
      <PopperElement
        visible
        strategyValue="absolute"
        offsetValue={{ x: 0, y: direction === 'top' ? -28.5 : -28.5 }}
        placementValue={direction === 'top' ? 'top-start' : 'bottom-start'}
        referenceElement={
          <SC.ReferenceContainer>
            <SC.ReferenceInsideBorder>
              <SC.StyledInput
                hasIcon={hasIcon}
                ref={inputRef}
                type="text"
                placeholder={placeholder}
                disabled={disabled}
                value={displayValue()}
                onChange={handleOptionChange}
                onKeyDown={handleKeyDown}
                onFocus={handleFocusClick}
                onClick={handleFocusClick}
                tabIndex={0}
                error={error}
              />
            </SC.ReferenceInsideBorder>
          </SC.ReferenceContainer>
        }
        popperElement={
          <SC.PopperContainer visible={dropdownVisible} direction={direction}>
            <SC.OptionsDropdown direction={direction}>
              <SC.OptionDropdownInner>
                {isLoading ? (
                  <SC.EmptyDummyItem>{t(`general.loading`)}</SC.EmptyDummyItem>
                ) : (
                  getOptions()
                )}
              </SC.OptionDropdownInner>
            </SC.OptionsDropdown>
          </SC.PopperContainer>
        }
      />
    </SC.Container>
  );
}
