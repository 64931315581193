import ErrorBoundaryCustom from 'components/dumb/layouts/ErrorBoundaryCustom';
import { ReactNode } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import PATH from 'routes/paths';
import useInAppNavigate from '../../../hooks/useInAppNavigate';

interface ErrorBoundarySmartProps {
  children?: ReactNode;
}

export default function ErrorBoundarySmart({
  children,
}: ErrorBoundarySmartProps) {
  const { navigate } = useInAppNavigate();

  return (
    <ErrorBoundary
      FallbackComponent={ErrorBoundaryCustom}
      onReset={() => navigate(PATH.INDEX)}
    >
      {children}
    </ErrorBoundary>
  );
}
