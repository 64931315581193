export default {
  buildProfile: {
    chooseRole: 'Select your role',
    whatIsAnExplorer: "What's an Explorer?",
    whatIsAnExminder: "What's an Exminder?",
    exminderDefinition:
      'Exminders are professionals who turn their experience into valuable lessons for others.\n' +
      '\n' +
      'They are the creators of all different Experiences, open up opportunities for others and change the world little by little.\n',
    explorerDefinition:
      'Explorers are the ones who say yes to each opportunity, as they work on their quest for knowledge.\n' +
      '\n' +
      'They discover new people to learn from, create special relationships and live unique Experiences.',
    youAreAnExminder: 'Perfect! You’re an Exminder.',
    youAreAnExplorer: 'Perfect! You’re an Explorer.',
    tellUsMore: 'Tell us more about yourself!',
    buildProfile: 'Build profile',
    profileIsSet: 'Mission accomplished, your profile is complete!',
    welcomeToExmindsCommunity:
      'Welcome to the Exminds community and get ready to live it all!',
  },
};
