import { JSX, SVGProps } from 'react';

export default function HandsOnIcon(
  props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>
) {
  return (
    <svg
      width="25"
      height="26"
      viewBox="0 0 25 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.49476 14.412C9.49476 15.367 8.33976 15.8455 7.66476 15.17L5.55226 13.0575C4.96826 12.4735 4.02176 12.4735 3.43776 13.0575C2.90076 13.5945 2.85176 14.4495 3.32426 15.0445L8.69176 21.8035C10.0488 23.1605 11.9238 24 13.9953 24C18.1373 24 21.4953 20.642 21.4953 16.5V8.5C21.4953 7.6715 20.8238 7 19.9953 7C19.1668 7 18.4953 7.6715 18.4953 8.5V13V5.5C18.4953 4.6715 17.8238 4 16.9953 4C16.1668 4 15.4953 4.6715 15.4953 5.5V12V11V3.5C15.4953 2.6715 14.8238 2 13.9953 2C13.1668 2 12.4953 2.6715 12.4953 3.5V11V12V4.5C12.4953 3.6715 11.8238 3 10.9953 3C10.1668 3 9.49526 3.6715 9.49526 4.5V12L9.49476 14.412Z"
        stroke="#0082AA"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
