export default {
  createProfile: {
    buildProfile: {
      youAreAnExminder: 'Perfect! You’re an Exminder.',
      youAreAnExplorer: 'Perfect! You’re an Explorer.',
      tellUsMore: 'Tell us more about yourself!',
      buildProfile: 'Build profile',
      jumpInApp: 'Jump in App',
      skipStepModal: {
        title: 'Skip the step?',
        subtitle: {
          exminder:
            'Explorers will only buy Experiences from you if they know more about your past!' +
            '\n\n' +
            'Get more credibility by building your profile and earn people’s trust',
          explorer:
            'Exminders will accept to share their Experiences with you only if they know more about your past!' +
            '\n\n' +
            'Get more credibility by building your profile and earn people’s trust',
        },
        doItLater: 'I’ll do it later',
      },
    },
    onBoardingSkippedModal: {
      title: 'Continue Onboarding',
      subtitle:
        'To access more of the Exminds Universe you need to create a profile.',
      createProfile: 'Create profile',
    },
    onboardingChat: {
      firstNameStep: {
        welcomeToExminds: 'Welcome to Exminds!',
        inOrderToJoin:
          'First, introduce yourself to the world. Create a profile that truly reflects who you are, so that all your future interactions with other users will be at the highest level.' +
          '\n\nLet’s begin!',
        whatIsYourFirstName: "What's your first name?",
      },
      lastNameStep: {
        whatIsYourLastName: "What's your last name, ",
      },
      dateOfBirthStep: {
        whatIsYourDateOfBirth: 'What’s your date of birth?',
        disclaimer:
          'We request this information only to confirm that you fulfill the age requirement for our app and possess the required legal permissions.',
      },
      nativeLanguageStep: {
        whatIsYourNativeLanguage: 'What’s your native language?',
        disclaimer:
          'Your native language can help us to best match you with people who share similar backgrounds.',
      },
      otherLanguagesStep: {
        whatOtherLanguagesDoYouSpeak: 'What other languages do you speak?',
        disclaimer:
          'Your amazing skillset would not be complete without you sharing the additional languages you speak.',
      },
      locationStep: {
        whichCountryAreYouLocatedIn: 'Which country are you located in?',
        disclaimer:
          'To facilitate connections with people that are close to you or who have similar backgrounds, please provide the country you are currently in.',
      },
      cityStep: {
        whichCityAreYouLocatedIn: 'Which city are you located in?',
        disclaimer:
          'To optimize your Experience and connect you with people close to you, kindly provide the name of your current city.',
      },
      mobileNumberStep: {
        oneLastQuestion: 'One last question...',
        whatIsYourMobileNumber: 'What’s your mobile number?',
        mobileNumberSkipped: 'Mobile number skipped',
        neverExpose:
          'Don’t worry, we’ll never expose your number to anyone, unless you want to.',
        amazing: 'Amazing!\nNow let’s find out more about your passions.',
        disclaimer:
          'Also, upcoming verfication and security measures may request your telephone number.',
      },
    },
  },
};
