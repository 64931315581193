export default {
  countdown: {
    month: '{{count}} lună',
    months: '{{count}} luni',
    day: '{{count}} zi',
    days: '{{count}} zile',
    hour: '{{count}} oră',
    hours: '{{count}} ore',
    h: '{{count}}o',
    minute: '{{count}} minut',
    minutes: '{{count}} minute',
    m: '{{count}}m',
    second: '{{count}} secundă',
    seconds: '{{count}} secunde',
    s: '{{count}}s',
  },
};
