import styled from 'styled-components';
import { zIndex } from '../../../../helpers/constants/zIndexValues';

export const Container = styled.div`
  width: 100%;
  height: 58px;
  position: relative;
`;

export const ReferenceContainer = styled.div`
  width: 100%;
  height: 58px;
  position: relative;
  z-index: ${zIndex.popperReferenceElement};

  background: ${({ theme }) => theme.palette.main.white};
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.05);
  border-radius: 43px;
`;

export const ReferenceInsideBorder = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  ::before {
    content: '';
    position: absolute;
    z-index: -1;
    inset: 0;
    border-radius: 40px;
    border: 1px solid transparent;
    background: linear-gradient(
        87.33deg,
        rgba(0, 175, 185, 0.2) 21.62%,
        rgba(0, 175, 185, 0) 100%
      )
      border-box;
    mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
`;

export const PopperContainer = styled.div<{
  visible: boolean;
  direction: string;
}>`
  width: 100%;
  height: 100%;
  max-height: 322px;

  background: ${({ theme }) => theme.palette.main.white};
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.05);
  border-radius: ${(props) =>
    props.direction === 'top' ? '15px 15px 0 0' : '0 0 15px 15px'};

  display: ${(props) => (props.visible ? 'flex' : 'none')};
  flex-direction: column;
  align-items: center;

  padding: 0;
  overflow: hidden;
`;

export const OptionEmoji = styled.div`
  text-align: left;
`;

export const OptionLabel = styled.div`
  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;

export const StyledInput = styled.input<{
  hasIcon: boolean;
  error: boolean;
}>`
  width: 100%;
  height: 58px;

  padding: 20px 20px 20px ${({ hasIcon }) => (hasIcon ? '50px' : '20px')};
  background: transparent;
  border: none;

  font-family: ${({ theme }) => theme.typography.fontFamily};
  color: ${({ theme }) => `${theme.palette.main.darkGray}`};
  font-weight: 600;
  line-height: 18px;
  text-align: left;

  ::placeholder {
    font-family: ${({ theme }) => theme.typography.fontFamily};
    color: ${({ theme }) => `${theme.palette.main.darkGray}33`};
    font-weight: 500;
    line-height: 18px;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    font-family: ${({ theme }) => theme.typography.fontFamily};
    color: ${({ theme }) => `${theme.palette.main.darkGray}33`};
    font-weight: 500;
    line-height: 18px;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    font-family: ${({ theme }) => theme.typography.fontFamily};
    color: ${({ theme }) => `${theme.palette.main.darkGray}33`};
    font-weight: 500;
    line-height: 18px;
  }

  -webkit-appearance: none;

  outline: ${({ error, theme }) =>
    error ? `2px solid ${theme.palette.main.red}` : 'none'};

  &:focus {
    outline: ${({ error, theme }) =>
      error ? `2px solid ${theme.palette.main.red}` : 'none'};
  }
  border-radius: 43px;
`;

export const OptionsDropdown = styled.div<{ direction: string }>`
  width: 100%;
  height: 100%;
  max-height: 208px;
  overflow: auto;
  padding: 0;
  margin: ${(props) =>
    props.direction === 'top' ? '0 0 28.5px 0' : '28.5px 0 0 0'};

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  ::-webkit-scrollbar {
    display: none;
    width: 4px;
    background-color: transparent;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 10px;
    margin-bottom: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.palette.main.marine};
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => `${theme.palette.main.marine}CC`};
  }
`;

export const OptionDropdownItem = styled.div<{
  isSelected: boolean;
  isHighlighted: boolean;
  isSelectedDisabled: boolean;
}>`
  width: 100%;
  height: 52px;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  padding: 0 20px;
  cursor: pointer;
  border: 0;
  margin: 0;
  outline: 1px solid rgba(0, 0, 0, 0.05);

  font-family: ${({ theme }) => theme.typography.fontFamily};
  color: ${({ theme }) => `${theme.palette.main.darkGray}CC`};
  font-weight: ${({ isSelectedDisabled, isSelected }) =>
    // eslint-disable-next-line no-nested-ternary
    isSelectedDisabled === false ? (isSelected ? `600` : `500`) : `500`};
  font-size: ${({ theme }) => theme.typography.pxToRem(0)};
  line-height: 18px;
  text-align: left;

  background: ${({ isSelectedDisabled, isHighlighted, isSelected, theme }) =>
    // eslint-disable-next-line no-nested-ternary
    isSelectedDisabled === false
      ? isSelected
        ? `#e9f7f5`
        : 'transparent'
      : isHighlighted
      ? `${theme.palette.main.darkGray}0D`
      : 'transparent'};

  &:hover {
    background: ${({ isSelectedDisabled, isHighlighted, isSelected, theme }) =>
      // eslint-disable-next-line no-nested-ternary
      isSelectedDisabled === false
        ? isSelected
          ? `#e9f7f5`
          : `${theme.palette.main.darkGray}0D`
        : isHighlighted
        ? `${theme.palette.main.darkGray}0D`
        : `${theme.palette.main.darkGray}0D`};
  }
`;

export const OptionDropdownInner = styled.div`
  width: 100%;
`;

export const EmptyDummyItem = styled.div`
  width: 100%;
  height: 52px;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  padding: 0 20px;
  border: 0;
  margin: 0;

  font-family: ${({ theme }) => theme.typography.fontFamily};
  color: ${({ theme }) => `${theme.palette.main.darkGray}CC`};
  font-weight: 500;
  font-size: ${({ theme }) => theme.typography.pxToRem(0)};
  line-height: 18px;
  text-align: left;

  background: transparent;
`;
