export default {
  accountSettings: {
    myAccount: 'Contul Meu',
    personalDetails: 'Detalii Personale',
    accountDetails: 'Detalii Cont',
    appLanguage: 'Limba Aplicației',
    currentLanguage: 'Limba Curentă',
    addCurrentLanguage: 'Adaugă limba curentă',
    paymentDetails: 'Detalii Plată',
    financialDetails: 'Detalii Financiare',
    notificationsDetails: 'Notificări',
    appSettings: 'Setări Aplicație',
    deleteMyAccount: 'Șterge contul',
    personalDetailsSettings: {
      dateOfBirth: 'Data Nașterii',
      nativeLanguage: 'Limba Maternă',
      addNativeLanguage: 'Adaugă limba maternă',
      otherLanguages: 'Alte limbi cunoscute',
      addOtherLanguages: 'Adaugă alte limbi cunoscute',
      country: 'Țara',
      addCountry: 'Adaugă țara',
      city: 'Oraș',
      addCity: 'Adaugă orașul',
      currentLocation: 'Locația Curentă',
      addCurrentLocation: 'Adaugă locația curentă',
      notOldEnough: 'Trebuie să ai cel puțin 18 ani',
    },
    accountDetailsSettings: {
      firstName: 'Prenume',
      middleName: 'Numele Mijlociu',
      lastName: 'Numele de familie',
      email: 'Email',
      mobile: 'Mobil',
      password: 'Parolă',
    },
    firstNameSettings: {
      currentName: 'Prenumele curent',
      newName: 'Prenume nou',
      newNamePlaceholder: 'Scrie noul tău prenume',
      details: 'Detalii',
      detailsPlaceholder:
        'Oferă-ne mai multe detalii despre motivul pentru care dorești să-ți schimbi numele',
      nameRequired: 'Prenumele este obligatoriu',
      detailsRequired: 'Detaliile sunt obligatorii',
    },
    middleNameSettings: {
      currentName: 'Numele mijlociu curent',
      newName: 'Numele mijlociu nou',
      newNamePlaceholder: 'Scrie noul tău nume mijlociu',
      details: 'Detalii',
      detailsPlaceholder:
        'Oferă-ne mai multe detalii despre motivul pentru care dorești să-ți schimbi numele',
      nameRequired: 'Numele mijlociu este obligatoriu',
      detailsRequired: 'Detaliile sunt obligatorii',
    },
    lastNameSettings: {
      currentName: 'Numele de familie curent',
      newName: 'Numele de familie nou',
      newNamePlaceholder: 'Scrie noul tău nume de familie',
      details: 'Detalii',
      detailsPlaceholder:
        'Oferă-ne mai multe detalii despre motivul pentru care dorești să-ți schimbi numele',
      nameRequired: 'Numele de familie este obligatoriu',
      detailsRequired: 'Detaliile sunt obligatorii',
    },
    nameChangedConfirmation: {
      message:
        'Vei primi o notificare dacă schimbarea numelui a fost aprobată.',
    },
    emailSettings: {
      currentEmail: 'Email curent',
      newEmail: 'Email nou',
      newEmailPlaceholder: 'Scrie noul tău email',
      emailConfirmation:
        'Ai primit noutăți pe adresa ta de mail. Vezi despre ce este vorba!',
      emailVerified:
        'Totul pare în regulă! Adresa ta de email a fost confirmată.',
      emailRequired: 'Email-ul este obligatoriu',
      emailInvalid: 'Adresă de email invalidă',
    },
    mobileSettings: {
      currentMobile: 'Număr mobil curent',
      newMobile: 'Număr mobil nou',
      newMobilePlaceholder: 'Scrie noul tău număr de mobil',
      pinConfirmation:
        'Tocmai ai primit un cod prin SMS, introdu-l mai jos, iar dacă nu ai primit nimic momentan, apasă pe Retrimite codul.',
      typeCode: 'Introdu codul',
      resendCode: 'Retrimite codul',
      pinVerified: 'Mulțumim! Numărul tău de mobil a fost confirmat.',
      mobileRequired: 'Numărul de mobil este obligatoriu',
      mobileInvalid: 'Număr de mobil invalid',
      mobileNotSet: 'Număr de mobil nesetat',
    },
    passwordSettings: {
      currentPassword: 'Parola curentă',
      newPassword: 'Parolă nouă',
      repeatPassword: 'Reintrodu noua parolă',
      currentPasswordPlaceholder: 'Scrie parola curentă',
      newPasswordPlaceholder: 'Scrie parola nouă',
      repeatPasswordPlaceholder: 'Rescrie parola nouă',
      currentPasswordRequired: 'Parola curentă este obligatorie',
      passwordRequired: 'Parola este obligatorie',
      confirmPasswordRequired: 'Confirmarea parolei este obligatorie',
      noMatch: 'Parolele nu se potrivesc',
      notStrong:
        'Parola trebuie să aibă cel puțin 8 caractere, să conțină cel puțin o literă mare, o literă mică și un număr',
    },
    paymentDetailsSettings: {
      paymentDetails: 'Detalii Plată',
      bankAccount: 'Cont Bancar',
      invalidIban: 'Format IBAN invalid',
      requiredIban: 'IBAN-ul este obligatoriu',
    },
    financialDetailsSettings: {
      financialDetails: 'Detalii Financiare',
      financialRegistration: {
        title: 'Înregistrare Informații Financiare',
        registered: {
          subtitle: 'Ești înregistrat/ă cu Stripe',
          description:
            'Ți-ai înregistrat deja contul.\n\nBucură-te de sentimentul de a-ți monetiza abilitățile prin pachete de Experiență!\n',
        },
        notRegistered: {
          subtitle: 'Nu ești înregistrat/ă cu Stripe',
          description:
            'Pentru a face publice Experiențele tale este nevoie să înregistrezi contul prin Stripe Connect.\n\nExperiențele tale vor fi ascunse până când vei face această schimbare.',
          action: 'Înregistrează-te cu Stripe',
        },
        phoneNotSet: {
          subtitle: 'Numărul tău de telefon nu este setat',
          description:
            'Pentru a te înregistra cu Stripe trebuie să-ți setezi mai întâi numărul de telefon.',
          action: 'Setează numărul de telefon',
        },
      },
      financialReport: {
        title: 'Raport Financiar',
        subtitle: 'Istoric Câștiguri',
        description:
          'Pentru a accesa istoricul câștigurilor tale, apasă următorul buton:',
        action: 'Vezi Tabloul de Bord Stripe Express',
      },
      financialPermissions: {
        title: 'Permisiuni Financiare',
        action:
          'Autorizează aplicația Exminds să emită automat facturi către Exploreri în numele meu',
        authorize:
          'Acum Exminds este autorizat să emită facturi către Exploreri în numele tău',
        unauthorize:
          'Acum Exminds nu mai este autorizat să emită facturi către Exploreri în numele tău',
      },
    },
    deleteAccountSettings: {
      warning: 'Atenție!',
      description:
        'Această acțiune nu poate fi anulată și informațiile tale de profil vor fi șterse permanent',
      checkbox:
        'Înțeleg și sunt de acord că toate informațiile mele de profil vor fi șterse permanent',
      deleteAccount: 'Șterge Cont',
    },
    phoneConfirmation: {
      title: 'Confirmare telefon',
      description:
        'Pentru a publica Experiența solicită un cod prin SMS pentru a confirma numărul de telefon',
      sendCode: 'Solicită codul',
    },
    thankYou: 'Mulțumim',
    save: 'Salvează',
  },
};
