import styled from 'styled-components';
import { Form } from 'formik';
import FieldInput from '../../../atomics/FieldInput';

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const ExperienceTypeButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export const PriceRangeInputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

export const PriceRangeInput = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  gap: 10px;
`;

export const PriceRangeDivider = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  font-weight: 700;
  font-size: ${({ theme }) => theme.typography.pxToRem(0)};
  line-height: ${({ theme }) => theme.typography.pxToRem(42)};
  color: ${({ theme }) => theme.palette.main.marine};
`;

export const BoldText = styled.div`
  font-weight: 700;
  font-size: ${({ theme }) => theme.typography.pxToRem(0)};
  line-height: ${({ theme }) => theme.typography.pxToRem(4)};
  color: ${({ theme }) => theme.palette.main.marine};
`;

export const FormWrapper = styled(Form)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

export const InputsContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const Input = styled(FieldInput)<{ error?: boolean }>`
  text-align: center;
  outline: ${({ error, theme }) =>
    error ? `2px solid ${theme.palette.main.red}` : 'none'};

  &:focus {
    outline: ${({ error, theme }) =>
      error ? `2px solid ${theme.palette.main.red}` : 'none'};
  }
  border-radius: 43px;
`;

export const Error = styled.div`
  padding: 0 20px;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-weight: 500;
  font-size: ${({ theme }) => theme.typography.pxToRem(-2)};
  color: ${({ theme }) => theme.palette.main.red};
  text-align: left;
  word-break: break-word;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export const CurrencyDropdownInputContainer = styled.div`
  position: relative;
  width: 100%;
  z-index: 6;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const CountryDropdownInputContainer = styled.div`
  position: relative;
  width: 100%;
  z-index: 5;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const CityDropdownInputContainer = styled.div`
  position: relative;
  width: 100%;
  z-index: 4;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const LanguageDropdownInputContainer = styled.div`
  position: relative;
  width: 100%;
  z-index: 3;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const DomainDropdownInputContainer = styled.div`
  position: relative;
  width: 100%;
  z-index: 2;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const InputContainer = styled.div`
  width: 100%;
  position: relative;
  z-index: 2;
`;

export const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 15px;
`;
