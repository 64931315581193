import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  scroll-snap-type: x mandatory;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const MainContent = styled.div`
  height: 100%;
  display: flex;
  margin: 0 auto;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 5px;
  padding: 0 20px;
`;

export const DivContent = styled.div`
  scroll-snap-align: none;
`;
