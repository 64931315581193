export default {
  packageAddressSelection: {
    buttons: {
      back: 'Back',
      next: 'Next',
    },
    title: 'Experience Place',
    subtitle: 'Where will your Experience take place?',
    hintSearch: 'Type in your location...',
    expand: 'Click to expand map',
  },
};
