import { lazy } from 'react';
import PATH from './paths';
import { Route } from './types';

const ConnectedWelcomePage = lazy(
  () => import('components/smart/pages/auth/ConnectedWelcomePage')
);
const ConnectedSignInPage = lazy(
  () => import('components/smart/pages/auth/ConnectedSignInPage')
);
const ConnectedSignUpPage = lazy(
  () => import('components/smart/pages/auth/ConnectedSignUpPage')
);
const ConnectedVerifyEmailPage = lazy(
  () => import('components/smart/pages/auth/ConnectedVerifyEmailPage')
);
const ConnectedEmailVerifiedPage = lazy(
  () => import('components/smart/pages/auth/ConnectedEmailVerifiedPage')
);

const ConnectForgotPasswordPage = lazy(
  () => import('components/smart/pages/auth/ConnectedForgotPasswordPage')
);

const ConnectedResetPasswordPage = lazy(
  () => import('components/smart/pages/auth/ConnectedResetPasswordPage')
);

const ConnectedConfirmResetPasswordPage = lazy(
  () => import('components/smart/pages/auth/ConnectedConfirmResetPasswordPage')
);

const WELCOME: Route = {
  component: ConnectedWelcomePage,
  path: PATH.AUTH.WELCOME,
  isProtected: false,
  redirectIfAuthenticated: true,
};

const SIGN_IN: Route = {
  component: ConnectedSignInPage,
  path: PATH.AUTH.SIGN_IN,
  isProtected: false,
  redirectIfAuthenticated: true,
};

const SIGN_UP: Route = {
  component: ConnectedSignUpPage,
  path: PATH.AUTH.SIGN_UP,
  isProtected: false,
  redirectIfAuthenticated: true,
};

const VERIFY_EMAIL: Route = {
  component: ConnectedVerifyEmailPage,
  path: PATH.AUTH.VERIFY_EMAIL,
  isProtected: false,
  redirectIfAuthenticated: true,
};

const EMAIL_VERIFIED: Route = {
  component: ConnectedEmailVerifiedPage,
  path: PATH.AUTH.EMAIL_VERIFIED,
  isProtected: false,
  redirectIfAuthenticated: false,
};

const PASSWORD_FORGOT: Route = {
  component: ConnectForgotPasswordPage,
  path: PATH.AUTH.FORGOT_PASSWORD,
  isProtected: false,
  redirectIfAuthenticated: true,
};

const PASSWORD_RESET: Route = {
  component: ConnectedResetPasswordPage,
  path: PATH.AUTH.RESET_PASSWORD,
  isProtected: false,
  redirectIfAuthenticated: false,
};

const CONFIRM_PASSWORD_RESET: Route = {
  component: ConnectedConfirmResetPasswordPage,
  path: PATH.AUTH.CONFIRM_RESET_PASSWORD,
  isProtected: false,
  redirectIfAuthenticated: false,
};

export default [
  WELCOME,
  SIGN_IN,
  SIGN_UP,
  VERIFY_EMAIL,
  EMAIL_VERIFIED,
  PASSWORD_FORGOT,
  PASSWORD_RESET,
  CONFIRM_PASSWORD_RESET,
];
