import AbstractClientStore from './AbstractClientStore';

const LOCAL_STORAGE_EXPERIENCE_VIEW_KEY = 'navigateToExperienceId';

class ExperienceViewClientStore extends AbstractClientStore {
  static getExperienceView(): string | null {
    const experienceView = this.storage.getItem(
      LOCAL_STORAGE_EXPERIENCE_VIEW_KEY
    );
    return experienceView;
  }

  static setExperienceView(experienceId: string): void {
    this.storage.setItem(LOCAL_STORAGE_EXPERIENCE_VIEW_KEY, experienceId);
  }

  static removeExperienceView(): void {
    this.storage.removeItem(LOCAL_STORAGE_EXPERIENCE_VIEW_KEY);
  }
}

export default ExperienceViewClientStore;
