import { JSX, SVGProps } from 'react';

export default function ObserverIcon(
  props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>
) {
  return (
    <svg
      width="24"
      height="17"
      viewBox="0 0 22 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.5923 8.08004C19.4757 3.12153 15.4942 0.0449219 11.1983 0.0449219C6.90236 0.0449219 2.92079 3.12153 0.804271 8.08004C0.746576 8.21343 0.716797 8.3574 0.716797 8.50294C0.716797 8.64848 0.746576 8.79245 0.804271 8.92584C2.92079 13.8843 6.90236 16.961 11.1983 16.961C15.4942 16.961 19.4757 13.8843 21.5923 8.92584C21.65 8.79245 21.6797 8.64848 21.6797 8.50294C21.6797 8.3574 21.65 8.21343 21.5923 8.08004ZM11.1983 14.8464C7.8768 14.8464 4.73345 12.4253 2.92079 8.50294C4.73345 4.58053 7.8768 2.15943 11.1983 2.15943C14.5197 2.15943 17.6631 4.58053 19.4757 8.50294C17.6631 12.4253 14.5197 14.8464 11.1983 14.8464ZM11.1983 4.27393C10.3693 4.27393 9.55903 4.52196 8.8698 4.98665C8.18057 5.45134 7.64338 6.11182 7.32617 6.88457C7.00895 7.65732 6.92595 8.50763 7.08767 9.32798C7.24938 10.1483 7.64855 10.9019 8.23469 11.4933C8.82083 12.0847 9.56762 12.4875 10.3806 12.6507C11.1936 12.8139 12.0363 12.7301 12.8021 12.41C13.568 12.0899 14.2225 11.5479 14.6831 10.8524C15.1436 10.157 15.3894 9.33936 15.3894 8.50294C15.3894 7.38133 14.9478 6.30567 14.1618 5.51258C13.3758 4.71948 12.3098 4.27393 11.1983 4.27393ZM11.1983 10.6174C10.7838 10.6174 10.3786 10.4934 10.034 10.2611C9.68942 10.0287 9.42082 9.6985 9.26222 9.31212C9.10361 8.92575 9.06211 8.50059 9.14297 8.09042C9.22382 7.68024 9.42341 7.30348 9.71648 7.00776C10.0095 6.71204 10.3829 6.51065 10.7894 6.42906C11.1959 6.34747 11.6173 6.38935 12.0002 6.54939C12.3831 6.70943 12.7104 6.98045 12.9407 7.32818C13.1709 7.67591 13.2938 8.08473 13.2938 8.50294C13.2938 9.06374 13.073 9.60157 12.6801 9.99812C12.2871 10.3947 11.754 10.6174 11.1983 10.6174Z"
        fill="black"
      />
    </svg>
  );
}
