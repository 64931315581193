export default {
  pin: {
    pinSent: ' Un cod PIN de verificare a fost trimis pe adresa ta de email',
    clickLink:
      'Accesează link-ul primit pe mail pentru a continua procesul de înregistrare',
    codeNotReceived: 'Nu ai primit codul?',
    resendCode: 'Click pentru a-l retrimite',
    getPinCodeHere: 'Obține codul PIN aici',
    enterPinCodeHere: 'Introdu codul PIN aici',
    exminder: {
      enterTheCode: 'Introdu aici codul prezent pe aplicația Explorerului',
    },
    explorer: {
      presentThisCode: 'Prezintă acest cod Exminderului',
    },
    weAreGlad: 'Mulțumim că duci povestea Exminds la nivelul următor',
    letTheExperienceBegin: 'Să înceapă Experiența!',
  },
};
