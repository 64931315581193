export default {
  notifications: {
    title: 'Notificări',
    news: 'Noutăți',
    messages: 'Mesaje',
    noNewsTitle: 'Nicio noutate pentru azi!',
    noNewsDescription:
      'Explorează universul Exminds și intră în legătură cu ceilalți din jur!',
    noMessagesTitle: 'Niciun mesaj pentru azi!',
    noMessagesDescription:
      'Explorează universul Exminds și intră în legătură cu ceilalți din jur!',
    experienceWillStart: 'Experiența ta va începe în',
    makeSureYouAreReady:
      'Asigură-te că ești pregătit să te bucuri de Experiența ta așteptată!\n\nEști pregătit?',
    experienceWillTakePlace:
      'Experiența ta va avea loc în curând! Asigură-te că ai totul pregătit.\n\nNu vrei să o ratezi, nu?',
    yourExperienceIsCloser:
      'Experiența ta este mai aproape decât crezi! Asigură-te că ești în apropiere de punctul de întâlnire și că nu vei întârzia!\n\nExminderul te așteaptă să te întâlnească!',
  },
};
