export default {
  moneyWithExminds: {
    title: 'Money with Exminds',
    description_first_1: 'As an Exminder, ',
    description_first_bold:
      'you can list as many Permanent and One Time Experience packages you want',
    description_first_2: ', each of them framing your skills.\n\n',
    description_second:
      "You can be sure that we won't collect any anticipated fees, but only know this: ",
    description_second_bold:
      'when your package is purchased, a 24% commission fee of its price is being redirected to Exminds.\n\n',
    description:
      'So, please make sure you set your price according to your needs.' +
      '\n\n' +
      'This ensures us that we can always continue to offer you a high-quality experience.' +
      '\n\n' +
      "Let's get the Experiences rolling!",
  },
};
