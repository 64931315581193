import { useTranslation } from 'react-i18next';
import Button from 'components/dumb/atomics/Button';
import ErrorBoundaryIcon from 'assets/icons/ErrorBoundaryIcon';
import theme from 'styles/theme/classic';
import * as SC from './styles';
import { backgroundContainerClass } from '../../../../helpers/constants/styling';

interface ErrourBoundaryCustomProps {
  resetErrorBoundary: any;
  title?: string;
  subtitle?: string;
}

export default function ErrorBoundaryCustom({
  resetErrorBoundary,
  title,
  subtitle,
}: ErrourBoundaryCustomProps) {
  const { t } = useTranslation();

  return (
    <SC.Container>
      <SC.Background>
        <SC.EllipseContainer>
          <SC.ClosedEllipseRotated />
          <SC.OpenEllipse />
          <SC.OpenEllipseSmall />
          <SC.ClosedEllipse />
        </SC.EllipseContainer>
        <SC.Wrapper className={backgroundContainerClass}>
          <SC.MainContent>
            <SC.IconContainer>
              <ErrorBoundaryIcon />
            </SC.IconContainer>
            <SC.BoldText>{title ?? t(`errorBoundary.title`)}</SC.BoldText>
            <SC.RegularText>
              {subtitle ?? t(`errorBoundary.subtitle`)}
            </SC.RegularText>
          </SC.MainContent>
          <SC.ButtonContainer>
            <Button
              color={theme.palette.button.main.yellow}
              onClick={resetErrorBoundary}
            >
              {t(`errorBoundary.recoveryButton`)}
            </Button>
          </SC.ButtonContainer>
        </SC.Wrapper>
      </SC.Background>
    </SC.Container>
  );
}
