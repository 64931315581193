export default {
  educationModal: {
    errors: {
      short: 'This field is too short',
      long: 'This field is too long',
      required: 'This field is required',
      futureDate: 'Cannot set a future date',
      afterStartDate: 'End date must be after start date',
    },
    title: 'ADD EDUCATION',
    titleEdit: 'EDIT EDUCATION',
    subtitle: '*Indicates required field',
    form: {
      school: 'School*',
      degree: 'Degree*',
      fieldOfStudy: 'Field of study*',
      startDate: 'Start Date*',
      endDate: 'End Date*',
      isCurrent: 'I’m currently studying here',
      description: 'Description*',
      grade: 'Grade*',
      country: 'Country*',
      location: 'City*',
    },
    placeholder: {
      school: 'Ex: University of Bucharest',
      degree: 'Ex: Bachelors',
      fieldOfStudy: 'Ex: Medical Engineering',
      startDate: 'Select month and year',
      endDate: 'Select month and year',
      description: 'Type here maximum 500 characters',
      grade: 'Ex: AAA',
      country: 'Ex: Germany',
      location: 'Ex: Berlin',
      present: 'Present',
    },
    button: {
      exit: 'Exit',
      done: 'Done',
    },
  },
};
