import { lazy } from 'react';
import PATH from './paths';
import { Route } from './types';

const ConnectedInvoicesPage = lazy(
  () => import('../components/smart/pages/invoices/ConnectedInvoicesPage')
);

const INVOICES: Route = {
  component: ConnectedInvoicesPage,
  path: PATH.INVOICES,
  isProtected: true,
};

export default [INVOICES];
