import styled from 'styled-components';

export const Container = styled.div<{
  width: string;
  height: string;
  disabled: boolean;
}>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};

  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

  border-radius: 50%;
  border: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  background: ${({ color }) => color};

  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export const IconContainer = styled.div<{
  disabled: boolean;
  rotateIcon?: number;
  width: string;
  height: string;
  color: string;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (hover: hover) {
    ${Container}:not([disabled]):hover & {
      opacity: 0.7;
    }
  }
  transform: ${({ rotateIcon }) =>
    rotateIcon ? `rotate(${rotateIcon}deg)` : 'none'};

  svg {
    width: ${({ width }) => width};
    height: ${({ height }) => height};
    path {
      fill: ${({ color }) => color};
    }
  }
`;
