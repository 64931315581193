export default {
  chat: {
    messageInputPlaceholder: 'Write a message...',
    standardMessageExplorerPin:
      "Remember! Only share your PIN when the Experience is taking place to avoid losing the purchase without being able to ask for refund if the Exminder isn't present at the location.",
    standardMessageExplorerMeet:
      'Make sure you meet the Exminder within 15 days from the purchase date. You can always ask for a refund 3 days post-meeting or 3 days after the 15 days countdown stops. If no refund is requested within 3 days post-meeting, the Experience is considered completed.',
    standardMessageExminderPin:
      "Remember! Only request the PIN when the Experience is taking place to avoid losing the purchase without a refund if the Explorer isn't present at the location.",
    standardMessageExminderMeet:
      'Make sure you complete the Experience within 15 days, or the Explorer can request a refund and leave possible negative feedback for not delivering your services. If no refund is requested within 3 days post-meeting, the Experience is considered completed.',
  },
};
