import {
  EXMINDS_WS_ENTRY_ROUTE,
  EXMINDS_WS_HOST,
  EXMINDS_WS_PORT,
  EXMINDS_WS_PROTOCOL,
} from '../helpers/constants/envVars';
import { SocketClient } from './socketClient';

export default class SocketConfig {
  static get wsProtocol(): string {
    return EXMINDS_WS_PROTOCOL;
  }

  static get wsHost(): string {
    return EXMINDS_WS_HOST;
  }

  static get wsPort(): string {
    return EXMINDS_WS_PORT;
  }

  static get wsEntryPoint(): string {
    return EXMINDS_WS_ENTRY_ROUTE;
  }

  static get wsUrl(): string {
    return `${this.wsProtocol}://${this.wsHost}${
      this.wsPort ? `:${this.wsPort}` : ''
    }${this.wsEntryPoint}`;
  }

  static createSocket(protocols?: string | string[]): SocketClient {
    return new SocketClient(this.wsUrl, protocols);
  }
}
