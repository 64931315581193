import Modal from 'components/dumb/atomics/Modals/Modal';
import { useTheme } from 'styled-components';
import FiltersForm, { ExperienceUrlFilters, Filters } from './FiltersForm';
import { Option } from '../../atomics/DropdownInput';

interface FiltersModalProps {
  isOpen: boolean;
  filters: ExperienceUrlFilters;
  requestIsLoading: boolean;
  countries: Option[] | [];
  cities: Option[] | [];
  languages: Option[] | [];
  handleSelectedCountry: CallbackFunction;
  handleSearchedCity: CallbackFunction;
  onApplyFilters: (data: Filters) => void;
  onClose: CallbackFunction;
  onClearFilters: CallbackFunction;
}

export default function FiltersModal({
  isOpen,
  filters,
  requestIsLoading,
  countries,
  cities,
  languages,
  handleSelectedCountry,
  handleSearchedCity,
  onApplyFilters,
  onClose,
  onClearFilters,
}: FiltersModalProps) {
  const theme = useTheme();
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      hasCloseIcon
      backgroundColor={`${theme.palette.main.cyan}1A`}
    >
      {isOpen && countries.length > 0 && languages.length > 0 && (
        <FiltersForm
          filters={filters}
          requestIsLoading={requestIsLoading}
          countries={countries}
          cities={cities}
          languages={languages}
          handleSelectedCountry={handleSelectedCountry}
          handleSearchedCity={handleSearchedCity}
          onApplyFilters={onApplyFilters}
          onClose={onClose}
          onClearFilters={onClearFilters}
        />
      )}
    </Modal>
  );
}
