export function formatNumberWithDigits(value: number, digits: number): string {
  if (!value) {
    return '';
  }
  const formattedValue = value.toFixed(digits);
  return parseFloat(formattedValue).toString();
}

export function parseNumberString(value: string): number | undefined {
  const parsedValue = parseFloat(value);
  return Number.isNaN(parsedValue) ? undefined : parsedValue;
}
