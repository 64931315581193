import React from 'react';
import { useTranslation } from 'react-i18next';
import * as SC from './styles';
import { SideBarSteps } from '../../../../../../helpers/constants/enums';
import AddNewIcon from '../../../../../../assets/icons/SideBarMenuIcons/AddNewIcon';
import ExminderIcon from '../../../../../../assets/icons/introductionIcons/ExminderIcon';

export type ExminderRequiredInformation = {
  hasWorkExperience: boolean;
  hasEducation: boolean;
  hasMilestones: boolean;
  hasAwards: boolean;
  isStripeRegistered: boolean;
};

interface Props {
  currentStep: number;
  shouldAnimate: boolean;
  exminderRequiredInformation: ExminderRequiredInformation;
}

export default function ExminderCompleteProfileStep({
  currentStep,
  shouldAnimate,
  exminderRequiredInformation,
}: Props) {
  const { t } = useTranslation();
  if (currentStep !== SideBarSteps.CompleteProfileStep) {
    return null;
  }

  return (
    <SC.Container>
      <SC.RoleIconContainer animate={shouldAnimate}>
        <ExminderIcon />
      </SC.RoleIconContainer>
      <SC.MainContent>
        <SC.BoldText>
          {t(`sidebarMenu.completeProfile.exminder.youAreAnExminder`)}
        </SC.BoldText>
        <SC.RegularText>
          {t(`sidebarMenu.completeProfile.fillIn`)}
        </SC.RegularText>
        <SC.CompleteProfileContent>
          <SC.RegularText>
            {t(`sidebarMenu.completeProfile.exminder.complete`)}
          </SC.RegularText>
          <SC.MissingInformationContent>
            {!exminderRequiredInformation.hasWorkExperience && (
              <SC.MissingInformationRow>
                <SC.IconContainer>
                  <AddNewIcon />
                </SC.IconContainer>
                <SC.LightText>
                  {t(`sidebarMenu.completeProfile.exminder.workExperience`)}
                </SC.LightText>
              </SC.MissingInformationRow>
            )}
            {!exminderRequiredInformation.hasEducation && (
              <SC.MissingInformationRow>
                <SC.IconContainer>
                  <AddNewIcon />
                </SC.IconContainer>
                <SC.LightText>
                  {t(`sidebarMenu.completeProfile.exminder.education`)}
                </SC.LightText>
              </SC.MissingInformationRow>
            )}
            {!exminderRequiredInformation.hasMilestones && (
              <SC.MissingInformationRow>
                <SC.IconContainer>
                  <AddNewIcon />
                </SC.IconContainer>
                <SC.LightText>
                  {t(`sidebarMenu.completeProfile.exminder.milestones`)}
                </SC.LightText>
              </SC.MissingInformationRow>
            )}
            {!exminderRequiredInformation.hasAwards && (
              <SC.MissingInformationRow>
                <SC.IconContainer>
                  <AddNewIcon />
                </SC.IconContainer>
                <SC.LightText>
                  {t(`sidebarMenu.completeProfile.exminder.awards`)}
                </SC.LightText>
              </SC.MissingInformationRow>
            )}
            {!exminderRequiredInformation.isStripeRegistered && (
              <SC.MissingInformationRow>
                <SC.IconContainer>
                  <AddNewIcon />
                </SC.IconContainer>
                <SC.LightText>
                  {t(`sidebarMenu.completeProfile.exminder.stripe`)}
                </SC.LightText>
              </SC.MissingInformationRow>
            )}
          </SC.MissingInformationContent>
        </SC.CompleteProfileContent>
      </SC.MainContent>
    </SC.Container>
  );
}
