import { JSX, SVGProps } from 'react';

export default function ExperienceCanceledIcon(
  props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>
) {
  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.2326 18.9998L31.2076 9.04064C31.5057 8.74249 31.6732 8.33812 31.6732 7.91647C31.6732 7.49483 31.5057 7.09045 31.2076 6.79231C30.9094 6.49416 30.505 6.32666 30.0834 6.32666C29.6618 6.32666 29.2574 6.49416 28.9592 6.79231L19.0001 16.7673L9.0409 6.79231C8.74275 6.49416 8.33838 6.32666 7.91673 6.32666C7.49509 6.32666 7.09071 6.49416 6.79257 6.79231C6.49442 7.09045 6.32692 7.49483 6.32692 7.91647C6.32692 8.33812 6.49442 8.74249 6.79257 9.04064L16.7676 18.9998L6.79257 28.959C6.64416 29.1062 6.52637 29.2813 6.44599 29.4742C6.3656 29.6672 6.32422 29.8741 6.32422 30.0831C6.32422 30.2922 6.3656 30.4991 6.44599 30.6921C6.52637 30.885 6.64416 31.0601 6.79257 31.2073C6.93976 31.3557 7.11488 31.4735 7.30782 31.5539C7.50076 31.6343 7.70771 31.6757 7.91673 31.6757C8.12575 31.6757 8.3327 31.6343 8.52565 31.5539C8.71859 31.4735 8.89371 31.3557 9.0409 31.2073L19.0001 21.2323L28.9592 31.2073C29.1064 31.3557 29.2815 31.4735 29.4745 31.5539C29.6674 31.6343 29.8744 31.6757 30.0834 31.6757C30.2924 31.6757 30.4994 31.6343 30.6923 31.5539C30.8853 31.4735 31.0604 31.3557 31.2076 31.2073C31.356 31.0601 31.4738 30.885 31.5541 30.6921C31.6345 30.4991 31.6759 30.2922 31.6759 30.0831C31.6759 29.8741 31.6345 29.6672 31.5541 29.4742C31.4738 29.2813 31.356 29.1062 31.2076 28.959L21.2326 18.9998Z"
        fill="#DE0F0F"
      />
    </svg>
  );
}
