import { lazy } from 'react';
import PATH from './paths';
import { Route } from './types';

const ConnectedTermsAndConditionsPage = lazy(
  () =>
    import(
      '../components/smart/pages/termsAndConditions/ConnectedTermsAndConditionsPage'
    )
);

const TERMS_AND_CONDITIONS: Route = {
  component: ConnectedTermsAndConditionsPage,
  path: PATH.TERMS_AND_CONDITIONS,
  isProtected: true,
};

export default [TERMS_AND_CONDITIONS];
