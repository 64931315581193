import { lazy } from 'react';
import PATH from './paths';
import { Route } from './types';

const ConnectedExperienceDateChangedLayout = lazy(
  () =>
    import(
      '../components/smart/pages/experienceDateUpdate/ConnectedExperienceDateChangeNotificationPage'
    )
);

const ConnectedExperienceDateChangedConfirmationLayout = lazy(
  () =>
    import(
      '../components/smart/pages/experienceDateUpdate/ConnectedExperienceDateChangeConfirmationPage'
    )
);

const ConnectedExperieceDateRejectedNotificationLayout = lazy(
  () =>
    import(
      '../components/smart/pages/experienceDateUpdate/ConnectedExperienceDateRejectedNotificationPage'
    )
);

const ConnectedExperieceDateAcceptedNotificationLayout = lazy(
  () =>
    import(
      '../components/smart/pages/experienceDateUpdate/ConnectedExperienceDateAcceptedNotificationPage'
    )
);
const EXPERIENCE_DATE_CHANGED: Route = {
  component: ConnectedExperienceDateChangedLayout,
  path: PATH.EXPERIENCE_DATE_CHANGED.DATE_CHANGED,
  isProtected: true,
};

const EXPERIENCE_DATE_CHANGE_CONFIRMATION: Route = {
  component: ConnectedExperienceDateChangedConfirmationLayout,
  path: PATH.EXPERIENCE_DATE_CHANGED.DATE_CHANGE_CONFIRMATION,
  isProtected: true,
};

const EXPERIENCE_DATE_CHANGE_REJECTED: Route = {
  component: ConnectedExperieceDateRejectedNotificationLayout,
  path: PATH.EXPERIENCE_DATE_CHANGED.DATE_CHANGE_REJECTED,
  isProtected: true,
};

const EXPERIENCE_DATE_CHANGE_ACCEPTED: Route = {
  component: ConnectedExperieceDateAcceptedNotificationLayout,
  path: PATH.EXPERIENCE_DATE_CHANGED.DATE_CHANGE_ACCEPTED,
  isProtected: true,
};

export default [
  EXPERIENCE_DATE_CHANGED,
  EXPERIENCE_DATE_CHANGE_CONFIRMATION,
  EXPERIENCE_DATE_CHANGE_REJECTED,
  EXPERIENCE_DATE_CHANGE_ACCEPTED,
];
