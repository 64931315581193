export default {
  appFeedback: {
    tellUsYourFeedback: 'Tell us your feedback!',
    continuouslyImproving:
      'Continuously improving the app is a priority, and we genuinely welcome and value your feedback, whether positive or negative.',
    typeInYourThoughts: 'Type in your thoughts on Exminds...',
    confirmation: {
      thankYou: 'Thank you for your feedback!',
      exmindsUniverse:
        'The Exminds Universe is evolving!' +
        '\n\n' +
        'Our team is currently analysing your thoughts. We will contact you using the email address associated with this account: ',
    },
  },
};
