import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const StyledContainer = styled(ToastContainer)<{
  isFlutterView: boolean;
}>`
  &&&.Toastify__toast-container {
    background: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    padding-top: ${({ isFlutterView }) => isFlutterView && '70px'};
  }

  .Toastify__toast--info {
    background: ${({ theme }) => `${theme.palette.toast.info}66`};

    svg {
      fill: ${({ theme }) => theme.palette.toast.info};
    }
  }

  .Toastify__toast--success {
    background: ${({ theme }) => `${theme.palette.toast.success}66`};

    svg {
      fill: ${({ theme }) => theme.palette.toast.success};
    }
  }

  .Toastify__toast--warning {
    background: ${({ theme }) => `${theme.palette.toast.warning}66`};

    svg {
      fill: ${({ theme }) => theme.palette.toast.warning};
    }
  }

  .Toastify__toast--error {
    background: ${({ theme }) => `${theme.palette.toast.error}66`};

    svg {
      fill: ${({ theme }) => theme.palette.toast.error};
    }
  }

  .Toastify__toast {
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    backdrop-filter: blur(10px);
    border-radius: 10px;
    @media only screen and (max-width: 480px) {
      border-radius: 0;
    }
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-weight: 500;
    font-style: normal;
    font-size: ${({ theme }) => theme.typography.pxToRem(-1)};
    color: ${({ theme }) => `${theme.palette.main.white}`};
  }

  .Toastify__close-button {
    opacity: 0.7;

    svg {
      fill: ${({ theme }) => theme.palette.main.white};
    }
  }

  .Toastify__close-button:hover,
  .Toastify__close-button:focus {
    opacity: 1;

    svg {
      fill: ${({ theme }) => theme.palette.main.white};
    }
  }

  .Toastify__progress-bar--info {
    background: ${({ theme }) => theme.palette.toast.info};
  }

  .Toastify__progress-bar--success {
    background: ${({ theme }) => theme.palette.toast.success};
  }

  .Toastify__progress-bar--warning {
    background: ${({ theme }) => theme.palette.toast.warning};
  }

  .Toastify__progress-bar--error {
    background: ${({ theme }) => theme.palette.toast.error};
  }
`;
