import { lazy } from 'react';
import PATH from './paths';
import { Route } from './types';

const ConnectedExplorerProfilePage = lazy(
  () =>
    import('../components/smart/pages/explorer/ConnectedExplorerProfilePage')
);

const ConnectedSetExplorerDomains = lazy(
  () =>
    import('../components/smart/pages/explorer/ConnectedSetExplorerDomainsPage')
);

const ConnectedSetExplorerHobbies = lazy(
  () =>
    import('../components/smart/pages/explorer/ConnectedSetExplorerHobbiesPage')
);

const ConnectedFavoritesExperiencePage = lazy(
  () => import('../components/smart/pages/explorer/ConnectedFavoritesPage')
);

const ConnectedExplorerMyExperiencesPage = lazy(
  () =>
    import('../components/smart/pages/explorer/ConnectedExplorerMyExperiences')
);

const EXPLORER_VIEW_ONE: Route = {
  component: ConnectedExplorerProfilePage,
  path: PATH.EXPLORER.VIEW_ONE,
  isProtected: true,
  hasMenuBar: true,
};

const EXPLORER_SET_DOMAINS: Route = {
  component: ConnectedSetExplorerDomains,
  path: PATH.EXPLORER.SET_DOMAINS,
  isProtected: true,
};

const EXPLORER_SET_HOBBIES: Route = {
  component: ConnectedSetExplorerHobbies,
  path: PATH.EXPLORER.SET_HOBBIES,
  isProtected: true,
};

const FAVORITE_EXPERIENCES: Route = {
  component: ConnectedFavoritesExperiencePage,
  path: PATH.EXPERIENCE.FAVORITES,
  isProtected: true,
  hasMenuBar: true,
};

const EXPLORER_EXPERIENCES: Route = {
  component: ConnectedExplorerMyExperiencesPage,
  path: PATH.EXPLORER.MY_EXPERIENCES,
  isProtected: true,
  hasMenuBar: true,
};

export default [
  EXPLORER_VIEW_ONE,
  EXPLORER_SET_DOMAINS,
  EXPLORER_SET_HOBBIES,
  FAVORITE_EXPERIENCES,
  EXPLORER_EXPERIENCES,
];
