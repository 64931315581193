export const EXMINDS_APP_ENVIRONMENT =
  process.env.REACT_APP_EXMINDS_APP_ENVIRONMENT || 'development';

export const IS_DEV_APP_ENVIRONMENT = EXMINDS_APP_ENVIRONMENT !== 'production';

export const IS_FLUTTER_ENVIRONMENT = window.flutter_inappwebview;

export const EXMINDS_API_PROTOCOL =
  process.env.REACT_APP_EXMINDS_API_PROTOCOL || 'http';

export const EXMINDS_API_HOST =
  process.env.REACT_APP_EXMINDS_API_HOST || 'localhost';

export const EXMINDS_API_PORT = process.env.REACT_APP_EXMINDS_API_PORT || '';

export const EXMINDS_API_ENTRY_ROUTE =
  process.env.REACT_APP_CAFM_API_ENTRY_ROUTE || '/api';

export const EXMINDS_WS_PROTOCOL =
  process.env.REACT_APP_EXMINDS_WS_PROTOCOL || 'ws';

export const EXMINDS_WS_HOST =
  process.env.REACT_APP_EXMINDS_WS_HOST || 'localhost';

export const EXMINDS_WS_PORT = process.env.REACT_APP_EXMINDS_WS_PORT || '';

export const EXMINDS_WS_ENTRY_ROUTE =
  process.env.REACT_APP_EXMINDS_WS_ENTRY_ROUTE || '/entrypoint';

export const I18N_DEBUG_MODE = process.env.REACT_APP_I18N_DEBUG_MODE === 'true';

export const AWS_S3_CDN_ENDPOINT =
  process.env.REACT_APP_AWS_S3_CDN_ENDPOINT || '';

export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN || '';
