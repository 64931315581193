export default {
  addExperience: {
    btnSettings: 'Setări',
    welcomeMessage: 'Bine ai venit, ',
    youHave: 'Ai',
    experience: 'Experiență activă',
    experiences: 'Experiențe active',
    filterAll: 'Toate',
    filterActive: 'Active',
    filterDraft: 'Ciorne',
    filterCompleted: 'Finalizate',
    filterCanceled: 'Anulate',
    filterExpired: 'Expirate',
    filterUpcoming: 'Din viitor',
    filterInProgress: 'În desfășurare',
    extraRewards:
      'Creează și activează mai multe Experiențe pentru și mai multe beneficii!',
    previewProfileBtn: 'Previzualizează Profil',
    chooseCategoryDropDown: 'Tipul Experiențelor',
    categoryAllExperiences: 'Toate Experiențele',
    categoryHandsOnExperience: 'Experiențe Tip Practică',
    categoryObserverExperience: 'Experiențe Tip Observator',
    createExperienceBtn: 'Creează o Experiență nouă',
  },
};
