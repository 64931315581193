import { ReactElement, KeyboardEvent } from 'react';
import * as SC from './styles';
import classic from '../../../../styles/theme/classic';

interface ButtonSize {
  width: string;
  height: string;
}
interface IconSize {
  width: string;
  height: string;
}

interface ButtonProps {
  className?: string;
  color: string;
  buttonSize?: ButtonSize;
  icon: ReactElement;
  onClick: CallbackFunction;
  disabled?: boolean;
  rotateIcon?: number;
  iconSize?: IconSize;
  iconColor?: string;
}

export default function IconButton({
  className = '',
  color,
  buttonSize = {
    width: `47px`,
    height: `47px`,
  },
  icon,
  onClick,
  disabled = false,
  rotateIcon,
  iconSize = {
    width: `20px`,
    height: `20px`,
  },
  iconColor = classic.palette.main.marine,
  ...props
}: ButtonProps) {
  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (
      (e.key === ' ' || e.key === 'Enter' || e.key === 'Spacebar') &&
      onClick &&
      !disabled
    ) {
      e.preventDefault();
      onClick();
    }
  };

  return (
    <SC.Container
      className={className}
      color={color}
      onClick={() => {
        if (onClick && !disabled) {
          onClick();
        }
      }}
      width={buttonSize.width}
      height={buttonSize.height}
      disabled={disabled}
      role="button"
      aria-pressed="false"
      tabIndex={0}
      {...props}
      onKeyDown={handleKeyDown}
    >
      <SC.IconContainer
        disabled={disabled}
        rotateIcon={rotateIcon}
        width={iconSize.width}
        height={iconSize.height}
        color={iconColor}
      >
        {icon}
      </SC.IconContainer>
    </SC.Container>
  );
}
