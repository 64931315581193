import {
  add,
  differenceInDays,
  differenceInYears,
  format,
  isBefore,
  isWithinInterval,
  parse,
  subDays,
  subMonths,
  subYears,
} from 'date-fns';
import { ExperienceDateType } from 'helpers/constants/enums';

export enum UnitType {
  DAYS = 'days',
  MONTHS = 'months',
  YEARS = 'years',
}

class DateUtils {
  static formatDate(date: Date, formatting = 'd MMM yyy'): string {
    if (!date) {
      return '';
    }
    return format(new Date(date), formatting);
  }

  static formatDateTime(date: Date): string {
    if (!date) {
      return '';
    }
    return format(new Date(date), 'd MMM yyy h:mm a');
  }

  static formatDateWithSubtraction(
    date: Date,
    unitsToSubtract: number,
    unitType: UnitType,
    formatting = 'yyyy-MM-dd'
  ) {
    let substractedDate;
    switch (unitType) {
      case UnitType.DAYS:
        substractedDate = subDays(date, unitsToSubtract);
        break;
      case UnitType.MONTHS:
        substractedDate = subMonths(date, unitsToSubtract);
        break;
      case UnitType.YEARS:
        substractedDate = subYears(date, unitsToSubtract);
        break;
      default:
        substractedDate = date;
    }
    return format(substractedDate, formatting);
  }

  static resetDateToTime(date: Date, time: string): Date {
    if (!time) {
      return date;
    }
    const [hour, minute] = time.split(':');
    date.setHours(Number(hour));
    date.setMinutes(Number(minute));
    date.setSeconds(0);
    return date;
  }

  static dayMonthYearFormattedDate(date: string): string {
    if (!date) {
      return '';
    }
    const newDate = parse(date, 'yyyy-MM-dd', new Date());
    return format(newDate, 'd MMM yyyy');
  }

  static monthYearFormattedDate(date: string): string {
    if (!date) {
      return '';
    }
    const newDate = new Date(date);
    return format(newDate, 'MMM yyyy');
  }

  static messageReceivedAt(receivedAt: string) {
    const date = new Date(receivedAt);
    const today = new Date();
    if (format(date, 'yyyy-MM-dd') === format(today, 'yyyy-MM-dd')) {
      return format(date, 'HH:mm');
    }

    const daysAgo = differenceInDays(today, date);
    if (daysAgo > 0 && daysAgo <= 7) {
      const weekday = format(date, 'EEE');
      return `${weekday}`;
    }
    return format(date, 'd MMM');
  }

  static isSameDay(startDate: Date, endDate: Date): boolean {
    if (!startDate || !endDate) {
      return false;
    }
    const startDateFormat = format(new Date(startDate), 'yyyy-MM-dd');
    const endDateFormat = format(new Date(endDate), 'yyyy-MM-dd');
    return startDateFormat === endDateFormat;
  }

  static extractTimeFromDate(date: Date): string {
    if (!date) {
      return '';
    }
    return format(new Date(date), 'h:mm aa');
  }

  static calculateAge = (date: string): number => {
    const dateOfBirth = new Date(date);
    const today = new Date();
    return differenceInYears(today, dateOfBirth);
  };

  static isDate18YearsAgo(date: string) {
    const currentDate = new Date();
    const eighteenYearsAgo = subYears(currentDate, 18);

    return isBefore(new Date(date), eighteenYearsAgo);
  }

  static decreaseTimeByOneHour(time: string) {
    const [hours, minutes] = time.split(':');
    const currentTime = new Date();
    currentTime.setHours(Number(hours), Number(minutes));

    currentTime.setHours(currentTime.getHours() - 1);

    const updatedHour = currentTime.getHours().toString().padStart(2, '0');
    const updatedMinute = currentTime.getMinutes().toString().padStart(2, '0');
    const updatedTime = `${updatedHour}:${updatedMinute}`;

    return updatedTime;
  }

  static areDatesDifferent = (initialDate: Date, newDate: Date): boolean =>
    initialDate.getTime() !== newDate.getTime();

  static isEligibleForRefund(
    packageType: ExperienceDateType,
    createdAt?: string,
    endTime?: Date
  ): boolean {
    const currentDate = new Date();

    if (packageType === ExperienceDateType.VARIABLE && createdAt) {
      const refundWindowEndDate = add(new Date(createdAt), {
        days: 18,
      });

      const refundWindowStartDate = add(new Date(createdAt), {
        days: 2,
      });

      const isWithinRefundWindow = isWithinInterval(currentDate, {
        start: refundWindowStartDate,
        end: refundWindowEndDate,
      });

      return isWithinRefundWindow;
    }
    if (packageType === ExperienceDateType.FIXED && endTime) {
      const refundWindowStartDate = add(new Date(endTime), {
        hours: 24,
      });

      const refundWindowEndDate = add(new Date(endTime), {
        days: 4,
      });

      const isWithinRefundWindow = isWithinInterval(currentDate, {
        start: refundWindowStartDate,
        end: refundWindowEndDate,
      });

      return isWithinRefundWindow;
    }
    return false;
  }
}

export default DateUtils;
