export default {
  faq: {
    1: {
      question: 'Cum funcționează Exminds?',
      answer:
        'Exminds creează un univers digital care conectează oamenii online pentru Experiențe offline. ' +
        'Aici timpul devine cea mai importantă formă de schimb uman. În primul rând, alege-ți luptătorul:' +
        '\n\n',
      answerExminderBold: '- Vreau să fiu un Exminder!\n',
      answerExminderItalic:
        'Te simți ca Yoda din Star Wars? Atunci predă calea Jediului și câștigă bani. Da, hrrrm.\n\n',
      answerExplorerBold: '- Vreau să fiu un Explorer!\n',
      answerExplorerItalic:
        'Da, exact ca Dora Exploratoarea, călătorești prin lume în căutarea răspunsurilor, ' +
        'chiar dacă înseamnă să-ți spargi pușculița.\n\n',
      answerEndOne: 'În al doilea rând, circuitul ',
      exmindsCircuit: 'Exminds',
      answerEndTwo:
        ' funcționează astfel: Exminderul creează pachetul de Experiență, ' +
        'Explorerul îl cumpără și... magia se întâmplă. Experiența are loc în lumea reală, ' +
        'în condițiile convenite, apoi se oferă feedback-ul final și aventura continuă.\n\n' +
        'Partea cea mai bună? Mai devreme sau mai târziu, toți Explorerii evoluează și descoperă ce pot oferi lumii, ' +
        'devenind în cele din urmă Exminderi.',
    },
    2: {
      question: 'Care este diferența între un Exminder și un Explorer?',
      answer:
        'Un Exminder este o persoană care, prin experiența sa de viață și profesională, poate oferi valoare altora. ' +
        'Exminderii sunt capabili să creeze pachete de Experiențe care pot fi cumpărate de Exploreri, ' +
        'deci acest lucru necesită deținerea unei companii sau a statutului de contribuabil activ în țara de rezidență.' +
        '\n\n' +
        'Un Explorer este persoana care evoluează trăind noi Experiențe, alături de oamenii pe care a visat să îi întâlnească. ' +
        'Ei pot accesa oportunități nelimitate cumpărând pachetele de Experiențe disponibile pe platforma noastră ' +
        'și nu necesită nicio formă de entitate legală pentru a face asta.' +
        '\n\n' +
        'Dacă crezi că ești pregătit pentru provocare, poți trăi ambele roluri - accesează pachete globale ' +
        'sau creează-le tu însuți. Alegerea îți aparține.',
    },
    3: {
      question: 'Care este primul pas după ce mi-am creat contul?',
      answer:
        'Dacă cineva cu fața ascunsă ți-ar vinde limonadă, ai cumpăra? Desigur că ne place să ne cunoaștem oamenii!' +
        '\n\n' +
        'Tocmai ai descoperit Universul Exminds și acum este momentul să lași lumea să te cunoască pe tine cel real. ' +
        'Următorul pas este să-ți completezi întregul profil și să câștigi încrederea comunității.' +
        '\n\n' +
        'Apoi, aventura ta începe și Experiențele sunt nelimitate!',
    },
    4: {
      question: 'Am nevoie de certificări pentru a folosi Exminds?',
      answer:
        'Nu trebuie să fii vrăjitor pentru a folosi Exminds, este vorba despre a trăi momente unice în viață.' +
        '\n\n' +
        'Ca Exminder, dacă Experiența necesită certificări specifice sau studii specializate, ' +
        'este responsabilitatea ta să îți demonstrezi pregătirea. ' +
        'Exminds nu efectuează verificări în acest sens.' +
        '\n\n' +
        'Ca Explorer, scufundă-te în lumea vastă și de neimaginat a experienței umane, ' +
        'unde poți învăța atât de mult de la fiecare persoană pe care o întâlnești. Desigur, ' +
        'ai nevoie doar de dorința de evoluție pentru a te bucura de acest lucru!',
    },
    5: {
      question: 'Ce include un Pachet de Experiență?',
      answer:
        'Fiecare pachet de Experiență este creat, organizat și personalizat de Exminder. ' +
        'Totul va fi descris în detaliu, astfel încât să poți înțelege care sunt beneficiile tale ca Explorer participativ.' +
        '\n\n' +
        'Serviciul și prețul său sunt oferite integral de Exminder, deci așteaptă-te la ce e mai bun, pregătește-te pentru autenticitate. ' +
        'Surprizele fac parte din proces și trăirea Experienței va include întotdeauna plata acesteia.',
    },
    6: {
      question: 'Cum funcționează un Pachet One-Time?',
      answer:
        'Pachetul One-Time este conceput pentru Exminderi cu un program încărcat sau evenimente speciale. Exminderul trebuie să furnizeze detalii extinse despre data, locația și durata Experienței pentru Explorer. Înainte de a face o achiziție, Explorerul ar trebui să revizuiască în detaliu toate detaliile Experienței pentru a minimiza situațiile de rambursare.',
    },
    7: {
      question: 'Cum funcționează un Pachet Permanent?',
      answer:
        'Pachetul Permanent rămâne pe profilul tău până când îl dezactivezi. Acest lucru le permite Explorerilor să-și exprime interesul pentru Experiențele tale făcând clic pe "Sunt interesat". În funcție de disponibilitatea ta, poți programa apoi întâlniri în consecință. Ai opțiunea de a accepta sau respinge cererile lor. Dacă sunt acceptate, aceștia pot proceda cu achiziția, inițiind un contor de 20 de zile.',
    },
    8: {
      question:
        'Care este diferența dintre o Experiență de tip Observator și una de tip Practică?',
      answer:
        'Experiența de tip Observator este atunci când un Explorer care are ocazia să urmeze un profesionist (Exminder), folosind strategic întrebări pentru a obține insight-uri, învățând prin urmarea exemplului.\n' +
        '\n' +
        'Experiența Practică de tip Observator merge dincolo de observație, permițând Explorerului să se implice activ în rolul Exminderului, participând complet la sarcini și discuții. Această implicare practică stimulează o înțelegere mai profundă, făcând ca Explorerul să devină o parte integrantă a Experienței.',
    },
    9: {
      question:
        'Trebuie să dețin o formă legală, cum ar fi o companie, un statut de lucrător independent etc.?',
      answer:
        'Exminderul are nevoie de o formă legală de înregistrare a banilor și de plata taxelor, deci da, ' +
        'trebuie să ai o companie funcțională, fie că îți asumi responsabilitatea pe cont propriu.' +
        '\n\n' +
        'Explorerul nu are nevoie de o companie, dar dacă dorești o factură specifică, ' +
        'trebuie să verificăm în prealabil ce implică, deci mai bine ne contactezi și ne vom ocupa noi de asta.',
    },
    10: {
      question: 'Cum îmi pot publica Experiența?',
      answer:
        'După finalizarea procesului de creare a Experienței, asigură-te că ai finalizat următoarele etape: Confirmă-ți numărul de telefon, înregistrează detalii financiare cu Stripe și setează Beneficii și Cerințe pentru fiecare pachet în secțiunea "Experiențele mele". Apoi, activează-ți Experiența și aceasta va fi trimisă pentru verificarea calității echipei noastre pentru a asigura că se aliniază cu Regulile Comunității Exminds înainte de a fi publicată. Dacă acest lucru nu se întâmplă, vei fi notificat prin aplicație și email.',
    },
    11: {
      question: 'Care sunt costurile utilizării aplicației?',
      answer:
        'Toți Explorerii sunt fericiți și liberi să exploreze și să cumpere toate Experiențele dorite fără costuri.' +
        '\n\n' +
        'Pentru Exminderi, poți lista orice pachete dorești, de orice fel. ' +
        'Poți fi sigur că nu există taxe anticipate, doar când pachetul tău este cumpărat, ' +
        'plătești o taxă de comision companiei Exminds.',
    },
    12: {
      question: 'Cum pot invita un prieten gratuit?',
      answer:
        'Este ușor-cât-ai-spune-Exminds: achiziționează pachetul tău de Experiență preferat și introdu adresa de email a prietenului tău în câmpul care va apărea pe ecran imediat după ce achiziția a fost făcută. Prietenul tău va primi apoi un link de invitație la adresa de email furnizată și poate crea un cont pentru a afla mai multe informații despre Exminder și Experiența la care va participa alături de tine.',
    },
    13: {
      question:
        'Care este perioada în care Explorerul și Exminderul se pot întâlni pentru un Pachet Permanent?',
      answer:
        'După ce plata este efectuată, ai 20 de zile să vă întâlniți unul pe celălalt. Exminderul are sarcina de a solicita codul PIN de la Explorer pentru a dovedi că întâlnirea a avut loc în viața reală.',
    },
    14: {
      question: 'Ca Exminder, când primesc banii pentru un Pachet One-Time?',
      answer:
        'Trei (3) zile după ce o Experiență se încheie DACĂ nimeni nu a raportat o problemă, Exminderul primește banii. ' +
        'Dacă apar situații neprevăzute, cum ar fi neprezentarea cuiva, ' +
        'nepotrivirea termenilor acordului, ' +
        'Echipa Centrală Exminds va analiza fiecare caz separat și îl va rezolva cât mai curând posibil.' +
        '\n\n' +
        'Poți raporta astfel de situații în orice moment prin email la ',
      experiencesMail: 'experiences@exminds.com',
      answerEnd: '. Fără griji, ești în mâini bune.',
    },
    15: {
      question:
        'Când primesc banii după ce un Pachet Permanent a fost finalizat?',
      answer:
        'Pentru Pachetele Permanente, codul PIN trebuie introdus în termen de 15 zile, ceea ce înseamnă că ai maxim 15 zile să întâlnești Explorerul, începând cu ziua în care Experiența ta a fost achiziționată.' +
        '\n\n' +
        'În caz contrar, Explorerul are o fereastră de 3 zile pentru a solicita rambursarea, după cele 15 zile. Dacă nu se inițiază nicio rambursare în acest interval de timp, Experiența este considerată completată prin acord implicit reciproc.',
    },
    16: {
      question:
        'Când primesc Exminderii transferul de bani de la Exminds în contul lor bancar?',
      answer:
        'În medie, fondurile sunt primite în termen de 7 zile de la data introducerii codului PIN, cu condiția să nu existe dispute.',
    },
    17: {
      question:
        'Trebuie să trimit facturi Explorerilor sau Exminds va face asta?',
      answer:
        'În scopuri de facturare, dacă ai un Cont de Companie (Persoană Juridică), vei avea opțiunea, în timpul creării contului, să alegi dacă dorești ca Exminds să trimită facturile în numele tău. Această opțiune poate fi setată și ulterior în secțiunea Detalii Financiare.',
    },
    18: {
      question: 'Ce urmează?',
      answer:
        'Vrei niște spoilere despre expansiunea Universului Exminds?' +
        '\n\n' +
        'Misiunea noastră importantă și viziunea globală se unesc pentru a remodela viitorul interacțiunii umane. ' +
        'Aducem noi afaceri și parteneriate, ' +
        'lucrăm la construirea de noi oportunități pentru comunități.' +
        '\n\n' +
        'Lucrăm la lumea care îți va schimba lumea. Ai încredere în proces și rămâi de partea Exminds!',
    },
    19: {
      question: 'Ai o întrebare care nu a fost răspunsă aici?',
      answerFirst:
        'Rezolvarea problemelor este numele nostru de mijloc. Dacă unicornul tău și-a pierdut cornul, ' +
        'sau un buton în aplicație nu mai funcționează, contactează-ne la ',
      supportMail: 'support@exminds.com',
      fullstop: '.',
      answerSecond:
        '\n\nEchipa lucrează zi și noapte pentru a asigura că Exminds este o călătorie extraordinară. Mulțumim că ești la bord!',
    },
  },
};
