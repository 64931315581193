export default {
  communityGuidelines: {
    title: 'Community Guidelines',
    description:
      'Our community thrives on mutual respect, trust, and a passion for learning and sharing in order to accelerate evolution. ' +
      'To ensure a positive experience for all members of the Exminds Universe, we have established the following Community Guidelines:',
    finalNotes: {
      first:
        'Failure to comply with these community guidelines may result in shadow banning your Experiences or profile. ' +
        'By participating in the Exminds Experiences, you agree to follow these guidelines and contribute to a flourishing ' +
        'and inspiring community environment that is focused on active learning and understands its challenges.',
      second:
        'Thank you for being a part of the Exminds community! ' +
        'Together, we can create meaningful connections between Exminders and Explorers around the world.',
    },
    respectAndDiversity: {
      title: 'Respect and Diversity',
      description:
        'Treat every member of the Exminds community with respect, regardless of their background, beliefs, or life experiences. ' +
        'Exminds is about embracing diversity and engaging in constructive dialogue that fosters understanding and inclusion. ' +
        'On top of that: analyze everything that happens during an Experience with critical thinking and remain open to new perspectives.',
    },
    safetyFirst: {
      title: 'Safety First',
      description:
        'Prioritize safety and well-being at all times. ' +
        'Exercise caution and use your best judgment when participating in Experiences, ' +
        'especially the ones taking place outdoors, in crowded places or having a risky subject to learn. ' +
        'Report any behaviour that makes you feel uncomfortable or unsafe to the Exminds Core team, immediately.',
    },
    clearCommunication: {
      title: 'Clear Communication',
      description:
        'Communicate openly, honestly, and respectfully with your fellow Exminders and Explorers. ' +
        'Provide clear expectations and guidelines for your Experiences, and actively listen to the needs and feedback of others. ' +
        'This should happen prior to, during and after the Experience took place.',
    },
    confidentialityAndPrivacy: {
      title: 'Confidentiality and Privacy',
      description:
        'Respect the confidentiality and privacy of all members. ' +
        'Do not share personal information or confidential details outside of the designated Experience interactions without explicit consent. ' +
        'Remember: human interaction comes with empathy and care for others.',
    },
    professionalism: {
      title: 'Professionalism',
      description:
        'Maintain a high standard of professionalism in all interactions and experiences. ' +
        'Uphold ethical conduct, integrity, and professionalism in your work, ' +
        'and refrain from engaging in any behaviour that may compromise the reputation of Exminds or its members.',
    },
    feedbackAndImprovement: {
      title: 'Feedback and Improvement',
      description:
        'Welcome feedback as an opportunity for growth and improvement. ' +
        'Provide constructive feedback to fellow Exminders and Explorers in a respectful manner, ' +
        'and be open to receiving feedback graciously. Our intention focuses on growing together!',
    },
    accountability: {
      title: 'Accountability',
      description:
        'Take responsibility for your actions and their impact on others. ' +
        'Adhere to the terms of service and community guidelines, ' +
        'and be accountable for upholding the values of Exminds in all interactions.',
    },
    fairExchange: {
      title: 'Fair Exchange',
      description:
        'Ensure that experiences are conducted with fairness and mutual benefit for both Exminders and Explorers. ' +
        'Respect the time, effort, and expertise of your fellow members, ' +
        'and strive to create meaningful and fulfilling experiences for all parties involved.',
    },
    zeroToleranceForHarassment: {
      title: 'Zero Tolerance for Harassment',
      description:
        'Exminds has zero tolerance for harassment, discrimination, or any form of harmful behaviour. Harassment, including but not limited to verbal, physical, or sexual harassment, will not be tolerated under any circumstances.',
    },
    complianceWithLawsAndRegulations: {
      title: 'Compliance with Laws and Regulations',
      description:
        'Adhere to all applicable laws, regulations, and policies governing your experiences and interactions within the Exminds community. ' +
        'Do not engage in any illegal or unethical activities that violate local, national, or international laws.',
    },
  },
};
