export default {
  domains: {
    pleaseLetUsKnowYourInterests: 'Time to share your interests!',
    pickOneOrMultipleDomains:
      'Select one or more domains that align most closely with the Experiences you plan to enjoy.',
    editDomains: 'Edit your domains',
    select:
      'Deselect the ones you want to remove and click on the ones you want to add',
    all: 'All',
    business: 'Business',
    lifestyle: 'Lifestyle',
    experts: 'Experts',
    technology: 'Technology',
    creativity: 'Creativity',
    traveling: 'Traveling',
    adventures: 'Adventures',
    parenting: 'Parenting',
    healthcare: 'Healthcare',
    mindfulness: 'Mindfulness',
    small_talk: 'Small Talk',
    extras: 'Extras',
  },
};
