export default {
  profile: {
    myProfile: 'My Profile',
    editProfile: 'Edit Profile',
    activeExperiences: 'Active Experiences',
    previousExperiences: 'Past Experiences',
    mostPopular: 'Most Popular',
    receivedReview: 'Received Review',
    memberSince: 'Member Since',
    addWork: 'Add Work',
    addEducation: 'Add Education',
    addDescription: 'Add Description',
    biography: 'Biography',
    domains: 'Domains',
    hobbies: 'Hobbies',
    nativeLanguage: 'Native Language',
    otherLanguages: 'Other Languages',
    languages: 'Languages',
    testimonials: 'Testimonials',
    milestones: 'Milestones',
    awards: 'Awards',
    videoTestimonials: 'Video Testimonials',
    editLanguages: 'Edit your languages',
    chooseUpToSevenLanguages: 'Choose up to 7 languages',
    workExperience: 'Work Experience',
    education: 'Education',
    jobRole: 'Job Role',
    location: 'Location',
    rating: 'Rating',
    tabs: {
      workEducation: 'Work &\nEducation',
      myUniverse: 'My\nUniverse',
      reviews: 'Testimonials',
    },
    noActiveExperience: {
      messageAuthUser: 'You don’t have any active Experiences yet',
      messageUser: 'This Exminder doesn’t have any active Experiences yet',
    },
    noReviews: {
      messageAuthUser: 'You don’t have any testimonials yet',
      messageUser: 'This Exminder didn’t receive any testimonials yet',
    },
    missingInformation: {
      messageAuthUser: 'You haven’t provided any information on this field yet',
      messageUser:
        'This Exminder did not provide any information on this field yet',
    },
    missingInformationCard: "There's no information on this field yet",
    editFromPersonalDetails: ' Edit this section from ”Personal Details”',
    mentorOf: 'Mentor of',
    people: 'people',
    wantedBy: 'Wanted by',
    makeARequest: 'Make a Request',
    seeExperiences: 'See Experiences',
    about: 'About',
    source: 'Source',
    noExmindsAccount: 'This person doesn’t currently have an Exminds account.',
    doYouWantToBringThisPerson: 'Do you want to bring this person to the app?',
    requestTheProfile: 'Request the profile below!',
    requestSent: 'Your request has been sent!',
    youAreThe: 'You are the',
    explorerInLine: 'Explorer in line!',
    exploreMore: 'Until then why wouldn’t you explore more?',
  },
};
