export default {
  paymentFlow: {
    requirements: 'Requirements',
    discountCode: 'Discount code',
    enterDiscountCodes: 'Enter here the discount codes you have',
    accept: 'I accept the',
    termsAndConditions: 'Terms and Conditions',
    andThe: 'and the',
    privacyPolicy: 'Privacy Policy',
    document: 'document',
    purchaseConfirmation: 'Purchase Confirmation',
    ifYouPressBuy:
      'You will be redirected to our provider to process the payment. Thank you!',
    paymentRejected: 'We’re sorry!\nYour payment was declined!',
    couldntProceed:
      'We couldn’t proceed with your Experience purchasing process.',
    insufficientFunds:
      'It looks like you have insufficient funds on your account.',
    badCard: 'It looks like there’s something happening with your card.',
    paymentProvider:
      'It looks like there’s something happening with our payment provider.',
    exmindsIsOnFire: 'Hip-Hip Hooray!\nExminds is on fire!',
    paymentAccepted: 'Your payment was accepted!',
    purchasingProceeded:
      'We proceeded with your Experience purchasing process.',
    invoiceSentOnEmail: 'We’ve sent you the invoice on your email address.',
    findExperience: 'You can find this Experience by accessing:',
    pathToExperience: 'Menu -> My Account - Explorer -> My Calendar',
    youCanNowTalkToExminder: 'You can now talk to the Exminder!',
    youCanNowTalkToExplorer: 'You can now talk to the Explorer!',
    theChatIsAllYours: 'The chat is all yours!\nSend a message to',
    selectADate: 'and select a date you are both available on.',
    theRestIsHistory: 'The rest is all history ready to be written!',
    enjoy: 'Enjoy!',
    paymentConflict: {
      title: 'Oh no!\nSomebody else bought this Experience!',
      description:
        'We couldn’t proceed with your Experience purchasing process.' +
        '\n\n' +
        'It looks like another Explorer bought the Experience before you finished the payment process.' +
        '\n\n' +
        'Contact us at ',
      supportMail: 'support@exminds.com',
      forTheRefund: ' for the refund initiation process.',
    },
  },
};
