import { lazy } from 'react';
import PATH from './paths';
import { Route } from './types';

const ConnectedAcceptRejectExperiencePage = lazy(
  () =>
    import(
      '../components/smart/pages/experienceJoinRequest/ConnectedAcceptRejectExperiencePage'
    )
);

const ConnectedExperienceAcceptancePage = lazy(
  () =>
    import(
      '../components/smart/pages/experienceJoinRequest/ConnectedExperienceAcceptancePage'
    )
);

const EXPERIENCE_JOIN_REQUEST_DECISION: Route = {
  component: ConnectedAcceptRejectExperiencePage,
  path: PATH.EXPERIENCE_JOIN_REQUEST.DECISION,
  isProtected: true,
};

const EXPERIENCE_JOIN_REQUEST_ACCEPTANCE: Route = {
  component: ConnectedExperienceAcceptancePage,
  path: PATH.EXPERIENCE_JOIN_REQUEST.ACCEPTANCE,
  isProtected: true,
};

export default [
  EXPERIENCE_JOIN_REQUEST_DECISION,
  EXPERIENCE_JOIN_REQUEST_ACCEPTANCE,
];
