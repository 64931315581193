import styled from 'styled-components';
import Button from '../../Button';
import { zIndex } from '../../../../../helpers/constants/zIndexValues';

export const Backdrop = styled.div<{ isOpen: boolean }>`
  position: fixed;
  z-index: ${zIndex.modal};
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: ${({ theme }) =>
    `linear-gradient(180deg, ${theme.palette.main.marine}40 0%, ${theme.palette.main.yellow}40 100%)`};
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  transition: opacity 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),
    visibility 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  -webkit-transition: opacity 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),
    visibility 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  will-change: opacity, visibility;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
`;

export const Container = styled.div<{ isOpen: boolean }>`
  width: 100%;
  max-height: 100%;
  max-width: 480px;
  min-width: 240px;
  background: ${({ theme }) => theme.palette.main.white};
  padding: 25px;
  border-radius: 10px;
  transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),
    opacity 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  -webkit-transition: -webkit-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),
    opacity 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transform: translate3d(0, ${({ isOpen }) => (isOpen ? '0' : '100vh')}, 0);
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  overflow: auto;
  will-change: transform, opacity;
`;

export const IconContainer = styled.div`
  svg {
    width: 63px;
    height: 38px;
  }
`;

export const Title = styled.div`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-style: normal;
  font-weight: 700;
  font-size: ${({ theme }) => theme.typography.pxToRem(2)};
  line-height: ${({ theme }) => theme.typography.pxToRem(8)};
  color: ${({ theme }) => theme.palette.main.darkGray};
  text-align: center;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: pre-wrap;
  word-break: break-word;
`;

export const Text = styled.div`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-style: normal;
  font-weight: 500;
  font-size: ${({ theme }) => theme.typography.pxToRem(0)};
  line-height: ${({ theme }) => theme.typography.pxToRem(8)};
  color: ${({ theme }) => theme.palette.main.darkGray};
  text-align: center;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: pre-wrap;
  word-break: break-word;
`;

export const List = styled.ul`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-style: normal;
  font-weight: 500;
  font-size: ${({ theme }) => theme.typography.pxToRem(0)};
  line-height: ${({ theme }) => theme.typography.pxToRem(4)};
  color: ${({ theme }) => theme.palette.main.darkGray};
  text-align: left;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: pre-wrap;
  word-break: break-word;
  margin: 0;
  padding-left: 17px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Item = styled.li``;

export const StyledButton = styled(Button)`
  width: 100%;
  min-width: 100px;
  max-width: 100%;
  &:hover {
    opacity: 0.9;
  }
  word-break: break-word;
`;

export const ButtonContainer = styled.div<{ buttonsWrapWidth: number }>`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 5px;
  @media only screen and (max-width: ${({ buttonsWrapWidth }) =>
      `${buttonsWrapWidth}px`}) {
    flex-direction: column-reverse;
  }
`;
