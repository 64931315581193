export default {
  menuBar: {
    mindFeed: 'Mind Feed',
    notifications: 'Notificări',
    universe: 'Univers',
    myExperiences: 'Experiențele Mele',
    favorites: 'Favorite',
    menu: 'Meniu',
  },
};
