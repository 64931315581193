export default {
  dispute: {
    tellUsWhatHappened: 'Povestește-ne mai multe!',
    itHasBeen: 'Au trecut',
    letUsKnow:
      ' de la finalizarea Experienței. Lasă-ne mai jos părerea ta în legătură cu desfășurarea lucrurilor și atmosfera întâlnirii.',
    thankYou: 'Mulțumim pentru mesaj!',
    analyseWords:
      'Vom analiza răspunsul tău și vom reveni cu noutăți cât mai repede posibil.',
    remember:
      'Dar ține minte!\n' +
      'Dacă există evenimente neplăcute care au loc în timpul Experienței contactează-ne la ',
    supportMail: 'support@exminds.com',
  },
};
