import styled from 'styled-components';
import SwitchAccountIcon from '../../../../assets/icons/buttonIcons/SwitchAccountIcon';
import { zIndex } from '../../../../helpers/constants/zIndexValues';

export const Container = styled.div<{
  width: number;
  height: number;
}>`
  position: relative;
  ${({ width }) => `width: ${width}px;`}
  ${({ height }) => `height: ${height}px;`}
    border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export const Background = styled.div<{ exminderView?: boolean }>`
  width: 100%;
  height: 100%;
  background: ${({ exminderView, theme }) =>
    exminderView
      ? `linear-gradient(
    156.59deg,
    ${theme.palette.main.turquoise} 12.66%,
    ${theme.palette.main.turquoise}C2 88.02%
  );`
      : `linear-gradient(
    156.59deg,
    ${theme.palette.main.yellow} 12.66%,
    ${theme.palette.main.yellow}C2 88.02%
  );`};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-touch-callout: none;
  backdrop-filter: blur(2px);
`;

export const AvatarImage = styled.img<{
  width: number;
  height: number;
  $isExpandable: boolean;
}>`
  position: absolute;
  ${({ width }) => `width: ${width - 12}px;`}
  ${({ height }) => `height: ${height - 12}px;`}
    background: linear-gradient(156.59deg,
    #eac300 12.66%,
    rgba(234, 195, 0, 0.76) 88.02%);
  filter: drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.45));
  border-radius: 50%;
  object-fit: cover;
  object-position: 50% 0;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
  cursor: ${({ $isExpandable }) => $isExpandable && 'pointer'};
  transition: ${({ $isExpandable }) =>
    $isExpandable && 'opacity 0.3s ease, filter 0.3s ease'};
`;

export const ExpandIconContainer = styled.div`
  position: absolute;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  transition: opacity 0.3s ease, filter 0.3s ease;

  svg {
    path {
      fill: ${({ theme }) => theme.palette.main.white};
    }
  }

  opacity: 0;
`;

export const AvatarImageWrapper = styled.div<{
  width: number;
  height: number;
  $isExpandable: boolean;
}>`
  position: relative;
  ${({ width }) => `width: ${width - 12}px;`}
  ${({ height }) => `height: ${height - 12}px;`}
    display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  ${({ $isExpandable }) =>
    $isExpandable &&
    `
    @media (hover: hover) {
      &:hover {
        ${AvatarImage} {
          opacity: 0.7;
          filter: drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.45)) blur(3px);
        }
        ${ExpandIconContainer} {
          opacity: 1;
        }
      }
    }
  `}
`;

export const LoadingBackground = styled.div<{
  width: number;
  height: number;
}>`
  position: absolute;
  ${({ width }) => `width: ${width - 12}px;`}
  ${({ height }) => `height: ${height - 12}px;`}
    background: linear-gradient(156.59deg,
    #eac300 12.66%,
    rgba(234, 195, 0, 0.76) 88.02%);
  filter: drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.45));
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LoadingSpinner = styled.div`
  border: ${({ theme }) => `2.5px solid ${theme.palette.main.white}`};
  border-top: 2.5px solid transparent;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const Input = styled.input`
  display: none;
`;

export const EditButton = styled.div`
  position: absolute;
  bottom: 5px;
  right: 5px;
  width: 38px;
  height: 38px;
  background: ${({ theme }) =>
    `linear-gradient(166deg, ${theme.palette.main.marine} 0%, ${theme.palette.main.turquoise} 100%)`};
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.25) inset;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 0;
  margin: 0;
  border: 0;

  &:hover {
    background: ${({ theme }) =>
      `linear-gradient(166deg, ${theme.palette.main.darkMarine} 0%, ${theme.palette.main.turquoise} 100%)`};
  }

  svg {
    width: 18px;
    height: 18px;
  }
`;

export const SwitchButton = styled.div<{ $isExminderView?: boolean }>`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 46px;
  height: 46px;
  background: ${({ theme, $isExminderView }) =>
    $isExminderView ? theme.palette.main.turquoise : theme.palette.main.yellow};
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 0;
  margin: 0;
  border: 0;
  transform: translate3d(0, 0, 0);

  &:hover {
    background: ${({ $isExminderView }) =>
      $isExminderView ? `rgb(0, 190, 199)` : `rgb(252, 210, 0)`};
  }
`;

export const StyledSwitchAccountIcon = styled(SwitchAccountIcon)<{
  $animate?: boolean;
}>`
  transition: transform 1s linear;
  -webkit-transition: transform 1s linear;
  transform: ${({ $animate }) =>
    $animate ? 'rotate(360deg)' : 'rotate(0deg)'};
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: ${({ theme }) => `${theme.palette.main.turquoise}40`};
  backdrop-filter: blur(10px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${zIndex.expanded};
  cursor: pointer;
  animation: fadeIn 0.3s ease;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

export const ExpandedAvatarImage = styled.img`
  width: 80vw;
  height: 80vw;
  max-width: 700px;
  max-height: 700px;
  border-radius: 50%;
  object-fit: cover;
  object-position: 50% 0;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
  animation: zoomIn 0.3s ease;

  @keyframes zoomIn {
    from {
      transform: scale(0.5);
    }
    to {
      transform: scale(1);
    }
  }
`;
