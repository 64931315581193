export default {
  acceptRejectExperience: {
    congratulations: 'Congratulations!',
    youWillEarn: 'You will earn',
    interestedInYourExperience: 'is interested in your Experience',
    purchasedYourExperience: 'requested to purchase your Experience',
    youReceived: 'You received',
    youEarned: "This month you've earned",
    ohNo: 'Oh no!',
    returnedYourExperience: 'returned your Experience',
    seeWhatWentWrong: 'See what went wrong\nStart chat',
  },
};
