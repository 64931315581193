import styled from 'styled-components';

export const StatusOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 29px;
  gap: 7px;
  z-index: 3;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.6) 0%,
    rgba(0, 0, 0, 0.6) 100%
  );
`;

export const OverlayStatusIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 38px;
    height: 38px;

    path {
      fill: ${({ theme }) => theme.palette.main.white};
    }
  }
`;

export const OverlayStatusText = styled.div`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-weight: 600;
  font-size: ${({ theme }) => theme.typography.pxToRem(-2)};
  line-height: ${({ theme }) => theme.typography.pxToRem(2)};
  color: ${({ theme }) => theme.palette.main.white};
  text-align: center;
  text-transform: uppercase;
  font-style: italic;
`;
