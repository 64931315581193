import React, { useState, useRef } from 'react';
import * as SC from './styles';
import SearchIcon from '../../../../assets/icons/buttonIcons/SearchIcon';
import CloseIcon from '../../../../assets/icons/buttonIcons/CloseIcon';

interface Props {
  placeholder: string;
  onSearch: (value: string) => void;
}

const SearchBar: React.FC<Props> = ({ placeholder, onSearch }) => {
  const [input, setInput] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);

  const focusInput = () => {
    inputRef.current?.focus();
  };

  const handleSearchTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInput(e.target.value);
    onSearch(e.target.value);
  };

  const clearInput = () => {
    setInput('');
    onSearch('');
    inputRef.current?.focus();
  };

  const handleIconClick = () => {
    if (input.length > 0) {
      clearInput();
    } else {
      onSearch(input);
    }
  };

  const renderIcon = () => {
    if (input.length > 0) {
      return <CloseIcon />;
    }
    return <SearchIcon />;
  };

  return (
    <SC.Container onClick={focusInput}>
      <SC.InsideBorder>
        <SC.InputText
          ref={inputRef}
          type="text"
          placeholder={placeholder}
          value={input}
          onChange={handleSearchTextChange}
        />
        <SC.IconContainer onClick={handleIconClick}>
          {renderIcon()}
        </SC.IconContainer>
      </SC.InsideBorder>
    </SC.Container>
  );
};

export default SearchBar;
