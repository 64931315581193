import { JSX, SVGProps } from 'react';

export default function SmallTalkIcon(
  props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>
) {
  return (
    <svg
      width="29"
      viewBox="0 0 33 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.625 23.625H18.125C20.2799 23.625 22.3465 22.769 23.8702 21.2452C25.394 19.7215 26.25 17.6549 26.25 15.5V13.875H27.875C29.1679 13.875 30.4079 13.3614 31.3221 12.4471C32.2364 11.5329 32.75 10.2929 32.75 9C32.75 7.70707 32.2364 6.46709 31.3221 5.55285C30.4079 4.63861 29.1679 4.125 27.875 4.125H26.25V2.5C26.25 2.06902 26.0788 1.6557 25.774 1.35095C25.4693 1.0462 25.056 0.875 24.625 0.875H5.125C4.69402 0.875 4.2807 1.0462 3.97595 1.35095C3.67121 1.6557 3.5 2.06902 3.5 2.5V15.5C3.5 17.6549 4.35602 19.7215 5.87976 21.2452C7.40349 22.769 9.47012 23.625 11.625 23.625ZM26.25 7.375H27.875C28.306 7.375 28.7193 7.54621 29.024 7.85095C29.3288 8.1557 29.5 8.56902 29.5 9C29.5 9.43098 29.3288 9.8443 29.024 10.149C28.7193 10.4538 28.306 10.625 27.875 10.625H26.25V7.375ZM6.75 4.125H23V15.5C23 16.7929 22.4864 18.0329 21.5721 18.9471C20.6579 19.8614 19.4179 20.375 18.125 20.375H11.625C10.3321 20.375 9.09209 19.8614 8.17785 18.9471C7.26361 18.0329 6.75 16.7929 6.75 15.5V4.125ZM31.125 26.875H1.875C1.44402 26.875 1.0307 27.0462 0.725951 27.351C0.421205 27.6557 0.25 28.069 0.25 28.5C0.25 28.931 0.421205 29.3443 0.725951 29.649C1.0307 29.9538 1.44402 30.125 1.875 30.125H31.125C31.556 30.125 31.9693 29.9538 32.274 29.649C32.5788 29.3443 32.75 28.931 32.75 28.5C32.75 28.069 32.5788 27.6557 32.274 27.351C31.9693 27.0462 31.556 26.875 31.125 26.875Z"
        fill="white"
      />
    </svg>
  );
}
