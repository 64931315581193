export default {
  domains: {
    pleaseLetUsKnowYourInterests:
      'Este timpul să ne împărtășești interesele tale!',
    pickOneOrMultipleDomains:
      'Selectează unul sau mai multe domenii care se aliniază cel mai bine cu Experiențele de care plănuiești să te bucuri.',
    editDomains: 'Editează domeniile',
    select: 'Selectează din listă domeniile finale',
    all: 'Toate',
    business: 'Afaceri',
    lifestyle: 'Lifestyle',
    experts: 'Profesioniști',
    technology: 'Tehnologie',
    creativity: 'Creativitate',
    traveling: 'Călătorii',
    adventures: 'Aventuri',
    parenting: 'Părinți',
    healthcare: 'Sănătate',
    mindfulness: 'Spiritualitate',
    small_talk: 'Discuții',
    extras: 'Extra',
  },
};
