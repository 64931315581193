export default {
  languages: {
    editLanguages: 'Edit your languages',
    romanian: '🇷🇴 Romanian',
    english: '🇬🇧 English',
    french: '🇫🇷 French',
    italian: '🇮🇹 Italian',
    spanish: '🇪🇸 Spanish',
    simplifiedChinese: '🇨🇳 Simplified Chinese',
    german: '🇩🇪 German',
    portuguese: '🇵🇹 Portuguese',
    hungarian: '🇭🇺 Hungarian',
  },
};
