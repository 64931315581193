export default {
  report: {
    whyAreYouReporting: 'De ce raportezi utilizatorul',
    letUsKnowMoreDetails:
      'Oferă-ne mai multe detalii despre situație. Ne va ajuta să o elucidăm mai rapid.',
    category: {
      nudity: 'Nuditate',
      violence: 'Violență',
      harassment: 'Hărțuire',
      suicide_or_self_injury: 'Auto-vătămare',
      false_information: 'Informații false',
      spam: 'Spam',
    },
    youHaveReported: 'Ai raportat utilizatorul',
    yourReportWasSent:
      'Raportarea ta a fost trimisă echipei noastre de Suport. Te vom notifica după ce analizăm solicitarea ta.',
    experience: {
      doubts: 'Ai îndoieli cu privire la Experiență?',
      reportExperience: 'Raportează Experiența',
      whyAreYouReporting: 'De ce raportezi această Experiență?',
      youHaveReported: 'Ai raportat această Experiență',
    },
  },
};
