export default {
  addProfilePicture: {
    addAProfilePicture: 'Adaugă o poză de profil',
    profilePictureIdealSize: 'Dimensiune ideală: cel puțin 200 x 200 pixeli',
    checkRecommendations: '⚠️ Verifică recomandările de mai jos',
    tryNotToHave: 'Încearcă să nu ai',
    blurry: 'poze neclare sau încețoșate',
    containOtherPeople: 'poze în care apar alte persoane',
    busyBackground: 'poze cu un fundal aglomerat',
    sunglasses: 'ochelari de soare',
    misleading: 'poze care îți blochează fața',
    closeOrFar: 'poze în care ești prea aproape sau prea departe de cameră',
    brightOrDark: 'poze care sunt prea luminoase sau prea întunecate',
  },
};
