export default {
  communityGuidelines: {
    title: 'Regulile Comunității',
    description:
      'Comunitatea Exminds prosperă pe baza respectului reciproc, încrederii și pasiunii pentru învățare, ' +
      'cu scopul de a accelera evoluția oamenilor. ' +
      'Pentru a asigura o experiență pozitivă pentru toți membrii Universului Exminds, am stabilit următoarele reguli:',
    finalNotes: {
      first:
        'Nerespectarea Regulilor Comunității poate rezulta în interzicerea Experiențelor sau a profilului tău. ' +
        'Participând la Experiențele Exminds, ești de acord să urmezi aceste reguli și să contribui la un mediu prosper și inspirant, ' +
        'concentrat pe învățarea activă și înțelegerea provocărilor.',
      second:
        'Îți mulțumim că faci parte din comunitatea Exminds! Împreună, vom creea conexiuni semnificative între Exminderi și Exploreri din întreaga lume.',
    },
    respectAndDiversity: {
      title: 'Respect și Diversitate',
      description:
        'Tratează fiecare membru al comunității Exminds cu respect, indiferent de background-ul său, credințele sau experiențele de viață. ' +
        'Exminds este despre înțelegerea diversității și angajarea în discuții constructive care să promoveze incluziunea. ' +
        'În plus, analizează tot ce se întâmplă în timpul unei Experiențe cu gândire critică și rămâi deschis către noi perspective.',
    },
    safetyFirst: {
      title: 'Siguranța este pe primul loc',
      description:
        'Prioritizează siguranța în orice moment. ' +
        'Fii precaut și folosește cea mai bună judecată atunci când participi la Experiențe, ' +
        'în special cele care au loc în aer liber, în locuri aglomerate sau care implică riscuri. ' +
        'Raportează pe loc orice comportament care te face să te simți incomod sau nesigur echipei Exminds.',
    },
    clearCommunication: {
      title: 'Comunicare clară',
      description:
        'Comunică deschis, sincer și cu respect față de Exminderi și Exploreri. ' +
        'Oferă așteptări și linii directoare clare pentru Experiențele tale și ascultă activ nevoile și feedback-ul celorlalți. ' +
        'Acest lucru ar trebui să se întâmple înainte, în timpul și după ce Experiența a avut loc.',
    },
    confidentialityAndPrivacy: {
      title: 'Confidențialitate și Intimitate',
      description:
        'Respectă confidențialitatea și intimitatea tuturor membrilor. ' +
        'Nu distribui informații personale sau detalii confidențiale în afara interacțiunilor desemnate Experiențelor fără consimțământ din partea tuturor părților. ' +
        'Ține minte: interacțiunea umană vine cu empatie și grijă față de ceilalți.',
    },
    professionalism: {
      title: 'Profesionalism',
      description:
        'Menține un standard ridicat de profesionalism în toate interacțiunile și experiențele. ' +
        'Respectă conduita, integritatea și profesionalismul în munca ta și nu promova ' +
        'orice comportament care ar putea compromite reputația Exminds sau a membrilor săi.',
    },
    feedbackAndImprovement: {
      title: 'Feedback și Îmbunătățire',
      description:
        'Primește feedback-ul ca o oportunitate pentru creștere. ' +
        'Oferă feedback constructiv Exminderilor și Explorerilor într-un mod respectuos și fii deschis să îl primești la rândul tău. ' +
        'Intenția noastră se concentrează pe creșterea împreună!',
    },
    accountability: {
      title: 'Responsabilitate',
      description:
        'Asumă-ți responsabilitatea pentru acțiunile tale și impactul lor asupra altora. ' +
        'Respectă Termenii & Condițiile și Regulile Comunității și susține valorile Exminds în toate interacțiunile.',
    },
    fairExchange: {
      title: 'Schimb echitabil',
      description:
        'Asigură-te că experiențele sunt realizate ca un beneficiu reciproc atât pentru Exminders, cât și pentru Exploratori. ' +
        'Respectă timpul, efortul și expertiza colegilor tăi și creează experiențe semnificative pentru toate părțile implicate.',
    },
    zeroToleranceForHarassment: {
      title: 'Toleranță zero',
      description:
        'Exminds are toleranță zero față de hărțuire, discriminare sau orice formă de comportament dăunător. ' +
        'Hărțuirea de orice fel nu va fi tolerată în niciun caz și se va penaliza ca atare.',
    },
    complianceWithLawsAndRegulations: {
      title: 'Respectă legile și reglementările',
      description:
        'Respectă toate legile, reglementările și politicile aplicabile care guvernează Experiențele și interacțiunile tale în cadrul comunității Exminds. ' +
        'Nu te implici în activități ilegale sau etice care încalcă legile locale, naționale sau internaționale.',
    },
  },
};
