import { lazy } from 'react';
import PATH from './paths';
import { Route } from './types';

const StripeRegisterLayout = lazy(
  () =>
    import('../components/smart/pages/stripe/ConnectedStripeRegistrationPage')
);

const StripeRegisterAgreement = lazy(
  () => import('../components/smart/pages/stripe/ConnectedStripeAgreementPage')
);
const StripeRegisterForm = lazy(
  () => import('../components/smart/pages/stripe/ConnectedStripeFormPage')
);
const StripeRegisterBusinessForm = lazy(
  () =>
    import('../components/smart/pages/stripe/ConnectedStripeBusinessFormPage')
);
const StripeRegisterSuccess = lazy(
  () => import('../components/smart/pages/stripe/ConnectedSuccessPage')
);

const STRIPE_REGISTER_INDEX: Route = {
  component: StripeRegisterLayout,
  path: PATH.STRIPE_REGISTER.INDEX,
  isProtected: true,
};
const STRIPE_REGISTER_AGREEMENT: Route = {
  component: StripeRegisterAgreement,
  path: PATH.STRIPE_REGISTER.AGREEMENT,
  isProtected: true,
};
const STRIPE_REGISTER_FORM: Route = {
  component: StripeRegisterForm,
  path: PATH.STRIPE_REGISTER.FORM,
  isProtected: true,
};
const STRIPE_REGISTER_BUSINESS_FORM: Route = {
  component: StripeRegisterBusinessForm,
  path: PATH.STRIPE_REGISTER.BUSINESS_FORM,
  isProtected: true,
};
const STRIPE_REGISTER_SUCCESS: Route = {
  component: StripeRegisterSuccess,
  path: PATH.STRIPE_REGISTER.SUCCESS,
  isProtected: true,
};
const STRIPE_REGISTER_SUCCESS_CONFIRM_INDIVIDUAL_ACCOUNT: Route = {
  component: StripeRegisterSuccess,
  path: PATH.STRIPE_REGISTER.CONFIRM_INDIVIDUAL_ACCOUNT,
  isProtected: true,
};
const STRIPE_REGISTER_SUCCESS_CONFIRM_BUSINESS_ACCOUNT: Route = {
  component: StripeRegisterSuccess,
  path: PATH.STRIPE_REGISTER.CONFIRM_BUSINESS_ACCOUNT,
  isProtected: true,
};
export default [
  STRIPE_REGISTER_INDEX,
  STRIPE_REGISTER_AGREEMENT,
  STRIPE_REGISTER_FORM,
  STRIPE_REGISTER_BUSINESS_FORM,
  STRIPE_REGISTER_SUCCESS,
  STRIPE_REGISTER_SUCCESS_CONFIRM_INDIVIDUAL_ACCOUNT,
  STRIPE_REGISTER_SUCCESS_CONFIRM_BUSINESS_ACCOUNT,
];
