import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  padding: 0 40px;
`;

export const BoldText = styled.div`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-weight: 700;
  font-size: ${({ theme }) => theme.typography.pxToRem(4)};
  color: ${({ theme }) => theme.palette.main.black};
  line-height: 24px;
  text-align: center;
`;

export const RegularText = styled.div`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-weight: 600;
  font-size: ${({ theme }) => theme.typography.pxToRem(0)};
  color: ${({ theme }) => theme.palette.main.darkGray};
  line-height: 22px;
  text-align: center;
`;
