export default {
  aboutTheApp: {
    aboutTheAppIntro: {
      title: 'Bine ai venit în noua dimensiune a petrecerii timpului!',
      subtitle:
        'Conectează-te cu persoanele care îți împărtășesc interesele și evoluează prin partajarea unor Experiențe incredibile cu acestea.',
    },
    aboutTheAppFirst: {
      title:
        'Exminds este o platformă reală de partajare a Experiențelor, menită să ajute oamenii să-și valorifice timpul.',
      subtitle:
        'Aici poți transforma Experiența ta de viață în lecții pentru alții, sau poți câștiga cunoștințe valoroase de la persoanele pe care le admiri.\n\nConectează-te online. Trăiește Experiența offline.',
    },
    aboutTheAppSecond: {
      title: 'EXMINDER',
      subtitle:
        'Este momentul potrivit pentru a împărtăși înțelepciunea ta cu ceilalți? \n\nCreează pachete de Experiență care ar putea schimba viața altor persoane, împărtășește-ți abilitățile în timp ce conduci calea și transformă-ți timpul în valoare.',
    },
    aboutTheAppThird: {
      title: 'EXPLORER',
      subtitle:
        'Cât de cool ar fi să înveți orice dorești de la oricine de pe planetă? \n\nDă mâna cu persoanele pe care ai dorit mereu să le întâlnești, trăiește Experiențe transformatoare și construiește-ți propria realitate.',
    },
    aboutTheAppFourth: {
      title: 'POȚI SĂ TE UPGRADEZI',
      subtitle:
        'De la un Explorer începător la un Exminder maestru, te poți upgrada ori de câte ori ești gata să împărtășești cunoștințele tale cu lumea. \n\nExminds este totul despre evoluție. Momentul să crești este acum.',
    },
    aboutTheAppFifth: {
      title: 'Cei care Experimentează împreună, rămân împreună',
      subtitle:
        'Revoluția Exminds funcționează astfel: Exminderul creează pachetul de Experiență, Explorerul îl alege și... magia se întâmplă. \n\nE timpul pentru o Experiență reală de viață!',
    },
    aboutTheAppSixth: {
      title: 'Restul este...Realitate!',
      subtitle:
        'Integrează practica în viața ta de zi cu zi și bucură-te de călătoria ta în Universul Exminds!\n\nÎmbrățișează măreția care te așteaptă!',
    },
    aboutTheAppSeventh: {
      title: 'ABONAMENTE LUNARE',
      subtitle:
        'Te poți abona pentru o încercare de o lună, dar garantăm că nu vei dori să părăsești acest loc după aceea.',
    },
  },
};
