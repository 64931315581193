import { lazy } from 'react';
import PATH from './paths';
import { Route } from './types';

const ConnectedMoneyWithExmindsPage = lazy(
  () =>
    import(
      '../components/smart/pages/moneyWithExminds/ConnectedMoneyWithExmindsPage'
    )
);

const MONEY_WITH_EXMINDS: Route = {
  component: ConnectedMoneyWithExmindsPage,
  path: PATH.MONEY_WITH_EXMINDS,
  isProtected: true,
};

export default [MONEY_WITH_EXMINDS];
