export function capitalize(str: string): string {
  if (!str) return '';
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function removeUnderscoresAndCapitalize(str: string): string {
  return str
    .replace(/_/g, ' ')
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

export function getElementFromString(
  value: string,
  separator: string,
  position: number
): string {
  const elements = value.split(separator);
  if (position < 0 || position >= elements.length) {
    return '';
  }
  return elements[position] ?? '';
}

export function extractFirstElementBetweenQuotes(value: string) {
  const regex = /"([^"]*)"/;
  const match = value.match(regex);
  if (match) {
    return match[1];
  }
  return '';
}

export function formatFullName(name: string) {
  const nameParts = name.split(' ');
  if (nameParts.length < 2) return name;

  const lastName = nameParts[nameParts.length - 1];
  if (!lastName) return name;

  const lastNameInitial = `${lastName.charAt(0)}.`;
  const firstNameParts = nameParts.slice(0, -1).join(' ');

  return `${firstNameParts} ${lastNameInitial}`;
}
