export default {
  sidebarMenu: {
    myProfile: 'Profilul Meu',
    guestProfile: 'Profil Vizitator',
    continueOnboarding: 'Continuă Onboarding-ul',
    accountSettings: 'Setări Cont',
    myCalendar: 'Calendarul Meu',
    statistics: 'Statistici',
    favorites: 'Favorite',
    aboutTheApp: 'Despre Aplicație',
    moneyWithExminds: 'Bani prin Exminds',
    exmindsPartners: 'Parteneri Exminds',
    faq: 'Întrebări Frecvente',
    communityGuidelines: 'Regulile Comunității',
    invoices: 'Facturi',
    feedback: 'Trimite-ne feedback-ul tău!',
    termsAndConditions: 'Termeni și Condiții',
    privacyPolicy: 'Politica de Confidențialitate',
    changeRole: {
      areYouSure: 'Ești sigur că vrei să îți schimbi rolul?',
      beCautious:
        'Fii precaut, acest lucru îți poate schimba viața literalmente!',
    },
    completeProfile: {
      exminder: {
        youAreAnExminder: 'Acum ești un Exminder',
        complete: 'Completează-ți Profilul de Exminder',
        workExperience: 'Experiență de Muncă',
        education: 'Educație',
        milestones: 'Repere',
        awards: 'Premii',
        stripe: 'Cont Stripe',
      },
      explorer: {
        youAreAnExplorer: 'Acum ești un Explorer',
        complete: 'Completează-ți Profilul de Explorer',
        biography: 'Biografie',
        education: 'Educație',
        jobRole: 'Rol Profesional',
        otherInformation:
          'Toate celelalte informații au fost furnizate din contul tău de Exminder',
      },
      fillIn: 'Încă mai ai câteva lucruri de completat:',
      button: 'Completează Profilul',
    },
  },
};
