export default {
  experienceCreationLayout: {
    leaveTheProcess: 'Ești sigur că vrei să părăsești pagina?',
    dataWillBeLost: 'Progresul tău actual va fi pierdut.',
    dateType: {
      chooseTheDateType: 'Alege tipul Experienței',
      oneTime: 'Unică',
      permanent: 'Permanentă',
      oneTimeDescription:
        'Experiența este programată și are loc într-o dată programată',
      permanentDescription:
        'Experiența rămâne accesibilă până când este dezactivată manual',
    },
    draft: {
      title: 'Ai o Experiență nefinalizată',
      description:
        'Dorești să continui completarea Experienței sau să creezi o Experiență nouă?',
      startNew: 'Experiență Nouă',
      continueWithDraft: 'Continuă Editarea',
    },
    step1: {
      welcomeMessageFirstPart: 'Bună',
      welcomeMessageSecondPart:
        '! Ne bucurăm că ai decis să întâlnești mai mulți Exploreri și să câștigi bani din abilitățile tale.',
      newExperienceMessage: 'Să adăugăm o nouă Experiență!',
      boxesMessage: 'Vor fi 11 casete de completat',
      nameMessage: 'Cum se va numi Experiența?',
    },
    locationStep: {
      title: 'Punct de Întâlnire',
      message: 'Stabilește un punct unde tu și Explorerul vă veți întâlni.',
    },
    step3: {
      title: 'Limba Experienței',
      message: 'Ce limbă va fi vorbită în timpul desfășurării Experienței?',
    },
    step4: {
      title: 'Data de Început a Experienței',
      message: 'În ce zi va începe Experiența?',
    },
    step5: {
      title: 'Data de Finalizare a Experienței',
      message: 'În ce zi se va încheia Experiența?',
    },
    step6: {
      title: 'Ora de Începere',
      message: 'La ce oră va începe Experiența?',
      checkbox: 'Experiența începe și se termină în aceeași zi.',
    },
    step7: {
      title: 'Ora de Finalizare',
      message: 'La ce oră se va termina Experiența?',
      error:
        'Dacă data de început este aceeași cu data de finalizare, ora de finalizare trebuie să fie mai târziu decât ora de început',
    },
    step8: {
      title: 'Numărul de Exploreri',
      message: 'Câți oameni pot cumpăra Experiența?',
    },
    step9: {
      title: 'Video de Prezentare',
      message: 'Încarcă videoclipul tău de prezentare',
      errorMessage: ' Browserul tău nu suportă tagul video.',
      videoUpload:
        'Încărcarea poate dura câteva momente, în funcție de lungimea videoclipului și marimea fotografiei de prezentare.',
      closeApp:
        'Vă rugăm să mențineți aplicația Exminds deschisă în timp ce Experiența este creată.',
    },
    step10: {
      title: 'Descrierea Experienței',
      message:
        'Spune-ne mai multe informații. Fii cât mai specific, deoarece Explorerii vor descoperi și mai multe detalii despre tine și vor reuși să se conecteze mai ușor la întreaga Experiență.',
    },
    step11: {
      title: 'Declinarea de Responsabilitate Exminder',
      message:
        'Care sunt cerințele Experienței? Aici poți scrie toate detaliile pe care vrei ca Explorerul să le cunoască.',
    },
    step12: {
      title: 'Preț',
      message: 'Cât va costa Experiența?',
    },
    step13: {
      title: 'Monedă',
      message: 'Ce monedă vei folosi?',
    },
    step14: {
      title: 'Costuri suplimentare',
      message: 'Există costuri suplimentare?',
      costs:
        'Care sunt costurile suplimentare?\nScrie mai multe detalii mai jos.',
      noAdditionalCosts: 'Fără costuri suplimentare',
    },
    step15: {
      title: 'Discounturi',
      message: 'Vrei să adaugi discounturi?',
      discounts:
        'Scrie aici procentajul și noi vom calcula noul preț pentru tine.\n' +
        '\n' +
        'Nu-ți face griji, poți să-l dezactivezi mai târziu.',
      actualPrice: 'Preț actual',
      newPrice: 'Preț nou',
      discountPercentage: 'Procentaj reducere',
    },
    step16: {
      title: 'Data limită de achiziție',
      message:
        'Care este data limită până la care Explorerii pot cumpăra Experiența?',
    },
    step17: {
      title: 'Contact',
      message: 'Pot să te contacteze prin mesaje sau mobil?',
    },
    step18: {
      title: 'Alege tipul Experienței',
      message: 'Alege opțiunea care descrie cel mai bine Experiența',
      observerDescription:
        'Explorerul observă activitatea desfășurată și nu participă direct',
      handsOnDescription: 'Explorerul se implică în activitate',
      handsOn: 'Experiență tip\nPractică',
      observer: 'Experiență tip\nObservator',
    },
    step19: {
      title: 'Domeniul Experienței',
      message: 'Ce domeniu descrie cel mai bine Experiența?',
    },
    step20: {
      title: 'Imagine de prezentare',
      message: 'Încarcă imaginea de prezentare',
    },
  },
};
