import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import en from './en';
import ro from './ro';
import { getElementFromString } from '../helpers/functions/strings';

const detectionOptions = {
  order: ['localStorage', 'navigator'],
  lookupLocalStorage: 'i18nextLng',
  caches: ['localStorage'],
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(
    {
      resources: {
        en,
        ro,
      },
      fallbackLng: 'en',
      debug: false,

      ns: ['translations'],
      defaultNS: 'translations',

      keySeparator: '.',

      detection: detectionOptions,
      load: 'languageOnly',

      interpolation: {
        escapeValue: false,
      },
    },
    () => {
      const currentLanguage = i18n.language;
      i18n.changeLanguage(getElementFromString(currentLanguage, '-', 0));
    }
  );

export default i18n;
