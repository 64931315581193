import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  border: 1px solid;
  border-right: 0;
  border-top: 0;
  border-color: ${({ theme }) => theme.palette.main.marine};

  &:first-child {
    border-left: 0;
  }

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export const Content = styled.div<{ isSelected: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 15px;
  color: ${({ theme }) => theme.palette.main.marine};
  ${({ isSelected }) => (isSelected ? `font-weight:700;` : `font-weight:400;`)}
`;

export const SelectionLineContainer = styled.div`
  width: 100%;
  height: 5px;
`;

export const SelectionLine = styled.div`
  width: 100%;
  height: 5px;
  background: ${({ theme }) => theme.palette.main.marine};
`;
