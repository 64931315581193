export default {
  currency: {
    currency: 'Currency',
    chooseCurrency: 'Choose currency',
    noCurrencyFound: 'No currencies found',
    euro: 'EURO',
    usd: 'USD',
    ron: 'RON',
    cny: 'CNY',
  },
};
