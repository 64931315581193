export default {
  auth: {
    signUp: 'Sign Up',
    signIn: 'Sign In',
    logIn: 'Log In',
    logOut: 'Log Out',
    continueWithGoogle: 'Continue with Google',
    continueWithApple: 'Continue with Apple',
    verifyEmail: 'Please verify your email',
    emailVerified: 'Your email has been verified',
    emailVerifiedDescription:
      'Now that you are verified, let’s go back to creating your account and get closer to becoming an Exmind!',
    checkSpamFolder:
      'You’re almost there!\nAn email has been sent to your address.\nIf you don’t see it, you may need to check you spam folder.',
    resendEmail: 'Resend',
    returnToProcess: 'Return',
    createAccount: 'Create Account',
    enterCredentials: 'Enter your credentials to continue',
    enterEmailAndPassword: 'Enter your email and password',
    email: 'Email',
    password: 'Password',
    reEnterPassword: 'Re-enter password',
    byContinuing: 'I agree with the',
    termsOfService: 'Terms of Service',
    and: 'and',
    privacyPolicy: 'Privacy Policy',
    alreadyHaveAccount: 'Already have an account?',
    dontHaveAccount: "Don't have an account?",
    forgotPassword: 'Forgot Password?',
    forgotPasswordTitle: 'Forgot Password',
    resetPassword: 'Reset Password',
    enterYourEmail: 'Enter your email address associated with your account',
    enterAndConfirmNewPassword: 'Enter and confirm a new password',
    newPassword: 'New Password',
    repeatPassword: 'Repeat Password',
    passwordIsFresh: 'Your password is fresh',
    returnToLogIn: 'Now you can return to your log in process!',
    leavingTheExmindsWorld: 'Leaving the Exminds Universe?',
    areYouSureYouWantToLogOut: 'Are you sure you want to log out?',
    leaveTheProcess: 'Are you sure you want to leave the page?',
    dataWillBeLost: 'Any data will be lost',
    openApp: {
      title: 'Open App Required',
      description:
        'In order to continue, please open the app first. This step is necessary to ensure the best experience and full functionality.',
      action: 'Confirm',
    },
  },
};
