export default {
  news: {
    new_package_approved: {
      title: 'A apărut o nouă Experiență',
      subtitle:
        'Salut {{receiverFirstName}}, {{exminderName}} a publicat o nouă Experiență. Descoperă {{experienceName}}, care va avea loc în {{experienceLocation}} și este în domeniul {{experienceDomain}}.',
    },
    interested_in_experience: {
      title: 'Cineva nou este interesat de Experiența ta',
      title_secondary: 'Experiențele sunt pe cale să expire',
      subtitle:
        'Verifică cine dorește să cumpere Experiența ta și începe discuțiile despre datele disponibile odată ce Experiența este achiziționată!',
    },
    complete_profile: {
      title: 'Completează-ți informațiile profilului!',
      subtitle:
        'Explorerii vor să știe mai multe despre tine, înainte de a publica prima ta Experiență.',
    },
    package_sold: {
      title: 'Experiența ta "{{experienceTitle}}" a fost vândută! 💸',
      subtitle: '{{explorerName}} tocmai a cumpărat Experiența ta. Bravo! 😁',
    },
    package_bought: {
      title: 'Acum poți trimite mesaje lui {{exminderName}}!',
      subtitle:
        'Află mai multe despre Experiență punând mai multe întrebări sau orice îți place.',
    },
    package_canceled: {
      title: 'Experiența ta "{{experienceTitle}}" a fost anulată! ❌',
      subtitle:
        'Ne pare rău! {{exminderName}} tocmai a anulat Experiența ta 😔',
    },
    package_completed: {
      title:
        'Experiența ta recentă "{{experienceTitle}}" este completă! Lasă un review!',
      explorerSubtitle:
        'Spune-ne impresia ta despre Experiență și despre Exminderul {{exminderName}}!',
      exminderSubtitle:
        'Spune-ne impresia ta despre Explorerul {{explorerName}}!',
    },
    purchased_package_date_updated: {
      title: '{{exminderName}} a schimbat data Experienței!',
      subtitle:
        'Data Experienței recent achiziționate a fost schimbată chiar acum de Exminder!',
    },
    purchased_package_date_update_accepted: {
      title: '{{explorerName}} a acceptat actualizarea datei!',
      subtitle:
        'Schimbarea datei solicitată pentru Experiența ta a fost acceptată de Explorer!',
    },
    purchased_package_date_update_rejected: {
      title: '{{explorerName}} a respins actualizarea datei!',
      subtitle:
        'Schimbarea datei solicitată pentru Experiența ta a fost respinsă de Explorer.',
    },
    favorite_package_spot_freed: {
      title: 'Experiența pe care ai salvat-o are un loc disponibil!',
      subtitle:
        'Un loc pentru una dintre Experiențele tale favorite tocmai s-a eliberat! Explorează-o acum!',
    },
    package_payment_refunded: {
      title: 'Banii tăi au fost transferați în contul tău bancar',
      subtitle:
        'Fondurile din experiență au fost transferate cu succes în contul tău bancar. 💰',
    },
    package_7_days_before_start: {
      title: '7 zile până la Experiența ta "{{experienceTitle}}"',
      subtitle:
        'A mai rămas o săptămână până când experiența ta începe. Timp să te pregătești!',
    },
    package_1_day_before_start: {
      title: '1 zi până la Experiența ta "{{experienceTitle}}"',
      subtitle: 'Experiența ta este doar la 1 zi distanță. Pregătește-te!',
    },
    package_2_hours_before_start: {
      title: 'Mai puțin de 2 ore până la Experiența ta "{{experienceTitle}}"',
      subtitle:
        'Experiența ta are loc în 2 ore!!! Ești pregătit deja? Nu întârzia!',
    },
    join_package_request_created: {
      title: 'Cineva nou este interesat de Experiența ta "{{experienceTitle}}"',
      subtitle:
        'Verifică cine dorește să cumpere Experiența ta și începe discuțiile despre datele disponibile odată ce Experiența este achiziționată!',
    },
    join_package_request_accepted: {
      title: '{{exminderName}} a aprobat cererea ta',
      subtitle:
        'Experiența pe care ai solicitat-o este acum disponibilă și Exminderul este gata să te întâlnească! Vezi mai multe despre ea!',
    },
    join_package_request_rejected: {
      title: '{{exminderName}} a respins cererea ta',
      subtitle:
        'Experiența pe care ai solicitat-o nu este disponibilă acum. Vezi mai multe despre ea!',
    },
    pending_approval_package_accepted: {
      title: 'Experiența ta a fost aprobată!',
      subtitle:
        '"{{experienceName}}" a fost aprobată de echipa Exminds. Acum poate fi văzută de toți Explorerii!',
    },
    pending_approval_package_rejected: {
      title: 'Experiența ta a fost respinsă!',
      subtitle:
        '"{{experienceName}}" a fost respinsă de echipa Exminds. Asigură-te că Experiența respectă cerințele aplicației.',
    },
    packageReminder_2days: {
      title: 'Nu uitați să cumpărați Experiența!',
      subtitle:
        'Hei {{receiverFullName}}, iti aducem aminte că solicitarea ta pentru Experiența {{experienceName}} a fost acceptată de {{exminderName}}. Asigură-te că o cumperi înainte să fie prea târziu!',
    },
    packageReminder_3days: {
      title: 'Ești încă interesat?',
      subtitle:
        'Grăbește-te, {{receiverFullName}}, solicitarea ta pentru Experiența {{experienceName}} a fost acceptată de {{exminderName}}. Cumpăr-o înainte să fie prea târziu!',
    },
    packageReminder_lastDay: {
      title: 'Mai ai doar o zi să cumperi Experiența',
      subtitle:
        'Grăbește-te, {{receiverFullName}}, este ultima zi să cumperi Experiența {{experienceName}}. Cumpăr-o înainte să fie prea târziu!',
    },
  },
};
