export default {
  menuBar: {
    mindFeed: 'Mind Feed',
    notifications: 'Notifications',
    universe: 'Universe',
    myExperiences: 'My Experiences',
    favorites: 'Favorites',
    menu: 'Menu',
  },
};
