export default {
  toast: {
    account: {
      deleted: 'Your account has been deleted successfully',
      passwordReset: 'Your password has been changed successfully ',
      appLanguage: 'App language updated successfully',
    },
    payment: {
      unfinishedTransaction: 'You have an unfinished transaction',
      paymentMethodFailed: 'The payment method failed',
      findOutMore: 'Find out more about what happened',
      experienceOutOfStock:
        'This experience is no longer available, someone else purchased it before you!',
    },
    profile: {
      photoUploadSuccess:
        'Your new profile photo has been uploaded successfully',
      photoUploadError: 'Your new profile photo could not be uploaded',
    },
    experience: {
      activateSuccess: 'Your Experience status has been set to active',
      activateError: 'Your Experience status could not be set to active',
      purchaseDeadlineActivateError:
        'The purchase deadline of the Experience must be at least 2 hours from now ',
      draftSuccess: 'Your Experience status has been set to draft',
      draftError: 'Your Experience status could not be set to draft',
      editSuccess: 'Your Experience has been edited successfully',
      editError: 'Your Experience could not be edited',
      duplicateSuccess: 'Your Experience has been duplicated successfully',
      duplicateError: 'Your Experience could not be duplicated',
      cancelSuccess: 'Your Experience has been canceled successfully',
      cancelError: 'Your Experience could not be canceled',
      deleteSuccess: 'Your Experience has been deleted successfully',
      deleteError: 'Your Experience could not be deleted',
      inviteSuccess: 'Your invitation has been sent successfully',
    },
    experienceRequest: {
      request: 'A request was sent to the Exminder',
      accept: 'An acceptance notification was sent to the Explorer',
      reject: 'A denial notification was sent to the Explorer',
    },
    experienceReportSuccess: 'You have successfully reported this Experience',
    experienceReport: 'You have already reported this Experience',
    exminderReport: 'You have already reported this Exminder',
    phoneNumberAlreadyExists: 'This phone number already exists',
    error: 'An error has occurred. Please try again later.',
    tooManyEmailResendRequests: 'A resend request has already been sent',
    tooManyForgotPasswordRequests:
      'A reset confirmation email request has already been sent',
    tooManySmsCodeRequests: 'An SMS code request has already been sent',
    tooManyRequests: 'Too many requests have been sent',
    fileError: 'The file could not be uploaded',
    resendEmail:
      'We have resent an email with a confirmation link to your email address.',
    invalidCredentials: 'Invalid credentials. Please try again.',
    confirmationCode: 'A confirmation code has been sent.',
    charactersLimit:
      'Title must be shorter than or equal to 30 characters. Description must be shorter than or equal to 300 characters.',
    experienceLinkToClipboard: 'Experience link has been saved to clipboard',
    referralLinkToClipboard: 'Your referral link has been saved to clipboard',
    locationNotFoundMap:
      'This location can not be found. Please select a different one.',
    editBenefits: 'Benefits were successfully updated!',
    editRequirements: 'Requirements were successfully updated!',
    filesError: 'The image and video files could not be uploaded',
    imageError: 'The image could not be uploaded. Please try again',
    videoError: 'The video could not be uploaded. Please try again',
  },
};
