import { useLocation, useNavigate } from 'react-router-dom';

function useSetQueryParams() {
  const navigate = useNavigate();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);

  const navigateWithSearch = (
    route: string,
    queryParams: { key: string; value: string }[]
  ) => {
    queryParams.forEach(({ key, value }) => searchParams.set(key, value));

    navigate({
      pathname: route,
      search: searchParams.toString(),
    });
  };

  return { searchParams, navigateWithSearch };
}

export default useSetQueryParams;
