export default {
  buildProfile: {
    chooseRole: 'Selectează-ți rolul',
    whatIsAnExplorer: 'Ce este un Explorer?',
    whatIsAnExminder: 'Ce este un Exminder?',
    exminderDefinition:
      'Exminderii sunt profesioniștii care transformă experiența lor în lecții valoroase pentru alții.' +
      '\n\n' +
      'Ei sunt creatorii tuturor diferitelor Experiențe, lansează oportunități pentru cei dornici să învețe și îmbunătățesc lumea zilnic.\n',
    explorerDefinition:
      'Explorerii sunt cei care spun da fiecărei oportunități, în timp ce lucrează la procesul lor de autocunoaștere. Explorerii descoperă persoane noi de interes de la care să învețe, creează conexiuni și au parte de Experiențe unice din care evoluează.' +
      '\n\n' +
      'Descoperă noi persoane de la care să învețe, creează relații speciale și trăiesc Experiențe unice.',
    youAreAnExminder: 'Perfect! Ești Exminder.',
    youAreAnExplorer: 'Perfect! Ești Explorer.',
    tellUsMore: 'Spune-ne mai multe despre tine!',
    buildProfile: 'Construiește profil',
    profileIsSet: 'Misiune îndeplinită - Profilul tău este complet!',
    welcomeToExmindsCommunity:
      'Bine ai venit în Universul Exminds - Pregătește-te să trăiești realitatea la alt nivel!',
  },
};
