export default {
  afterExperience: {
    experienceHasEnded: 'Experiența ta a luat sfârșit!',
    wereYouHappy: 'Ești mulțumit de Experiența ce tocmai a avut loc?',
    no: 'NU!',
    yes: 'DA!',
    somethingWentWrong: 'Ceva nu a mers bine!',
    itWasAsExpected: 'A fost așa cum mă așteptam!',
    tellUsMore: 'Spune-ne mai multe!',
    whatWentWrong: 'Ce nu a mers bine în timpul Experienței?',
    optional: 'Opțional*',
    receivedMessage: 'Am primit mesajul tău!',
    thankYouForMessage: 'Mulțumim pentru mesajul tău!',
    assistantsWillRespond:
      'Unul dintre asistenții noștri va răspunde la solicitarea ta în termen de 3 zile.',
    weAreGlad: 'Ne bucurăm că Experiența a fost pe placul tău!',
    tellUseMoreAbout:
      'Lasă-ne mai multe detalii despre cum a decurs totul și despre Explorer/Exminder!',
    leaveAReview: 'Lasă o recenzie',
  },
};
