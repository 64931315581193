import { JSX, SVGProps } from 'react';

export default function BusinessIcon(
  props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>
) {
  return (
    <svg
      width="28"
      viewBox="0 0 31 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M26.2913 5.25H21.6663V3.70833C21.6663 2.89058 21.3415 2.10632 20.7633 1.52809C20.185 0.94985 19.4008 0.625 18.583 0.625H12.4163C11.5986 0.625 10.8143 0.94985 10.2361 1.52809C9.65786 2.10632 9.33301 2.89058 9.33301 3.70833V5.25H4.70801C3.48138 5.25 2.305 5.73728 1.43764 6.60463C0.570283 7.47199 0.0830078 8.64837 0.0830078 9.875V23.75C0.0830078 24.9766 0.570283 26.153 1.43764 27.0204C2.305 27.8877 3.48138 28.375 4.70801 28.375H26.2913C27.518 28.375 28.6944 27.8877 29.5617 27.0204C30.4291 26.153 30.9163 24.9766 30.9163 23.75V9.875C30.9163 8.64837 30.4291 7.47199 29.5617 6.60463C28.6944 5.73728 27.518 5.25 26.2913 5.25ZM12.4163 3.70833H18.583V5.25H12.4163V3.70833ZM27.833 23.75C27.833 24.1589 27.6706 24.551 27.3815 24.8401C27.0923 25.1292 26.7002 25.2917 26.2913 25.2917H4.70801C4.29913 25.2917 3.907 25.1292 3.61789 24.8401C3.32877 24.551 3.16634 24.1589 3.16634 23.75V16.0417H9.33301V17.5833C9.33301 17.9922 9.49543 18.3843 9.78455 18.6735C10.0737 18.9626 10.4658 19.125 10.8747 19.125C11.2836 19.125 11.6757 18.9626 11.9648 18.6735C12.2539 18.3843 12.4163 17.9922 12.4163 17.5833V16.0417H18.583V17.5833C18.583 17.9922 18.7454 18.3843 19.0346 18.6735C19.3237 18.9626 19.7158 19.125 20.1247 19.125C20.5336 19.125 20.9257 18.9626 21.2148 18.6735C21.5039 18.3843 21.6663 17.9922 21.6663 17.5833V16.0417H27.833V23.75ZM27.833 12.9583H3.16634V9.875C3.16634 9.46612 3.32877 9.074 3.61789 8.78488C3.907 8.49576 4.29913 8.33333 4.70801 8.33333H26.2913C26.7002 8.33333 27.0923 8.49576 27.3815 8.78488C27.6706 9.074 27.833 9.46612 27.833 9.875V12.9583Z"
        fill="white"
      />
    </svg>
  );
}
