import aboutTheApp from './aboutTheApp/en';
import acceptRejectExperience from './acceptRejectExperience/en';
import accountSettings from './accountSettings/en';
import addExperience from './addExperience/en';
import addProfilePicture from './addProfilePicture/en';
import additionalCosts from './additionalCosts/en';
import afterExperience from './afterExperience/en';
import domains from './domains/en';
import auth from './auth/en';
import awardModal from './awardModal/en';
import benefitsRequirements from './benefitsRequirments/en';
import buildProfile from './buildProfile/en';
import chat from './chat/en';
import chooseHobbies from './chooseHobbies/en';
import currency from './currency/en';
import deleteButton from './deleteButton/en';
import disclaimer from './disclaimer/en';
import educationModal from './educationModal/en';
import errors from './errors/en';
import exminderPackage from './exminderPackage/en';
import experience from './experience/en';
import experienceActions from './experienceActions/en';
import experienceChanges from './experienceChanges/en';
import experienceCreationLayout from './experienceCreation/en';
import experienceFeed from './experienceFeed/en';
import formValidations from './formValidations/en';
import general from './general/en';
import languages from './languages/en';
import menuBar from './menuBar/en';
import milestoneModal from './milestoneModal/en';
import mostPopularPeople from './mostPopularPeople/en';
import packageAddressSelection from './packageAddressSelection/en';
import passwordRequirements from './passwordRequirments/en';
import paymentFlow from './paymentFlow/en';
import pin from './pin/en';
import profile from './profile/en';
import review from './review/en';
import sidebarMenu from './sidebarMenu/en';
import stripeRegistration from './stripeRegistration/en';
import toast from './toast/en';
import user from './user/en';
import wallet from './wallet/en';
import welcome from './welcome/en';
import workExperienceModal from './workExperienceModal/en';
import yearsOfExperience from './yearsOfExperience/en';
import notifications from './notifications/en';
import auction from './auction/en';
import createProfile from './createProfile/en';
import favorites from './favorites/en';
import misc from './misc/en';
import news from './newsNotifications/en';
import dispute from './dispute/en';
import report from './report/en';
import placeholders from './placeholders/en';
import errorBoundary from './errorBoundary/en';
import faq from './faq/en';
import moneyWithExminds from './moneyWithExminds/en';
import appFeedback from './appFeedback/en';
import update from './update/en';
import experienceInviteGuest from './experienceInviteGuest/en';
import invoices from './invoices/en';
import filters from './filters/en';
import countdown from './countdown/en';
import referralDashboard from './referralDashboard/en';
import communityGuidelines from './communityGuidelines/en';

const en = {
  translations: {
    ...general,
    ...passwordRequirements,
    ...auth,
    ...formValidations,
    ...welcome,
    ...pin,
    ...domains,
    ...buildProfile,
    ...addProfilePicture,
    ...chooseHobbies,
    ...yearsOfExperience,
    ...aboutTheApp,
    ...mostPopularPeople,
    ...menuBar,
    ...paymentFlow,
    ...disclaimer,
    ...toast,
    ...exminderPackage,
    ...experienceFeed,
    ...acceptRejectExperience,
    ...benefitsRequirements,
    ...experienceCreationLayout,
    ...sidebarMenu,
    ...review,
    ...addExperience,
    ...user,
    ...profile,
    ...experienceActions,
    ...languages,
    ...workExperienceModal,
    ...educationModal,
    ...accountSettings,
    ...experience,
    ...additionalCosts,
    ...stripeRegistration,
    ...chat,
    ...wallet,
    ...currency,
    ...afterExperience,
    ...errors,
    ...deleteButton,
    ...experienceChanges,
    ...milestoneModal,
    ...awardModal,
    ...misc,
    ...createProfile,
    ...favorites,
    ...auction,
    ...packageAddressSelection,
    ...news,
    ...notifications,
    ...dispute,
    ...report,
    ...placeholders,
    ...errorBoundary,
    ...faq,
    ...moneyWithExminds,
    ...appFeedback,
    ...update,
    ...experienceInviteGuest,
    ...invoices,
    ...filters,
    ...countdown,
    ...referralDashboard,
    ...communityGuidelines,
  },
};

export default en;
