import { JSX, SVGProps } from 'react';

export default function ExperienceExpiredIcon(
  props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>
) {
  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.8783 9.49997H26.9166C27.3366 9.49997 27.7393 9.66678 28.0362 9.96372C28.3332 10.2606 28.5 10.6634 28.5 11.0833V21.1216C28.5 21.5416 28.6668 21.9443 28.9637 22.2412C29.2606 22.5382 29.6634 22.705 30.0833 22.705C30.5032 22.705 30.906 22.5382 31.2029 22.2412C31.4998 21.9443 31.6666 21.5416 31.6666 21.1216V11.0833C31.6666 9.82352 31.1662 8.61534 30.2754 7.72454C29.3846 6.83375 28.1764 6.3333 26.9166 6.3333H16.8783C16.4584 6.3333 16.0556 6.50012 15.7587 6.79705C15.4618 7.09398 15.295 7.49671 15.295 7.91664C15.295 8.33656 15.4618 8.73929 15.7587 9.03622C16.0556 9.33315 16.4584 9.49997 16.8783 9.49997ZM34.3741 32.1258L5.87413 3.6258C5.57599 3.32765 5.17161 3.16016 4.74997 3.16016C4.32832 3.16016 3.92395 3.32765 3.6258 3.6258C3.32765 3.92395 3.16016 4.32832 3.16016 4.74997C3.16016 5.17161 3.32765 5.57599 3.6258 5.87413L6.79247 9.0408C6.48942 9.67901 6.33255 10.3768 6.3333 11.0833V30.0833C6.33486 30.3959 6.42892 30.701 6.60361 30.9602C6.7783 31.2195 7.02582 31.4212 7.31497 31.54C7.50288 31.6286 7.70894 31.672 7.91664 31.6666C8.12501 31.6678 8.33158 31.6279 8.52449 31.5491C8.7174 31.4703 8.89286 31.3542 9.0408 31.2075L13.3158 26.9166H24.6841L32.1258 34.3741C32.273 34.5225 32.4481 34.6403 32.6411 34.7207C32.834 34.8011 33.0409 34.8425 33.25 34.8425C33.459 34.8425 33.6659 34.8011 33.8589 34.7207C34.0518 34.6403 34.2269 34.5225 34.3741 34.3741C34.5225 34.2269 34.6403 34.0518 34.7207 33.8589C34.8011 33.6659 34.8425 33.459 34.8425 33.25C34.8425 33.0409 34.8011 32.834 34.7207 32.6411C34.6403 32.4481 34.5225 32.273 34.3741 32.1258ZM12.6666 23.75C12.4583 23.7488 12.2517 23.7887 12.0588 23.8675C11.8659 23.9463 11.6904 24.0624 11.5425 24.2091L9.49997 26.2675V11.7325L21.5175 23.75H12.6666Z"
        fill="#898A8D"
      />
    </svg>
  );
}
