export default {
  experienceChanges: {
    seeExperience: 'See Experience',
    reject: 'Decline',
    accept: 'Accept',
    seeCalendar: 'See Calendar',
    goToMenu: 'Go to Menu',
    exminderView: {
      pendingApproval: 'Pending approval...',
      oneExplorerRejected:
        'One Explorer declined the new date of your Experience!',
      explorerRejected: 'Explorer declined the new date of your Experience!',
      explorersRejected: 'Explorers declined the new date of your Experience!',
      allExplorersRejected:
        'All Explorers declined the new date of your Experience!',
      oneExplorerApproved:
        'One Explorer approved the new date of your Experience!',
      explorerApproved: 'Explorer approved the new date of your Experience!',
      explorersApproved: 'Explorers approved the new date of your Experience!',
      allExplorersAccepted:
        'All Explorers approved the new date of your Experience!',
      youWillBeCharged: 'You will be charged a 3% fee out of your Bank Account',
      promoteYourExperience: 'Promote your Experience for new Explorers',
      youAreInLuck: 'You’re in luck!\nYou won’t be charged for this change!',
      seeFullList: 'See Full List',
      approved: 'Approved',
      rejected: 'Declined',
      updatedTheDateInYourCalendar: 'We’ve updated the date in your calendar!',
    },
    experienceDateChange: {
      changedTheDate: 'changed the date of the Experience you bought',
      oldDate: 'Old Date',
      newDate: 'New Date',
    },
    experienceDateChangeConfirmation: {
      areYouSure: 'Are you sure you want to change the date of the Experience?',
      youWillBeCharged:
        'You will be charged a 3% fee if the Explorers will not accept the change and cancel the Experience',
      byTickingThisBox: 'By ticking this box I accept the ',
    },
    experienceNewDateRejection: {
      experienceAutomaticallyCanceled:
        'Your Experience was automatically canceled due to the lack of answer',
      exminderWillBeNotified:
        'The Exminder will be notified about your decision',
      youRejectedTheNewDate: 'You declined the new date',
      yourCalendarWasUpdated: 'Your calendar was automatically updated!',
    },
    areYouSureYouWantToMissTheExperience:
      'Are you sure you want to miss the Experience?',
    otherExplorers:
      'Other Explorers might be taking your place and leave this opportunity to grow, behind!',
    exmindsWillRefund:
      'Exminds will refund you the cost of the Experience within 10 working days!',
    youRejectedTheExperience: 'You declined the Experience!',
    sorryToSeeYouGo: 'We’re sorry to see you go!',
    weAreSorry: 'We’re sorry but',
    canceledTheExperience: 'canceled the Experience',
  },
};
