export default {
  createProfile: {
    buildProfile: {
      youAreAnExminder: 'Perfect! Ești Exminder.',
      youAreAnExplorer: 'Perfect! Ești Explorer.',
      tellUsMore: 'Spune-ne mai multe despre tine!',
      buildProfile: 'Construiește profilul',
      jumpInApp: 'Intră în aplicație',
      skipStepModal: {
        title: 'Sări peste pas?',
        subtitle: {
          exminder:
            'Explorerii vor cumpăra Experiențe de la tine doar dacă știu mai multe despre trecutul tău!' +
            '\n\n' +
            'Câștigă mai multă credibilitate construindu-ți profilul și câștigând încrederea oamenilor',
          explorer:
            'Exminderii vor accepta să împărtășească Experiențele lor cu tine doar dacă știu mai multe despre trecutul tău!' +
            '\n\n' +
            'Câștigă mai multă credibilitate construindu-ți profilul și câștigând încrederea oamenilor',
        },
        doItLater: 'O voi face mai târziu',
      },
    },
    onBoardingSkippedModal: {
      title: 'Continuă Onboarding-ul',
      subtitle:
        'Pentru a accesa mai mult din Universul Exminds este necesar să îți creezi un profil.',
      createProfile: 'Creează profil',
    },
    onboardingChat: {
      firstNameStep: {
        welcomeToExminds: 'Bine ai venit în Universul Exminds!',
        inOrderToJoin:
          'Creează un profil care să reflecte cine ești cu adevărat. În așa fel toate interacțiunile tale viitoare cu ceilalți utilizatori vor fi mereu la cel mai înalt nivel.' +
          '\n\nHai să începem!',
        whatIsYourFirstName: 'Care este prenumele tău?',
      },
      lastNameStep: {
        whatIsYourLastName: 'Care este numele tău de familie, ',
      },
      dateOfBirthStep: {
        whatIsYourDateOfBirth: 'Care este data ta de naștere?',
        disclaimer:
          'Solicităm aceste informații doar pentru a confirma că îndeplinești cerința de vârstă pentru aplicația noastră și deții permisiunile legale necesare.',
      },
      nativeLanguageStep: {
        whatIsYourNativeLanguage: 'Care este limba ta maternă?',
        disclaimer:
          'Limba maternă ne ajută să îți arătăm persoane din medii similare.',
      },
      otherLanguagesStep: {
        whatOtherLanguagesDoYouSpeak: 'Ce alte limbi vorbești?',
        disclaimer:
          'Împărtășește cu noi și ce alte limbi cunoști pentru a îți crea un profil complet.',
      },
      locationStep: {
        whichCountryAreYouLocatedIn: 'În ce țară te afli?',
        disclaimer:
          'Pentru a facilita conexiuni cu persoane care sunt aproape de tine sau care au medii similare, te rugăm să furnizezi țara în care te afli în prezent.',
      },
      cityStep: {
        whichCityAreYouLocatedIn: 'În ce oraș te afli?',
        disclaimer:
          'Pentru a optimiza Experiența ta și a te conecta cu persoane apropiate de tine, te rugăm să furnizezi numele orașului tău actual.',
      },
      mobileNumberStep: {
        oneLastQuestion: 'O ultimă întrebare...',
        whatIsYourMobileNumber: 'Care este numărul tău de telefon mobil?',
        mobileNumberSkipped: 'Număr de telefon omis',
        neverExpose:
          'Nu-ți face griji, nu vom expune numărul tău nimănui, decât dacă dorești tu.',
        amazing: 'Minunat!\nAcum să aflăm mai multe despre pasiunile tale.',
        disclaimer:
          'De asemenea, măsurile de verificare ulterioare pot solicita numărul tău de telefon.',
      },
    },
  },
};
