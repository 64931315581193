export default {
  aboutTheApp: {
    aboutTheAppIntro: {
      title: 'Welcome to the new dimension of spending time!',
      subtitle:
        'Connect with people of your interest and evolve by sharing incredible Experiences with them.',
    },
    aboutTheAppFirst: {
      title:
        'Exminds is a real Experience-Sharing platform meant to help people value their time.',
      subtitle:
        'Here you can turn your life Experience into lessons for others, or you can gain valuable knowledge from people you look up to.\n\nConnect online. Experience offline.',
    },
    aboutTheAppSecond: {
      title: 'EXMINDER',
      subtitle:
        'Is it the right time to share your wisdom with others? \n' +
        '\n' +
        'Tailor Experience packages that could change other people’s life, share your skills while leading the way and transform your time into value.',
    },
    aboutTheAppThird: {
      title: 'EXPLORER',
      subtitle:
        'How cool would it be to learn anything you want from anyone on the planet? \n' +
        '\n' +
        'Shake hands with people you have always wanted to meet, live transformative Experiences and build your own reality.',
    },
    aboutTheAppFourth: {
      title: 'YOU CAN UPGRADE',
      subtitle:
        'From an apprentice Explorer to a master Exminder, you can upgrade anytime you are ready to share your knowledge with the world. \n' +
        '\n' +
        'Exminds is all about evolution. The time to grow is now.',
    },
    aboutTheAppFifth: {
      title: 'Those who Experience together, stay together',
      subtitle:
        'The Exminds revolution works like this: the Exminder creates the Experience package, the Explorer chooses it and… magic happens. \n' +
        '\n' +
        "It's time for a real life Experience!",
    },
    aboutTheAppSixth: {
      title: 'The rest is...Reality!',
      subtitle:
        'Bring practice into your daily life and enjoy your journey in the Exminds Universe!\n' +
        '\n' +
        'Embrace the greatness that lies ahead!',
    },
    aboutTheAppSeventh: {
      title: 'MONTHLY SUBSCRIPTIONS',
      subtitle:
        'You can subscribe for a one month trial, but we guarantee that you won’t want to leave this place after.',
    },
  },
};
