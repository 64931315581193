import { JSX, SVGProps } from 'react';

export default function ExperienceCompletedIcon(
  props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>
) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M33.1498 15.9168C33.0113 15.642 32.7994 15.4108 32.5375 15.249C32.2757 15.0872 31.9742 15.0011 31.6664 15.0002H23.3331V5.00015C23.351 4.63463 23.2481 4.27337 23.0402 3.97215C22.8324 3.67093 22.5312 3.4465 22.1831 3.33349C21.8485 3.22339 21.4876 3.22215 21.1522 3.32995C20.8168 3.43775 20.5242 3.64904 20.3164 3.93349L6.9831 22.2668C6.81605 22.5083 6.71573 22.7896 6.69232 23.0822C6.66891 23.3749 6.72322 23.6686 6.84977 23.9335C6.96631 24.2364 7.16875 24.4987 7.43225 24.6882C7.69575 24.8777 8.00885 24.9861 8.3331 25.0002H16.6664V35.0002C16.6667 35.3516 16.7781 35.694 16.9846 35.9784C17.1911 36.2627 17.4823 36.4745 17.8164 36.5835C17.9839 36.6354 18.1578 36.6634 18.3331 36.6668C18.5961 36.6675 18.8555 36.6059 19.0901 36.4872C19.3247 36.3684 19.5279 36.1958 19.6831 35.9835L33.0164 17.6502C33.196 17.4015 33.3035 17.1081 33.327 16.8022C33.3505 16.4964 33.2892 16.19 33.1498 15.9168ZM19.9998 29.8668V23.3335C19.9998 22.8915 19.8242 22.4675 19.5116 22.155C19.1991 21.8424 18.7751 21.6668 18.3331 21.6668H11.6664L19.9998 10.1335V16.6668C19.9998 17.1088 20.1754 17.5328 20.4879 17.8453C20.8005 18.1579 21.2244 18.3335 21.6664 18.3335H28.3331L19.9998 29.8668Z"
        fill="#00AFB9"
      />
    </svg>
  );
}
