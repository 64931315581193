import { ReactNode } from 'react';
import * as SC from './styles';
import ButtonWrapper from '../../../atomics/ButtonWrapper';
import theme from '../../../../../styles/theme/classic';

export interface SideBarCardProps {
  icon: ReactNode;
  text: string;
  iconColor?: string;
  $iconSize?: {
    width?: string;
    height?: string;
  };
  extendedBoldText?: string;
  handleClick?: CallbackFunction;
  disabled?: boolean;
}

export default function SideBarCard({
  icon,
  text,
  iconColor = theme.palette.main.marine,
  $iconSize = { width: '25px', height: '25px' },
  extendedBoldText = '',
  handleClick,
  disabled = false,
}: SideBarCardProps) {
  return (
    <ButtonWrapper
      onClick={() => {
        if (!disabled && handleClick) {
          handleClick();
        }
      }}
      disabled={disabled}
    >
      <SC.Container>
        <SC.MainContent>
          <SC.IconContainer $iconColor={iconColor} $iconSize={$iconSize}>
            {icon}
          </SC.IconContainer>
          <SC.TextContainer>
            <SC.Text>{text}</SC.Text>
            {extendedBoldText && <SC.Text>-</SC.Text>}
            <SC.BoldText>{extendedBoldText}</SC.BoldText>
          </SC.TextContainer>
        </SC.MainContent>
      </SC.Container>
    </ButtonWrapper>
  );
}
