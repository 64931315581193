import styled from 'styled-components';
import { Field } from 'formik';

export const Container = styled.div`
  width: 100%;
  height: 58px;
  position: relative;
`;

export const ReferenceContainer = styled.div`
  width: 100%;
  height: 58px;
  position: relative;
  z-index: 4;

  background: ${({ theme }) => theme.palette.main.white};
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.05);
  border-radius: 43px;
`;

export const ReferenceInsideBorder = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  ::before {
    content: '';
    position: absolute;
    z-index: -1;
    inset: 0;
    border-radius: 40px;
    border: 1px solid transparent;
    background: linear-gradient(
        87.33deg,
        rgba(0, 175, 185, 0.2) 21.62%,
        rgba(0, 175, 185, 0) 100%
      )
      border-box;
    mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
`;

export const StyledInput = styled(Field)`
  width: 100%;
  height: 58px;

  padding: 20px !important;
  background: transparent;
  border: none;

  font-family: ${({ theme }) => theme.typography.fontFamily};
  color: ${({ theme }) => `${theme.palette.main.darkGray}`};
  font-weight: 600;
  line-height: 18px;
  text-align: left;

  ::placeholder {
    font-family: ${({ theme }) => theme.typography.fontFamily};
    color: ${({ theme }) => `${theme.palette.main.darkGray}33`};
    font-weight: 500;
    line-height: 18px;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    font-family: ${({ theme }) => theme.typography.fontFamily};
    color: ${({ theme }) => `${theme.palette.main.darkGray}33`};
    font-weight: 500;
    line-height: 18px;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    font-family: ${({ theme }) => theme.typography.fontFamily};
    color: ${({ theme }) => `${theme.palette.main.darkGray}33`};
    font-weight: 500;
    line-height: 18px;
  }

  -webkit-appearance: none;

  &:focus {
    outline: none;
  }

  &:disabled {
    opacity: 0.5;
  }
`;
