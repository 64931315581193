export default {
  acceptRejectExperience: {
    congratulations: 'Felicitări!',
    youWillEarn: 'Vei câștiga',
    interestedInYourExperience: 'este interesat/ă de Experiența ta',
    purchasedYourExperience: 'este interesat/ă să cumpere Experiența ta',
    youReceived: 'Ai primit',
    youEarned: 'Luna aceasta ai câștigat',
    ohNo: 'Oh, nu!',
    returnedYourExperience: 'a returnat Experiența ta',
    seeWhatWentWrong: 'Vezi ce a mers prost\nÎncepe chat',
  },
};
