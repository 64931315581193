export default {
  filters: {
    experienceType: 'Tipul experienței',
    priceRange: 'Intervalul de preț',
    currency: 'Monedă',
    country: 'Țară',
    city: 'Oraș',
    spokenLanguage: 'Limba vorbită',
    domain: 'Domeniu',
    minimumPrice: 'Preț minim',
    maximumPrice: 'Preț maxim',
  },
};
