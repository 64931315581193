import { JSX, SVGProps } from 'react';

export default function AllDomainsIcon(
  props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>
) {
  return (
    <svg
      width="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M23.75 2.5H11.25C10.2554 2.5 9.30161 2.89509 8.59835 3.59835C7.89509 4.30161 7.5 5.25544 7.5 6.25V7.5H6.25C5.25544 7.5 4.30161 7.89509 3.59835 8.59835C2.89509 9.30161 2.5 10.2554 2.5 11.25V23.75C2.5 24.7446 2.89509 25.6984 3.59835 26.4017C4.30161 27.1049 5.25544 27.5 6.25 27.5H18.75C19.7446 27.5 20.6984 27.1049 21.4017 26.4017C22.1049 25.6984 22.5 24.7446 22.5 23.75V22.5H23.75C24.7446 22.5 25.6984 22.1049 26.4017 21.4017C27.1049 20.6984 27.5 19.7446 27.5 18.75V6.25C27.5 5.25544 27.1049 4.30161 26.4017 3.59835C25.6984 2.89509 24.7446 2.5 23.75 2.5ZM20 23.75C20 24.0815 19.8683 24.3995 19.6339 24.6339C19.3995 24.8683 19.0815 25 18.75 25H6.25C5.91848 25 5.60054 24.8683 5.36612 24.6339C5.1317 24.3995 5 24.0815 5 23.75V15H20V23.75ZM20 12.5H5V11.25C5 10.9185 5.1317 10.6005 5.36612 10.3661C5.60054 10.1317 5.91848 10 6.25 10H18.75C19.0815 10 19.3995 10.1317 19.6339 10.3661C19.8683 10.6005 20 10.9185 20 11.25V12.5ZM25 18.75C25 19.0815 24.8683 19.3995 24.6339 19.6339C24.3995 19.8683 24.0815 20 23.75 20H22.5V11.25C22.4966 10.8236 22.4205 10.4008 22.275 10H25V18.75ZM25 7.5H10V6.25C10 5.91848 10.1317 5.60054 10.3661 5.36612C10.6005 5.1317 10.9185 5 11.25 5H23.75C24.0815 5 24.3995 5.1317 24.6339 5.36612C24.8683 5.60054 25 5.91848 25 6.25V7.5Z"
        fill="#0082AA"
      />
    </svg>
  );
}
