import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AppRole } from 'api/types/user';
import ExminderViewClientStore from 'clientStore/ExminderViewClientStore';

export const setAppRole = createAsyncThunk(
  'role/set_role',
  (roleView: string) => {
    ExminderViewClientStore.setExminderView(
      roleView === AppRole.ContentCreator
    );
    return roleView;
  }
);

export interface ViewAppRole {
  role: string;
}

const initialState: ViewAppRole = {
  role: '',
};

const appRoleSlice = createSlice({
  name: 'appRole',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setAppRole.fulfilled, (state, action) => {
      state.role = action.payload;
    });
  },
});

export default appRoleSlice.reducer;
