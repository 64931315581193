// @ts-strict-ignore
import AbstractRequest from './AbstractRequest';
import { ApiMethod } from './constants';
import { ApiResponseExminderCv } from './types/cv';

export interface ApiRequestAddWorkExperience {
  title: string;
  industry: string;
  company: string;
  startDate: string;
  endDate: string | null;
  description: string;
  cityId: string;
}

export interface ApiRequestAddEducation {
  school: string;
  degree: string;
  fieldOfStudy: string;
  startDate: string;
  endDate: string | null;
  description: string;
  grade: string;
  cityId: string;
}

export interface ApiRequestAddMilestone {
  name: string;
  displayName: string;
  description: string;
  date: string;
  link: string;
  presentationPhotoId?: string;
}

interface ApiRequestAddAward {
  name: string;
  place: string;
  description: string;
  date: string;
  link: string;
  presentationPhotoId?: string;
}

export class CvRequest extends AbstractRequest {
  get apiResource(): string {
    return 'cv';
  }

  get dynamicIds(): Record<string, string> {
    return {
      id: '{id}',
    };
  }

  get routes(): Record<string, string> {
    return {
      GET_CV_DATA: `${this.apiRoute}/by-content-creator-id/${this.dynamicIds.id}`,
      ADD_WORK_EXPERIENCE: `${this.apiRoute}/work-experience`,
      UPDATE_WORK_EXPERIENCE: `${this.apiRoute}/work-experience/${this.dynamicIds.id}`,
      DELETE_WORK_EXPERIENCE: `${this.apiRoute}/work-experience/${this.dynamicIds.id}`,
      ADD_EDUCATION: `${this.apiRoute}/education`,
      UPDATE_EDUCATION: `${this.apiRoute}/education/${this.dynamicIds.id}`,
      DELETE_EDUCATION: `${this.apiRoute}/education/${this.dynamicIds.id}`,
      ADD_MILESTONE: `${this.apiRoute}/milestone`,
      UPDATE_MILESTONE: `${this.apiRoute}/milestone/${this.dynamicIds.id}`,
      DELETE_MILESTONE: `${this.apiRoute}/milestone/${this.dynamicIds.id}`,
      ADD_AWARD: `${this.apiRoute}/award`,
      UPDATE_AWARD: `${this.apiRoute}/award/${this.dynamicIds.id}`,
      DELETE_AWARD: `${this.apiRoute}/award/${this.dynamicIds.id}`,
    };
  }

  async getCvData(id: string): Promise<ApiResponseExminderCv> {
    const result = await this.request(
      ApiMethod.GET,
      this.routes.GET_CV_DATA!.replace(this.dynamicIds.id!, id)
    );

    return result.data;
  }

  async addWorkExperience(workExperience: ApiRequestAddWorkExperience) {
    const result = await this.request(
      ApiMethod.POST,
      this.routes.ADD_WORK_EXPERIENCE!,
      workExperience
    );

    return result.data;
  }

  async updateWorkExperience(
    id: string,
    workExperience: ApiRequestAddWorkExperience
  ) {
    const result = await this.request(
      ApiMethod.PATCH,
      this.routes.UPDATE_WORK_EXPERIENCE!.replace(this.dynamicIds.id!, id),
      workExperience
    );

    return result.data;
  }

  async deleteWorkExperience(id: string) {
    const result = await this.request(
      ApiMethod.DELETE,
      this.routes.DELETE_WORK_EXPERIENCE!.replace(this.dynamicIds.id!, id)
    );

    return result.data;
  }

  async addEducation(education: ApiRequestAddEducation) {
    const result = await this.request(
      ApiMethod.POST,
      this.routes.ADD_EDUCATION!,
      education
    );

    return result.data;
  }

  async updateEducation(id: string, education: ApiRequestAddEducation) {
    const result = await this.request(
      ApiMethod.PATCH,
      this.routes.UPDATE_EDUCATION!.replace(this.dynamicIds.id!, id),
      education
    );

    return result.data;
  }

  async deleteEducation(id: string) {
    const result = await this.request(
      ApiMethod.DELETE,
      this.routes.DELETE_EDUCATION!.replace(this.dynamicIds.id!, id)
    );

    return result.data;
  }

  async addMilestone(milestone: ApiRequestAddMilestone) {
    const result = await this.request(
      ApiMethod.POST,
      this.routes.ADD_MILESTONE!,
      milestone
    );

    return result.data;
  }

  async updateMilestone(id: string, milestone: ApiRequestAddMilestone) {
    const result = await this.request(
      ApiMethod.PATCH,
      this.routes.UPDATE_MILESTONE!.replace(this.dynamicIds.id!, id),
      milestone
    );

    return result.data;
  }

  async deleteMilestone(id: string) {
    const result = await this.request(
      ApiMethod.DELETE,
      this.routes.DELETE_MILESTONE!.replace(this.dynamicIds.id!, id)
    );

    return result.data;
  }

  async addAward(award: ApiRequestAddAward) {
    const result = await this.request(
      ApiMethod.POST,
      this.routes.ADD_AWARD!,
      award
    );

    return result.data;
  }

  async updateAward(id: string, award: ApiRequestAddAward) {
    const result = await this.request(
      ApiMethod.PATCH,
      this.routes.UPDATE_AWARD!.replace(this.dynamicIds.id!, id),
      award
    );

    return result.data;
  }

  async deleteAward(id: string) {
    const result = await this.request(
      ApiMethod.DELETE,
      this.routes.DELETE_AWARD!.replace(this.dynamicIds.id!, id)
    );

    return result.data;
  }
}

export const cvRequest = new CvRequest();
