import { useTranslation } from 'react-i18next';
import { ButtonHTMLAttributes } from 'react';
import * as SC from './styles';
import { ExperienceType } from '../../../../../../helpers/constants/enums';
import HandsOnIcon from '../../../../../../assets/icons/experience/details/HandsOnIcon';
import ObserverIcon from '../../../../../../assets/icons/experience/details/ObserverIcon';

interface ExperienceTypeButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  experienceType: ExperienceType;
  isActive: boolean;
  onClick: CallbackFunction;
}

export default function ExperienceTypeButton({
  experienceType,
  isActive,
  onClick,
}: ExperienceTypeButtonProps) {
  const { t } = useTranslation();

  return (
    <SC.Container onClick={onClick}>
      <SC.ReferenceContainer $isActive={isActive}>
        <SC.ReferenceInsideBorder>
          {experienceType === ExperienceType.HANDS_ON ? (
            <SC.HandsOnIconContainer $isActive={isActive}>
              <HandsOnIcon />
            </SC.HandsOnIconContainer>
          ) : (
            <SC.ObserverIconContainer $isActive={isActive}>
              <ObserverIcon />
            </SC.ObserverIconContainer>
          )}
          <SC.Text $isActive={isActive}>
            {experienceType === ExperienceType.HANDS_ON
              ? t(`experience.type.handsOn`)
              : t(`experience.type.observer`)}
          </SC.Text>
        </SC.ReferenceInsideBorder>
      </SC.ReferenceContainer>
    </SC.Container>
  );
}
