import { lazy } from 'react';
import PATH from './paths';
import { Route } from './types';

const ConnectedMindFeedPage = lazy(
  () => import('../components/smart/pages/mindFeed/ConnectedMindFeedPage')
);

const MIND_FEED: Route = {
  component: ConnectedMindFeedPage,
  path: PATH.MIND_FEED,
  isProtected: true,
  hasMenuBar: true,
};

export default [MIND_FEED];
