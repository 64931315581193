export default {
  moneyWithExminds: {
    title: 'Bani prin Exminds',
    description_first_1: 'Ca Exminder, ',
    description_first_bold:
      'poți lista oricâte pachete de Experiențe Permanente și Unice dorești',
    description_first_2:
      ', fiecare dintre ele reușind să pună în valoare părți distincte ale abilităților tale.\n\n',
    description_second: 'Exminds nu va prelua nicio taxă anticipată, însă, ',
    description_second_bold:
      'ca Exminder, când pachetul tău este cumpărat, un comision de 24% din prețul său este redirecționat către aplicație.\n\n',
    description:
      'Așadar, sfatul nostru este să setezi prețul Experienței în funcție de nevoile tale.' +
      '\n\n' +
      'Acest lucru ne asigură că putem mereu să îți oferim Experiențe de înaltă calitate.' +
      '\n\n' +
      'La cât mai multe Experiențe împreună!',
  },
};
