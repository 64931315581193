import { JSX, SVGProps } from 'react';

export default function MenuIcon(
  props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>
) {
  return (
    <svg
      width="27"
      viewBox="0 0 30 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.2557 15.476C21.6446 14.4154 22.6584 12.961 23.156 11.3152C23.6536 9.66932 23.6103 7.91386 23.0321 6.29301C22.4539 4.67217 21.3696 3.26653 19.93 2.27167C18.4904 1.27681 16.7671 0.742188 14.9999 0.742188C13.2327 0.742187 11.5094 1.27681 10.0698 2.27167C8.63018 3.26653 7.54586 4.67217 6.96767 6.29301C6.38949 7.91386 6.34618 9.66932 6.84379 11.3152C7.34139 12.961 8.35515 14.4154 9.74404 15.476C7.36415 16.4014 5.28761 17.9363 3.7358 19.9171C2.184 21.8979 1.21509 24.2503 0.932373 26.7235C0.911909 26.9041 0.928288 27.0868 0.980576 27.2612C1.03286 27.4356 1.12004 27.5983 1.23711 27.7401C1.47357 28.0263 1.81748 28.2096 2.19321 28.2497C2.56893 28.2899 2.94568 28.1835 3.24058 27.954C3.53549 27.7245 3.72438 27.3907 3.76571 27.026C4.07679 24.3381 5.39726 21.8557 7.47485 20.0531C9.55244 18.2504 12.2415 17.2539 15.0282 17.2539C17.8149 17.2539 20.504 18.2504 22.5816 20.0531C24.6592 21.8557 25.9796 24.3381 26.2907 27.026C26.3292 27.3639 26.4953 27.6759 26.7569 27.9019C27.0186 28.1279 27.3572 28.2518 27.7074 28.2497H27.8632C28.2346 28.2083 28.574 28.026 28.8075 27.7427C29.041 27.4594 29.1497 27.098 29.1099 26.7372C28.8258 24.257 27.8517 21.8986 26.2919 19.9148C24.7321 17.9309 22.6455 16.3965 20.2557 15.476V15.476ZM14.9999 14.4997C13.8791 14.4997 12.7835 14.1772 11.8516 13.5728C10.9198 12.9685 10.1935 12.1095 9.76456 11.1045C9.33566 10.0995 9.22344 8.99364 9.44209 7.92675C9.66074 6.85985 10.2004 5.87985 10.9929 5.11066C11.7854 4.34147 12.7951 3.81764 13.8944 3.60543C14.9936 3.39321 16.133 3.50212 17.1684 3.91841C18.2039 4.33469 19.0889 5.03964 19.7115 5.94411C20.3342 6.84858 20.6665 7.91195 20.6665 8.99974C20.6665 10.4584 20.0695 11.8574 19.0068 12.8888C17.9441 13.9203 16.5028 14.4997 14.9999 14.4997Z"
        fill="#0082AA"
      />
    </svg>
  );
}
