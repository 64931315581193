import { RefObject, useEffect, useState } from 'react';

function useOnEndReached(ref?: RefObject<HTMLDivElement>): boolean {
  const [endReached, setEndReached] = useState<boolean>(false);

  useEffect(() => {
    const handleScroll = () => {
      if (ref && ref.current) {
        const { scrollHeight, scrollTop, clientHeight } = ref.current;
        if (scrollTop + clientHeight >= scrollHeight - 1) {
          setEndReached(true);
        } else {
          setEndReached(false);
        }
      } else if (
        window.innerHeight + window.scrollY >=
        document.body.scrollHeight - 1
      ) {
        setEndReached(true);
      } else {
        setEndReached(false);
      }
    };

    const scrollElement = ref?.current || window;

    scrollElement.addEventListener('scroll', handleScroll);
    return () => {
      scrollElement.removeEventListener('scroll', handleScroll);
    };
  }, [ref]);

  return endReached;
}

export default useOnEndReached;
