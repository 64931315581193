import AbstractRequest from './AbstractRequest';
import { ApiMethod } from './constants';
import {
  ApiResponseGetCitiesByCountryId,
  ApiResponseGetCountries,
} from './types/country';

export class CountryRequest extends AbstractRequest {
  get apiResource(): string {
    return 'country';
  }

  get dynamicIds(): Record<string, string> {
    return {
      countryId: '{countryId}',
    };
  }

  get routes(): Record<string, string> {
    return {
      GET_COUNTRIES: `${this.apiRoute}`,
      GET_CITIES_BY_COUNTRY_ID: `${this.apiRoute}/city/${this.dynamicIds.countryId}`,
    };
  }

  async getCountries(): Promise<ApiResponseGetCountries[]> {
    const result = await this.request(
      ApiMethod.GET,
      this.routes.GET_COUNTRIES!
    );

    return result.data;
  }

  async getCitiesByCountryId(queryOptions: {
    id: string;
    offset: number;
    limit: number;
    searchText?: string;
  }): Promise<ApiResponseGetCitiesByCountryId[]> {
    const queryParams = [
      { key: 'limit', value: `${queryOptions.limit}` },
      { key: 'offset', value: `${queryOptions.offset}` },
    ];
    if (queryOptions.searchText) {
      queryParams.push({
        key: 'searchText',
        value: `${queryOptions.searchText}`,
      });
    }

    const result = await this.request(
      ApiMethod.GET,
      this.routes.GET_CITIES_BY_COUNTRY_ID!.replace(
        this.dynamicIds.countryId!,
        queryOptions.id
      ) + this.buildQueryParams(queryParams)
    );

    return result.data;
  }
}

export const countryRequest = new CountryRequest();
