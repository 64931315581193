import { useInfiniteQuery } from '@tanstack/react-query';
import { fileRequest } from 'api/FileRequest';
import { ApiResponsePackage, packageRequest } from 'api/PackageRequest';

function useGetExperiences(
  queryKey: string,
  queryOptions: {
    limit: number;
    domain?: string;
    type?: string;
    minPrice?: number;
    maxPrice?: number;
    currency?: string;
    cityId?: string;
    languageId?: string;
    searchText?: string;
  }
) {
  const QUERY_KEY = [queryKey, queryOptions];
  const {
    limit,
    domain,
    type,
    minPrice,
    maxPrice,
    currency,
    cityId,
    languageId,
    searchText,
  } = queryOptions;
  const fetchExperiences = async ({ pageParam }: any) =>
    packageRequest.getPackages({
      limit,
      offset: pageParam,
      domain,
      type,
      minPrice,
      maxPrice,
      currency,
      cityId,
      languageId,
      searchText,
    });

  return useInfiniteQuery({
    queryKey: QUERY_KEY,
    queryFn: fetchExperiences,
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (
        lastPage.results.length === 0 ||
        allPages.length * limit >= lastPage.total ||
        lastPage.total <= limit
      ) {
        return undefined;
      }
      return lastPageParam + limit;
    },
    select: (data) => ({
      pages: data.pages
        .flatMap((res) => res.results)
        .map((element: ApiResponsePackage) => ({
          ...element,
          presentationPhoto: fileRequest.getPublicUrl(
            element.presentationPhoto
          ),
          presentationVideo: fileRequest.getPublicUrl(
            element.presentationVideo
          ),
          city: element.city.name,
          language: element.language.name,
          domain: element.domain.name,
          consumersCount: element.consumers.length,
          isPermanent: element.isPermanent,
          exminder: {
            id: element.contentCreator.id,
            firstName: element.contentCreator.firstName,
            lastName: element.contentCreator.lastName,
            profilePicture: fileRequest.getPublicUrl(
              element.contentCreator.profilePicture
            ),
            workExperience: element.contentCreator.cv?.workExperiences.map(
              (workExperience: any) => ({
                companyName: workExperience.company,
                companyRole: workExperience.title,
                startDate: workExperience.startDate,
              })
            ),
          },
        })),
      pageParams: data.pageParams,
    }),
  });
}
export default useGetExperiences;
