export default {
  favorites: {
    lastDay: 'Ultima zi',
    last7Days: 'Ultimele 7 zile',
    last30Days: 'Ultimele 30 de zile',
    last90days: 'Ultimele 90 de zile',
    all: 'Toate',
    favorites: 'Favorite',
    count_one: 'Ai {{count}} Experiență salvată ca favorită',
    count_none: 'Nu ai Experiențe salvate ca favorite',
    count: 'Ai {{count}} Experiențe salvate ca favorite',
    seeMoreToChoose:
      'Explorează Universul Exminds pentru a le alege pe cele mai potrivite pentru tine',
  },
};
