import { JSX, SVGProps } from 'react';

export default function PlusIcon(
  props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>
) {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.3335 9.66634H12.3335V1.66634C12.3335 1.31272 12.193 0.973581 11.943 0.723532C11.6929 0.473484 11.3538 0.333008 11.0002 0.333008C10.6465 0.333008 10.3074 0.473484 10.0574 0.723532C9.80731 0.973581 9.66683 1.31272 9.66683 1.66634V9.66634H1.66683C1.31321 9.66634 0.974069 9.80682 0.724021 10.0569C0.473972 10.3069 0.333496 10.6461 0.333496 10.9997C0.333496 11.3533 0.473972 11.6924 0.724021 11.9425C0.974069 12.1925 1.31321 12.333 1.66683 12.333H9.66683V20.333C9.66683 20.6866 9.80731 21.0258 10.0574 21.2758C10.3074 21.5259 10.6465 21.6663 11.0002 21.6663C11.3538 21.6663 11.6929 21.5259 11.943 21.2758C12.193 21.0258 12.3335 20.6866 12.3335 20.333V12.333H20.3335C20.6871 12.333 21.0263 12.1925 21.2763 11.9425C21.5264 11.6924 21.6668 11.3533 21.6668 10.9997C21.6668 10.6461 21.5264 10.3069 21.2763 10.0569C21.0263 9.80682 20.6871 9.66634 20.3335 9.66634Z"
        fill="white"
      />
    </svg>
  );
}
